import React from "react";

import * as table from "@components/Table/style.scss";

import { IDataItemProps, SortObject } from "./types";

const sortTypes: { [key: string]: SortObject } = {
    up: {
        string: (a: string, b: string) => {
            if (isSize(a) && isSize(b)) {
                return compareSizes(a, b);
            }

            return a.localeCompare(b);
        },
        number: (a: number, b: number) => a - b,
    },
    down: {
        string: (a: string, b: string) => {
            if (isSize(a) && isSize(b)) {
                return compareSizes(b, a);
            }

            return b.localeCompare(a);
        },
        number: (a: number, b: number) => b - a,
    },
};

function compare(a: any, b: any, type: string) {
    if (typeof a === "string") {
        return sortTypes[type].string(a, b);
    }
    if (typeof a === "number") return sortTypes[type].number(a, b);
    if (typeof a === "object" && typeof a.sortColumn === "string")
        return sortTypes[type].string(a.sortColumn, b.sortColumn);
    if (typeof a === "object" && typeof a.sortColumn === "number")
        return sortTypes[type].number(a.sortColumn, b.sortColumn);
    return 0;
}

const getHeader = (str: string) => {
    if (!str) return "";
    if (str === "№") return "n";
    return str.replace(" ", "_").toLowerCase();
};

const DataItem: React.FC<IDataItemProps> = ({ date, time }) =>
    date ? (
        <>
            <div className={table.dateItem}>
                {date}, {time}
            </div>
        </>
    ) : (
        <div />
    );

const isSize = (str: string) => {
    const regex = /(Gb|Mb|Kb|B)$/gm;
    return str.match(regex);
};
const compareSizes = (a: string, b: string) => {
    const weights: { [key: string]: number } = {
        B: 0,
        Kb: 1,
        Mb: 2,
        Gb: 3,
    };
    const [firstValue, firstWeight] = a.split(" ");
    const [secondValue, secondWeight] = b.split(" ");
    if (weights[firstWeight] !== weights[secondWeight]) return weights[firstWeight] - weights[secondWeight];
    return Number(firstValue) - Number(secondValue);
};

export { compare, getHeader, DataItem };
