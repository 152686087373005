import React, { useCallback, useEffect, useState } from "react";

import * as style from "@screens/Profile/AccountSecurity/TwoFactoryAuth/style.scss";

const getSeconds = (number: number) => (number >= 10 ? `0:${number}` : `0:0${number}`);

const Timer = ({ takeOffTimer }: { takeOffTimer: () => void }) => {
    const [seconds, setSeconds] = useState(1);

    const startTimer = useCallback(() => {
        setSeconds(59);
        const interval = setInterval(() => {
            setSeconds((prevSec) => {
                const sec = --prevSec;
                if (sec === 0) {
                    clearInterval(interval);
                }
                return sec;
            });
        }, 1000);

        return interval;
    }, []);

    useEffect(() => {
        if (seconds === 0) {
            takeOffTimer();
        }
    }, [seconds, takeOffTimer]);

    useEffect(() => {
        const idInterval = startTimer();
        return () => {
            clearInterval(idInterval);
        };
    }, [startTimer]);

    return <div className={style.inputButton}>{getSeconds(seconds)}</div>;
};

export { Timer };
