import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";

const loadEvents = (data) =>
    new Promise((resolve, reject) => {
        const access_token = getPortalTokenFromState();
        const request = "partner/event/list";
        const params = {};

        const body = {};
        if (data.end_time) {
            params.cursor = data.end_time;
        }

        try {
            const types = data.types;
            const temp = [];
            for (const k in types) {
                temp.push(types[k]);
            }
            body.event_types = temp;
        } catch (e) {
            window.console.error(e);
        }

        try {
            const authors = data.authors;
            const temp = [];
            for (const k in authors) {
                temp.push(authors[k]);
            }
            body.event_author = { firebase_emails: temp };
        } catch (e) {
            window.console.error(e);
        }

        Network.post(request, params, body, access_token)
            .then((response) => resolve(response.response))
            .catch(() => reject());
    });

export { loadEvents };
