import styled from "styled-components";

export const ItemMetric = styled.div<{ selected: boolean }>`
    font-size: 13px;
    margin-bottom: 4px;
    display: flex;
    flex-wrap: wrap;
    color: #b2b9c4;
    justify-content: space-between;
    cursor: pointer;
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    :hover {
        opacity: 1;
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const GraphContainer = styled.div<{ $opened: boolean }>`
    background: ${({ theme }) => theme.colors.lightBg};
    color: #b2b9c4;
    border-radius: 6px;
    width: ${({ $opened }) => ($opened ? "100%" : "49%")};
    margin-bottom: 2%;
    font-size: 12px;
    border: unset;
    .recharts-tooltip-wrapper {
        z-index: 1;
    }

    @media only screen and (max-width: 1280px) {
        width: 99%;
    }
`;

export const Titles = styled.div<{ $width: string }>`
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    width: ${({ $width }) => $width};
    margin: 24px;
`;

export const Circle = styled.div<{ $color: string }>`
    height: 10px;
    width: 10px;
    background-color: ${({ $color }) => $color};
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
`;
