import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@monaco-editor/react";

import * as style from "@screens/Settings/style.scss";
import { TextField, CustomSelect } from "@components/FormikFields";
import * as buttonStyle from "@components/Button/style.scss";
import RootState from "@state/interfaces";
import { editActiveProject } from "@state/activeProject";
import { If } from "@components/If";
import { uploadIconProject } from "@services/Project";
import { monacoEditorOptions } from "@common/constant/monacoOption";

import { Permissions } from "../../../enums";

const pattern = new RegExp(/^[A-Za-z\d _]*[A-Za-z\d][A-Za-z\d _]*$/);

const GeneralSettings = () => {
    const dispatch = useDispatch();
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const permissions = useSelector((state: RootState) => state.permissions);
    const optionsLicense = useMemo(
        () =>
            activeProject.licenses?.map(({ id, name }) => ({
                value: id,
                label: name,
            })) || [],
        [activeProject],
    );
    const license = useMemo(() => activeProject.default_license_id || 1, [activeProject]);
    const disabled = useMemo(() => {
        const config =
            typeof activeProject.config === "string" && activeProject.config.length
                ? JSON.parse(activeProject.config)
                : {};
        if (config.read_only) {
            return true;
        }

        if (activeProject.publickey) return !permissions.includes(Permissions.ProjectUpdate);
        return true;
    }, [activeProject, permissions]);
    const [icon, setIcon] = useState("");
    const refIcon = useRef<File>();
    const config = useMemo(() => {
        if (typeof activeProject.config === "object") {
            return JSON.stringify({ ...activeProject.config }, undefined, 4);
        }
        return activeProject.config;
    }, [activeProject.config]);

    useEffect(() => {
        activeProject.icon && setIcon(`${activeProject.icon}`);
    }, [activeProject]);

    const uploadIcon = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return false;
        const [newIcon] = e.target.files;
        const fr = new FileReader();
        fr.onload = (event: any) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                setIcon(`${fr.result}`);
            };
        };

        fr.readAsDataURL(newIcon);
        refIcon.current = newIcon;
        await uploadIconProject({
            publickey: activeProject.publickey,
            icon: newIcon,
        });

        return true;
    };

    return (
        <>
            <Formik
                initialValues={{
                    private_key: activeProject.privatekey,
                    project_name: activeProject.carrier_name,
                    default_license: license,
                    config,
                    description: activeProject.description,
                }}
                enableReinitialize
                validate={({ private_key, project_name }) => {
                    const errors: any = {};
                    if (!private_key.trim()) errors.private_key = window.locales.emptyPrivateKey;
                    if (private_key.length < 6) errors.private_key = window.locales.invalidPrivateKey;
                    if (!project_name.trim()) errors.project_name = window.locales.invalidCompanyName;
                    if (!pattern.test(project_name)) errors.project_name = window.locales.incorrectCompanyName;

                    return errors;
                }}
                onSubmit={(
                    { private_key, project_name, default_license, config, description },
                    { resetForm, setFieldError },
                ) => {
                    let dataConfig;
                    if (typeof config === "string") {
                        try {
                            dataConfig = JSON.parse(config);
                        } catch (e) {
                            setFieldError("config", window.locales.invalidConfig);
                            return;
                        }
                    } else {
                        dataConfig = config;
                    }

                    const data = {
                        ...activeProject,
                        privatekey: private_key,
                        carrier_name: project_name,
                        description,
                        config: {
                            ...dataConfig,
                            default_license_id: default_license,
                        },
                    };

                    resetForm({
                        values: {
                            private_key,
                            project_name,
                            default_license,
                            description,
                            config,
                        },
                    });

                    dispatch(editActiveProject(data));
                }}
            >
                {({ values, handleSubmit, dirty, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <section>
                            <div className={style.fieldsContainer}>
                                <Field
                                    component={TextField}
                                    disabled={disabled}
                                    type="password"
                                    name="private_key"
                                    placeholder="Test 22"
                                />
                                <Field
                                    component={TextField}
                                    disabled={disabled}
                                    type="text"
                                    name="project_name"
                                    placeholder="Project name"
                                />
                                <span>{window.locales.type}</span>
                                <div className={style.textContainer}>{window.locales[activeProject.project_type]}</div>
                                <Field
                                    component={CustomSelect}
                                    disabled={disabled}
                                    value={values.default_license}
                                    options={optionsLicense}
                                    name="default_license"
                                    placeholder="Default license"
                                />
                                <span>{window.locales.icon}</span>
                                <div className={style.iconContainer}>
                                    <If condition={!!icon}>
                                        <img className={style.iconFile} alt="icon" src={icon} />
                                    </If>
                                    <If condition={!icon}>
                                        <div />
                                    </If>
                                    <div className={style.uploadButtonContainer}>
                                        <label htmlFor="settingsButtonUploadImage">
                                            {icon ? window.locales.changeImage : window.locales.chooseImage}
                                        </label>
                                        <input
                                            autoComplete="off"
                                            id="settingsButtonUploadImage"
                                            onChange={(e) => {
                                                uploadIcon(e);
                                            }}
                                            type="file"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.configContainer}>
                                <Editor
                                    height="50vh"
                                    options={monacoEditorOptions}
                                    value={values.config}
                                    onChange={(value) => setFieldValue("config", value)}
                                    theme="vs-dark"
                                    defaultLanguage="json"
                                />
                            </div>
                        </section>
                        <div className={style.singleButtonContainer}>
                            <button
                                className={buttonStyle.buttonSubmit}
                                type="submit"
                                disabled={disabled || !(isValid && dirty)}
                            >
                                {window.locales.save}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default GeneralSettings;
