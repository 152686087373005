import React from "react";
import { Field, Formik } from "formik";
import countryList from "country-list";

import { IServerPoolModal, TServerPoolForm } from "@screens/Settings/VPN/Offload/types";
import { Modal } from "@components/Modal";
import { TextAreaField, TextField, CustomSelect } from "@components/FormikFields";
import { deviceOptions, protocolOptions } from "@screens/Settings/VPN/utils";

const countries = Object.keys(countryList.getCodeList()).map((key) => {
    const country = key.toUpperCase();
    return {
        value: country,
        label: country,
    };
});

const OffloadPoolModal = ({
    title,
    action,
    close,
    pool = {
        server_pool: "",
        user_region: "",
        protocol: [],
        device_type: [],
        app_version: "",
        proxy: "",
        service: "",
    },
    isOpen,
    buttonName,
}: IServerPoolModal) => (
    <Modal
        isOpen={isOpen}
        onClose={close}
        title={title}
        confirm={{
            label: buttonName,
            onConfirm: () => {},
        }}
        typeButton="submit"
        nameForm="editForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                user_region: pool?.user_region || "",
                protocol: pool?.protocol || "",
                device_type: pool?.device_type || "",
                app_version: pool?.app_version || "",
                proxy: pool?.proxy || "",
                service: pool?.service || "",
            }}
            validate={({ proxy, service }) => {
                const errors: any = {};
                if (!service) {
                    errors.service = window.locales.invalidService;
                    return errors;
                }

                if (!proxy) {
                    errors.proxy = window.locales.invalidProxy;
                    return errors;
                }

                return errors;
            }}
            onSubmit={({ service, app_version, proxy, protocol, user_region, device_type }) => {
                const serverPool: TServerPoolForm = {
                    selector: {
                        device_type: {
                            in: [],
                        },
                        protocol: {
                            in: [],
                        },
                        proxy: [],
                        app_version: {
                            semver: "",
                        },
                        user_region: "",
                    },
                };

                user_region && (serverPool.selector.user_region = user_region);
                protocol.length && (serverPool.selector.protocol.in = protocol);
                device_type.length && (serverPool.selector.device_type.in = device_type);
                app_version && (serverPool.selector.app_version.semver = app_version);
                proxy && (serverPool.selector.proxy = proxy.split(","));

                action(serverPool, service);
                close();
            }}
        >
            {({ values, handleSubmit, setValues }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="editForm" onSubmit={handleSubmit}>
                    <section>
                        <Field component={TextField} type="text" name="service" placeholder="e.g. netflix" />
                    </section>
                    <section>
                        <Field
                            name="user_region"
                            options={countries}
                            component={CustomSelect}
                            placeholder="Select region"
                        />
                    </section>
                    <section>
                        <Field
                            name="protocol"
                            options={protocolOptions}
                            component={CustomSelect}
                            placeholder="e.g. ipsec, openvpn"
                            isMulti
                        />
                    </section>
                    <section>
                        <Field
                            name="device_type"
                            options={deviceOptions}
                            component={CustomSelect}
                            placeholder="e.g. ipsec, openvpn"
                            isMulti
                        />
                    </section>
                    <section>
                        <Field component={TextField} type="text" name="app_version" placeholder="Version" />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.proxy}
                            type="text"
                            name="proxy"
                            placeholder="Separate by comma"
                        />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { OffloadPoolModal };
