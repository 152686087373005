import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { useParams } from "react-router-dom";

import * as style from "@screens/Users/User/Content/style.scss";
import { IconSettings } from "@common/icons";
import { editLimits } from "@services/Traffic";
import { TextField } from "@components/FormikFields";
import { Modal } from "@components/Modal";
import RootState from "@state/interfaces";
import { getUser } from "@state/users";
import Checkbox from "@components/Checkbox";

const onlyNumbers = /^\d+$/;

const EditLimitModal = ({
    traffic,
}: {
    traffic: {
        traffic_limit: string;
        traffic_unlimited: boolean;
    };
}) => {
    const { user } = useParams();
    const selectedUser = useSelector((state: RootState) => state.users.selectedUser);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const closeModal = () => setIsOpen(false);

    return (
        <>
            <IconSettings theme="blue" onClick={() => setIsOpen(true)} />
            <Modal
                isOpen={isOpen}
                onClose={closeModal}
                title={window.locales.editLimit}
                confirm={{
                    label: window.locales.confirm,
                    onConfirm: () => {},
                }}
                /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
                typeButton="submit"
                nameForm="trafficForm"
                disabled={false}
            >
                <Formik
                    initialValues={{
                        traffic_limit: traffic.traffic_limit,
                        traffic_unlimited: traffic.traffic_unlimited,
                        reset: true,
                    }}
                    validate={({ traffic_limit, traffic_unlimited }) => {
                        const errors: any = {};
                        if (!traffic_unlimited && !onlyNumbers.test(traffic_limit))
                            errors.traffic_limit = window.locales.incorrectValue;
                        return errors;
                    }}
                    onSubmit={async ({ traffic_limit, traffic_unlimited, reset }) => {
                        await editLimits(selectedUser.id, {
                            limit: traffic_limit,
                            unlimited: traffic_unlimited,
                            reset,
                        });
                        closeModal();
                        dispatch(getUser(user));
                    }}
                >
                    {({ values, handleSubmit }) => (
                        /* ! add id to match nameForm from the Modal props  */
                        <form id="trafficForm" onSubmit={handleSubmit}>
                            {!values.traffic_unlimited && (
                                <section className={style.trafficModal}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="traffic_limit"
                                        disabled={values.traffic_unlimited}
                                    />
                                </section>
                            )}
                            <section>
                                <Field component={Checkbox} type="checkbox" name="reset" text="Traffic reset" />
                            </section>
                            <section>
                                <Field
                                    component={Checkbox}
                                    type="checkbox"
                                    name="traffic_unlimited"
                                    text="Set traffic unlimited"
                                />
                            </section>
                        </form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default EditLimitModal;
