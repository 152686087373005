import React from "react";

import Network from "@common/Network";
import { IStatusList, ITask } from "@screens/Export/types";
import { Data } from "@components/Table/types";
import { getDateString } from "@common/methods";
import { IconCompleted, IconExporting, IconExportingFailed, IconQueryFailed } from "@common/icons";
import IconDownload from "@common/img/svg/download-icon.svg";
import { DownloadContainer } from "@screens/Export/components";
import * as table from "@components/Table/style.scss";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";

const headings = [
    {
        isSort: true,
        heading: window.locales.created,
    },
    {
        isSort: true,
        heading: window.locales.type,
    },
    {
        isSort: true,
        heading: window.locales.exportFrom,
    },
    {
        isSort: true,
        heading: window.locales.exportTill,
    },
    {
        isSort: true,
        heading: window.locales.exportStatus,
    },
    {
        isSort: false,
        heading: window.locales.download,
    },
];

const NAMES_TYPE = {
    BY_CARRIER: window.locales.BY_CARRIER,
    BY_ALL_USERS: window.locales.BY_ALL_USERS,
    BY_USER: window.locales.BY_ALL_USERS,
    BY_USER_ID: window.locales.BY_ALL_USERS,
    BY_DEVICE_ID: window.locales.BY_ALL_DEVICES,
    BY_ALL_DEVICES: window.locales.BY_ALL_DEVICES,
};

const NAMES_STATUS: IStatusList = {
    NEW: <IconExporting label={window.locales.EXPORTING} />,
    EXPORTING: <IconExporting label={window.locales.EXPORTING} />,
    QUERYING: <IconExporting label={window.locales.EXPORTING} />,
    COMPLETED: <IconCompleted label={window.locales.COMPLETED} />,
    EXPORTING_FAILED: <IconQueryFailed label={window.locales.EXPORTING_FAILED} />,
    QUERYING_FAILED: <IconExportingFailed label={window.locales.QUERYING_FAILED} />,
    NO_DATA: <>NO_DATA</>,
};

interface IDataItemProps {
    date: string;
    time: string;
}

const getDate = (value: number) =>
    getDateString(value) || {
        date: "",
        time: "",
    };

const CreatedTime: React.FC<IDataItemProps> = ({ date, time }) => (
    <>
        <div className={table.dateItem}>{date}</div>
        <div className={table.time}>{time}</div>
    </>
);

const createdStatus = (statusType: string) => NAMES_STATUS[statusType];

const downloadReport = (taskCode: string, count: number) => {
    const download = (ith: number) => {
        if (ith >= count) {
            return;
        }
        const access_token = getPortalTokenFromState();
        Network.get(
            "partner/report/download",
            {
                task_code: taskCode,
                part: ith,
            },
            access_token,
        )
            .then((response) => {
                if (response && response.response && response.response.report_url) {
                    const link = document.createElement("a");
                    link.href = response.response.report_url;
                    link.click();
                    // Chrome and other browsers limit number of simulteneus downloads up to 10
                    // So increase delay on each 10th download
                    setTimeout(
                        () => {
                            download(ith + 1);
                        },
                        ith > 0 && ith % 10 === 0 ? 10000 : 100,
                    );
                }
            })
            .catch((e) => window.console.error(e));
    };
    download(0);
};

const createdDownload = (taskCode: string, count: number) =>
    count > 0 ? (
        <DownloadContainer onClick={() => downloadReport(taskCode, count)}>
            <img src={IconDownload} alt="download" />
        </DownloadContainer>
    ) : (
        <div />
    );

const accumulateExportData = (tasks: ITask[]): Data[] =>
    tasks.map((task) => {
        const { status, report_count, task_code, report_type, start_time, end_time, created_time } = task;
        const inProgress: { [key: string]: any } = {};
        if (inProgress[report_type] && ["NEW", "EXPORTING", "QUERYING", "COMPLETED"].indexOf(status) !== -1) {
            inProgress[report_type] = true;
        }

        return {
            created: {
                sortColumn: created_time,
                component: <CreatedTime {...getDate(created_time)} />,
            },
            type: NAMES_TYPE[report_type] || report_type,
            from: {
                sortColumn: start_time,
                component: <CreatedTime {...getDate(start_time)} />,
            },
            till: {
                sortColumn: end_time,
                component: <CreatedTime {...getDate(end_time)} />,
            },
            status: {
                sortColumn: status,
                component: createdStatus(status),
            },
            download: {
                sortColumn: status,
                component: createdDownload(task_code, report_count),
            },
        };
    });

export { accumulateExportData, headings };
