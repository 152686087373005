import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@screens/Error/components";
import rightWorldSVG from "@common/img/svg/right-side-world.svg";
import * as style from "@screens/Error/style.scss";

const Exception = () => {
    const navigate = useNavigate();
    return (
        <div className={style.errorPageContainer}>
            <div className={style.worldContainer}>
                <img src={rightWorldSVG} alt="img" />
            </div>
            <div className={style.formContainer}>
                <div className={style.text}>
                    <div className={style.title}>Oops! Something went wrong</div>
                    <div className={style.description}>Please refresh page and try again</div>
                </div>
                <Button onClick={() => navigate(-1)}>Refresh</Button>
            </div>
        </div>
    );
};

export { Exception };
