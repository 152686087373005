(function () {
    const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
    const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");

    if (matcher.matches) {
        darkSchemeIcon.remove();
        document.head.append(lightSchemeIcon);
    } else {
        lightSchemeIcon.remove();
        document.head.append(darkSchemeIcon);
    }
})();
