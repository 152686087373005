import styled from "styled-components";

const Button = styled.div`
    cursor: pointer;
    font-weight: 500;
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.button.bg};
    width: max-content;
    border-radius: 12px;
    font-size: 16px;

    :hover {
        background-color: ${({ theme }) => theme.colors.buttonHover.bg};
    }

    :active {
        background-color: ${({ theme }) => theme.colors.buttonActive.bg};
    }
`;

export { Button };
