import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { accumulateProjects, headings } from "@screens/Projects/Projects.utils";
import Table from "@components/Table";
import { RootState } from "@state/index";
import { Search } from "@components/Search/Search";
import { resetActiveProject } from "@state/activeProject";
import { getProjects } from "@state/projects";
import * as commonStyle from "@common/style/style.scss";
import { useFilteredData } from "@common/hooks/useFilteredData";

import { ProjectType, Projects } from "./Projects.types";

const filterFunction =
    (searchValue: string) =>
    ({ publickey = "", carrier_name = "" }: ProjectType): boolean =>
        publickey.includes(searchValue) || carrier_name.includes(searchValue);

const ProjectsList = () => {
    const projects: Projects = useSelector((state: RootState) => state.projects);
    const account = useSelector((state: RootState) => state.account);
    const navigate = useNavigate();
    const projectList = useMemo(() => Object.entries(projects).map(([, value]) => value), [projects]);
    const { setData, setSearch, filteredList } = useFilteredData<ProjectType>(filterFunction);

    const dispatch = useDispatch();

    useEffect(() => {
        if (account.authorized) {
            dispatch(getProjects());
        }
    }, [dispatch, account.authorized]);

    useEffect(() => {
        dispatch(resetActiveProject());
    }, [dispatch]);

    useEffect(() => {
        if (!projectList.length) return;
        setData(projectList);
    }, [projectList, setData]);

    return (
        <div className={commonStyle.mainPageContainer}>
            <Table
                tableData={accumulateProjects(filteredList, navigate)}
                headings={headings}
                title="projects"
                emptyMessage={window.locales.noProjects}
            >
                <Search callback={setSearch} />
            </Table>
        </div>
    );
};

export default ProjectsList;
