import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";

const jsonToBase64 = (jsonObject: Record<string, any>): string => {
    try {
        const jsonString = JSON.stringify(jsonObject);
        return Buffer.from(jsonString).toString("base64");
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
        return "";
    }
};

export default jsonToBase64;
