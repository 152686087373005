import React, { ReactElement } from "react";

import iconClose from "@common/img/svg/close-icon.svg";
import { useCreatePortalInBody } from "@common/hooks/useCreatePortalInBody";
import { Button } from "@common/button";
import { TargetIcon } from "@components/TargetIcon";
import { If } from "@components/If";
import * as style from "@components/Modal/style.scss";

import { ModalContent } from "./components";
import { IModalButtonProps, IModalProps } from "./types";

const ButtonContainer = ({ button, confirm, disabled, typeButton, nameForm }: IModalButtonProps) =>
    React.cloneElement(button, {
        confirm,
        disabled,
        typeButton,
        nameForm,
    });

const Modal = ({
    children,
    confirm,
    onClose,
    isOpen,
    title,
    nameForm,
    typeButton,
    disabled = false,
    isNegative = false,
}: IModalProps): ReactElement => {
    const createBodyPortal = useCreatePortalInBody();

    return (
        <>
            {createBodyPortal(
                <If condition={isOpen}>
                    <div className={style.modalOverlay}>
                        <div className={style.styledModal}>
                            <ModalContent $isOpen={isOpen}>
                                <div className={style.modalTitle}>
                                    {title}
                                    <TargetIcon onClick={onClose}>
                                        <img src={iconClose} alt="close" />
                                    </TargetIcon>
                                </div>
                                <div className={style.modalBody}>{children}</div>
                                <div className={style.modalActions}>
                                    {/* <ButtonContainer button={<Button name="reset" onClick={onClose} type="button" form="" disabled={disabled}>{window.locales.cancel}</Button>} /> */}
                                    <ButtonContainer
                                        button={
                                            <Button
                                                isNegative={isNegative}
                                                name="button"
                                                onClick={confirm.onConfirm}
                                                type={typeButton}
                                                form={nameForm}
                                                disabled={disabled}
                                            >
                                                {confirm.label}
                                            </Button>
                                        }
                                    />
                                </div>
                            </ModalContent>
                        </div>
                    </div>
                </If>,
            )}
        </>
    );
};

export { Modal };
