import React, { useEffect, useState } from "react";

import Table from "@components/Table";
import { getTaskList } from "@services/Export";
import { accumulateExportData, headings } from "@screens/Export/utils";
import { ExportActions } from "@screens/Export/ExportActions";
import * as tableContainerStyle from "@components/TableContainer/style.scss";
import { Data } from "@components/Table/types";

const Export = () => {
    const [body, setBody] = useState<Data[]>([]);

    useEffect(() => {
        exported();
        const interval = setInterval(exported, 60000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const exported = async () => {
        const tasks = await getTaskList();
        const body = accumulateExportData(tasks);
        setBody(body);
    };

    return (
        <div id="screenUsers">
            <h1>{window.locales.exportData}</h1>
            <div className={tableContainerStyle.tableContainer}>
                <Table
                    tableData={body}
                    headings={headings}
                    title={window.locales.exportData}
                    emptyMessage={window.locales.exportData}
                >
                    <ExportActions exported={exported} />
                </Table>
            </div>
        </div>
    );
};

export default Export;
