import { getFunctions, httpsCallable } from "firebase/functions";

const isValidPwd = (email: string, password: string) => {
    const functions = getFunctions();
    const checkPasswordStrength = httpsCallable(functions, "checkPasswordStrength");

    return checkPasswordStrength({
        email,
        credential: Buffer.from(password).toString("base64"),
    });
};

export { isValidPwd };
