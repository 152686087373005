import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import cloneDeep from "lodash/cloneDeep";

import { IPropsModal } from "@components/Modal/types";
import { Modal } from "@components/Modal";
import { CustomSelect, TextAreaField, TextField } from "@components/FormikFields";
import { IPermission, IPool } from "@screens/Network/Pools/types";
import * as buttonStyle from "@components/Button/style.scss";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import { If } from "@components/If";
import iconTrash from "@common/img/svg/trash-icon.svg";
import { IconPlus } from "@common/icons";

const permissionsOptions = [
    ["can_read", "Read"],
    ["can_use", "Use"],
    ["can_write", "Write"],
].map(([value, label]) => ({
    value,
    label,
}));

const PoolModal = ({ title, action, close, isOpen, buttonName, item }: IPropsModal<IPool>) => {
    const [listPermissions, setListPermissions] = useState<IPermission[]>([]);

    useEffect(() => setListPermissions(cloneDeep(item.permissions)), [item.permissions]);

    const deletePermission = (index: number) => {
        setListPermissions((prevState) => {
            prevState.splice(index, 1);
            return [...prevState];
        });
    };

    const addPermission = (permissions: IPermission[]) => {
        setListPermissions([
            ...permissions,
            {
                subject: "",
                list: [],
                can_list: false,
                can_read: false,
                can_use: false,
                can_write: false,
            },
        ]);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={close}
            title={title}
            confirm={{
                label: buttonName,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="editForm"
            disabled={false}
        >
            <Formik
                initialValues={{
                    pool_name: item.name,
                    description: item.description,
                    permissions: listPermissions,
                    pool_selector: item.selector,
                }}
                validate={({ pool_name, permissions }) => {
                    const errors: any = {};
                    if (!pool_name) errors.pool_name = window.locales.emptyName;
                    const index = permissions.findIndex((permission) => !permission.subject);
                    if (index !== -1) errors[`permissions.${index}.subject`] = window.locales.emptyField;
                    return errors;
                }}
                enableReinitialize
                onSubmit={({ pool_name, description, permissions }) => {
                    action({
                        name: pool_name,
                        permissions,
                        description,
                        selector: { project: { in: [] } },
                        locations: null,
                        groups: [],
                        version: 0,
                    });
                    close();
                }}
            >
                {({ values, handleSubmit, touched }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="editForm" onSubmit={handleSubmit} className={poolStyle.bigModalContainer}>
                        <section>
                            <Field component={TextField} placeholder="Pool name" type="text" name="pool_name" />
                        </section>
                        <If condition={values.permissions.length > 0}>
                            <section>
                                <span>{window.locales.permissions}</span>
                                {values.permissions.map((permission, i) => (
                                    <div key={i} className={poolStyle.permissionContainer}>
                                        <Field
                                            extraTouched={touched.permissions?.length && !!touched.permissions[i]}
                                            component={TextField}
                                            placeholder="Project id"
                                            type="text"
                                            name={`permissions.${i}.subject`}
                                        />
                                        <Field
                                            isMulti
                                            component={CustomSelect}
                                            options={permissionsOptions}
                                            name={`permissions.${i}.list`}
                                            placeholder="Select permission"
                                        />
                                        <TargetIcon onClick={() => deletePermission(i)}>
                                            <img src={iconTrash} alt="img" />
                                        </TargetIcon>
                                    </div>
                                ))}
                            </section>
                        </If>
                        <section className={poolStyle.extraButtons}>
                            <button
                                type="button"
                                onClick={() => addPermission(values.permissions)}
                                className={buttonStyle.buttonAdd}
                            >
                                <IconPlus theme="blue" />
                                {window.locales.add}
                            </button>
                        </section>
                        <section>
                            <Field
                                component={TextAreaField}
                                placeholder="Pool selector"
                                type="text"
                                name="pool_selector"
                                disabled
                            />
                        </section>
                        <section>
                            <Field
                                component={TextAreaField}
                                value={values.description}
                                type="text"
                                name="description"
                                placeholder="Type a description"
                            />
                        </section>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default PoolModal;
