import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Field, Formik } from "formik";

import { editAndCreateObjectRequest, getObjectByKindRequest } from "@services/Objects";
import { ObjectEnum } from "@screens/Objects/Object/Object.constants";
import Base64toJSON from "@common/methods/base64toJSON";
import * as buttonStyle from "@components/Button/style.scss";
import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";
import { checkObjectResponse } from "@common/methods/objectRequest";
import jsonToBase64 from "@common/methods/jsonToBase64";
import { IMetadata } from "@screens/Objects/Objects.types";
import { AuthConfigType } from "@screens/Auth/AuthConfig/AuthConfig.types";
import { TextField } from "@components/FormikFields";
import * as localStyle from "@screens/Auth/Auth.styled.scss";
import * as userStyle from "@screens/Auth/AuthUser/AuthUser.styled.scss";
import { ToggleSwitch } from "@components/ToggleSwitch";
import { If } from "@components/If";

const durationRegex = /^[+-]?\d*(\.\d+)?(ns|us|µs|ms|s|m|h)(\d+(\.\d+)?(ns|us|µs|ms|s|m|h))*$/;

const defaultAuthConfig: AuthConfigType = {
    max_sessions_per_user: 0,
    access_token_expire_time: "",
    refresh_token_expire_time: "",
    login_rate_limit_count: 0,
    login_rate_limit_interval: "",
};

function AuthConfig() {
    const { project = "" } = useParams();
    const refMetadata = useRef<IMetadata>();
    const [withRate, setWithRate] = useState<boolean>(false);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [authConfig, setAuthConfig] = useState<AuthConfigType>(defaultAuthConfig);
    const getData = useCallback(async () => {
        const { data } = await getObjectByKindRequest({
            kind: ObjectEnum.PROJECT_AUTH_CONFIG,
            name: "default",
            namespace: project,
        });

        if (!data) return;
        const { object } = data;
        if (!object) return;
        refMetadata.current = object.metadata;
        const config = Base64toJSON(object.data) as AuthConfigType;
        setWithRate(!!config.login_rate_limit_count);
        setAuthConfig(config);
    }, [project]);
    useEffect(() => {
        getData().then();
    }, [getData]);

    return (
        <Formik
            initialValues={{
                ...authConfig,
            }}
            validate={({
                access_token_expire_time,
                refresh_token_expire_time,
                login_rate_limit_count,
                login_rate_limit_interval,
            }) => {
                const errors: Partial<AuthConfigType> = {};
                if (access_token_expire_time && !durationRegex.test(access_token_expire_time)) {
                    errors.access_token_expire_time = window.locales.incorrectValue;
                }
                if (refresh_token_expire_time && !durationRegex.test(refresh_token_expire_time)) {
                    errors.refresh_token_expire_time = window.locales.incorrectValue;
                }
                if (withRate && !login_rate_limit_count) {
                    errors.login_rate_limit_count = window.locales.emptyField;
                }
                if (withRate && !login_rate_limit_interval) {
                    errors.login_rate_limit_interval = window.locales.emptyField;
                }

                return errors;
            }}
            validateOnChange={false}
            enableReinitialize
            onSubmit={async (values) => {
                try {
                    setIsRequesting(true);
                    let metadata: Partial<IMetadata> = {
                        name: "default",
                        namespace: project,
                    };
                    if (refMetadata.current) {
                        metadata = refMetadata.current;
                    }

                    const { data } = await editAndCreateObjectRequest({
                        ...metadata,
                        data: jsonToBase64(values),
                        kind: ObjectEnum.PROJECT_AUTH_CONFIG,
                        namespace: project,
                        name: "default",
                        onlyCreate: !refMetadata.current,
                        onlyUpdate: !!refMetadata.current,
                    });
                    checkObjectResponse(data, () => {});
                } catch (e: unknown | IErrorResponse) {
                    const error = e as IErrorResponse;
                    errorInfo.setErrorInfo({
                        title: error.code,
                        description: error.message,
                    });
                } finally {
                    setIsRequesting(false);
                }
            }}
        >
            {({ handleSubmit }) => (
                <form id="editForm" onSubmit={handleSubmit} className={localStyle.authPageContainer}>
                    <section>
                        <Field
                            component={TextField}
                            placeholder="Type max sessions per user"
                            type="text"
                            name="max_sessions_per_user"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextField}
                            placeholder="Type access token expire time"
                            type="text"
                            name="access_token_expire_time"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextField}
                            placeholder="Type refresh token expire time"
                            type="text"
                            name="refresh_token_expire_time"
                        />
                    </section>
                    <div className={userStyle.switcher}>
                        <div className={userStyle.labelSwitcher}>Set rate limit</div>
                        <Field
                            component={ToggleSwitch}
                            value={withRate}
                            onClick={() => {
                                setWithRate(!withRate);
                            }}
                        />
                    </div>
                    <If condition={withRate}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type login rate limit interval"
                                type="text"
                                name="login_rate_limit_interval"
                            />
                        </section>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type login rate limit count"
                                type="text"
                                name="login_rate_limit_count"
                            />
                        </section>
                    </If>
                    <div className={buttonStyle.singleButtonContainer}>
                        <button type="submit" disabled={isRequesting} className={buttonStyle.buttonSubmit}>
                            {window.locales.save}
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default AuthConfig;
