import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";

import { GetMetricsRequest } from "@services/Graphs";
import { IconQuestionGraph } from "@common/icons";
import { ColorsCountries, Conditions } from "@constants/charts";
import { RootState } from "@state/index";
import * as style from "@screens/Dashboard/GeoChart/style.scss";
import { GeoChartItemType, GeoChartType } from "@screens/Dashboard/GeoChart/GeoChart.types";

import graphsMetadata from "../../../graphs.config.json";
import { SortButton, ColorItem } from "./components";
import StatsList from "../StatsList";

const GeoChart = () => {
    const [geoData, setGeoData] = useState([["Country", 0]]);
    const [listCountry, setListCountry] = useState<GeoChartType>([]);
    const [sortedByPercent, setSortedByPercent] = useState(false);
    const [sortedByAlphabet, setSortedByAlphabet] = useState(false);
    const activeProject = useSelector((state: RootState) => state.activeProject);

    const sortByAlphabet = () => {
        sortedByAlphabet ? listCountry.reverse() : listCountry.sort((a, b) => a[0].localeCompare(b[0]));
        setListCountry([...listCountry]);
        setSortedByAlphabet(!sortedByAlphabet);
        setSortedByPercent(false);
    };

    const sortByPercent = () => {
        sortedByPercent
            ? listCountry.sort((a: GeoChartItemType, b: GeoChartItemType) => b[1] - a[1])
            : listCountry.sort((a: GeoChartItemType, b: GeoChartItemType) => a[1] - b[1]);
        setListCountry([...listCountry]);
        setSortedByAlphabet(false);
        setSortedByPercent(!sortedByPercent);
    };

    useEffect(() => {
        const defaultValue = [["Country", "Load"]];
        setGeoData(defaultValue);

        const { location } = graphsMetadata;
        const { publickey } = activeProject;
        const date = new Date();
        const currentDate = new Date(date.setMinutes(date.getMinutes() - 5));

        const requests = location.map((graphMetadata) =>
            graphMetadata.items.map((graphId) =>
                GetMetricsRequest(publickey, graphId, {
                    startDate: currentDate,
                    endDate: currentDate,
                }),
            ),
        );

        requests.forEach((request) =>
            Promise.all(request).then((responses) => {
                responses.forEach((countryItems) => {
                    const data =
                        countryItems &&
                        countryItems.map(({ values, metric }) => [
                            metric.country.toUpperCase(),
                            +Number(values[0][1]).toFixed(2),
                        ]);
                    if (data) {
                        setGeoData([...defaultValue, ...data]);
                        const sortedList = data.sort(([, a], [, b]) => Number(b) - Number(a)) as GeoChartType;
                        setListCountry(sortedList);
                    }
                });
            }),
        );
    }, [activeProject, activeProject.config]);

    return (
        <div className={style.locationContainer}>
            <div className={style.mapContainer}>
                <div className={style.mapTitleContainer}>
                    <div className={style.titleContainer}>{window.locales.location_loading_map}</div>
                    <a href="https://auravpn.gitbook.io/paas/console-details/project-dashboard/location-loading">
                        <IconQuestionGraph width={20} />
                    </a>
                </div>
                <div className={style.legendContainer}>
                    {ColorsCountries.map((color, index) => (
                        <div className={style.legendContainer} key={index}>
                            <ColorItem color={color} />
                            <div className={style.textItem}>{Conditions[index]}</div>
                        </div>
                    ))}
                </div>
                <div className={style.map}>
                    <Chart
                        options={{
                            backgroundColor: "transparent",
                            colorAxis: {
                                colors: ColorsCountries,
                                values: [0, 30, 60, 90],
                            },
                            datalessRegionColor: "#878C95",
                            legend: "none",
                        }}
                        chartType="GeoChart"
                        width="100%"
                        height="670px"
                        data={geoData}
                    />
                </div>
            </div>
            <div className={style.statsContainer}>
                <div className={style.titleContainer}>
                    <SortButton onClick={sortByAlphabet} selected={sortedByAlphabet}>
                        {window.locales.location_loading_table}
                    </SortButton>
                    <SortButton onClick={sortByPercent} selected={sortedByPercent}>
                        %
                    </SortButton>
                </div>
                <div className={style.statsListContainer}>
                    <StatsList data={listCountry} />
                </div>
            </div>
        </div>
    );
};

export default GeoChart;
