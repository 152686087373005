import React from "react";

import * as table from "@components/Table/style.scss";
import { IServerSelector } from "@screens/Network/Pool/Servers/types";

const serverSelector = (server_country: string | { in: string[] }) =>
    typeof server_country === "string" ? (
        <div className={table.tableTagCountries}>{server_country}</div>
    ) : typeof server_country === "object" && server_country.in ? (
        server_country.in.map((countryName: string, index: number) => (
            <div className={table.tableTagCountries} key={index}>
                {countryName}
            </div>
        ))
    ) : null;

const LabelsServer = (server_selector: IServerSelector) => (
    <div className={table.tableTagContainer}>
        {Object.entries(server_selector)
            .filter(([, selectorValue]) => selectorValue)
            .map(([selectorName, selectorValue], index) => (
                <div className={table.tableTagName} key={index}>
                    {selectorName}: {serverSelector(selectorValue)}
                </div>
            ))}
    </div>
);

const LabelsOfList = (labels: string[]) => (
    <div>
        {labels.map((label) => (
            <div className={table.tableTagName} key={label}>
                {serverSelector(label)}
            </div>
        ))}
    </div>
);

export { LabelsServer, LabelsOfList };
