import React from "react";

import triangleIcon from "@common/img/svg/triangle-icon.svg";

import * as style from "./style.scss";
import { IconAProps } from "../types";

const ArrowSort = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement> & IconAProps>(
    ({ sorted, rotate, onClick }, ref) => (
        <div
            onClick={onClick}
            className={`${style.iconContainer} ${rotate && style.rotate} ${sorted && style.sorted}`}
            ref={ref}
        >
            <img src={triangleIcon} alt="img" />
        </div>
    ),
);

export default ArrowSort;
