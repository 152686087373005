import React from "react";

import * as style from "@components/CheckMark/style.scss";

export const CheckMark = () => (
    <span className={style.checkMarkContainer}>
        <div className={style.stem} />
        <div className={style.kick} />
    </span>
);
