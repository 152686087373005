import { Field, Formik } from "formik";
import React from "react";

import { Modal } from "@components/Modal";
import { CustomSelect, TextAreaField } from "@components/FormikFields";
import { If } from "@components/If";
import { IClientNetworks } from "@screens/Settings/VPN/ClientNetworks/types";

const optionsTypes = [
    ["wifi", "WiFi"],
    ["wwan", "WWAN"],
    ["lan", "LAN"],
].map(([value, label]) => ({
    value,
    label,
}));

const optionsActions = [
    ["enable", window.locales.enable],
    ["disable", window.locales.disable],
].map(([value, label]) => ({
    value,
    label,
}));

const optionsAuthorized = [
    ["yes", window.locales.yes],
    ["no", window.locales.no],
    ["na", window.locales.doesntMatter],
].map(([value, label]) => ({
    value,
    label,
}));

const wifi = "wifi";

const ClientNetworksModal = ({
    title,
    submit,
    close,
    isOpen,
    buttonName,
    clientNetwork,
}: {
    title: string;
    submit: (network: IClientNetworks) => void;
    close: () => void;
    isOpen: boolean;
    buttonName: string;
    clientNetwork: IClientNetworks;
}) => (
    <Modal
        isOpen={isOpen}
        onClose={close}
        title={title}
        confirm={{
            label: buttonName,
            onConfirm: () => {},
        }}
        /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
        typeButton="submit"
        nameForm="emailForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                type: clientNetwork.type,
                ssid: clientNetwork.ssid ? clientNetwork.ssid.join(", ") : "",
                bssid: clientNetwork.bssid ? clientNetwork.bssid.join(", ") : "",
                authorized: clientNetwork.authorized,
                action: clientNetwork.action,
            }}
            validate={({ type, action }) => {
                const errors: any = {};
                if (!type) errors.type = window.locales.invalidType;
                if (!action) errors.action = window.locales.invalidAction;
                return errors;
            }}
            onSubmit={({ type, ssid, bssid, authorized, action }) => {
                const listSsid = ssid
                    ? Array.isArray(ssid)
                        ? ssid
                        : ssid.split(",").map((item: string) => item.trim())
                    : [];
                const listBssid = bssid
                    ? Array.isArray(bssid)
                        ? bssid
                        : bssid.split(",").map((item: string) => item.trim())
                    : [];

                submit({
                    id: clientNetwork.id,
                    type,
                    ssid: listSsid,
                    bssid: listBssid,
                    authorized,
                    action,
                });

                close();
            }}
        >
            {({ values, handleSubmit }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="emailForm" onSubmit={handleSubmit}>
                    <section>
                        <Field name="type" options={optionsTypes} component={CustomSelect} placeholder="Select type" />
                    </section>
                    <If condition={values.type === wifi}>
                        <>
                            <section>
                                <Field
                                    component={TextAreaField}
                                    value={values.ssid}
                                    type="text"
                                    name="ssid"
                                    placeholder={window.locales.ssidLabel}
                                />
                            </section>
                            <section>
                                <Field
                                    component={TextAreaField}
                                    value={values.bssid}
                                    type="text"
                                    name="bssid"
                                    placeholder={window.locales.bssidLabel}
                                />
                            </section>
                            <section>
                                <Field
                                    name="authorized"
                                    options={optionsAuthorized}
                                    component={CustomSelect}
                                    placeholder="Select authorized"
                                />
                            </section>
                        </>
                    </If>
                    <section>
                        <Field
                            name="action"
                            options={optionsActions}
                            component={CustomSelect}
                            placeholder="Select action"
                        />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { ClientNetworksModal };
