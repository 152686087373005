import styled from "styled-components";

export const ColorItem = styled.div`
    background: ${(props) => props.color};
    border-radius: 4px;
    width: 20px;
    height: 20px;
`;

export const SortButton = styled.div<{ selected: boolean }>`
    cursor: pointer;
    color: ${(props) => (props.selected ? "#00A2FF" : "inherit")};

    :hover {
        color: #00a2ff;
    }

    :active {
        color: inherit;
    }
`;
