import React, { Component } from "react";

import { IconFilter } from "@common/icons";

import { Select2 } from "@common/form";

class Filter extends Component {
    constructor(props) {
        super(props);

        this.privateSearchId = `privateSearch${++window.formId}`;
        this.state = {
            i: 0,
            show: false,
        };

        let types = [];
        let authors = [];
        try {
            typeof props.params.types === "string" && (types = JSON.parse(props.params.types));
        } catch (e) {
            window.console.error(e);
        }
        try {
            typeof props.params.authors === "string" && (authors = JSON.parse(props.params.authors));
        } catch (e) {
            window.console.error(e);
        }

        this.form = {
            types,
            authors,
            end_time: props.params.end_time || Date.now(),
            start_time: props.params.start_time || Date.now() - 24 * 60 * 60 * 1000,
        };

        this._escFunction = this.escFunction.bind(this);
        this._click = this.click.bind(this);

        document.addEventListener("keydown", this._escFunction);
        document.addEventListener("click", this._click);
    }

    click(e) {
        if (!this.state.show) return;
        const is = (el1, el2) => {
            if (el1 && el1.className && el1.className.indexOf && el1.className.indexOf("css-") !== -1) return true;
            if (!el1) return false;
            if (el1 === el2) return true;
            if (el1.id === "loadingContainer") return true;
            return is(el1.parentNode, el2);
        };

        if (e.target && e.target.id && e.target.id.indexOf("react-select-") !== -1) return;

        const el = document.getElementById(this.privateSearchId);
        if (!is(e.target, el)) {
            return this.setState({ show: false });
        }
    }

    escFunction(event) {
        if (event.keyCode === 27 && this.state.show) {
            this.setState({ show: false });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.params !== this.props.params) {
            const { types, authors, end_time, start_time } = this.props.params;
            this.form = {
                types,
                authors,
                end_time: end_time ?? Date.now(),
                start_time: start_time ?? Date.now() - 24 * 60 * 60 * 1000,
            };
        }
    }

    render() {
        const types = this.createTypesOptions();
        const authors = this.createAuthorsOptions();

        return (
            <div className={"EventFilterContainer"} id={this.privateSearchId}>
                <div className={`eventFilterContainer ${this.state.show ? "show" : ""}`}>
                    <div onClick={this.changeVisibility.bind(this)}>
                        <IconFilter label={window.locales.filters} theme={"blue"} />
                    </div>
                </div>
                {this.state.show && (
                    <div className={"FilterContainer"}>
                        <div className={"row"}>
                            <div className={"name"}>{window.locales.authors}</div>
                            <Select2
                                options={authors}
                                isMulti
                                label={"Pick"}
                                theme={"dark"}
                                onChange={this.onChange.bind(this, "authors")}
                                value={this.form.authors}
                            />
                        </div>
                        <div className={"row"}>
                            <div className={"name"}>{window.locales.eventType}</div>
                            <Select2
                                options={types}
                                isMulti
                                label={"Pick"}
                                theme={"dark"}
                                onChange={this.onChange.bind(this, "types")}
                                value={this.form.types}
                                placeholder={window.locales.selectPlaceholderType}
                            />
                        </div>
                        <div className={"row"}>
                            <div className={"findBtn"} onClick={this.search.bind(this)}>
                                {window.locales.find}
                            </div>
                            <div className={"resetBtn"} onClick={this.resetSearch.bind(this)}>
                                {window.locales.reset}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    onChange(key, value) {
        this.form[key] = value;
    }

    createTypesOptions() {
        const types = [];
        const map = {
            CREATE_FIREBASE_ADMIN: window.locales.events.CREATE_FIREBASE_ADMIN,
            UPDATE_CARRIER_FIREBASE_ADMIN_ROLE: window.locales.events.UPDATE_CARRIER_FIREBASE_ADMIN_ROLE,
            DELETE_CARRIER_FIREBASE_ADMIN: window.locales.events.DELETE_CARRIER_FIREBASE_ADMIN,
            CREATE_CARRIER_TEST_PURCHASE_SETTINGS: window.locales.events.CREATE_CARRIER_TEST_PURCHASE_SETTINGS,
            UPDATE_CARRIER_TEST_PURCHASE_SETTINGS: window.locales.events.UPDATE_CARRIER_TEST_PURCHASE_SETTINGS,
            DELETE_CARRIER_PURCHASE_SETTINGS: window.locales.events.DELETE_CARRIER_PURCHASE_SETTINGS,
            CREATE_AUTH_METHOD: window.locales.events.CREATE_AUTH_METHOD,
            DELETE_CARRIER_AUTH_METHOD: window.locales.events.DELETE_CARRIER_AUTH_METHOD,
            UPDATE_CARRIER_AUTH_METHOD: window.locales.events.UPDATE_CARRIER_AUTH_METHOD,
            UPDATE_CARRIER: window.locales.events.UPDATE_CARRIER,
            UPDATE_CARRIER_ICON: window.locales.events.UPDATE_CARRIER_ICON,
            ADD_FILE: window.locales.events.ADD_FILE,
            CREATE_SUBSCRIBER: window.locales.events.CREATE_SUBSCRIBER,
            UPDATE_SUBSCRIBER: window.locales.events.UPDATE_SUBSCRIBER,
            DELETE_USER_DATA: window.locales.events.DELETE_USER_DATA,
            SET_TRAFFIC_LIMIT: window.locales.events.SET_TRAFFIC_LIMIT,
            DELETE_TRAFFIC_LIMIT: window.locales.events.DELETE_TRAFFIC_LIMIT,
            DELETE_DEVICE: window.locales.events.DELETE_DEVICE,
            CREATE_CARRIER_COUNTRY: window.locales.events.CREATE_CARRIER_COUNTRY,
            DELETE_CARRIER_COUNTRY: window.locales.events.DELETE_CARRIER_COUNTRY,
            MASS_USER_UPDATE: window.locales.events.MASS_USER_UPDATE,
            GENERATE_REPORT: window.locales.events.GENERATE_REPORT,
        };

        for (const k in map) {
            types.push([k, map[k]]);
        }

        return types;
    }

    createAuthorsOptions() {
        const authors = [];

        for (const k in this.props.admins) {
            authors.push([this.props.admins[k].email, this.props.admins[k].email]);
        }

        return authors;
    }

    changeVisibility() {
        this.setState({ show: !this.state.show });
    }

    search() {
        this.props.setParams(this.form);
    }

    resetSearch() {
        this.form = {
            end_time: Date.now(),
            start_time: Date.now() - 24 * 60 * 60 * 1000,
        };
        this.props.setParams(this.form);
    }
}

export default Filter;
