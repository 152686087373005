import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import * as style from "@components/Header/style.scss";
import * as arrowsStyle from "@components/Arrows/style.scss";
import { If } from "@components/If";
import { SideMenu } from "@components/Menu";
import { RootState } from "@state/index";
import { CatchOutsideClick } from "@common/methods/catchOutsideClick";

const Role = ({ role }: { role: string }) => (
    <If condition={!!role}>
        <div className={style.roleContainer}>{role}</div>
    </If>
);

const Profile = () => {
    const menuRef = useRef(null);
    const activeProject = useSelector((state: RootState) => state.activeProject);

    const account = useSelector((state: RootState) => state.account);
    const [isMenu, setIsMenu] = useState(false);
    CatchOutsideClick(menuRef, () => setIsMenu(false));

    const role = useMemo(() => {
        if (activeProject.publickey) {
            return window.locales.roles[activeProject.current_user_role.name].name;
        }
        return "";
    }, [activeProject.current_user_role.name, activeProject.publickey]);

    return (
        <div ref={menuRef}>
            <div onClick={() => setIsMenu(!isMenu)}>
                <Role role={role} />
                <div className={style.emailContainer}>
                    <span>{account.name}</span>
                    <If condition={!!account.name}>
                        <i className={isMenu ? arrowsStyle.arrowUp : arrowsStyle.arrowDown} />
                    </If>
                    <If condition={isMenu}>
                        <SideMenu />
                    </If>
                </div>
            </div>
        </div>
    );
};

export default Profile;
