import { call, put, select, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";

import { deleteAccount, getAccount, setAccount, updateAccount } from "@state/account";
import { getInfoFromFirebase } from "@services/firebase/InitApp";
import { signOut } from "@services/firebase";
import { clearStore } from "@state/index";
import RootState from "@state/interfaces";
import { IAccount } from "@state/account/types";
import { getToken } from "@state/token";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { successInfo } from "@state/success";
import { deleteTokenInStorage } from "@common/tokenStorage";

const selectAccount = (state: RootState) => state.account;
const selectActiveProject = (state: RootState) => state.activeProject;

export function* updateAccountSaga(action: PayloadAction<IAccount>): SagaIterator {
    try {
        const account = yield select(selectAccount);
        const getData = window.fb.default.database().ref(`/users/${account.uid}`).once("value");

        let result = yield call(() => getData);
        result = result && result.val && result.val() ? result.val() : {};

        const setData = window.fb.default
            .database()
            .ref(`/users/${account.uid}`)
            .set({
                ...result,
                ...action.payload,
            });

        yield call(() => setData);

        yield put(
            setAccount({
                ...account,
                isOkta: false,
                infoFromFirebase: { ...action.payload },
            }),
        );

        successInfo.setSuccessInfo();
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* deleteAccountSaga(): SagaIterator {
    try {
        deleteTokenInStorage();
        yield put(clearStore());
        yield call(signOut);
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* getAccountSaga(): SagaIterator {
    try {
        const activeProject = yield select(selectActiveProject);
        const { currentUser } = window.fb.default.auth();
        if (!currentUser) {
            return;
        }

        const { uid, phoneNumber, email, emailVerified } = currentUser;
        const firebaseToken = yield call(() => currentUser.getIdToken(true));

        const infoFromFirebase = yield call(() => getInfoFromFirebase(uid));

        const user = {
            uid,
            authorized: true,
            phoneNumber,
            name: email,
            verified: emailVerified,
            firebaseToken,
            isOkta: false,
            infoFromFirebase,
        };

        yield put(setAccount(user));
        if (activeProject.publickey) {
            yield put(getToken(activeProject));
        }
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export const AccountSagas = [
    takeEvery(getAccount.type, getAccountSaga),
    takeEvery(deleteAccount.type, deleteAccountSaga),
    takeEvery(updateAccount.type, updateAccountSaga),
];
