import React, { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { deleteAccount } from "@state/account";
import * as style from "@components/Menu/style.scss";
import { RootState } from "@state/index";
import { AuthContext } from "@common/auth-context";
import { EAuthorizationStatus } from "@interfaces";
import { deleteTokenInStorage } from "@common/tokenStorage";

const SideMenu = memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);
    const [, setAuthorizationStatus] = useContext(AuthContext);

    const logOut = async () => {
        deleteTokenInStorage();
        dispatch(deleteAccount());
        setAuthorizationStatus(EAuthorizationStatus.Unauthorized);
    };

    const changeLocales = (lang: string) => () => {
        window.setLocales(lang);
        location.reload();
    };

    return (
        <div className={style.menuContainer}>
            {!account.isOkta && (
                <div className={style.menuItems} onClick={() => navigate("/profile")}>
                    {window.locales.viewProfile}
                </div>
            )}
            <div className={style.menuItems}>
                {window.locales.locales}
                <div className={style.localesItems}>
                    <span className={style.localesItem} onClick={changeLocales("en")}>
                        {window.locales.english}
                    </span>
                    <span className={style.localesItem} onClick={changeLocales("jp")}>
                        {window.locales.japanese}
                    </span>
                </div>
            </div>
            <div className={style.menuItems} onClick={logOut}>
                {window.locales.signOut}
            </div>
        </div>
    );
});

export { SideMenu };
