import { Field, Formik } from "formik";
import React from "react";

import { Modal } from "@components/Modal";
import { TextAreaField, TextField } from "@components/FormikFields";

import { TLocation } from "../types";

const LocationModal = ({
    title,
    isOpen,
    closeModal,
    action,
    location,
}: {
    title: string;
    isOpen: boolean;
    closeModal: () => void;
    action: (item: TLocation) => void;
    location: TLocation;
}) => (
    <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={title}
        confirm={{
            label: window.locales.confirm,
            onConfirm: () => {},
        }}
        /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
        typeButton="submit"
        nameForm="tokenForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                name: location.name,
                labels: location.labels,
                server_selector: location.server_selector,
                description: location.description,
            }}
            validate={({ name, labels, server_selector }) => {
                const errors: any = {};
                const { emptyName, invalidConfig } = window.locales;
                if (!name) errors.name = emptyName;

                try {
                    typeof server_selector === "string" && JSON.parse(server_selector);
                } catch (e) {
                    errors.server_selector = invalidConfig;
                }

                try {
                    typeof labels === "string" && JSON.parse(labels);
                } catch (e) {
                    errors.labels = invalidConfig;
                }

                return errors;
            }}
            onSubmit={(values) => {
                const { name = "", server_selector, labels, description = "" } = values;
                const instanceServerSelector =
                    typeof server_selector === "string" ? JSON.parse(server_selector) : server_selector;
                const instanceLabels = typeof labels === "string" ? JSON.parse(labels) : labels;

                action({
                    name: name.trim(),
                    description: description.trim(),
                    server_selector: instanceServerSelector,
                    labels: instanceLabels,
                });
                closeModal();
            }}
        >
            {({ values, handleSubmit }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="tokenForm" onSubmit={handleSubmit}>
                    <section>
                        <Field
                            component={TextField}
                            placeholder="Type name"
                            type="text"
                            name="name"
                            disabled={location.name}
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.labels}
                            placeholder="Type labels"
                            type="text"
                            name="labels"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.server_selector}
                            placeholder="Type server selector"
                            type="text"
                            name="server_selector"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.description}
                            placeholder="Type description"
                            type="text"
                            name="description"
                        />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { LocationModal };
