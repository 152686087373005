import React from "react";

import * as supportStyle from "@screens/Settings/Members/Supports/style.scss";
import iconIOs from "@common/img/svg/ios-icon.svg";

const MACOsSupport = () => (
    <span className={supportStyle.icon}>
        <img src={iconIOs} alt="ios" />
    </span>
);

export { MACOsSupport };
