import React, { useEffect, useRef, useState } from "react";
import { Field, Formik } from "formik";

import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { CustomInput } from "@screens/Landing/Authorization/Input";
import { If } from "@components/If";
import { InputCode } from "@screens/Landing/Authorization/InputCode";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";

// Validate phone number
const validatePhoneNumberRegex = /^\+[1-9][0-9]{7,14}$/;

const PhoneForm = ({ goToForgotPwd }: { goToForgotPwd: () => void }) => {
    const refCaptcha = useRef(null);
    const buttonRef = useRef(null);
    const verificationId = useRef(null);
    const [userName, setUserName] = useState("");

    // // TODO: Change states to reducer
    const [isError, setIsError] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");

    useEffect(() => {
        const { currentUser } = window.fb.default.auth();
        setUserName(currentUser.email);
        refCaptcha.current = new window.fb.default.auth.RecaptchaVerifier(buttonRef.current, {
            size: "invisible",
        });
    }, []);

    const usingReCaptcha = async (setErrors: any, phoneNumber: string, password: string) => {
        try {
            setIsProcess(true);
            const { currentUser } = window.fb.default.auth();
            const credential = window.fb.default.auth.EmailAuthProvider.credential(currentUser.email, password);
            await currentUser.reauthenticateWithCredential(credential);
            const multiFactorSession = await currentUser.multiFactor.getSession();
            const phoneInfoOptions = {
                phoneNumber,
                session: multiFactorSession,
            };

            const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
            verificationId.current = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, refCaptcha.current);

            setCurrentPhoneNumber(phoneNumber);
        } catch (e: any) {
            const error: IErrorResponse = e;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
            setErrors({
                phone: " ",
                password: " ",
            });
        } finally {
            setIsError(false);
            setIsProcess(false);
        }
    };

    const enrollPhone = async (code: string) => {
        try {
            setIsProcess(true);
            if (!verificationId.current) return;
            const cred = window.fb.default.auth.PhoneAuthProvider.credential(verificationId.current, code);
            const multiFactorAssertion = window.fb.default.auth.PhoneMultiFactorGenerator.assertion(cred);
            await window.fb.default.auth().currentUser.multiFactor.enroll(multiFactorAssertion);
            location.reload();
        } catch (e: any) {
            setIsError(true);
            const error: IErrorResponse = e;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Verification</div>
                    <div className={authorizationStyle.text}>
                        <If condition={!currentPhoneNumber}>
                            <>
                                Enter phone number for <span>{userName}</span> to enable two-factor authentication
                            </>
                        </If>
                        <If condition={!!currentPhoneNumber}>
                            <>
                                Enter 6-digit code, we’ve sent to the number <span>{currentPhoneNumber}</span>
                                <div
                                    onClick={() => setCurrentPhoneNumber("")}
                                    className={authorizationStyle.buttonEdit}
                                >
                                    {window.locales.reset}
                                </div>
                            </>
                        </If>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        phone: "",
                        password: "",
                    }}
                    validate={({ password, phone }) => {
                        const errors: any = {};
                        if (!password) errors.password = window.locales.invalidEmptyPassword;
                        if (!phone) errors.phone = window.locales.invalidEmptyPhoneNumber;
                        if (!validatePhoneNumberRegex.test(phone)) errors.phone = window.locales.invalidPhoneNumber;
                        return errors;
                    }}
                    onSubmit={() => {}}
                >
                    {({ handleSubmit, values, setErrors, isValid }) => (
                        <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                            <If condition={!currentPhoneNumber}>
                                <>
                                    <div className={authorizationStyle.fieldContainer}>
                                        <Field
                                            component={CustomInput}
                                            value={values.password}
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div className={authorizationStyle.fieldContainer}>
                                        <Field
                                            component={CustomInput}
                                            value={values.phone}
                                            type="text"
                                            name="phone"
                                            placeholder="Phone number (e.g. +16505550101)"
                                        />
                                    </div>
                                    <div className={authorizationStyle.infoContainer}>
                                        <span>Forgot your password?</span>
                                        <div className={authorizationStyle.buttonResetPassword} onClick={goToForgotPwd}>
                                            Reset password
                                        </div>
                                    </div>
                                    <div className={authorizationStyle.buttonContainer}>
                                        <button
                                            type="button"
                                            disabled={!isValid || isProcess}
                                            className={buttonStyle.buttonSubmit}
                                            onClick={() =>
                                                isValid && usingReCaptcha(setErrors, values.phone, values.password)
                                            }
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </>
                            </If>
                            <If condition={!!currentPhoneNumber}>
                                <>
                                    <div className={authorizationStyle.fieldContainer}>
                                        <InputCode
                                            isError={isError}
                                            submit={(num: string) => {
                                                setIsError(false);
                                                enrollPhone(num);
                                            }}
                                        />
                                    </div>
                                </>
                            </If>
                            <div ref={buttonRef} />
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default PhoneForm;
