import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
    margin-right: 5px;
    cursor: pointer;
    &:hover {
        path {
            fill: #ffffff;
        }
    }
`;

export default (props) => (
    <Icon width={props.width} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.2751 6.29286C12.6877 7.89172 11.9141 9.54215 10.4184 10.2642C9.85105 10.5221 9.79948 11.0894 9.7479 11.6052H8.20062C8.2522 10.4189 8.56166 9.38742 9.7479 8.82008C10.5731 8.45905 10.9857 7.58226 10.7278 6.70547C10.3152 5.00347 7.78802 4.79716 7.22068 6.91178H5.62183C6.44704 2.68255 11.4499 2.992 12.2751 6.29286Z"
            fill="#B2B9C4"
        />
        <path
            d="M9.02565 12.6367C9.43826 12.6367 9.79929 12.9978 9.79929 13.4104C9.79929 13.8745 9.43826 14.2356 9.02565 14.2356C8.56147 14.2356 8.20044 13.8745 8.20044 13.4104C8.20044 12.9978 8.56147 12.6367 9.02565 12.6367Z"
            fill="#B2B9C4"
        />
        <path
            d="M9.02579 0C13.9771 0 18 4.02292 18 9.02579C18 13.9771 13.9771 18 9.02579 18C4.02292 18 0 13.9771 0 9.02579C0 4.02292 4.02292 0 9.02579 0ZM9.02579 1.2894C4.74499 1.2894 1.2894 4.74499 1.2894 9.02579C1.2894 13.255 4.74499 16.7106 9.02579 16.7106C13.255 16.7106 16.7106 13.255 16.7106 9.02579C16.7106 4.74499 13.255 1.2894 9.02579 1.2894Z"
            fill="#B2B9C4"
        />
    </Icon>
);
