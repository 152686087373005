import React, { useMemo, useReducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch, useSelector } from "react-redux";

import Table from "@components/Table";
import { TPools } from "@screens/Network/types";
import { Modal } from "@components/Modal";
import { changePool } from "@state/pools";
import { accumulatePools, headings } from "@screens/Network/Pool/Locations/utils";
import { If } from "@components/If";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import RootState from "@state/interfaces";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import { LocationModal } from "@screens/Network/Pool/Locations/LocationModal";
import * as buttonStyle from "@components/Button/style.scss";
import { defaultPool } from "@screens/Network/Pool/utils";
import { IconPlus } from "@common/icons";

import { TLocation } from "./types";

const initLocation: TLocation = {
    name: "",
    description: "",
    labels: {
        city: "",
        country: "",
        subdivision: "",
    },
    server_selector: {
        server_city: "",
        server_country: "",
    },
};

const initialState = initialStateModal(initLocation);
const reducerLocation = reducerModal(initialState);

const PoolLocationsProject = () => {
    const pool = useSelector((state: RootState) => state.pools.pool ?? defaultPool);
    const dispatch = useDispatch();
    const pools: TPools = useSelector((state: RootState) => state.pools);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const canWrite = useMemo(
        () =>
            pool.permissions.find((permission: { subject: string; can_write: boolean }) => {
                const { subject, can_write } = permission;
                return subject === activeProject.publickey && can_write;
            }),
        [activeProject.publickey, pool.permissions],
    );
    const [modals, dispatchModal] = useReducer(reducerLocation, initialState);

    const showEditModal = (location: TLocation) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: location,
        });
    };

    const showDeleteModal = (location: TLocation) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: location,
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initLocation,
        });
    };

    const addLocation = (location: TLocation) => {
        dispatch(
            changePool({
                pool: pools.pool.name,
                body: {
                    add_locations: [location],
                },
            }),
        );
    };

    const editLocation = (location: TLocation) => {
        dispatch(
            changePool({
                pool: pools.pool.name,
                body: {
                    add_locations: [location],
                    del_locations: [location.name],
                },
            }),
        );
    };

    const deleteLocation = () => {
        dispatch(
            changePool({
                pool: pool.name,
                body: {
                    del_locations: [modals.item.name],
                },
            }),
        );
        closeModal();
    };

    const body = useMemo(() => {
        const { locations } = cloneDeep(pool);
        if (locations) {
            return accumulatePools(locations, showEditModal, showDeleteModal, canWrite);
        }
        return [];
    }, [pool, canWrite]);

    return (
        <>
            <Table tableData={body} headings={headings} title="PoolLocations" emptyMessage={window.locales.noLocations}>
                <If condition={!!canWrite}>
                    <div className={poolStyle.extraButtons}>
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: initLocation,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </If>
            </Table>

            {/* Modal add location */}
            <LocationModal
                title={window.locales.addLocationRules}
                isOpen={modals.createModal}
                closeModal={closeModal}
                action={addLocation}
                location={modals.item}
            />

            {/* Modal edit location */}
            <LocationModal
                title={window.locales.editLocationRules}
                isOpen={modals.editModal}
                closeModal={closeModal}
                action={editLocation}
                location={modals.item}
            />

            {/* Modal delete location */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.deleteLocationRules}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteLocation,
                }}
            >
                Do you want to delete the pool location?
            </Modal>
        </>
    );
};

export default PoolLocationsProject;
