import React from "react";
import { Editor } from "@monaco-editor/react";

import { Modal } from "@components/Modal";
import { monacoEditorOptions } from "@common/constant/monacoOption";

type ServerStatusModalProps = {
    closeModal: () => void;
    item: Record<string, unknown> | null;
};

function ServerStatusModal({ closeModal, item }: ServerStatusModalProps) {
    return (
        <Modal
            isOpen={!!item}
            onClose={closeModal}
            title={window.locales.server}
            confirm={{
                label: window.locales.confirm,
                onConfirm: closeModal,
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="tokenForm"
            disabled={false}
        >
            <Editor
                height="60vh"
                options={monacoEditorOptions}
                value={JSON.stringify(item, undefined, 4)}
                theme="vs-dark"
                defaultLanguage="json"
            />
        </Modal>
    );
}

export default ServerStatusModal;
