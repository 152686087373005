export const numberToIsoString = (expireDate: number) => {
    if (!expireDate) return "";
    const date = new Date(expireDate * 1000);
    const pad = (num: number) => (num < 10 ? "0" : "") + num;

    const isoString = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(
        date.getHours(),
    )}:${pad(date.getMinutes())}:${pad(date.getSeconds())}.000`;

    return isoString.substring(0, isoString.length - 1);
};
