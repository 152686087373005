import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Field, Formik } from "formik";
import cloneDeep from "lodash/cloneDeep";

import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import iconRefresh from "@common/img/svg/refresh-icon.svg";
import { TargetIcon } from "@components/TargetIcon";
import Table from "@components/Table/";
import { deleteUser, getRoles, loadUsers, setRole } from "@services/Access/access.service";
import { IconPlus } from "@common/icons";
import { Modal } from "@components/Modal";
import { deleteUserInvitationRequest, getListInvitationRequest, sendInviteUserRequest } from "@services/Invite";
import RootState from "@state/interfaces";
import { EModals, ESupport, IMember, IMemberModal, TAction, TInvite, TRow } from "@screens/Settings/Members/types";
import { testEmail } from "@common/methods/validation";
import { editActiveProject } from "@state/activeProject";
import { capitalize } from "@common/methods/capitalize";
import { Supports } from "@screens/Settings/Members/Supports";
import { TextField, CustomSelect } from "@components/FormikFields";
import Checkbox from "@components/Checkbox";
import { If } from "@components/If";
import * as style from "@screens/Settings/Members/style.scss";
import * as settingsStyle from "@screens/Settings/style.scss";
import * as table from "@components/Table/style.scss";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { successInfo } from "@state/success";
import { AdminType } from "@services/Access/Access.types";
import * as buttonStyle from "@components/Button/style.scss";
import * as tableContainerStyle from "@components/TableContainer/style.scss";
import { ConfigType } from "@screens/Projects/Projects.types";

import { Permissions } from "../../../enums";
import { StatusInvite } from "./components";

const initialStateModal = {
    editMemberModal: false,
    deleteMemberModal: false,
    deleteInviteModal: false,
    resendInviteModal: false,
    createInviteModal: false,
    member: null,
};

const headings = [
    {
        isSort: true,
        heading: window.locales.email,
    },
    {
        isSort: true,
        heading: window.locales.role,
    },
    {
        isSort: false,
        heading: window.locales.inviteStatus,
    },
    {
        isSort: false,
        heading: window.locales.clientSupport,
    },
];

enum ERole {
    Owner = "OWNER",
    Admin = "ADMIN",
}

const invitesRows = (invites: TInvite[], currentRole: string, action: (modal: EModals, member: IMember) => void) =>
    invites.map((invite) => {
        const { user_email, admin_role, expire_time, code } = invite;
        const date = expire_time * 1000;
        const time = new Date(date);
        const isExpiredDate = date <= Date.now();
        const status = (
            <div className={style.cellContainer}>
                <span>Invited</span>
                <StatusInvite $isExpired={isExpiredDate}>
                    {isExpiredDate ? "Expired" : `Expires on ${time.toLocaleString()}`}
                </StatusInvite>
            </div>
        );

        const row: TRow = {
            email: user_email,
            role: capitalize(admin_role.toLowerCase()),
            inviteStatus: {
                component: status,
            },
            clientSupport: "",
        };

        if (currentRole === ERole.Owner) {
            row.buttons = {
                component: (
                    <div className={table.buttonsColumn}>
                        {isExpiredDate && (
                            <TargetIcon
                                onClick={() =>
                                    action(EModals.ResendInvite, {
                                        email: user_email,
                                        role: admin_role,
                                    })
                                }
                            >
                                <img src={iconRefresh} alt="img" />
                            </TargetIcon>
                        )}
                        <TargetIcon
                            onClick={() =>
                                action(EModals.DeleteInvite, {
                                    email: user_email,
                                    code,
                                })
                            }
                        >
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            };
        }

        return row;
    });

const membersRows = (
    users: AdminType[],
    currentRole: string,
    action: (type: EModals, member: IMember) => void,
    application: {
        ios?: { support: string[] };
        android?: { support: string[] };
        macos?: { support: string[] };
        windows?: { support: string[] };
    },
) =>
    users.map((user) => {
        const {
            email,
            role: { name },
        } = user;
        const nameOfRole = capitalize(window.locales.roles[name].name.toLowerCase());
        const row: TRow = {
            email,
            role: nameOfRole,
            inviteStatus: "Active",
        };

        const supports: ESupport[] = [];

        if (application) {
            const { ios, windows, android, macos } = application;
            ios?.support?.includes(email) && supports.push(ESupport.IOs);
            windows?.support?.includes(email) && supports.push(ESupport.Windows);
            android?.support?.includes(email) && supports.push(ESupport.Android);
            macos?.support?.includes(email) && supports.push(ESupport.MACOs);
        }

        row.clientSupport = { component: <Supports supports={supports} /> };

        if (currentRole === ERole.Owner) {
            row.buttons = {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon
                            onClick={() =>
                                action(EModals.EditMember, {
                                    email,
                                    role: name,
                                    supports,
                                })
                            }
                        >
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon
                            onClick={() =>
                                action(EModals.DeleteMember, {
                                    email,
                                })
                            }
                        >
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            };
        }

        return row;
    });

const reducerMembers = (state: IMemberModal, action: TAction) => {
    switch (action.type) {
        case EModals.EditMember:
            return {
                ...state,
                editMemberModal: true,
                member: action.payload,
            };
        case EModals.DeleteMember:
            return {
                ...state,
                deleteMemberModal: true,
                member: action.payload,
            };
        case EModals.ResendInvite:
            return {
                ...state,
                resendInviteModal: !state.resendInviteModal,
                member: action.payload,
            };
        case EModals.DeleteInvite:
            return {
                ...state,
                deleteInviteModal: !state.deleteInviteModal,
                member: action.payload,
            };
        case EModals.CreateInvite:
            return {
                ...state,
                createInviteModal: !state.createInviteModal,
                member: action.payload,
            };
        case EModals.CloseModals:
            return initialStateModal;
        default:
            return initialStateModal;
    }
};

const Members = () => {
    const dispatch = useDispatch();
    const [modals, dispatchModal] = useReducer(reducerMembers, initialStateModal);
    const [body, setBody] = useState<TRow[]>([]);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const permissions = useSelector((state: RootState) => state.permissions);
    const [rolesOptions, setRolesOptions] = useState<{ value: string; label: string }[] | []>([]);
    const [listMembers, setListMembers] = useState<string[]>([]);
    const [listInvites, setListInvites] = useState<string[]>([]);

    const getListInvitations = useCallback(async () => {
        const { current_user_role = { name: "" } } = activeProject;
        const {
            data: { invitations },
        } = await getListInvitationRequest(activeProject.publickey);
        setListInvites(invitations.map(({ user_email }: { user_email: string }) => user_email));
        return invitesRows(invitations, current_user_role.name, openModal);
    }, [activeProject]);

    const getUserRows = useCallback(async () => {
        const {
            data: { admins },
        } = await loadUsers(activeProject.publickey);
        const { current_user_role = { name: "" }, config } = activeProject;
        const {
            application = {
                ios: { support: [] },
                android: { support: [] },
                macos: { support: [] },
                windows: { support: [] },
            },
        } = config as ConfigType;
        setListMembers(admins.map(({ email }: { email: string }) => email));
        return membersRows(admins, current_user_role.name, openModal, application);
    }, [activeProject]);

    const openModal = (modal: EModals, member?: IMember) =>
        dispatchModal({
            type: modal,
            payload: member,
        });

    const closeModal = () =>
        dispatchModal({
            type: EModals.CloseModals,
            payload: null,
        });

    const deleteMember = async () => {
        try {
            const { member } = modals;
            await deleteUser(member.email, activeProject.publickey);
            successInfo.setSuccessInfo();
            closeModal();
            await getDataTable();
        } catch (e: any | IErrorResponse) {
            const error: IErrorResponse = e;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    const resendInvite = async () => {
        try {
            const { member } = modals;
            await sendInviteUserRequest(member.email, member.role, activeProject.publickey);
            successInfo.setSuccessInfo();
            closeModal();
            await getDataTable();
        } catch (e) {
            window.console.error(e);
        }
    };

    const createInvite = async (email: string, role: string) => {
        try {
            await sendInviteUserRequest(email, role, activeProject.publickey);
            successInfo.setSuccessInfo();
            closeModal();
            await getDataTable();
        } catch (e: any | IErrorResponse) {
            const error: IErrorResponse = e;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    const deleteInvite = async () => {
        try {
            const { member } = modals;
            await deleteUserInvitationRequest(member.code, activeProject.publickey);
            successInfo.setSuccessInfo();
            closeModal();
            await getDataTable();
        } catch (e) {
            window.console.error(e);
        }
    };

    const editSupports = (email: string, supports: string[]) => {
        try {
            const config = activeProject.config as ConfigType;
            let { application } = cloneDeep(config) as ConfigType;
            const {
                ios = {
                    support: [],
                },
                macos = {
                    support: [],
                },
                windows = {
                    support: [],
                },
                android = {
                    support: [],
                },
            } = application || {};

            if (Array.isArray(ios.support)) {
                ios.support = supports.includes(ESupport.IOs)
                    ? [...ios.support, email]
                    : ios.support.filter((user: string) => user !== email);

                ios.support.length > 0 &&
                    (application = {
                        ...application,
                        ios,
                    });
            }

            if (Array.isArray(macos.support)) {
                macos.support = supports.includes(ESupport.MACOs)
                    ? [...macos.support, email]
                    : macos.support.filter((user: string) => user !== email);

                macos.support.length > 0 &&
                    (application = {
                        ...application,
                        macos,
                    });
            }

            if (Array.isArray(windows.support)) {
                windows.support = supports.includes(ESupport.Windows)
                    ? [...windows.support, email]
                    : windows.support.filter((user: string) => user !== email);

                windows.support.length > 0 &&
                    (application = {
                        ...application,
                        windows,
                    });
            }

            if (Array.isArray(android.support)) {
                android.support = supports.includes(ESupport.Android)
                    ? [...android.support, email]
                    : android.support.filter((user: string) => user !== email);

                android.support.length > 0 &&
                    (application = {
                        ...application,
                        android,
                    });
            }

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config: {
                        ...config,
                        application,
                    },
                }),
            );
        } catch (e) {
            window.console.error(e);
        }
    };

    const editMember = async (email: string, role: string) => {
        try {
            await setRole(email, role, activeProject.publickey);
            successInfo.setSuccessInfo();
            closeModal();
            await getDataTable();
        } catch (e) {
            window.console.error(e);
        }
    };

    const getDataTable = useCallback(async () => {
        try {
            const membersBody = await getUserRows();
            const invitesBody = await getListInvitations();
            setBody([...membersBody, ...invitesBody]);
            const {
                data: { roles = [] },
            } = await getRoles();
            setRolesOptions(
                roles.map(({ name, description }: { name: string; description: string }) => ({
                    value: name,
                    label: description,
                })),
            );
        } catch (e) {
            window.console.error(e);
        }
    }, [getListInvitations, getUserRows]);

    useEffect(() => {
        if (activeProject.publickey) {
            getDataTable().then();
        }
    }, [activeProject.publickey, getDataTable]);

    if (!permissions.includes(Permissions.AccessRead)) {
        return <Navigate replace to={`/settings/${activeProject.publickey}`} />;
    }

    return (
        <div className={settingsStyle.tabContainer}>
            <Table
                title={window.locales.members}
                tableData={body}
                headings={headings}
                emptyMessage={window.locales.noMembers}
            >
                <If condition={activeProject.current_user_role.name === ERole.Owner}>
                    <div className={tableContainerStyle.toolsContainer}>
                        <div className={buttonStyle.buttonAdd}>
                            <IconPlus
                                theme="blue"
                                label={window.locales.add}
                                onClick={() => openModal(EModals.CreateInvite)}
                            />
                        </div>
                    </div>
                </If>
            </Table>

            {/* Modal deleting member */}
            <Modal
                isNegative
                isOpen={modals.deleteMemberModal}
                onClose={closeModal}
                title={window.locales.deleteMember}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteMember,
                }}
            >
                {`Do you really want to delete ${modals.member?.email}?`}
            </Modal>

            {/* Modal deleting invite */}
            <Modal
                isNegative
                isOpen={modals.deleteInviteModal}
                title={window.locales.deleteInvite}
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteInvite,
                }}
            >
                {`Do you really want to delete invite for ${modals.member?.email}?`}
            </Modal>

            {/* Modal resending invite */}
            <Modal
                isOpen={modals.resendInviteModal}
                onClose={closeModal}
                title={window.locales.resendInvite}
                confirm={{
                    label: window.locales.resend,
                    onConfirm: resendInvite,
                }}
            >
                {`Do you really want to resend code for ${modals.member?.email}?`}
            </Modal>

            {/* Modal sending invite */}
            <Modal
                isOpen={modals.createInviteModal}
                onClose={closeModal}
                title={window.locales.inviteMember}
                confirm={{
                    label: window.locales.invite,
                    onConfirm: () => {},
                }}
                /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
                typeButton="submit"
                nameForm="emailForm"
                disabled={false}
            >
                <Formik
                    initialValues={{
                        email: "",
                        role: "",
                    }}
                    validate={({ email, role }) => {
                        const errors: any = {};
                        if (!testEmail(email)) errors.email = window.locales.invalidEmail;
                        if (!role) errors.role = window.locales.invalidRole;
                        return errors;
                    }}
                    onSubmit={({ email, role }) => {
                        const lowerCaseEmail = email.toLowerCase().trim();
                        if (listMembers.includes(lowerCaseEmail)) {
                            closeModal();
                            return;
                        }

                        const include = listInvites.includes(lowerCaseEmail);
                        if (include) {
                            openModal(EModals.ResendInvite, {
                                email: lowerCaseEmail,
                                role,
                            });
                        } else {
                            createInvite(lowerCaseEmail, role).then();
                        }

                        closeModal();
                    }}
                >
                    {({ handleSubmit }) => (
                        /* ! add id to match nameForm from the Modal props  */
                        <form id="emailForm" onSubmit={handleSubmit}>
                            <section>
                                <Field component={TextField} type="text" name="email" />
                            </section>
                            <section>
                                <Field
                                    name="role"
                                    options={rolesOptions}
                                    component={CustomSelect}
                                    placeholder="Select role"
                                />
                            </section>
                        </form>
                    )}
                </Formik>
            </Modal>

            {/* Modal edit member */}
            <Modal
                isOpen={modals.editMemberModal}
                onClose={closeModal}
                title={window.locales.editMember}
                confirm={{
                    label: window.locales.save,
                    onConfirm: () => {},
                }}
                /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
                typeButton="submit"
                nameForm="editForm"
                disabled={false}
            >
                <Formik
                    initialValues={{
                        email: modals.member?.email,
                        role: modals.member?.role,
                        supports: modals.member?.supports || [],
                    }}
                    validate={({ role }) => {
                        const errors: any = {};
                        if (!role) errors.role = window.locales.invalidRole;
                        return errors;
                    }}
                    onSubmit={({ email, role, supports }) => {
                        editSupports(email, supports);
                        editMember(email, role).then();
                    }}
                >
                    {({ handleSubmit }) => (
                        /* ! add id to match nameForm from the Modal props  */
                        <form id="editForm" onSubmit={handleSubmit}>
                            <section>
                                <Field component={TextField} disabled type="text" name="email" />
                            </section>
                            <section>
                                <Field
                                    name="role"
                                    options={rolesOptions}
                                    component={CustomSelect}
                                    placeholder="Select role"
                                />
                            </section>
                            <section>
                                <div
                                    className={style.clientSupportContainer}
                                    role="group"
                                    aria-labelledby="checkbox-group"
                                >
                                    <label>
                                        <Field
                                            component={Checkbox}
                                            type="checkbox"
                                            name="supports"
                                            value={ESupport.IOs}
                                            text="iOs"
                                        />
                                    </label>
                                    <label>
                                        <Field
                                            component={Checkbox}
                                            type="checkbox"
                                            name="supports"
                                            value={ESupport.Android}
                                            text="Android"
                                        />
                                    </label>
                                    <label>
                                        <Field
                                            component={Checkbox}
                                            type="checkbox"
                                            name="supports"
                                            value={ESupport.MACOs}
                                            text="macOs"
                                        />
                                    </label>
                                    <label>
                                        <Field
                                            component={Checkbox}
                                            type="checkbox"
                                            name="supports"
                                            value={ESupport.Windows}
                                            text="Windows"
                                        />
                                    </label>
                                </div>
                            </section>
                        </form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default Members;
