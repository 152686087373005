import React, { ReactNode, useMemo } from "react";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { ThemeProvider } from "@mui/material/styles";
import { observer } from "mobx-react-lite";

import { theme } from "@screens/Objects/Object/KindForm/KindForm.utils";
import objectStore from "@state/object";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";

const KindFrom = observer(({ schema }: { schema: Record<string, unknown> }): ReactNode => {
    const value = useMemo(() => {
        try {
            return typeof objectStore.objectData.data === "string"
                ? JSON.parse(objectStore.objectData.data)
                : objectStore.objectData.data;
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
            return {};
        }
    }, [objectStore.objectData.data]);

    return (
        <ThemeProvider theme={theme}>
            <Form
                validator={validator}
                schema={schema}
                formData={value}
                onChange={(data) => {
                    objectStore.updateData(data.formData);
                }}
            >
                <div />
            </Form>
        </ThemeProvider>
    );
});

export default KindFrom;
