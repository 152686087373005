import React, { useMemo, useState } from "react";
import { ErrorMessage } from "formik";

import { CustomErrorMessage } from "@common/style";
import { FieldInput } from "@screens/Landing/Authorization/components";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import { getNameField } from "@components/FormikFields/utils";
import { Eye } from "@components/Eye/Eye";

const CustomInput = (data: any) => {
    const { field, form, type, autoComplete, disabled, onChange } = data;
    const [showPwd, setShowPwd] = useState(false);
    const name = getNameField(field.name);
    const typeOfInput = useMemo(() => {
        if (type === "password") {
            return showPwd ? "text" : "password";
        }
        return type;
    }, [showPwd, type]);

    return (
        <div className={authorizationStyle.inputContainer}>
            <div className={authorizationStyle.inputName}>{name}</div>
            <div className={authorizationStyle.input}>
                <FieldInput
                    $isInvalid={form.touched[field.name] && form.errors[field.name]}
                    type={typeOfInput}
                    {...field}
                    onChange={onChange ?? field.onChange}
                    placeholder={!form.touched[field.name] ? data.placeholder : ""}
                    autoComplete={autoComplete}
                    disabled={disabled}
                />
                {type === "password" && (
                    <div className={authorizationStyle.inputIcon}>
                        <Eye handle={() => setShowPwd(!showPwd)} isShow={!showPwd} />
                    </div>
                )}
            </div>
            <ErrorMessage component={CustomErrorMessage} name={field.name} />
        </div>
    );
};

export { CustomInput };
