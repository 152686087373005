import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";
import { successInfo } from "@state/success";

const loadTraffic = (uid) => {
    const request = `partner/subscribers/${uid}/traffic`;
    const access_token = getPortalTokenFromState();

    return new Promise((resolve, reject) => {
        Network.get(request, {}, access_token)
            .then((response) => {
                if (response.response && response.response.result && response.response.result === "OK") {
                    delete response.response.result;
                    return resolve(response.response);
                }
                return reject();
            })
            .catch(() => reject());
    });
};

const editLimits = (uid, data) =>
    new Promise((resolve, reject) => {
        const resetLimits = (url, params, access_token) =>
            Network.delete(url, params, null, access_token)
                .then(() => true)
                .catch(() => false);

        const request = `partner/subscribers/${uid}/traffic`;
        const access_token = getPortalTokenFromState();
        const params = {};

        if (data.unlimited || data.reset) {
            resetLimits(request, params, access_token).then((response) => {
                if (!response) return false;

                if (!data.unlimited && data.limit) {
                    params.traffic_limit = data.limit;

                    Network.post(request, params, null, access_token)
                        .then(() => {
                            successInfo.setSuccessInfo();
                            resolve();
                        })
                        .catch(() => reject({ message: "Internal server error" }));
                } else {
                    return resolve();
                }
            });
        } else {
            params.traffic_limit = data.limit;
            Network.post(request, params, null, access_token)
                .then(() => {
                    successInfo.setSuccessInfo();
                    resolve();
                })
                .catch(() => reject({ message: "Internal server error" }));
        }
    });

export { loadTraffic, editLimits };
