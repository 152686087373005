import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";

window.data.devices = {};

const getDevices = (uid, load) => {
    if (load && window.data.devices[uid]) delete window.data.devices[uid];

    return new Promise((resolve) => {
        if (window.data.devices[uid]) return resolve(window.data.devices[uid]);

        loadDevices(uid)
            .then((devices) => {
                window.data.devices[uid] = devices;
                return resolve(window.data.devices[uid]);
            })
            .catch(() => resolve([]));
    });
};

const loadDevices = (uid) =>
    new Promise((resolve, reject) => {
        const access_token = getPortalTokenFromState();
        const request = `partner/subscribers/${uid}/devices`;

        Network.get(request, {}, access_token)
            .then((response) => resolve(response.response.devices))
            .catch(() => reject());
    });

const deleteDevice = (uid, did) =>
    new Promise((resolve, reject) => {
        const access_token = getPortalTokenFromState();
        const request = `partner/subscribers/${uid}/devices/${did}`;

        Network.delete(request, {}, null, access_token)
            .then((response) => {
                if (response.response.result === "NOT_FOUND") {
                    return reject({ message: "Device not found" });
                }
                return resolve(true);
            })
            .catch(() => reject({ message: "Internal server error" }));
    });

export { getDevices, loadDevices, deleteDevice };
