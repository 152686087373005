import React from "react";

export default () => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.3421 27.9052C32.5993 25.1077 33.826 21.6493 33.826 18.0135C33.826 9.29407 26.7323 2.19995 18.0128 2.19995C9.2929 2.19995 2.19922 9.29407 2.19922 18.0135C2.19922 26.7299 9.2929 33.8232 18.0128 33.8232C21.704 33.8232 25.2002 32.5652 28.0211 30.2495L39.3352 41.6675L41.6778 39.3487L30.3421 27.9052ZM18.0128 32.1754C10.2011 32.1754 3.8479 25.8227 3.8479 18.0135C3.8479 10.2018 10.2011 3.84907 18.0128 3.84907C25.8211 3.84907 32.1777 10.2018 32.1777 18.0135C32.1777 25.8227 25.8211 32.1754 18.0128 32.1754Z"
            fill="#B2B9C4"
        />
        <path
            d="M23.0455 22.1276L18.9213 17.978L23.0424 13.8706L22.1457 12.9708L18.0242 17.0773L13.8987 12.9294L12.998 13.8253L17.1222 17.9754L13.0007 22.0819L13.897 22.9817L18.0193 18.8747L22.1448 23.023L23.0455 22.1276Z"
            fill="#B2B9C4"
        />
    </svg>
);
