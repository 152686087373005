import React, { Component } from "react";
import Select from "react-select";

const customStyles = {
    input: (styles) => ({
        ...styles,
        color: "#FFF",
    }),
};

class Select2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : undefined,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            value: props.value ? props.value : undefined,
        });
    }

    onChange(data) {
        let value;
        if (this.props.isMulti) {
            value = [];
            for (const k in data) {
                value.push(data[k].value);
            }
        } else {
            value = data.value;
        }
        this.setState(
            {
                value,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            },
        );
    }

    render() {
        const [options, value] = this.fillOptionsAndValue();

        const placeholder = this.props.placeholder ? this.props.placeholder : window.locales.selectPlaceholderDefault;

        const style = {
            width: "400px",
        };

        if (this.props.style) {
            for (const k in this.props.style) {
                style[k] = this.props.style[k];
            }
        }

        return (
            <div style={style}>
                <Select
                    options={options}
                    isMulti={!!this.props.isMulti}
                    className={`formSelect2 ${this.props.theme ? this.props.theme : "default"}`}
                    classNamePrefix="formSelect2"
                    label={this.props.label}
                    value={value}
                    placeholder={placeholder}
                    styles={customStyles}
                    onChange={this.onChange.bind(this)}
                />
            </div>
        );
    }

    fillOptionsAndValue() {
        let value = this.props.isMulti ? [] : {};
        const options = [];

        for (const k in this.props.options) {
            const option = this.props.options[k];

            options.push({
                value: option[0],
                label: option[1],
            });

            if (!this.state.value) continue;
            if (!!this.props.isMulti && this.state.value.indexOf(option[0]) !== -1) {
                value.push({
                    value: option[0],
                    label: option[1],
                });
            } else if (option[0] === this.state.value) {
                value = {
                    value: option[0],
                    label: option[1],
                };
            }
        }

        return [options, value];
    }
}

export default Select2;
