import { createSlice } from "@reduxjs/toolkit";

const purchasesSlice = createSlice({
    name: "purchases",
    initialState: [],
    reducers: {
        getPurchases() {},
        setPurchases(state, action) {
            return action.payload;
        },
    },
});

export const { getPurchases, setPurchases } = purchasesSlice.actions;

export default purchasesSlice.reducer;
