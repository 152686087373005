import React from "react";

import { Data } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import iconDelete from "@common/img/svg/delete-icon.svg";
import iconPen from "@common/img/svg/pen-icon.svg";
import { If } from "@components/If";
import { Eye } from "@components/Eye/Eye";
import { IPaymentMethod } from "@screens/Settings/Payments/types";
import * as table from "@components/Table/style.scss";

const headings = [
    {
        isSort: true,
        heading: window.locales.paymentMethod,
    },
    {
        isSort: false,
        heading: window.locales.paymentsSettings,
    },
    {
        isSort: false,
        heading: "",
    },
];

const accumulatePaymentMethods = (
    payments: IPaymentMethod[] = [],
    edit: boolean,
    editModal: (payment: IPaymentMethod) => void,
    deleteModal: (payment: IPaymentMethod) => void,
    handleVision: (purchase_type: string) => void,
): Data[] =>
    payments.map((payment) => {
        const { purchase_type = "", purchase_settings = null, show = false } = payment;
        const parsePurchaseSettings = purchase_settings ? JSON.stringify(purchase_settings) : "";

        return {
            payment_method: purchase_type,
            paymentsSettings: {
                component: (
                    <If condition={!!parsePurchaseSettings}>
                        <div className={table.tdJson}>{show ? parsePurchaseSettings : "•••••"}</div>
                    </If>
                ),
            },
            buttons: {
                component: (
                    <If condition={edit}>
                        <div className={table.buttonsColumn}>
                            <TargetIcon onClick={() => handleVision(purchase_type)}>
                                <Eye isShow={show} handle={() => {}} />
                            </TargetIcon>
                            <TargetIcon onClick={() => editModal(payment)}>
                                <img src={iconPen} alt="pen" />
                            </TargetIcon>
                            <TargetIcon onClick={() => deleteModal(payment)}>
                                <img src={iconDelete} alt="delete" />
                            </TargetIcon>
                        </div>
                    </If>
                ),
            },
        };
    });

export { accumulatePaymentMethods, headings };
