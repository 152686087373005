import { IFireShieldItem } from "@interfaces";
import { IFireshieldProps } from "@screens/Settings/Fireshield/types";

export const initFireShield: IFireshieldProps = {
    alert_page: {
        domain: "",
        path: "",
    },
    categories: [],
    domains: {},
    enabled: false,
    services: [],
};

const Services: IFireShieldItem[] = [
    {
        label: "bitdefender",
        value: "bitdefender",
    },
    {
        label: "securityzones",
        value: "securityzones",
    },
];

const Categories: IFireShieldItem[] = [
    {
        label: "safe",
        value: "safe",
    },
    {
        label: "unsafe",
        value: "unsafe",
    },
    {
        label: "unsafe:malware",
        value: "unsafe:malware",
    },
    {
        label: "unsafe:untrusted",
        value: "unsafe:untrusted",
    },
    {
        label: "unsafe:spam",
        value: "unsafe:spam",
    },
    {
        label: "unsafe:fraud",
        value: "unsafe:fraud",
    },
    {
        label: "unsafe:phishing",
        value: "unsafe:phishing",
    },
    {
        label: "custom",
        value: "custom",
    },
];

const Types: IFireShieldItem[] = [
    {
        label: "bypass",
        value: "bypass",
    },
    {
        label: "vpn",
        value: "vpn",
    },
    {
        label: "proxy_peer",
        value: "proxy_peer",
    },
    {
        label: "block_dns",
        value: "block_dns",
    },
    {
        label: "block_alert_page",
        value: "block_alert_page",
    },
];

export { Services, Categories, Types };
