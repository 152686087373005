import React, { memo, ReactElement } from "react";
import { NavLink, useParams } from "react-router-dom";

import * as localStyle from "@common/style/breadcrumbs.style.scss";
import * as style from "@screens/Objects/Object/ObjectWrapper/Header/ObjectHeader.style.scss";

const HeaderObject = memo((): ReactElement => {
    const { kind = "", name, namespace = "" } = useParams();

    return (
        <div className={style.objectHeaderContainer}>
            {(kind || name || namespace) && (
                <div className={localStyle.breadcrumbs}>
                    <NavLink
                        to={`../${namespace}`}
                        className={({ isActive }) =>
                            `${localStyle.breadcrumbs__item} ${!name && !kind && isActive && localStyle.isActive}`
                        }
                    >
                        {namespace}
                    </NavLink>
                    {kind && (
                        <NavLink
                            to={kind}
                            className={({ isActive }) =>
                                `${localStyle.breadcrumbs__item} ${!name && isActive && localStyle.isActive}`
                            }
                        >
                            {kind}
                        </NavLink>
                    )}
                    {name && (
                        <NavLink
                            to={`${kind}/${name}`}
                            className={({ isActive }) =>
                                `${localStyle.breadcrumbs__item} ${isActive && localStyle.isActive}`
                            }
                        >
                            {name.replaceAll("%dot%", ".")}
                        </NavLink>
                    )}
                </div>
            )}
        </div>
    );
});

export default HeaderObject;
