import React from "react";

import { TargetIcon } from "@components/TargetIcon";
import { TServerPool } from "@screens/Settings/VPN/PoolSelectionRules/types";
import * as table from "@components/Table/style.scss";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";

const headings = [
    {
        isSort: false,
        heading: window.locales.serverPool,
    },
    {
        isSort: false,
        heading: window.locales.device_id,
    },
    {
        isSort: false,
        heading: window.locales.device_type,
    },
    {
        isSort: false,
        heading: window.locales.user_country,
    },
    {
        isSort: false,
        heading: window.locales.username,
    },
    {
        isSort: false,
        heading: window.locales.protocol,
    },
    {
        isSort: false,
        heading: "",
    },
];

const accumulatePools = (
    serverPools: TServerPool[],
    editRule: (serverPool: TServerPool) => void,
    showDeleteModal: (serverPool: TServerPool) => void,
): any =>
    serverPools.map((serverPool) => {
        const { server_pool = "", device_id, username, protocol, user_country, device_type } = serverPool;

        return {
            server_pool,
            device_id,
            device_type,
            user_country,
            username,
            protocol,
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => editRule(serverPool)}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon onClick={() => showDeleteModal(serverPool)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };
    });

export { accumulatePools, headings };
