type TData = {
    direction: string;
    text: string;
};

const enum DIRECTIONS {
    RIGHT = "right",
    LEFT = "left",
    TOP = "top",
    BOTTOM = "bottom",
}

export { DIRECTIONS };

export type { TData };
