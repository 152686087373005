import React from "react";
import { NavigateFunction } from "react-router/dist/lib/hooks";

import { TableObjectProps } from "@components/Table/types";
import * as table from "@components/Table/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import { AuthUserDataType } from "@services/Auth/User/Auth.User.types";
import getDateUTCString from "@common/methods/getDateUTCString";

type UsersTableType = {
    id: TableObjectProps;
    username: TableObjectProps;
    create_time: TableObjectProps;
    buttons: TableObjectProps;
    event?: () => void;
};

const headingUsers = [
    {
        isSort: true,
        heading: window.locales.id,
    },
    {
        isSort: true,
        heading: window.locales.username,
    },
    {
        isSort: true,
        heading: window.locales.create_time,
    },
];

const accumulateUsers = (
    objects: AuthUserDataType[],
    navigate: NavigateFunction,
    onDelete: (user: AuthUserDataType) => void,
): UsersTableType[] =>
    objects.map((authUser: AuthUserDataType) => {
        const { id = "", username, create_time } = authUser as Required<AuthUserDataType>;
        return {
            id: {
                tooltip: {
                    title: id,
                    content: id,
                },
            },
            username: {
                tooltip: {
                    title: username,
                    content: username,
                },
            },
            create_time: {
                sortColumn: Number(new Date(create_time)),
                component: getDateUTCString(create_time),
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => navigate(id)}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon onClick={() => onDelete(authUser)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };
    });

export { accumulateUsers, headingUsers };
