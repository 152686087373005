import Select from "react-select";
import React from "react";

import { Categories, Types } from "@constants/fireshield";
import { customStyles } from "@screens/Settings/Fireshield/utils";
import { IconClose } from "@common/icons";
import { ISelector, ICategoryFormProps } from "@screens/Settings/Fireshield/types";
import * as fireshieldStyle from "@screens/Settings/Fireshield/style.scss";
import * as settingsStyle from "@screens/Settings/style.scss";

const CategoryForm = ({ setCategories, categories, setTypes, types }: ICategoryFormProps) => {
    const updateOptions = Categories.filter(
        (option) => !categories.some((listItem: ISelector) => listItem.value === option.value),
    );

    const selectedCategories = (selectedCategory: any, index: number) => {
        setCategories((prevState: any) => {
            if (prevState[index]) {
                prevState[index] = selectedCategory;
                return [...prevState];
            }
            setTypes((prev: any) => [...prev, ""]);
            return [...prevState, selectedCategory];
        });
    };

    const selectedTypes = (selectedType: ISelector | null, index: number) => {
        setTypes((prevState: any) => {
            prevState[index] = selectedType;
            return [...prevState];
        });
    };

    const deleteSelect = (index: number) => {
        const filteredTypes = types.filter((item: ISelector, i: number) => i !== index && item);
        const filteredCategories = categories.filter((item: ISelector, i: number) => i !== index && item);
        setCategories(filteredCategories);
        setTypes(filteredTypes);
    };

    return (
        <>
            {categories.map((value: ISelector, index: number) => (
                <div className={settingsStyle.twoFieldContainer} key={value.label}>
                    <div className={fireshieldStyle.selectContainer}>
                        <Select
                            isSearchable={false}
                            value={value}
                            onChange={(itemCategory) => selectedCategories(itemCategory, index)}
                            placeholder="categories"
                            styles={customStyles}
                            options={updateOptions}
                        />
                    </div>
                    <div className={fireshieldStyle.selectContainer}>
                        <div className={fireshieldStyle.containerWithIcon}>
                            <Select
                                isSearchable={false}
                                value={types[index]}
                                onChange={(itemType) => selectedTypes(itemType, index)}
                                placeholder="types"
                                styles={customStyles}
                                options={Types}
                            />
                            <div
                                className={fireshieldStyle.iconWrapper}
                                key={`${types[index]}`}
                                onClick={() => deleteSelect(index)}
                            >
                                <IconClose />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {updateOptions.length > 0 && (
                <div className={settingsStyle.twoFieldContainer}>
                    <div className={fireshieldStyle.selectContainer}>
                        <Select
                            isSearchable={false}
                            onChange={(itemType) => {
                                selectedCategories(itemType, Categories.length);
                            }}
                            placeholder="categories"
                            styles={customStyles}
                            options={updateOptions}
                            value={null}
                        />
                    </div>
                    <div className={fireshieldStyle.selectContainer}>
                        <Select isSearchable={false} placeholder="types" styles={customStyles} />
                    </div>
                </div>
            )}
        </>
    );
};

export { CategoryForm };
