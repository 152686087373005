import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { editActiveProject } from "@state/activeProject";
import { initFireShield, Services } from "@constants/fireshield";
import { RootState } from "@state/index";
import { validationDomain } from "@common/methods/сheckDomain";
import {
    customStyles,
    updateDomains,
    updateCategories,
    parseCategories,
    parseServices,
    parseDomains,
} from "@screens/Settings/Fireshield/utils";
import { DomainForm } from "@screens/Settings/Fireshield/DomainForm";
import { CategoryForm } from "@screens/Settings/Fireshield/CategoryForm";
import { ISelector } from "@screens/Settings/Fireshield/types";
import * as style from "@screens/Settings/Fireshield/style.scss";
import * as settingsStyle from "@screens/Settings/style.scss";

import { CustomCheckbox } from "./CustomCheckbox";
import { ButtonSettings, FieldInput } from "../components";

const Fireshield = () => {
    const dispatch = useDispatch();
    const activeProject = useSelector((state: RootState) => state.activeProject) || {
        publickey: "",
        config: {},
    };
    const defaultConfig = useMemo(() => activeProject.config, [activeProject.config]);
    const [config] = useState(defaultConfig);
    const fireShield = useMemo(() => {
        const data = defaultConfig.fireshield || {
            ...defaultConfig,
            fireshield: initFireShield,
        };

        return cloneDeep(data);
    }, [defaultConfig]);

    const [getTypes, setTypes] = useState<ISelector[]>([]);
    const [getCategories, setCategories] = useState<ISelector[]>([]);
    const [getServices, setServices] = useState<ISelector[]>([]);
    const [getEnabled, setEnabled] = useState(!!fireShield.enabled);
    const [getAlertPageDomain, setAlertPageDomain] = useState(() =>
        fireShield.alert_page ? fireShield.alert_page.domain : "",
    );
    const [getAlertPagePath, setAlertPagePath] = useState(() =>
        fireShield.alert_page ? fireShield.alert_page.path : "",
    );
    const [getDomains, setDomains] = useState(() => (fireShield.domains ? parseDomains(fireShield.domains) : []));
    const [getDomainsUrls, setDomainsUrls] = useState(() => (fireShield.domains ? fireShield.domains : {}));
    const [invalidAlertPageDomain, setInvalidAlertPageDomain] = useState(false);

    useEffect(() => {
        if (fireShield.categories) {
            setTypes(parseCategories(fireShield.categories, "type"));
            setCategories(parseCategories(fireShield.categories, "category"));
        }
    }, [fireShield.categories]);

    useEffect(() => {
        if (fireShield.services) {
            setServices(parseServices(fireShield.services));
        }
    }, [fireShield.services]);

    const saveConfig = () => {
        if (
            !validationDomain(getAlertPageDomain) &&
            getTypes.some((listItem: ISelector) => listItem.value === "block_alert_page")
        ) {
            setInvalidAlertPageDomain(true);
            return;
        }

        if (invalidAlertPageDomain) return;
        const newCategories = updateCategories(getCategories, getTypes);
        const newDomains = updateDomains(getDomainsUrls);
        const newServices = getServices.map(({ value }: { value: string }) => value);
        const newAlertPage =
            getAlertPagePath && getAlertPageDomain
                ? {
                      domain: getAlertPageDomain,
                      path: getAlertPagePath,
                  }
                : null;

        const newData: { [key: string]: any } = {
            ...(newAlertPage && { alert_page: newAlertPage }),
            enabled: getEnabled,
        };

        if (newCategories.length > 0) {
            newData.categories = newCategories;
        }

        if (Object.keys(newDomains).length > 0) {
            newData.domains = newDomains;
        }

        if (newServices.length > 0) {
            newData.services = newServices;
        }

        dispatch(
            editActiveProject({
                ...activeProject,
                config: {
                    ...config,
                    fireshield: newData,
                },
            }),
        );
    };

    const selectedServices = (selectedService: any) => setServices(selectedService);

    const changeDomain = (e: React.FormEvent<HTMLInputElement>) => {
        if (
            !validationDomain(e.currentTarget.value) &&
            getTypes.some((listItem: ISelector) => listItem.value === "block_alert_page")
        ) {
            setAlertPageDomain(e.currentTarget.value);
            setInvalidAlertPageDomain(true);
            return;
        }

        setInvalidAlertPageDomain(false);
        setAlertPageDomain(e.currentTarget.value);
    };

    const changePath = (e: React.FormEvent<HTMLInputElement>) => {
        setAlertPagePath(e.currentTarget.value);
    };

    return (
        <div className={settingsStyle.generalContainer}>
            <div className={settingsStyle.fieldContainer}>
                <div className={settingsStyle.nameFieldContainer}>
                    <div className={settingsStyle.nameField}>Enabled</div>
                </div>
                <div className={settingsStyle.fieldInputContainer}>
                    <CustomCheckbox onChange={() => setEnabled((prev) => !prev)} checked={getEnabled} />
                </div>
            </div>
            <div className={settingsStyle.fieldContainer}>
                <div className={settingsStyle.nameFieldContainer}>
                    <div className={settingsStyle.nameField}>Alert page</div>
                </div>
                <div className={settingsStyle.twoFieldContainer}>
                    <div className={style.inputContainer}>
                        <div className={style.inputName}>domain:</div>
                        <FieldInput
                            autoComplete="off"
                            maxLength={255}
                            $isInvalid={invalidAlertPageDomain}
                            placeholder="example.com"
                            value={getAlertPageDomain}
                            onChange={changeDomain}
                        />
                    </div>
                    <div className={style.inputContainer}>
                        <div className={style.inputName}>path:</div>
                        <FieldInput
                            autoComplete="off"
                            maxLength={255}
                            $isInvalid={false}
                            placeholder="example.html"
                            value={getAlertPagePath}
                            onChange={changePath}
                        />
                    </div>
                </div>
            </div>
            <div className={settingsStyle.fieldContainer}>
                <div className={settingsStyle.nameFieldContainer}>
                    <div className={settingsStyle.nameField}>Services</div>
                </div>
                <div className={settingsStyle.fieldInputContainer}>
                    <Select
                        onChange={selectedServices}
                        isMulti
                        value={getServices}
                        placeholder="pool"
                        styles={customStyles}
                        options={Services}
                    />
                </div>
            </div>
            <div className={settingsStyle.fieldContainer}>
                <div className={settingsStyle.nameFieldContainer}>
                    <div className={settingsStyle.nameField}>Categories</div>
                </div>
                <div className={style.elementsContainer}>
                    <CategoryForm
                        setCategories={setCategories}
                        categories={getCategories}
                        types={getTypes}
                        setTypes={setTypes}
                    />
                </div>
            </div>
            <div className={settingsStyle.fieldContainer}>
                <div className={settingsStyle.nameFieldContainer}>
                    <div className={settingsStyle.nameField}>Domains</div>
                </div>
                <div className={style.elementsContainer}>
                    <DomainForm
                        domains={getDomains}
                        setDomains={setDomains}
                        domainsUrl={getDomainsUrls}
                        setDomainsUrl={setDomainsUrls}
                    />
                </div>
            </div>
            <div className={settingsStyle.buttonContainer}>
                <ButtonSettings onClick={saveConfig}>{window.locales.saveChanges}</ButtonSettings>
            </div>
        </div>
    );
};

export default Fireshield;
