import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootSaga from "@state/saga";

import projects from "./projects";
import activeProject, { initActiveProject } from "./activeProject";
import permissions from "./permissions";
import objectPermissions from "./objectPermissions";
import users, { initUsers } from "./users";
import traffic from "./traffic";
import payments from "./payments";
import purchases from "./purchases";
import pools, { initPools } from "./pools";
import account from "./account";
import token, { initToken } from "./token";

const sagaMiddleware = createSagaMiddleware();

const clearStoreSlice = createSlice({
    name: "store",
    initialState: null,
    reducers: {
        clearStore() {},
        resetPartOfStore() {},
    },
});

export const { clearStore, resetPartOfStore } = clearStoreSlice.actions;

const combinedReducer = combineReducers({
    token,
    projects,
    activeProject,
    permissions,
    objectPermissions,
    users,
    traffic,
    payments,
    purchases,
    pools,
    account,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === clearStore.type) {
        state = undefined;
    }
    if (action.type === resetPartOfStore.type) {
        state = {
            ...state,
            activeProject: initActiveProject,
            payments: [],
            permissions: [],
            pools: initPools,
            objectPermissions: {},
            projects: [],
            users: initUsers,
            token: initToken,
        } as RootState;
    }

    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: false,
            serializableCheck: false,
            immutableCheck: false,
        }).concat(sagaMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);
