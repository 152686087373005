const getParameterByName = (name: string): string => {
    name = name.replace(/\[[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(window.location.href);
    if (!results) return "";
    if (!results[2]) return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export { getParameterByName };
