import React from "react";

import { ESort, IColumn, TableHeaderProps } from "@components/Table/types";
import ArrowSort from "@components/Table/TableHeader/ArrowSort";
import { getHeader } from "@components/Table/utils";
import * as style from "@components/Table/style.scss";

const Column = ({ isSort, heading, sortOptions, sort }: IColumn) => {
    if (!isSort) {
        return (
            <th className={style.th}>
                <div className={style.thContainer}>
                    <h6>{heading}</h6>
                </div>
            </th>
        );
    }

    const IsSorted = getHeader(heading) === sortOptions.heading;

    return (
        <th className={style.th}>
            <div className={style.thContainer}>
                <h6>{heading}</h6>
                <div
                    className={style.sortContainer}
                    onClick={() => sort(heading, sortOptions.type === ESort.Up ? ESort.Down : ESort.Up)}
                >
                    <ArrowSort sorted={IsSorted && sortOptions.type === ESort.Up} />
                    <ArrowSort sorted={IsSorted && sortOptions.type === ESort.Down} rotate />
                </div>
            </div>
        </th>
    );
};

const TableHeader = ({ headings, sort, sortOptions }: TableHeaderProps) => (
    <thead className={style.tHead}>
        <tr className={style.headersTR}>
            {headings.map(({ isSort, heading }, index) => (
                <Column key={index} isSort={isSort} sort={sort} heading={heading} sortOptions={sortOptions} />
            ))}
        </tr>
    </thead>
);

export { TableHeader };
