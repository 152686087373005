import styled from "styled-components";
import React from "react";

import * as dashboardStyle from "@screens/Dashboard/style.scss";

const ProgressBar = styled.div<{ $percent: number }>`
    width: ${($percent) => `${$percent}%`};
    background: ${(props) => props.color};
    height: 100%;
`;

const Percent = styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    position: absolute;
    top: 0;
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ({ percent }: { percent: number }) => {
    const getColor = (percent: number) => {
        if (percent < 30) return "#CAECFF";
        if (percent < 60) return "#8FD0F5";
        if (percent < 90) return "#00A2FF";
        return "#AB3962";
    };
    return (
        <div className={dashboardStyle.progressContainer}>
            <ProgressBar $percent={percent} color={getColor(percent)} />
            <Percent>{percent}%</Percent>
        </div>
    );
};
