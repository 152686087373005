import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Formik } from "formik";

import iconDelete from "@common/img/svg/delete-icon.svg";
import { editUserData } from "@services/Users/Users";
import { Modal } from "@components/Modal";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import { TextField } from "@components/FormikFields";
import { successInfo } from "@state/success";

const onlyNumbers = /^\d+$/;
const DeleteUser = () => {
    const { user, project } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const closeModal = () => setIsOpen(false);
    return (
        <div>
            <EnvIconContainer action={() => setIsOpen(true)}>
                <img src={iconDelete} alt="img" />
            </EnvIconContainer>
            <Modal
                isNegative
                isOpen={isOpen}
                onClose={closeModal}
                title={window.locales.deleteData}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: () => {},
                }}
                /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
                typeButton="submit"
                nameForm="userForm"
                disabled={false}
            >
                <Formik
                    initialValues={{
                        user_id: "",
                    }}
                    validate={({ user_id }) => {
                        const errors: Record<string, string> = {};
                        if (!onlyNumbers.test(user_id)) errors.user_id = window.locales.incorrectValue;
                        if (user !== user_id) errors.user_id = window.locales.incorrectValue;
                        return errors;
                    }}
                    onSubmit={async ({ user_id }) => {
                        await editUserData(user_id);
                        successInfo.setSuccessInfo();
                        navigate(`/projects/${project}/users`);
                    }}
                >
                    {({ handleSubmit }) => (
                        /* ! add id to match nameForm from the Modal props  */
                        <form id="userForm" onSubmit={handleSubmit}>
                            <section>
                                <Field component={TextField} type="text" name="user_id" placeholder="Enter user id" />
                            </section>
                        </form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default DeleteUser;
