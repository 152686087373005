import { makeAutoObservable } from "mobx";

type ErrorInfoType = {
    title?: string;
    description: string;
};

class ErrorObserver {
    isError = false;

    title = "";

    description = "";

    constructor() {
        makeAutoObservable(this);
    }

    setErrorInfo(errorInfo: ErrorInfoType) {
        this.isError = true;
        this.title = errorInfo.title || "";
        this.description = errorInfo.description;
    }

    resetErrorInfo() {
        this.isError = false;
    }
}

export const errorInfo = new ErrorObserver();
