import React, { Component } from "react";

import { deleteDevice, getDevices } from "@services/Devices";

import { connect } from "react-redux";
import Table from "@components/Table";
import { accumulateDevices, headingsType1, headingsType2 } from "@screens/Users/User/Content/Devices/utils";
import { Modal } from "@components/Modal";

class Devices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            user: props.selectedUser,
            deviceId: undefined,
            disabled: false,
            message: "",
            deleteModal: false,
        };

        this.dump = JSON.stringify(props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        const dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.setState(
            {
                user: props.selectedUser,
            },
            this.componentDidMount.bind(this),
        );
    }

    componentDidMount() {
        getDevices(this.state.user.id, true).then((devices) => {
            this.setState({
                devices,
            });
        });
    }

    toggleDeleteModal(id) {
        this.setState({
            deviceId: id,
            disabled: false,
            deleteModal: true,
        });
    }

    deleteDevice() {
        this.setState(
            {
                message: "",
                disabled: true,
            },
            () => {
                deleteDevice(this.state.user.id, this.state.deviceId)
                    .then(() => {
                        getDevices(this.state.user.id, true).then((devices) => {
                            this.setState(
                                {
                                    message: "",
                                    disabled: false,
                                    devices,
                                },
                                () => {
                                    this.modal.hide();
                                },
                            );
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            message: e.message,
                            disabled: false,
                        });
                    });
            },
        );
    }

    render() {
        const headings =
            this.props.activeProject.traffic_limit_type === "by_device" &&
            !this.props.traffic.traffic_unlimited &&
            this.props.activeProject.traffic
                ? [...headingsType1, ...headingsType2]
                : headingsType1;
        const body = accumulateDevices(
            this.state.devices,
            this.props.activeProject.traffic,
            this.toggleDeleteModal.bind(this),
        );

        return (
            <div className={"screenUsersContentContainer"}>
                <Table
                    tableData={body}
                    headings={headings}
                    title={window.locales.devices}
                    emptyMessage={window.locales.noDeviceRow}
                />

                {/* Modal delete location */}
                <Modal
                    isNegative
                    isOpen={this.state.deleteModal}
                    onClose={() => this.setState({ deleteModal: false })}
                    title={window.locales.deleteDevice}
                    confirm={{
                        label: window.locales.delete,
                        onConfirm: this.deleteDevice.bind(this),
                    }}
                >
                    {window.locales.deleteDeviceConfirmation}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeProject: state.activeProject,
    user: state.user,
    traffic: state.traffic,
    selectedUser: state.users.selectedUser,
});

export default connect(mapStateToProps)(Devices);
