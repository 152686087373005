import React from "react";

import * as style from "@components/ToggleSwitch/style.scss";

export const ToggleSwitch = (data: any) => {
    const { field } = data;

    return (
        <label className={style.switch}>
            <input
                className={style.checkBox}
                disabled={data.disabled}
                checked={data.value}
                onClick={data.onClick}
                type="checkbox"
                {...field}
            />
            <span className={style.slider} />
        </label>
    );
};
