import { DateRangePicker } from "rsuite";
import React from "react";
import "@components/DateFilter/DataFilter.style.less";

import { DataFilterType } from "@components/DateFilter/DataFilter.types";

function DateFilter({ period, changePeriod }: DataFilterType) {
    return <DateRangePicker appearance="default" value={period} onChange={(value) => changePeriod(value)} />;
}

export default DateFilter;
