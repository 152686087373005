type DateType = { date: string; time: string };
export default (time: number): DateType | null => {
    if (!time) return null;
    if (time < 946684800000) time *= 1000;

    const date = new Date(Number(time));
    const monthNames = window.locales.month;

    return {
        date: `${date.getUTCDate()} ${
            monthNames[date.getUTCMonth()]
        } ${date.getUTCFullYear()}${window.locales.yearSymbol}`,
        time: `${date.getUTCHours()}:${
            date.getUTCMinutes() > 9 ? date.getUTCMinutes() : `0${date.getUTCMinutes()}`
        } UTC`,
    };
};
