import React, { useMemo } from "react";
import { Field, Formik } from "formik";
import { v4 } from "uuid";

import { Modal } from "@components/Modal";
import * as style from "@screens/Settings/VPN/SNI/RuleModal/style.scss";
import { TextAreaField, TextField, CustomSelect } from "@components/FormikFields";
import { IHydraRuleModal, IRule, ISelector, TFormHydraPool } from "@screens/Settings/VPN/SNI/SNI.types";
import { If } from "@components/If";
import { optionsCountry, optionsState } from "@common/optionsState";

const pattern = /[!#$%^&*()?":{}|<>]/;
const getArray = (value: string) => value.replaceAll(" ", "").replace(";", ",").split(",");

const HydraRuleModal = ({ title, action, close, rule, isOpen, buttonName }: IHydraRuleModal) => {
    const data = useMemo(() => {
        const dataRule: TFormHydraPool = {
            device_type: "",
            user_country: "",
            user_state: "",
            app_version: "",
            sdk_version: "",
            description: "",
            user_city: "",
            domains: "",
        };

        if (rule) {
            const { description, patch, selector = {} } = rule;
            const {
                device_type = { in: [] },
                user = { in: [] },
                protocol = [],
                user_country = "",
                app_version = "",
                sdk_version = "",
                user_city = "",
                user_state = "",
            } = selector;

            dataRule.description = description;
            dataRule.user = user?.in?.join(", ");
            dataRule.device_type = device_type?.in?.join(", ");
            dataRule.domains = patch?.sd?.SNIs?.default.join(", ");
            dataRule.protocol = Array.isArray(protocol) ? protocol?.join(", ") : protocol;
            dataRule.app_version = app_version;
            dataRule.sdk_version = sdk_version;
            dataRule.user_country = user_country;
            dataRule.user_city = user_city;
            dataRule.user_state = user_state;
        }

        return dataRule;
    }, [rule]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={close}
            title={title}
            confirm={{
                label: buttonName,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="editForm"
            disabled={false}
        >
            <Formik
                initialValues={data}
                validate={({
                    domains,
                    description,
                    sdk_version,
                    app_version,
                    device_type,
                    user_city,
                    user_state,
                    user,
                    protocol,
                }) => {
                    const errors: any = {};
                    if (!domains) errors.domains = window.locales.emptyDomains;
                    if (description && pattern.test(description)) errors.description = window.locales.valueNotValid;
                    if (domains && pattern.test(domains)) errors.domains = window.locales.valueNotValid;
                    if (sdk_version && pattern.test(sdk_version)) errors.sdk_version = window.locales.valueNotValid;
                    if (app_version && pattern.test(app_version)) errors.app_version = window.locales.valueNotValid;
                    if (device_type && pattern.test(device_type)) errors.device_type = window.locales.valueNotValid;
                    if (user_city && pattern.test(user_city)) errors.user_city = window.locales.valueNotValid;
                    if (user_state && pattern.test(user_state)) errors.user_state = window.locales.valueNotValid;
                    if (protocol && pattern.test(protocol)) errors.protocol = window.locales.valueNotValid;
                    if (user && pattern.test(user)) errors.user = window.locales.valueNotValid;

                    return errors;
                }}
                onSubmit={({
                    device_type,
                    protocol,
                    user,
                    user_country,
                    app_version,
                    sdk_version,
                    description,
                    domains,
                    user_city,
                    user_state,
                }) => {
                    const listDomain = getArray(domains);
                    const selector: ISelector = {};

                    if (device_type) {
                        selector.device_type = { in: getArray(device_type) };
                    }

                    if (user) {
                        selector.user = { in: getArray(user) };
                    }

                    if (protocol) {
                        const valueOfProtocol = getArray(protocol);
                        const [oneValue] = valueOfProtocol;
                        selector.protocol = valueOfProtocol.length === 1 ? oneValue : valueOfProtocol;
                    }

                    if (user_country) {
                        selector.user_country = user_country;
                    }

                    if (user_city) {
                        selector.user_city = user_city;
                    }

                    if (user_state) {
                        selector.user_state = user_state;
                    }

                    if (app_version) {
                        selector.app_version = app_version;
                    }

                    if (sdk_version) {
                        selector.sdk_version = sdk_version;
                    }

                    const id = rule?.id || v4();
                    const patch = {
                        sd: {
                            SNIs: {
                                default: listDomain,
                            },
                        },
                    };

                    const role: IRule = {
                        id,
                        patch,
                        selector,
                    };

                    if (description) {
                        role.description = description;
                    }

                    action(role);
                    close();
                }}
            >
                {({ values, handleSubmit }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form className={style.ruleModal} id="editForm" onSubmit={handleSubmit}>
                        <div className={style.ruleField}>
                            <section>
                                <Field
                                    name="user_country"
                                    options={optionsCountry}
                                    component={CustomSelect}
                                    placeholder="Select country"
                                />
                            </section>
                            <section>
                                <If condition={values.user_country === "US"}>
                                    <Field
                                        component={CustomSelect}
                                        name="user_state"
                                        selected={values.user_state}
                                        placeholder="Select state"
                                        options={optionsState}
                                    />
                                </If>
                                <If condition={values.user_country !== "US"}>
                                    <Field
                                        name="user_state"
                                        component={TextField}
                                        value={values.user_state}
                                        type="text"
                                        placeholder="Select state"
                                    />
                                </If>
                            </section>
                            <section>
                                <Field
                                    name="user_city"
                                    component={TextField}
                                    value={values.user_city}
                                    type="text"
                                    placeholder="Select city"
                                />
                            </section>
                            <section>
                                <Field
                                    component={TextField}
                                    value={values.protocol}
                                    type="text"
                                    name="protocol"
                                    placeholder="Type names of protocol e.g protocol1, protocol2"
                                />
                            </section>
                        </div>
                        <div className={style.ruleField}>
                            <section>
                                <Field
                                    component={TextField}
                                    value={values.sdk_version}
                                    type="text"
                                    name="app_version"
                                    placeholder="App version"
                                />
                            </section>
                            <section>
                                <Field
                                    component={TextField}
                                    value={values.app_version}
                                    type="text"
                                    name="sdk_version"
                                    placeholder="SDK version"
                                />
                            </section>
                            <section className={style.lastField}>
                                <Field
                                    component={TextField}
                                    value={values.device_type}
                                    type="text"
                                    name="device_type"
                                    placeholder="Type names of device e.g windows, android"
                                />
                            </section>
                        </div>
                        <section>
                            <Field
                                component={TextAreaField}
                                value={values.user}
                                type="text"
                                name="user"
                                placeholder="Type names of user e.g id1, id2"
                            />
                        </section>
                        <section>
                            <Field
                                component={TextAreaField}
                                value={values.domains}
                                type="text"
                                name="domains"
                                placeholder="Type names of domain e.g example1.com, example2.com"
                            />
                        </section>
                        <section className={style.lastField}>
                            <Field
                                component={TextAreaField}
                                value={values.description}
                                type="text"
                                name="description"
                                placeholder="Type a description"
                            />
                        </section>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export { HydraRuleModal };
