import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Field, Formik } from "formik";

import { RootState } from "@state/index";
import { getNetwork, setVisibility } from "@services/Network";
import { accumulateCountries, headings, updateNetwork } from "@screens/Network/Countries/utils";
import Table from "@components/Table";
import { Data } from "@components/Table/types";
import { INetwork } from "@screens/Network/Countries/types";
import { CustomSelect } from "@components/FormikFields";
import { getCountryName } from "@common/methods";
import * as buttonStyle from "@components/Button/style.scss";
import * as tableContainerStyle from "@components/TableContainer/style.scss";
import { successInfo } from "@state/success";
import { IconPlus } from "@common/icons";

import { Permissions } from "../../../enums";

const Countries = () => {
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const permissions: string[] = useSelector((state: RootState) => state.permissions);
    const updateCountries = useMemo(() => permissions.includes(Permissions.ProjectCountriesUpdate), [permissions]);
    const [body, setBody] = useState<Data[]>([]);
    const [networks, setNetworks] = useState<INetwork[]>([]);

    const countryOptions = useMemo(
        () =>
            networks
                .filter((location) => !location.visibility)
                .map((location) => ({
                    label: getCountryName(location.country),
                    value: location.country,
                })),
        [networks],
    );

    const getNetworks = useCallback(() => {
        getNetwork(activeProject.publickey).then((network) => {
            setNetworks(network);
        });
    }, [activeProject.publickey]);

    const deleteCountries = useCallback(
        async (tba: INetwork[], country: INetwork) => {
            await setVisibility(activeProject.publickey, country.country, false);

            successInfo.setSuccessInfo();

            const network = updateNetwork(networks, country.country);
            setNetworks(network);
        },
        [activeProject.publickey, networks],
    );

    useEffect(() => {
        const countries = accumulateCountries(networks, deleteCountries, updateCountries);
        setBody(countries);
    }, [deleteCountries, networks, updateCountries]);

    useEffect(() => {
        if (activeProject.publickey) {
            getNetworks();
        }
    }, [activeProject.publickey, getNetworks]);

    return (
        <Table
            tableData={body}
            headings={headings}
            title={window.locales.country}
            emptyMessage={window.locales.noCountries}
        >
            <Formik
                initialValues={{
                    country: "",
                }}
                validate={() => {
                    const errors: any = {};
                    return errors;
                }}
                onSubmit={({ country }, { resetForm }) => {
                    setVisibility(activeProject.publickey, country, true).then(() => {
                        successInfo.setSuccessInfo();
                        getNetworks();
                        resetForm();
                    });
                }}
            >
                {({ values, handleSubmit }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="countryForm" onSubmit={handleSubmit}>
                        <div className={tableContainerStyle.toolsContainer}>
                            <section className={tableContainerStyle.selector}>
                                <Field
                                    component={CustomSelect}
                                    placeholder="Select country"
                                    value={values.country}
                                    name="country"
                                    disabled={!updateCountries}
                                    options={countryOptions}
                                />
                            </section>
                            <div>
                                <button
                                    className={buttonStyle.buttonAdd}
                                    type="submit"
                                    disabled={!values.country || !updateCountries}
                                >
                                    <IconPlus theme="blue" />
                                    {window.locales.add}
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Table>
    );
};

export default Countries;
