import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import authUserService from "@services/Auth/User/Auth.User.service";
import Table from "@components/Table";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import { useFilteredData } from "@common/hooks/useFilteredData";
import {
    accumulateUserEntitlements,
    headingUserEntitlements,
} from "@screens/Auth/AuthUser/AuthUserEntitlements/AuthUserEntitlements.utils";

const filterFunction =
    (searchValue: string) =>
    ([name]: [string, string]): boolean =>
        name.toLowerCase().includes(searchValue);

function AuthUserEntitlements(): ReactElement {
    const { project: namespace = "", user = "" } = useParams();
    authUserService.setProject(namespace);
    const { setData, setSearch, filteredList } = useFilteredData<[string, string]>(filterFunction);

    const getUserEntitlements = useCallback(async () => {
        const { data } = await authUserService.getEntitlements({ user_id: user });
        setData(Object.entries(data));
    }, [setData, user]);

    useEffect(() => {
        getUserEntitlements().then();
    }, [getUserEntitlements]);

    const tableData = useMemo(() => accumulateUserEntitlements(filteredList), [filteredList]);

    return (
        <>
            <Table
                tableData={tableData}
                headings={headingUserEntitlements}
                title="projects"
                emptyMessage={window.locales.noEntitlements}
            >
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={setSearch} />
                    </div>
                </div>
            </Table>
        </>
    );
}

export default AuthUserEntitlements;
