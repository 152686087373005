import React, { ReactElement } from "react";

import * as style from "@components/TargetIcon/style.scss";

const TargetIcon = ({ onClick, children }: { onClick: () => void; children: ReactElement }) => (
    <div
        className={style.targetIconContainer}
        onClick={(e) => {
            onClick();
            e.stopPropagation();
        }}
    >
        {children}
    </div>
);

export { TargetIcon };
