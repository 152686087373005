import React, { memo, ReactNode } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import activeSessionIcon from "@common/img/svg/SideMenu/active-session-icon.svg";
import dashboardIcon from "@common/img/svg/SideMenu/dashboard-icon.svg";
import userIcon from "@common/img/svg/SideMenu/user-icon.svg";
import networkIcon from "@common/img/svg/SideMenu/network-icon.svg";
import settingsIcon from "@common/img/svg/SideMenu/settings-icon.svg";
import exportDataIcon from "@common/img/svg/SideMenu/export-data-icon.svg";
import logIcon from "@common/img/svg/SideMenu/log-icon.svg";
import licenseKeyIcon from "@common/img/svg/SideMenu/license-key-icon.svg";
import iconLogoSmall from "@common/img/svg/pango-logo-white.svg";
import * as style from "@components/SideBar/style.scss";
import { EPage } from "@components/SideBar/types";
import { resetActiveProject } from "@state/activeProject";

const CustomLink = ({ children, page }: { children: ReactNode; page: EPage }) => {
    const { project } = useParams();
    return (
        <NavLink
            className={({ isActive }) => `${style.menuItemContainer} ${isActive && style.active}`}
            to={`${project}/${page}`}
        >
            {children}
        </NavLink>
    );
};

const SideBar = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div className={style.sideMenuContainer}>
            <div
                className={style.logoContainer}
                onClick={() => {
                    dispatch(resetActiveProject());
                    navigate("/projects");
                }}
            >
                <img src={iconLogoSmall} alt="pango" />
            </div>
            <CustomLink page={EPage.Dashboard}>
                <img src={dashboardIcon} alt="dashboard" />
                <span>{window.locales.dashboard}</span>
            </CustomLink>
            <CustomLink page={EPage.Users}>
                <img src={userIcon} alt="users" />
                <span>{window.locales.users}</span>
            </CustomLink>
            <CustomLink page={EPage.ActiveSession}>
                <img src={activeSessionIcon} alt="active" />
                <span>{window.locales.activeSessions}</span>
            </CustomLink>
            <CustomLink page={EPage.Network}>
                <img src={networkIcon} alt="network" />
                <span>{window.locales.network}</span>
            </CustomLink>
            <CustomLink page={EPage.Settings}>
                <img src={settingsIcon} alt="settings" />
                <span>{window.locales.settings}</span>
            </CustomLink>
            <CustomLink page={EPage.ExportData}>
                <img src={exportDataIcon} alt="export" />
                <span>{window.locales.exportData}</span>
            </CustomLink>
            <CustomLink page={EPage.Log}>
                <img src={logIcon} alt="log" />
                <span>{window.locales.log}</span>
            </CustomLink>
            <CustomLink page={EPage.LicenseKey}>
                <img src={licenseKeyIcon} alt="license" />
                <span>{window.locales.licenseKeys}</span>
            </CustomLink>
            <div className={style.versionApplicationContainer}>
                <div className={style.versionApplication}>v 3.33.0</div>
            </div>
        </div>
    );
});

export default SideBar;
