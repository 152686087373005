import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";

const base64ToJson = (base64String: string | undefined): { [key: string]: any } => {
    if (!base64String) return {};
    const json = Buffer.from(base64String, "base64").toString();
    try {
        return JSON.parse(json);
    } catch (e: unknown) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
        return {};
    }
};

export default base64ToJson;
