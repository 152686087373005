import React from "react";
import { getName, overwrite } from "country-list";

import * as style from "@screens/Dashboard/StatsList/style.scss";

import ProgressBar from "../ProgressBar";

overwrite([
    {
        code: "gb",
        name: "United Kingdom",
    },
]);

export default ({ data }: { data: Array<[string, number]> }) => (
    <>
        {data.map((item: [string, number], index: number) => (
            <div className={style.itemContainer} key={index}>
                <div className={style.nameItem}>{getName(item[0])}</div>
                <ProgressBar percent={Number(item[1])} />
            </div>
        ))}
    </>
);
