import React, { Component } from "react";

class ExportingFailed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id ? props.id : "",
            width: props.width ? props.width : 20,
            height: props.height ? props.height : 20,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
        };

        this.callbacs = {};
        this.callbacs.onClick = props.onClick ? props.onClick : () => {};
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            id: props.id ? props.id : "",
            width: props.width ? props.width : 20,
            height: props.height ? props.height : 20,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
        });
    }

    onClick() {
        this.callbacs.onClick();
    }

    render() {
        const style = this.state.style;
        if (!style.height) style.height = `${this.state.height}px`;

        return (
            <div
                id={this.state.id}
                className={`icons addUser ${this.state.theme}${this.state.label ? " flex" : ""}`}
                onClick={this.onClick.bind(this)}
                style={style}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={this.state.width}
                    height={this.state.height}
                    viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                >
                    <path
                        fill="#FF5C5C"
                        fillRule="evenodd"
                        d="M9.005 10.851l-.5-4.379C8.425 5.706 9.247 5 10.008 5c.762 0 1.583.706 1.484 1.472l-.48 4.38c-.06.53-.443.98-1.004.98-.56 0-.943-.45-1.003-.98zm2.481 3.526c0 .763-.662 1.395-1.483 1.395-.822 0-1.503-.632-1.503-1.395 0-.762.681-1.377 1.503-1.377.821 0 1.483.615 1.483 1.377zM10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"
                    />
                </svg>
                {this.state.label ? <div className="label">{this.state.label}</div> : ""}
            </div>
        );
    }
}

export default ExportingFailed;
