export enum StripeResponse {
    Success = "success",
    Cancel = "cancel",
    Error = "error",
}

export enum Permissions {
    ProjectRead = "project_read",
    AuthRead = "portal_project_auth_read",
    AccessRead = "portal_project_access_read",
    PaymentRead = "portal_project_payment_read",
    AuthUpdate = "portal_project_auth_update",
    ProjectUpdate = "portal_project_update",
    ProjectCountriesUpdate = "project_countries_update",
    ProjectCountriesRead = "project_countries_read",
    ProjectPaymentUpdate = "portal_project_payment_update",
}
