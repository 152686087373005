import React, { useState } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { observer } from "mobx-react-lite";

import * as commonStyle from "@common/style/style.scss";
import * as localStyle from "@screens/Pools/Pool/Pool.style.scss";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import { successInfo } from "@state/success";
import iconCopy from "@common/img/svg/copy-icon.svg";
import { If } from "@components/If";
import iconDelete from "@common/img/svg/delete-icon.svg";
import { GENERAL_NAMESPACE, ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import RootState from "@state/interfaces";
import { Modal } from "@components/Modal";
import { DiscoveryPoolConfig } from "@screens/Pools/DiscoveryPool.types";
import { editAndCreateObjectRequest } from "@services/Objects";
import jsonToBase64 from "@common/methods/jsonToBase64";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { usePool } from "@screens/Pools/Pool/usePool";
import objectStore from "@state/object";
import { NEW_PAGE } from "@common/constant/urls";
import { checkObjectResponse } from "@common/methods/objectRequest";

const PoolLocation = observer(() => {
    const { location = "", pool = "" } = useParams();
    const navigate = useNavigate();
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const canDelete = objectPermissions[ObjectEnum.DISCOVERY_POOL_CONFIG]?.includes(PermissionEnum.DELETE);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const { poolData, poolMetadata } = usePool(objectStore.objectData);

    const deleteLocation = async () => {
        if (!poolData.locations) return;
        try {
            setIsDeleteModal(false);
            const filteredLocations = poolData.locations.filter((item) => item.name !== location);
            const dataObject: DiscoveryPoolConfig = {
                ...poolData,
                locations: filteredLocations,
            };
            const { kind, revision: requireRevision } = poolMetadata;

            const { data } = await editAndCreateObjectRequest({
                ...poolMetadata,
                requireRevision,
                data: jsonToBase64(dataObject),
                kind,
                namespace: GENERAL_NAMESPACE,
                onlyCreate: false,
                onlyUpdate: false,
                returnPrevious: false,
                returnCurrent: true,
            });

            if (!data) return;
            checkObjectResponse(data, () => navigate(`/pools/${pool}/locations`));
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    return (
        <>
            <Modal
                isNegative
                isOpen={isDeleteModal}
                onClose={() => setIsDeleteModal(false)}
                title={window.locales.deleteObject}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteLocation,
                }}
            >
                Do you want to delete the location {poolMetadata.name}?
            </Modal>
            <div className={localStyle.poolHeader}>
                <span className={localStyle.item}>Location:</span> {location}
                <EnvIconContainer
                    action={() => {
                        successInfo.setSuccessInfoWithText(window.locales.copiedSuccess);
                        navigator.clipboard.writeText(location).then();
                    }}
                >
                    <img src={iconCopy} alt="Copy" />
                </EnvIconContainer>
                <div className={localStyle.poolHeader__actions}>
                    <If condition={canDelete && location !== NEW_PAGE}>
                        <EnvIconContainer action={() => setIsDeleteModal(true)}>
                            <img src={iconDelete} alt="img" />
                        </EnvIconContainer>
                    </If>
                </div>
            </div>
            <div className={commonStyle.underTabsContainer}>
                <NavLink
                    to="parameters"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.parameters}
                </NavLink>
                <NavLink
                    to="servers"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.servers}
                </NavLink>
            </div>
            <Outlet />
        </>
    );
});

export default PoolLocation;
