import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, Outlet, useNavigate, useParams } from "react-router-dom";

import arrowLeftSVG from "@common/img/svg/arrow-left-icon.svg";
import { getUser } from "@state/users";
import { RootState } from "@state/index";
import * as commonStyle from "@common/style/style.scss";
import * as style from "@screens/Users/User/Header/style.scss";
import Data from "@screens/Users/User/Header/DeleteUser";
import iconCopy from "@common/img/svg/copy-icon.svg";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import { successInfo } from "@state/success";

import * as localStyle from "./style.scss";

const regExp = /^([^_]+)([_@])(.*)/;

const UserName = ({ name }: { name: string }) => {
    const splitUsername = name.match(regExp);
    if (!name) {
        return null;
    }

    if (!splitUsername || name.length < 4) {
        return <h2 className={style.userName}>{name}</h2>;
    }

    return (
        <h2 className={style.userName}>
            {splitUsername[1]}
            <span>
                {splitUsername[2]}
                {splitUsername[3]}
            </span>
        </h2>
    );
};

const CustomLink = ({ to }: { to: string }) => (
    <NavLink
        to={to.toLowerCase()}
        className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink} `}
    >
        {to}
    </NavLink>
);

const User = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedUser = useSelector((state: RootState) => state.users.selectedUser);
    const traffic = useSelector((state: RootState) => state.traffic);
    const { user, project } = useParams();

    useEffect(() => {
        if (user) {
            dispatch(getUser(user));
        }
    }, [project, dispatch, user]);

    const backToUsers = () => {
        navigate(`/projects/${project}/users`);
    };

    if (selectedUser === undefined) {
        return <Navigate replace to={`/${project}/users`} />;
    }

    return (
        <div id="screenUser">
            <div className="header">
                <div className="navi">
                    <img src={arrowLeftSVG} alt="img" />
                    <span className="backUsers" onClick={backToUsers}>
                        {window.locales.users}
                    </span>
                </div>
                <div className="userName">
                    <div className={localStyle.userName}>
                        <UserName name={selectedUser.name} />
                        <EnvIconContainer
                            action={() => {
                                successInfo.setSuccessInfoWithText(window.locales.copiedSuccess);
                                navigator.clipboard.writeText(selectedUser.name);
                            }}
                        >
                            <img src={iconCopy} alt="Copy" />
                        </EnvIconContainer>
                    </div>
                    <span className="userId">ID: {selectedUser.id}</span>
                    <div className="menu">
                        <Data />
                    </div>
                </div>
                <div className={commonStyle.tabsContainer}>
                    <CustomLink to={window.locales.parameters} />
                    <CustomLink to={window.locales.sessions} />
                    <CustomLink to={window.locales.devices} />
                    <CustomLink to={window.locales.purchases} />
                </div>
            </div>
            {selectedUser.id && traffic && <Outlet />}
        </div>
    );
};

export default User;
