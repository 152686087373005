import React, { memo, ReactElement } from "react";

import * as commonStyle from "@common/style/style.scss";
import { ObjectTab } from "@screens/Objects/Object/Object.constants";
import { IObject } from "@screens/Objects/Object/Object.types";

const KindHeader = memo(
    ({ activeTab, changeTab }: IObject): ReactElement => (
        <div className={commonStyle.underTabsContainer}>
            <div
                onClick={() => changeTab(ObjectTab.JSON)}
                className={`${commonStyle.underLink} ${activeTab === ObjectTab.JSON && commonStyle.activeUnderLink}`}
            >
                {window.locales.json}
            </div>
            <div
                onClick={() => changeTab(ObjectTab.UI)}
                className={`${commonStyle.underLink} ${activeTab === ObjectTab.UI && commonStyle.activeUnderLink}`}
            >
                {window.locales.ui}
            </div>
        </div>
    ),
);

export default KindHeader;
