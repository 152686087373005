import React, { ReactElement, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useFilteredData } from "@common/hooks/useFilteredData";
import { AuthConfigType } from "@screens/Auth/AuthConfig/AuthConfig.types";
import Table from "@components/Table";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus } from "@common/icons";
import { editAndCreateObjectRequest, getObjectByKindRequest } from "@services/Objects";
import { ObjectEnum } from "@screens/Objects/Object/Object.constants";
import Base64toJSON from "@common/methods/base64toJSON";
import { EModals } from "@components/Modal/types";
import {
    accumulateEntitlements,
    headingEntitlements,
    ItemEntitlement,
} from "@screens/Auth/AuthEntitlements/AuthEntitlements.utils";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import AuthEntitlementModal from "@screens/Auth/AuthEntitlements/Modal";
import { Modal } from "@components/Modal";
import jsonToBase64 from "@common/methods/jsonToBase64";
import { checkObjectResponse } from "@common/methods/objectRequest";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { ObjectType } from "@screens/Objects/Objects.types";

const initEntitlement: ItemEntitlement = ["", "", ""];

const initialState = initialStateModal(initEntitlement);
const reducerObjects = reducerModal(initialState);

const filterFunction =
    (searchValue: string) =>
    ([name]: ItemEntitlement): boolean =>
        name.toLowerCase().includes(searchValue);
function AuthEntitlements(): ReactElement {
    const [authConfig, setAutConfig] = useState<ObjectType>();
    const refDefault = useRef<Record<string, unknown>>({});
    const [modals, dispatchModal] = useReducer(reducerObjects, initialState);
    const { project = "" } = useParams();
    const { setData, setSearch, filteredList } = useFilteredData<ItemEntitlement>(filterFunction);

    const showDeleteModal = (entitlement: ItemEntitlement) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: entitlement,
        });
    };

    const showEditModal = (entitlement: ItemEntitlement) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: entitlement,
        });
    };

    const showCreateModal = () => {
        dispatchModal({
            type: EModals.CreateModal,
            payload: initEntitlement,
        });
    };

    const tableData = useMemo(
        () => accumulateEntitlements(filteredList, refDefault.current, showEditModal, showDeleteModal),
        [filteredList],
    );

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initEntitlement,
        });
    };

    const getData = useCallback(async () => {
        const { data } = await getObjectByKindRequest({
            kind: ObjectEnum.PROJECT_AUTH_CONFIG,
            name: "default",
            namespace: project,
        });

        checkObjectResponse(data, () => {});

        if (!data.object) return;
        const { object } = data;
        const config = Base64toJSON(object.data) as AuthConfigType;
        setAutConfig(object);
        if (config?.entitlements?.properties) {
            const { properties, default: defaultValue = {} } = config.entitlements;
            refDefault.current = defaultValue;
            const listOfProperties: Array<ItemEntitlement> = Object.keys(properties).map((key: string) => [
                key,
                properties[key].reducer,
                properties[key].default,
            ]);
            setData(listOfProperties);
        }
    }, [project, setData]);

    useEffect(() => {
        getData().then();
    }, [getData]);

    const modifyAuthConfig = async (changedData: AuthConfigType) => {
        try {
            if (!authConfig) return;
            const { data } = await editAndCreateObjectRequest({
                ...authConfig.metadata,
                data: jsonToBase64(changedData),
                kind: ObjectEnum.PROJECT_AUTH_CONFIG,
                namespace: project,
                name: "default",
                onlyCreate: false,
                onlyUpdate: true,
            });

            checkObjectResponse(data, () => {});
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        } finally {
            await getData();
        }
    };

    const editEntitlement = async (editedEntitlement: ItemEntitlement) => {
        const data = authConfig?.data;
        const { entitlements, ...restData } = Base64toJSON(data) as AuthConfigType;
        if (!entitlements?.properties) {
            return;
        }
        const [name, reducer, defaultValue] = editedEntitlement;
        const { properties, default: defaultConfig = {} } = entitlements;
        properties[name] = { reducer };
        if (defaultValue) {
            defaultConfig[name] = defaultValue;
        }

        await modifyAuthConfig({
            ...restData,
            entitlements,
        });
    };

    const createEntitlement = async (editedEntitlement: ItemEntitlement) => {
        const data = authConfig?.data;
        const {
            entitlements = {
                properties: {},
                default: {},
            },
            ...restData
        } = Base64toJSON(data) as AuthConfigType;
        const [name, reducer, defaultValue] = editedEntitlement;
        const { properties, default: defaultConfig = {} } = entitlements;
        properties[name] = { reducer };

        if (defaultValue) {
            defaultConfig[name] = defaultValue;
        }

        await modifyAuthConfig({
            ...restData,
            entitlements,
        });
    };

    const deleteEntitlement = async () => {
        closeModal();
        const data = authConfig?.data;
        const { entitlements, ...restData } = Base64toJSON(data) as AuthConfigType;
        if (!entitlements?.properties) {
            return;
        }
        const [name] = modals.item;
        const { properties } = entitlements;
        delete properties[name];
        await modifyAuthConfig({
            ...restData,
            entitlements,
        });
    };

    return (
        <>
            <AuthEntitlementModal
                closeModal={closeModal}
                title={window.locales.addEntitlement}
                item={modals.item}
                isOpen={modals.createModal}
                action={createEntitlement}
            />
            <AuthEntitlementModal
                closeModal={closeModal}
                title={window.locales.editEntitlement}
                item={modals.item}
                isOpen={modals.editModal}
                action={editEntitlement}
            />
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.deleteEntitlement}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteEntitlement,
                }}
            >
                Do you want to delete the property {modals.item[0]}?
            </Modal>
            <Table
                tableData={tableData}
                headings={headingEntitlements}
                title="projects"
                emptyMessage={window.locales.noEntitlements}
            >
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={setSearch} />
                    </div>
                    <button disabled={!authConfig} className={buttonStyle.buttonAdd} onClick={showCreateModal}>
                        <IconPlus theme="blue" />
                        {window.locales.add}
                    </button>
                </div>
            </Table>
        </>
    );
}

export default AuthEntitlements;
