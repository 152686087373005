// extracted by mini-css-extract-plugin
var _1 = "style-arrowContainer__B6zJ5";
var _2 = "style-buttonContainer__ZlL3L";
var _3 = "style-contentContainer__r5xlm";
var _4 = "style-fieldsContainer__h5stx";
var _5 = "style-formContainer__BzYwz";
var _6 = "style-notificationContainer__undFi";
var _7 = "style-tabsContainer__Igx5u";
var _8 = "style-title__sl1px";
var _9 = "style-titleContainer__r4SN3";
var _a = "style-titleSection__IrQr4";
export { _1 as "arrowContainer", _2 as "buttonContainer", _3 as "contentContainer", _4 as "fieldsContainer", _5 as "formContainer", _6 as "notificationContainer", _7 as "tabsContainer", _8 as "title", _9 as "titleContainer", _a as "titleSection" }
