enum EModals {
    EditKey,
    DeleteKey,
    CreateKey,
    CloseModals,
}

interface ILicenseKey {
    token: string;
    project?: string;
    metadata: any;
    expire_time: number;
    create_time?: number;
    update_time?: number;
}

interface ILicenseKeyModal {
    editLicenseKeyModal: boolean;
    deleteLicenseKeyModal: boolean;
    createLicenseKeyModal: boolean;
    licenseKey: ILicenseKey | null;
}

type TAction = {
    type: EModals;
    payload: any;
};

export { EModals };
export type { ILicenseKey, ILicenseKeyModal, TAction };
