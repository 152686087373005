import React, { useMemo, useState } from "react";

import { getNameField } from "@components/FormikFields/utils";
import * as style from "@components/FormikFields/style.scss";
import { Eye } from "@components/Eye/Eye";

import { NameContainer, TextFieldInput } from "../components";

const TextField = (data: any) => {
    const { field, form, disabled, type, placeholder, extraTouched } = data;
    const [showPwd, setShowPwd] = useState(false);
    const { name } = field;
    const { touched, errors } = form;
    const isTouched = extraTouched ?? touched[name];
    const error = isTouched ? errors[name] : "";
    const getNewName = getNameField(name);

    const typeOfInput = useMemo(() => {
        if (type === "password") {
            return showPwd ? "text" : "password";
        }
        return type;
    }, [showPwd, type]);

    return (
        <>
            <NameContainer $isError={error}>{getNewName}</NameContainer>
            <div className={style.textFieldContainer}>
                <TextFieldInput
                    placeholder={placeholder}
                    autoComplete="off"
                    type={typeOfInput}
                    disabled={disabled}
                    {...field}
                    $error={error}
                />
                {type === "password" && (
                    <div className={style.inputIcon}>
                        <Eye handle={() => setShowPwd(!showPwd)} isShow={!showPwd} />
                    </div>
                )}
                <div className={style.textErrorContainer}>{error}</div>
            </div>
        </>
    );
};

export default TextField;
