import React, { ReactElement } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";

import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import { successInfo } from "@state/success";
import iconCopy from "@common/img/svg/copy-icon.svg";
import * as commonStyle from "@common/style/style.scss";
import * as localStyle from "@screens/Auth/Auth.styled.scss";
import { NAMESPACES_PAGE } from "@common/constant/urls";
import { ObjectEnum } from "@screens/Objects/Object/Object.constants";
import iconSetting from "@common/img/svg/setting-icon.svg";

function AuthProject(): ReactElement {
    const { project = "" } = useParams();
    const navigate = useNavigate();

    return (
        <div className={commonStyle.mainPageContainer}>
            <div className={localStyle.authHeaderContainer}>
                <span className={localStyle.item}>{window.locales.project}:</span> {project}
                <EnvIconContainer
                    action={() => {
                        successInfo.setSuccessInfoWithText(window.locales.copiedSuccess);
                        navigator.clipboard.writeText(project).then();
                    }}
                >
                    <img src={iconCopy} alt="Copy" />
                </EnvIconContainer>
                <div className={localStyle.authHeaderContainer__actions}>
                    <EnvIconContainer
                        action={() =>
                            navigate(`/${NAMESPACES_PAGE}/${project}/${ObjectEnum.PROJECT_AUTH_CONFIG}/default`)
                        }
                    >
                        <img src={iconSetting} alt="img" />
                    </EnvIconContainer>
                </div>
            </div>
            <div className={commonStyle.underTabsContainer}>
                <NavLink
                    to="config"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.config}
                </NavLink>
                <NavLink
                    to="entitlements"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.entitlements}
                </NavLink>
                <NavLink
                    to="user"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.user}
                </NavLink>
            </div>
            <Outlet />
        </div>
    );
}

export default AuthProject;
