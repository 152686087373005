import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { ToggleSwitch } from "@components/ToggleSwitch";
import { If } from "@components/If";
import * as style from "@screens/Profile/AccountSecurity/TwoFactoryAuth/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import * as profileStyle from "@screens/Profile/style.scss";
import { phonePattern } from "@screens/Profile/AccountSecurity/TwoFactoryAuth/utils";
import { MFAActions } from "@screens/Profile/AccountSecurity/TwoFactoryAuth/types";
import { ModalPassword } from "@screens/Profile/AccountSecurity/TwoFactoryAuth/ModalPassword";
import RootState from "@state/interfaces";
import { TextField } from "@components/FormikFields";
import { Timer } from "@components/Timer";
import { pageViewsTracking } from "@common/gaTracking/pageViewsTracking";

import reducer from "./reducer";

// Validate phone number
const validatePhoneNumberRegex = /^\+[1-9][0-9]{7,14}$/;

export const TwoFactoryAuthForm = () => {
    const [phoneMultiFactorInfo, setPhoneMultiFactorInfo] = useState({
        phoneNumber: "",
    });
    const location = useLocation();
    const navigate = useNavigate();
    const { currentUser } = window.fb.default.auth();
    const buttonRef = useRef(null);
    const verificationId = useRef(null);
    const [requiredPassword, setRequiredPassword] = useState(false);
    const [resolver, setResolver] = useState<any>(null);
    const account = useSelector((state: RootState) => state.account);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isEnroll, setIsEnroll] = useState(true);
    const [captcha, setCaptcha] = useState(null);

    const closeModalPassword = () => {
        dispatch({ type: MFAActions.CancelMFA });
        setRequiredPassword(false);
    };

    const [state, dispatch] = useReducer(reducer, {
        isEdit: false,
        phoneInstalled: !!phoneMultiFactorInfo.phoneNumber,
        isSentCode: false,
        timer: false,
    });

    useEffect(() => {
        pageViewsTracking(location.pathname);
        if (currentUser) {
            const [info] = currentUser ? currentUser.multiFactor.enrolledFactors : [];
            setPhoneMultiFactorInfo(info);
        }
    }, [currentUser, location.pathname, navigate]);

    useEffect(() => {
        const recaptchaVerifier = new window.fb.default.auth.RecaptchaVerifier(buttonRef.current, {
            size: "invisible",
        });
        setCaptcha(recaptchaVerifier);
    }, []);

    const switchTwoFactoryAuth = async () => {
        const [multiFactorHint] = resolver.hints;
        await window.fb.default.auth().currentUser.multiFactor.unenroll(multiFactorHint);
    };

    const usingReCaptcha = async (setErrors: any) => {
        try {
            const multiFactorSession = await window.fb.default.auth().currentUser.multiFactor.getSession();
            const phoneInfoOptions = {
                phoneNumber,
                session: multiFactorSession,
            };
            const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();

            verificationId.current = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, captcha);
            dispatch({ type: MFAActions.SentCode });
        } catch (e: any) {
            if (e.code === "auth/requires-recent-login") {
                setRequiredPassword(true);
            } else if (e.code === "auth/second-factor-already-in-use") {
                setErrors({
                    phone: window.locales.invalidEnroll,
                });
            } else if (e.code === "auth/invalid-phone-number") {
                setErrors({
                    phone: window.locales.incorrectPhoneNumber,
                });
            } else {
                // @ts-ignore
                window.grecaptcha.reset(buttonRef.current);
            }
        }
    };

    useEffect(() => {
        if (!resolver) {
            return;
        }

        const [multiFactorHint = { phoneNumber: "" }] = resolver?.hints ?? [];
        const phoneInfoOptions = {
            multiFactorHint,
            session: resolver.session,
        };
        const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
        phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, captcha)
            .then((value: string) => {
                dispatch({
                    type: MFAActions.SentCode,
                });
                // @ts-ignore
                verificationId.current = value;
            })
            .catch(() => {
                // @ts-ignore
                window.grecaptcha.reset(buttonRef.current);
            });
    }, [captcha, resolver]);

    const unenrollPhone = async (code: string) => {
        try {
            const cred = window.fb.default.auth.PhoneAuthProvider.credential(verificationId.current, code);
            const multiFactorAssertion = window.fb.default.auth.PhoneMultiFactorGenerator.assertion(cred);
            await resolver.resolveSignIn(multiFactorAssertion);
            await switchTwoFactoryAuth();
        } catch (e) {
            dispatch({
                type: MFAActions.EnableMFA,
            });
        }
    };

    const enrollPhone = async (verificationCode: string, phone: string, success: () => void, setErrors: any) => {
        try {
            if (!verificationId.current) return;
            const cred = window.fb.default.auth.PhoneAuthProvider.credential(verificationId.current, verificationCode);
            const multiFactorAssertion = window.fb.default.auth.PhoneMultiFactorGenerator.assertion(cred);
            await window.fb.default.auth().currentUser.multiFactor.enroll(multiFactorAssertion);
            success();
            dispatch({
                type: MFAActions.EnableMFA,
            });
        } catch (e: any) {
            if (e.code === "auth/invalid-verification-code") {
                setErrors({
                    code: window.locales.incorrectCode,
                });

                return;
            }

            setErrors({
                code: window.locales.incorrectSMSCode,
            });
        }
    };

    const applyAction = (setError: any) => (reauthenticate: any) =>
        reauthenticate
            .then(() => {
                usingReCaptcha(setError).then();
            })
            .catch((error: any) => {
                const { code } = error;
                if (code === "auth/multi-factor-auth-required") {
                    setIsEnroll(false);
                    setResolver(error.resolver);
                }
            })
            .finally(() => {
                setRequiredPassword(false);
            });

    if (!window.fb) {
        return <Navigate replace to="/" />;
    }

    return (
        <Formik
            initialValues={{
                twoFactory: state.phoneInstalled,
                phone: phoneMultiFactorInfo.phoneNumber,
                code: "",
            }}
            enableReinitialize
            validate={(values) => {
                const errors: any = {};
                const { phone, code } = values;
                if (!phone) errors.twoFactorNumber = window.locales.invalidEmptyPhoneNumber;
                if (!validatePhoneNumberRegex.test(phone)) errors.twoFactorNumber = window.locales.invalidPhoneNumber;
                if (!code) errors.code = window.locales.invalidSMSCode;
                return errors;
            }}
            onSubmit={(values, { setErrors, setFieldValue, resetForm }) => {
                if (isEnroll) {
                    enrollPhone(
                        values.code,
                        values.phone,
                        () => {
                            setFieldValue("twoFactory", true);
                            setFieldValue("code", "");
                        },
                        setErrors,
                    ).then();
                } else {
                    unenrollPhone(values.code).then(() => {
                        dispatch({
                            type: MFAActions.DisableMFA,
                        });

                        resetForm();
                        setFieldValue("phone", "");
                    });
                }
            }}
        >
            {({ values, handleSubmit, dirty, isValid, setErrors, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <div className={profileStyle.titleSection}>{window.locales.twoFactory}</div>
                    <ModalPassword
                        closeModal={() => {
                            closeModalPassword();
                            state.phoneInstalled ? setFieldValue("twoFactory", true) : setFieldValue("phone", "");
                        }}
                        isOpen={requiredPassword}
                        email={account.name}
                        applyForm={applyAction(setErrors)}
                    />
                    <If condition={state.phoneInstalled}>
                        <div className={style.switcher}>
                            <Field
                                component={ToggleSwitch}
                                disabled={!state.phoneInstalled}
                                value={values.twoFactory}
                                onClick={() => {
                                    setRequiredPassword(true);
                                }}
                                name="twoFactory"
                            />
                            <div className={style.labelSwitcher}>{values.twoFactory ? "ON" : "OFF"}</div>
                        </div>
                    </If>
                    <div className={style.inputWithButton}>
                        <div className={profileStyle.fieldsContainer}>
                            <Field
                                component={TextField}
                                value={values.phone}
                                maxLength={14}
                                type="tel"
                                name="phone"
                                disabled={state.isSentCode || !state.isEdit}
                                placeholder="Phone number (e.g. +16505550101)"
                            />
                        </div>
                        <If condition={isEnroll && state.isEdit && !state.timer && phonePattern.test(values.phone)}>
                            <div
                                className={style.inputButton}
                                onClick={() => {
                                    setPhoneNumber(values.phone);
                                    setRequiredPassword(true);
                                }}
                            >
                                {state.isSentCode ? window.locales.resend : window.locales.sendCode}
                            </div>
                        </If>
                        <If condition={state.timer}>
                            <Timer
                                takeOffTimer={() => {
                                    dispatch({
                                        type: MFAActions.DisableTimer,
                                    });
                                }}
                            />
                        </If>
                        <If condition={!state.isEdit && !state.phoneInstalled}>
                            <div
                                className={style.inputButton}
                                onClick={() => {
                                    setIsEnroll(true);
                                    dispatch({
                                        type: MFAActions.EditPhone,
                                    });
                                }}
                            >
                                {window.locales.edit}
                            </div>
                        </If>
                    </div>
                    <If condition={state.isSentCode}>
                        <div className={profileStyle.fieldsContainer}>
                            <Field
                                component={TextField}
                                type="text"
                                name="code"
                                placeholder="six-digit code sent to your phone"
                            />
                        </div>
                    </If>
                    <If condition={state.isSentCode}>
                        <div className={style.buttonContainer}>
                            <button className={buttonStyle.buttonSubmit} type="submit" disabled={!(isValid && dirty)}>
                                {window.locales.confirm}
                            </button>
                            <div
                                className={buttonStyle.inlineButtonCancel}
                                onClick={() => {
                                    dispatch({ type: MFAActions.CancelMFA });
                                    state.phoneInstalled
                                        ? setFieldValue("twoFactory", true)
                                        : setFieldValue("phone", "");
                                }}
                            >
                                {window.locales.cancel}
                            </div>
                        </div>
                    </If>
                    <div ref={buttonRef} />
                </form>
            )}
        </Formik>
    );
};
