const colors = {
    error: "#FF0001",
    negative: "#ED4055",
    white: "#fff",
    softWhite: "#FBFBFB",
    peach: "#F9E9DB",
    nightShade: "#1A1C23",
    mildGrey: "#5B5F64",
    darkGrey: "#303340",
    lightBg: "#1A1C23",
    darkBg: "#0A0B10",
    _80: "#FFFFFFCC",
    _50: "#FFFFFF80",
    _30: "rgba(255, 255, 255, 0.3)",
    _20: "rgba(255, 255, 255, 0.2)",
    bg: "#353535",
    input: {
        text: {
            default: "#303340",
            hover: "#3D4152",
            error: "#ED405526",
            disabled: "#1A1C23",
        },
        select: {
            default: "#303340",
            hover: "#1A1C23",
            error: "#1A1C23",
            disabled: "#1A1C23",
        },
    },
    buttonResetActive: {
        bg: "#1A1C23",
    },
    negativeButton: {
        bg: "#ED4055",
    },
    negativeButtonHover: {
        bg: "#D9253B",
    },
    negativeButtonActive: {
        bg: "#BA1327",
    },
    backgroundTable: "#1A1C2399",
    bgModal: "#252832",
    grey: "#878c94",
    color12: "rgba(255, 255, 255, .6)",
    lightGrey: "rgba(46, 166, 248, 0.21)",
    buttonReset: {
        bg: "transparent",
        font: "#fff",
    },
    buttonResetHover: {
        bg: "#303340",
        font: "#fff",
    },
    table: {
        bg: "#232A30",
        hoverTR: "rgba(255, 255, 255, 0.30)",
    },
};

export default colors;
