import React, { Component } from "react";

import Table from "@common/table";
import { connect } from "react-redux";

import { byteConvert, getDateString } from "@common/methods";

import { IconPen, IconStatus } from "@common/icons";

import { Input, Select, Checkbox } from "@common/form";

import { changeUserCondition, editUser } from "@services/Users/Users";

import countryData from "country-data";

import Limit from "./Limit";
import { getUser } from "@state/users";
import { Empty } from "@components/Empty";
import { withRouter } from "@common/withRouter";

class Parameters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.selectedUser,
            traffic: props.traffic,
            editCountry: false,
            editExtref: false,
            editLicence: false,
            editDescription: false,
            disabled: false,
            form: {
                country: false,
            },
        };

        this.form = {
            country: props.selectedUser.extra.country,
            extref: props.selectedUser.extref,
            given_name: props.selectedUser.given_name,
            licence: 0,
        };

        this.getUser = this.getUser.bind(this);
    }

    getUser() {
        const { user } = this.props.router.params;
        this.props.getUser(user);
    }

    UNSAFE_componentWillReceiveProps(props) {
        const dump = JSON.stringify(props);
        if (dump === this.dump) return;
        this.dump = dump;

        this.setState({
            user: props.user,
            traffic: props.traffic,
            activeProject: props.activeProject,
            editCountry: false,
            editExtref: false,
            editLicence: false,
            editDescription: false,
            form: {
                country: false,
            },
        });
    }

    fieldChanged(key, value) {
        this.form[key] = value;
    }

    showEditLicence() {
        this.form.licence = this.props.selectedUser.bundle.id;

        this.setState({ editLicence: true });
    }

    hideEditLicence() {
        this.setState({ editLicence: false });
    }

    saveLicence() {
        this.setState(
            {
                disabled: true,
            },
            () => {
                editUser(this.props.selectedUser.id, {
                    name: this.props.selectedUser.name,
                    license_id: this.form.licence,
                    extref: this.props.selectedUser.extref,
                    given_name: this.props.selectedUser.given_name,
                    country: this.props.selectedUser.extra.country,
                })
                    .then(() => {
                        const state = this.state;
                        for (const k in this.props.activeProject.licenses) {
                            const l = this.props.activeProject.licenses[k];
                            if (Number(l.id) !== Number(this.form.licence)) continue;

                            state.user.bundle = l;
                        }

                        state.disabled = false;
                        state.editLicence = false;
                        this.setState(state);
                    })
                    .catch(() => {
                        this.setState({
                            disabled: false,
                        });
                    })
                    .finally(() => {
                        this.getUser();
                        this.setState({ editLicence: false });
                    });
            },
        );
    }

    showEditExtref() {
        this.form.extref = this.props.selectedUser.extref;

        this.setState({ editExtref: true });
    }

    hideEditExtref() {
        this.setState({ editExtref: false });
    }

    async conditionChanged() {
        await changeUserCondition(this.props.selectedUser.id, !this.props.selectedUser.condition ? 1 : 0);

        this.getUser();
    }

    saveExtref() {
        this.setState(
            {
                disabled: true,
                editExtref: false,
            },
            () => {
                editUser(this.props.selectedUser.id, {
                    name: this.props.selectedUser.name,
                    license_id: this.props.selectedUser.bundle.id,
                    extref: this.form.extref,
                    given_name: this.props.selectedUser.given_name,
                    country: this.props.selectedUser.extra.country,
                })
                    .then(() => {
                        const state = this.state;
                        state.user.extref = this.form.extref;
                        state.disabled = false;
                        state.editExtref = false;
                        this.setState(state);
                    })
                    .catch(() => {
                        this.setState({
                            disabled: false,
                        });
                    })
                    .finally(() => this.getUser());
            },
        );
    }

    showEditDescription() {
        this.form.given_name = this.props.selectedUser.given_name;

        this.setState({ editDescription: true });
    }

    hideEditDescription() {
        this.setState({ editDescription: false });
    }

    saveDescription() {
        this.setState(
            {
                disabled: true,
                editDescription: false,
            },
            () => {
                editUser(this.props.selectedUser.id, {
                    name: this.props.selectedUser.name,
                    license_id: this.props.selectedUser.bundle.id,
                    extref: this.props.selectedUser.extref,
                    given_name: this.form.given_name,
                    country: this.props.selectedUser.extra.country,
                })
                    .then(() => {
                        const state = this.state;
                        state.user.given_name = this.form.given_name;
                        state.disabled = false;
                        state.editDescription = false;

                        for (const k in window.data.users[this.props.selectedUser.access_token]) {
                            if (
                                window.data.users[this.props.selectedUser.access_token][k].id ===
                                this.props.selectedUser.id
                            ) {
                                window.data.users[this.props.selectedUser.access_token][k].given_name =
                                    this.form.given_name;
                                break;
                            }
                        }

                        this.setState(state);
                    })
                    .catch(() => {
                        this.setState({
                            disabled: false,
                        });
                    })
                    .finally(() => this.getUser());
            },
        );
    }

    showEditCountry() {
        this.form.country = this.props.selectedUser.extra.country;

        this.setState({ editCountry: true });
    }

    hideEditCountry() {
        this.setState({ editCountry: false });
    }

    saveCountry() {
        if (this.form.country.length !== 0 && this.form.country.length !== 2) {
            return this.setState({
                form: {
                    country: true,
                },
            });
        }

        if (this.form.country.length === 2) {
            this.form.country = this.form.country.toUpperCase();

            let found = false;
            for (const k in countryData.countries) {
                if (countryData.countries[k].alpha2 === this.form.country) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                return this.setState({
                    form: {
                        country: true,
                    },
                });
            }
        }

        this.setState(
            {
                disabled: true,
                form: {
                    country: false,
                },
            },
            () => {
                editUser(this.props.selectedUser.id, {
                    name: this.props.selectedUser.name,
                    license_id: this.props.selectedUser.bundle.id,
                    extref: this.props.selectedUser.extref,
                    given_name: this.props.selectedUser.given_name,
                    country: this.form.country,
                })
                    .then(() => {
                        const state = this.state;
                        state.user.extra.country = this.form.country;
                        state.disabled = false;

                        for (const k in window.data.users[this.props.selectedUser.access_token]) {
                            if (
                                window.data.users[this.props.selectedUser.access_token][k].id ===
                                this.props.selectedUser.id
                            ) {
                                window.data.users[this.props.selectedUser.access_token][k].extra.country =
                                    this.form.country;
                                break;
                            }
                        }

                        this.setState(state);
                    })
                    .catch(() => {
                        this.setState({
                            disabled: false,
                        });
                    })
                    .finally(() => {
                        this.getUser();
                        this.setState({ editCountry: false });
                    });
            },
        );
    }

    render() {
        const regTime = getDateString(this.props.selectedUser.registration_time);
        const body = [];
        let licence;

        const { activated_devices, condition, auth_method, given_name } = this.props.selectedUser;

        const { traffic_limit_type, traffic_unlimited } = this.props.activeProject;

        if (traffic_limit_type && traffic_limit_type === "by_device" && !traffic_unlimited) {
            body.push([
                [window.locales.traffic],
                [
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {window.locales.by_device}
                        <Limit traffic={this.props.traffic} />
                    </div>,
                ],
            ]);
        } else if (this.props.traffic.traffic_unlimited) {
            body.push([
                [window.locales.traffic],
                [
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {window.locales.unlimited}
                        <Limit traffic={this.props.traffic} />
                    </div>,
                ],
            ]);
        } else {
            const traffic = this.props.traffic;
            const date = getDateString(traffic.traffic_start);
            const used = byteConvert(traffic.traffic_used ? traffic.traffic_used : 0);
            const limit = byteConvert(traffic.traffic_limit ? traffic.traffic_limit : 0);

            body.push([
                [window.locales.traffic],
                [
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ marginRight: "15px" }}>
                            {window.locales.trafficLimit}: {`${limit.size} ${limit.type}`},
                        </div>
                        <div style={{ marginRight: "15px" }}>
                            {window.locales.trafficUsed}: {`${used.size} ${used.type}`},
                        </div>
                        {date && (
                            <div>
                                {window.locales.trafficStart}: {date ? `${date.date}, ${date.time}` : ""}
                            </div>
                        )}
                        <Limit traffic={this.props.traffic} />
                    </div>,
                ],
            ]);
        }

        if (this.state.editLicence) {
            licence = [];

            for (const k in this.props.activeProject.licenses) {
                const l = this.props.activeProject.licenses[k];
                licence.push([l.id, l.name]);
            }

            body.push([
                [window.locales.licence],
                [
                    <div className={"containForm"}>
                        <Select
                            label={window.locales.licence}
                            theme={"tableForm"}
                            options={licence}
                            value={this.form.licence}
                            disabled={this.state.disabled}
                            onChange={this.fieldChanged.bind(this, "licence")}
                        />
                        <div className={"saveButton"} onClick={this.saveLicence.bind(this)}>
                            Save
                        </div>
                        <div className={"cancelButton"} onClick={this.hideEditLicence.bind(this)}>
                            Cancel
                        </div>
                    </div>,
                ],
            ]);
        } else {
            try {
                licence = this.props.selectedUser.bundle.name;
            } catch (e) {
                window.console.error(e);
            }

            body.push([
                [window.locales.licence, { width: "150px" }],
                [
                    <div className={"containForm"}>
                        <span>{licence}</span>
                        <IconPen theme={"blue"} onClick={this.showEditLicence.bind(this)} />
                    </div>,
                ],
            ]);
        }

        body.push(
            [[window.locales.activatedDevices, { width: "150px" }], [activated_devices]],
            [
                [window.locales.condition],
                [
                    <Checkbox
                        theme={"blue"}
                        status={!condition}
                        changed={this.conditionChanged.bind(this)}
                        label={
                            !condition
                                ? activated_devices === 0 && auth_method === "carrier_admin"
                                    ? window.locales.invited
                                    : window.locales.active
                                : window.locales.disabled
                        }
                    />,
                ],
            ],
            [[window.locales.registrationTime], [regTime ? `${regTime.date}, ${regTime.time}` : ""]],
        );

        if (this.props.selectedUser.purchases.length > 0) {
            body.push([
                [window.locales.purchases],
                [
                    <IconStatus
                        status={condition === 0 ? "on" : "off"}
                        label={
                            condition === 0
                                ? activated_devices === 0 && auth_method === "carrier_admin"
                                    ? window.locales.invited
                                    : window.locales.active
                                : window.locales.disabled
                        }
                    />,
                ],
            ]);
        }

        const social = {};

        for (const name in this.props.selectedUser.social) {
            let value = this.props.selectedUser.social[name];
            if (value === "") continue;
            if (typeof value === "object") value = JSON.stringify(value);

            social[name] = value;
        }

        let extref;
        if (!this.state.editExtref) {
            extref = (
                <div className={"containForm"}>
                    {this.props.selectedUser.extref && <span>{this.form.extref}</span>}{" "}
                    <IconPen theme={"blue"} onClick={this.showEditExtref.bind(this)} />
                </div>
            );
        } else {
            extref = (
                <div className={"containForm"}>
                    <Input
                        value={this.form.extref}
                        label={"Extref"}
                        theme={"tableForm"}
                        onChange={this.fieldChanged.bind(this, "extref")}
                        onEnter={this.saveExtref.bind(this)}
                        disabled={this.state.disabled}
                    />
                    <div className={"saveButton"} onClick={this.saveExtref.bind(this)}>
                        Save
                    </div>
                    <div className={"cancelButton"} onClick={this.hideEditExtref.bind(this)}>
                        Cancel
                    </div>
                </div>
            );
        }

        body.push([["Extref"], [extref]]);

        let description;
        if (!this.state.editDescription) {
            description = (
                <div className={"containForm"}>
                    {given_name && this.state.given_name !== "" && <span>{given_name}</span>}{" "}
                    <IconPen theme={"blue"} onClick={this.showEditDescription.bind(this)} />
                </div>
            );
        } else {
            description = (
                <div className={"containForm"}>
                    <Input
                        value={this.form.given_name}
                        label={window.locales.description}
                        theme={"tableForm"}
                        onChange={this.fieldChanged.bind(this, "given_name")}
                        onEnter={this.saveDescription.bind(this)}
                        disabled={this.state.disabled}
                    />
                    <div className={"saveButton"} onClick={this.saveDescription.bind(this)}>
                        Save
                    </div>
                    <div className={"cancelButton"} onClick={this.hideEditDescription.bind(this)}>
                        Cancel
                    </div>
                </div>
            );
        }

        body.push([[window.locales.description], [description]]);

        let country;

        if (!this.state.editCountry) {
            country = (
                <div className={"containForm"}>
                    {this.props.selectedUser.extra.country && <span>{this.form.country}</span>}{" "}
                    <IconPen theme={"blue"} onClick={this.showEditCountry.bind(this)} />
                </div>
            );
        } else {
            country = (
                <div className={"containForm"}>
                    <Input
                        value={this.form.country}
                        label={"User country"}
                        theme={"tableForm"}
                        onChange={this.fieldChanged.bind(this, "country")}
                        onEnter={this.saveCountry.bind(this)}
                        disabled={this.state.disabled}
                        notValid={this.state.form.country}
                    />
                    <div className={"saveButton"} onClick={this.saveCountry.bind(this)}>
                        Save
                    </div>
                    <div className={"cancelButton"} onClick={this.hideEditCountry.bind(this)}>
                        Cancel
                    </div>
                </div>
            );
        }

        body.push([["User country"], [country]]);

        body.push([[window.locales.authMethod], [<div className={"tableAbsoluteContainer"}>{auth_method}</div>]]);

        if (body.length === 0) {
            return <Empty message={window.locales.noSocialRow} />;
        }

        return (
            <div className={"screenUsersContentContainer"}>
                <Table body={body} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeProject: state.activeProject,
    user: state.user,
    traffic: state.traffic,
    selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = { getUser };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Parameters));
