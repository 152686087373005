import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { If } from "@components/If";
import { editActiveProject } from "@state/activeProject";
import TableLocation from "@screens/Network/Locations/TableLocations";
import { RootState } from "@state/index";
import * as style from "@screens/Network/Locations/style.scss";
import { customStyles } from "@components/FormikFields/CustomSelect/utils";
import * as buttonStyle from "@components/Button/style.scss";

import { Pool, TPool } from "./types";
import { TPools } from "../types";

type TValuePool = string | string[];

const Locations = () => {
    const dispatch = useDispatch();
    const [changed, setChanged] = useState(false);
    const activeProject = useSelector((state: RootState) => state.activeProject) ?? {
        publickey: "",
        config: {
            free_server_pool: "",
            paid_server_pool: "",
            private_pools: [],
        },
        role: "",
    };

    const pools: TPools = useSelector((state: RootState) => state.pools);
    const [disabledSave, setDisabledSave] = useState(true);
    const [selectedPools, setSelectedPools] = useState<TPool>({
        free_server_pool: "",
        paid_server_pool: "",
        private_pools: [],
    });
    const optionPools: TPools = useMemo(() => {
        const privatePools = pools.privatePools.map((privatePool: any) => ({
            value: privatePool.name,
            label: privatePool.name,
        }));
        const publicPools = pools.publicPools.map((publicPool: any) => ({
            value: publicPool.name,
            label: publicPool.name,
        }));
        return {
            privatePools,
            publicPools,
        };
    }, [pools]);

    const getPool = useCallback(() => {
        const { free_server_pool, paid_server_pool, private_pools } = activeProject.config;
        setSelectedPools({
            free_server_pool,
            paid_server_pool,
            private_pools,
        });
    }, [activeProject.config]);

    useEffect(() => {
        const { free_server_pool, paid_server_pool } = selectedPools;
        setDisabledSave(!free_server_pool || !paid_server_pool || activeProject.role === "SUPPORT");
    }, [selectedPools, activeProject.role]);

    useEffect(() => {
        getPool();
    }, [getPool]);

    const changePool = (typePool: string, value: TValuePool) => {
        setChanged(true);
        setSelectedPools((prevPools) => {
            const newPools = { ...prevPools };
            // @ts-ignore
            newPools[typePool] = value;
            return newPools;
        });
    };

    const savePools = () => {
        dispatch(
            editActiveProject({
                ...activeProject,
                config: {
                    ...activeProject.config,
                    ...selectedPools,
                },
            }),
        );
    };

    return (
        <TableLocation pools={selectedPools}>
            <div className={style.selectorBlockContainer}>
                <div className={style.selectorBlock}>
                    <div className={style.poolsBlock}>
                        <div className={style.poolName}>Limited users</div>
                        <Select
                            onChange={(value) => {
                                value?.label && changePool(Pool.Free, value.label);
                            }}
                            value={{ label: selectedPools.free_server_pool }}
                            placeholder="pool"
                            styles={customStyles}
                            options={optionPools.publicPools}
                        />
                    </div>
                    <div className={style.poolsBlock}>
                        <div className={style.poolName}>Unlimited users</div>
                        <Select
                            onChange={(value) => {
                                value?.label && changePool(Pool.Paid, value.label);
                            }}
                            value={{ label: selectedPools.paid_server_pool }}
                            placeholder="pool"
                            styles={customStyles}
                            options={optionPools.publicPools}
                        />
                    </div>
                    <div className={style.poolsBlock}>
                        <div className={style.poolName}>Private server pools</div>
                        <Select
                            onChange={(value) => {
                                const privatePools = value.map((pool) => pool.label);
                                changePool(Pool.Private, privatePools);
                            }}
                            isMulti
                            value={(() =>
                                selectedPools.private_pools?.map((privatePool: any) => ({
                                    value: privatePool,
                                    label: privatePool,
                                })))()}
                            placeholder="Select pools"
                            styles={customStyles}
                            options={optionPools.privatePools}
                        />
                    </div>
                </div>
                <div className={style.poolButtonsBlock}>
                    <If condition={changed}>
                        <button className={buttonStyle.buttonReset} onClick={getPool}>
                            {window.locales.reset}
                        </button>
                    </If>
                    <button
                        className={buttonStyle.buttonSubmit}
                        onClick={savePools}
                        disabled={disabledSave || !changed}
                    >
                        {window.locales.save}
                    </button>
                </div>
            </div>
        </TableLocation>
    );
};

export default Locations;
