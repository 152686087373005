import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Table from "@components/Table";
import { Modal } from "@components/Modal";
import { activatePurchase, deletePurchases } from "@services/Purchases";
import { getPurchases } from "@state/purchases";
import RootState from "@state/interfaces";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import { Data } from "@components/Table/types";
import { accumulatePurchases, headings } from "@screens/Users/User/Content/Purchases/utils";

const initialState = initialStateModal("");
const reducerLocation = reducerModal(initialState);

const Purchases = () => {
    const { user, project } = useParams();
    const purchases = useSelector((state: RootState) => state.purchases);
    const [body, setBody] = useState<Data[]>([]);
    const dispatch = useDispatch();
    const [modals, dispatchModal] = useReducer(reducerLocation, initialState);
    useEffect(() => {
        dispatch(getPurchases());
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            const content = accumulatePurchases(purchases, disablePurchaseModal, enablePurchaseModal, user);
            setBody(content);
        }
    }, [purchases, user]);

    const disablePurchaseModal = (id: number) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: id.toString(),
        });
    };

    const enablePurchaseModal = (id: number) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: id.toString(),
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: "",
        });
    };

    const disablePurchase = async () => {
        await deletePurchases(user, modals.item);
        dispatch(getPurchases());
        closeModal();
    };

    const enablePurchase = async () => {
        await activatePurchase(user, modals.item, project);
        dispatch(getPurchases());
        closeModal();
    };

    return (
        <div className="screenUsersContentContainer">
            <Table
                tableData={body}
                headings={headings}
                title="Purchases"
                emptyMessage={window.locales.noPurchasesRow}
            />

            {/* Modal disable purchase */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.disablePurchase}
                confirm={{
                    label: window.locales.disable,
                    onConfirm: disablePurchase,
                }}
            >
                {window.locales.disablePurchaseConfirmation}
            </Modal>

            {/* Modal enable purchase */}
            <Modal
                isNegative
                isOpen={modals.editModal}
                onClose={closeModal}
                title={window.locales.activatePurchase}
                confirm={{
                    label: window.locales.confirm,
                    onConfirm: enablePurchase,
                }}
            >
                {window.locales.enablePurchaseConfirmation}
            </Modal>
        </div>
    );
};

export default Purchases;
