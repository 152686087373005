import React, { ReactElement } from "react";
import { Field, Formik } from "formik";

import { Modal } from "@components/Modal";
import { ModalType } from "@screens/Features/FeatureModal/FeatureModal.types";
import { CustomSelect, TextField } from "@components/FormikFields";
import { ItemEntitlement } from "@screens/Auth/AuthEntitlements/AuthEntitlements.utils";

type EntitlementModalType = ModalType<ItemEntitlement>;

const reducerOptions = ["merge", "or", "and", "sum", "max", "min", "product", "union"].map((name) => ({
    value: name,
    label: name,
}));

function AuthEntitlementModal({ title, isOpen, closeModal, action, item }: EntitlementModalType): ReactElement {
    const [name, reducer, defaultValue] = item;

    const configValue =
        defaultValue && typeof defaultValue === "object" ? JSON.stringify(defaultValue) : defaultValue || "";
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            title={title}
            confirm={{
                label: window.locales.confirm,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="tokenForm"
            disabled={false}
        >
            <Formik
                initialValues={{
                    name,
                    reducer,
                    defaultValue: configValue,
                }}
                validate={(values) => {
                    const errors: Record<string, string> = {};
                    const { name } = values;
                    if (!name) errors.twoFactorNumber = window.locales.invalidFieldEmpty;
                    return errors;
                }}
                onSubmit={({ name, reducer, defaultValue }) => {
                    let value;
                    try {
                        value = JSON.parse(defaultValue as string);
                    } catch (e) {
                        value = defaultValue;
                    }

                    action([name, reducer, value]);
                    closeModal();
                }}
            >
                {({ handleSubmit }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="tokenForm" onSubmit={handleSubmit}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type name"
                                type="text"
                                name="name"
                                disabled={name}
                            />
                        </section>
                        <section>
                            <Field
                                name="reducer"
                                options={reducerOptions}
                                component={CustomSelect}
                                placeholder="Select reducer"
                            />
                        </section>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type default Value"
                                type="text"
                                name="defaultValue"
                            />
                        </section>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

export default AuthEntitlementModal;
