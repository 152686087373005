import React, { ReactNode, useMemo } from "react";
import { Editor, Monaco } from "@monaco-editor/react";
import { observer } from "mobx-react-lite";

import { monacoEditorOptions } from "@common/constant/monacoOption";
import objectStore from "@state/object";

const KindConfig = observer(({ schema }: { schema: Record<string, unknown> }): ReactNode => {
    const setSchema = (_: any, { languages }: Monaco) => {
        languages.json.jsonDefaults.setDiagnosticsOptions({
            allowComments: true,
            schemas: [
                {
                    fileMatch: ["*"],
                    schema,
                },
            ],
            validate: true,
        });
    };

    const handleChange = (data: string | undefined) => {
        if (data === undefined) return;
        objectStore.updateData(data);
    };

    const value = useMemo(
        () =>
            typeof objectStore.objectData.data === "string"
                ? objectStore.objectData.data
                : JSON.stringify(objectStore.objectData.data, undefined, 4),
        [objectStore.objectData.data],
    );

    return (
        <Editor
            height="50vh"
            defaultLanguage="json"
            options={monacoEditorOptions}
            value={value}
            onChange={handleChange}
            theme="vs-dark"
            onMount={setSchema}
        />
    );
});

export default KindConfig;
