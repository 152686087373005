import React from "react";

import Open from "@common/img/svg/eye-opened-icon.svg";
import Close from "@common/img/svg/eye-closed-icon.svg";

import * as style from "./style.scss";

type TEye = {
    isShow: boolean;
    handle: () => void;
};

const Eye = ({ isShow, handle }: TEye) => (
    <div className={style.eyeContainer} onClick={handle}>
        {isShow ? <img src={Open} alt="img" /> : <img src={Close} alt="img" />}
    </div>
);

export { Eye };
