import { FieldProps } from "formik";
import React from "react";
import Select from "react-select";

import { NameContainer } from "@components/FormikFields/components";
import { getNameField } from "@components/FormikFields/utils";

import { customStyles } from "./utils";

interface Option {
    label: string;
    value: string;
}

type OptionType = { [key: string]: any };
type OptionsType = Array<OptionType>;

interface CustomSelectProps extends FieldProps {
    options: OptionsType;
    isMulti: boolean;
    placeholder: string;
    disabled: boolean;
}

const CustomSelect = ({ placeholder, disabled = false, field, form, options, isMulti = false }: CustomSelectProps) => {
    const getNewName = getNameField(field.name);
    const { touched, errors } = form;
    const error = touched[field.name] ? errors[field.name] : false;

    const onChange = (option: any) => {
        form.setFieldValue(
            field.name,
            isMulti ? (option as Option[]).map((item: Option) => item.value) : (option as Option).value,
        );
    };

    const getValue = () => {
        if (!field.value) {
            return isMulti ? [] : ("" as any);
        }

        if (options) {
            return isMulti
                ? options.filter((option: any) => field.value.indexOf(option.value) >= 0)
                : options.find((option: any) => option.value === field.value);
        }
        return isMulti ? [] : ("" as any);
    };

    return (
        <>
            <NameContainer $isError={Boolean(error)}>{getNewName}</NameContainer>
            <Select
                isDisabled={disabled}
                name={field.name}
                value={getValue()}
                onChange={onChange}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                styles={customStyles}
            />
        </>
    );
};

export default CustomSelect;
