import React from "react";
import Select from "react-select";

import { Categories } from "@constants/fireshield";
import { customStyles } from "@screens/Settings/Fireshield/utils";
import { DomainInput } from "@screens/Settings/Fireshield/DomainForm/DomainInput";
import { FieldInput } from "@screens/Settings/components";
import { ISelector } from "@screens/Settings/Fireshield/types";
import * as fireshieldStyle from "@screens/Settings/Fireshield/style.scss";
import * as settingsStyle from "@screens/Settings/style.scss";

import { IDomainsFormProps } from "./types";

const DomainForm = ({ domains, setDomains, domainsUrl, setDomainsUrl }: IDomainsFormProps) => {
    const updateOptions = Categories.filter(
        (option) => !domains.some((listItem: ISelector) => listItem.value === option.value),
    );
    const addEmptyFieldDomains = (selectedDomain: any) => {
        if (selectedDomain) {
            const { value } = selectedDomain;
            domainsUrl[value] = [];
            setDomainsUrl({ ...domainsUrl });
        }

        setDomains((prev: any) => [...prev, selectedDomain]);
    };

    const selectedDomains = (selectedDomain: any, index: number) => {
        const { value } = selectedDomain;
        const currentDomainsList = Object.keys(domainsUrl)[index];
        if (!domainsUrl[currentDomainsList]) domainsUrl[value] = [];
        domainsUrl[value] = domainsUrl[Object.keys(domainsUrl)[index]];
        setDomainsUrl({ ...domainsUrl });
        setDomains((prevState: any) => {
            if (prevState[index]) {
                prevState[index] = selectedDomain;
                return [...prevState];
            }
            return [...prevState, selectedDomain];
        });
    };

    return (
        <>
            {domains.map((domain: ISelector, index) => (
                <div className={settingsStyle.twoFieldContainer} key={index}>
                    <div className={fireshieldStyle.selectContainer}>
                        <Select
                            isSearchable={false}
                            placeholder="types"
                            styles={customStyles}
                            onChange={(val) => selectedDomains(val, index)}
                            options={updateOptions}
                            value={domain}
                        />
                    </div>
                    <div className={fireshieldStyle.wrapInputContainer}>
                        <DomainInput setDomainsUrl={setDomainsUrl} domainsUrl={domainsUrl} domain={domain.label} />
                    </div>
                </div>
            ))}
            {updateOptions.length > 0 && (
                <div className={settingsStyle.twoFieldContainer}>
                    <div className={fireshieldStyle.selectContainer}>
                        <Select
                            menuPlacement="top"
                            isSearchable={false}
                            placeholder="domains"
                            onChange={addEmptyFieldDomains}
                            styles={customStyles}
                            options={updateOptions}
                            value={null}
                        />
                    </div>
                    <div className={fireshieldStyle.inputContainer}>
                        <FieldInput
                            autoComplete="off"
                            maxLength={255}
                            disabled
                            $isInvalid={false}
                            placeholder="example.com"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export { DomainForm };
