import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@screens/Error/components";
import notFoundIcon from "@common/img/svg/not-found-icon.svg";
import * as style from "@screens/Error/style.scss";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className={style.errorPageContainer}>
            <div className={style.worldContainer}>
                <img src={notFoundIcon} alt="img" />
            </div>
            <div className={style.formContainer}>
                <div className={style.text}>
                    <div className={style.title}>Oops! Page not found</div>
                    <div className={style.description}>The page you were looking for does not exist</div>
                </div>
                <Button onClick={() => navigate("/projects")}>Go to project</Button>
            </div>
        </div>
    );
};

export { NotFound };
