import React, { Component } from "react";
import { loadProjectActiveSessions } from "@services/Sessions";
import Table from "@components/Table";
import { connect } from "react-redux";
import { accumulateSessions } from "@screens/ActiveSessions/utils";
import { headings } from "@screens/ActiveSessions/utils";
import { pageViewsTracking } from "@common/gaTracking/pageViewsTracking";
import * as tableContainerStyle from "@components/TableContainer/style.scss";
import { withRouter } from "@common/withRouter";

class ActiveSessions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            body: [],
        };
    }

    updateTable(publickey) {
        if (!publickey) {
            return;
        }

        loadProjectActiveSessions().then((sessions) => {
            const body = accumulateSessions(sessions, this.openUserPage.bind(this));
            this.setState({ body });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeProject.publickey !== prevProps.activeProject.publickey) {
            this.updateTable(this.props.activeProject.publickey);
        }
    }

    componentDidMount() {
        pageViewsTracking(this.props.router.location.pathname);
        this.updateTable(this.props.activeProject.publickey);
    }

    openUserPage(uid) {
        this.props.router.navigate(`/projects/${this.props.activeProject.publickey}/users/${uid}`);
    }

    render() {
        return (
            <>
                <div className="head">
                    <h1>{window.locales.activeSessions}</h1>
                </div>
                <div className={tableContainerStyle.tableContainer}>
                    <Table
                        tableData={this.state.body}
                        headings={headings}
                        title={window.locales.activeSessions}
                        emptyMessage={window.locales.noProjectSessions}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeProject: state.activeProject,
    user: state.user,
});

export default connect(mapStateToProps)(withRouter(ActiveSessions));
