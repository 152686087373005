import React from "react";

import { Data } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import iconDelete from "@common/img/svg/delete-icon.svg";
import iconPen from "@common/img/svg/pen-icon.svg";
import { IAuthMethod } from "@screens/Settings/Authentifications/types";
import { If } from "@components/If";
import { Eye } from "@components/Eye/Eye";
import * as table from "@components/Table/style.scss";

const headings = [
    {
        isSort: true,
        heading: window.locales.authMethod,
    },
    {
        isSort: false,
        heading: window.locales.authSettings,
    },
    {
        isSort: false,
        heading: "",
    },
];

const accumulateAuthMethods = (
    authMethods: IAuthMethod[] = [],
    edit: boolean,
    editModal: (authMethod: IAuthMethod) => void,
    deleteModal: (authMethod: IAuthMethod) => void,
    handleVisibility: (auth_method_id: number) => void,
): Data[] =>
    authMethods.map((authMethodsItems) => {
        const { auth_method = "", auth_method_id = 0, auth_settings = null, show = false } = authMethodsItems;
        const parseAuthSettings = auth_settings ? JSON.stringify(auth_settings) : "";

        return {
            auth_method,
            productId: {
                component: (
                    <If condition={!!parseAuthSettings}>
                        <div className={table.tdJson}>{show ? parseAuthSettings : "•••••"}</div>
                    </If>
                ),
            },
            buttons: {
                component: (
                    <If condition={edit}>
                        <div className={table.buttonsColumn}>
                            <If condition={!!parseAuthSettings}>
                                <TargetIcon onClick={() => handleVisibility(auth_method_id)}>
                                    <Eye isShow={show} handle={() => {}} />
                                </TargetIcon>
                            </If>
                            <TargetIcon onClick={() => editModal(authMethodsItems)}>
                                <img src={iconPen} alt="pen" />
                            </TargetIcon>
                            <TargetIcon onClick={() => deleteModal(authMethodsItems)}>
                                <img src={iconDelete} alt="delete" />
                            </TargetIcon>
                        </div>
                    </If>
                ),
            },
        };
    });

export { accumulateAuthMethods, headings };
