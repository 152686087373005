import React from "react";

import { TableObjectProps } from "@components/Table/types";
import * as table from "@components/Table/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";

type EntitlementsTableType = {
    name: string;
    reducer: string;
    defaultValue: any;
    buttons?: TableObjectProps;
    event?: () => void;
};

const headingEntitlements = [
    {
        isSort: true,
        heading: window.locales.name,
    },
    {
        isSort: true,
        heading: window.locales.reducer,
    },
    {
        isSort: true,
        heading: window.locales.defaultValue,
    },
];

type ItemEntitlement = [string, string, unknown];

const accumulateEntitlements = (
    objects: ItemEntitlement[],
    defaultValue: Record<string, unknown>,
    showEditModal: (property: ItemEntitlement) => void,
    onDelete: (property: ItemEntitlement) => void,
): EntitlementsTableType[] =>
    objects.map((entitlement: ItemEntitlement) => {
        const [name, reducer] = entitlement;
        const value = defaultValue[name];

        const configValue =
            value && typeof value === "object"
                ? {
                      tooltip: {
                          title: JSON.stringify(value),
                          content: JSON.stringify(value, undefined, 4),
                      },
                  }
                : `${value}` || "no default";

        return {
            name,
            reducer,
            defaultValue: configValue,
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => showEditModal([name, reducer, defaultValue[name]])}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon onClick={() => onDelete([name, reducer, defaultValue[name]])}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };
    });

export { accumulateEntitlements, headingEntitlements };

export type { ItemEntitlement };
