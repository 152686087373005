import React, { Component } from "react";

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: props.width ? props.width : 19,
            height: props.height ? props.height : 21,
            theme: props.theme ? props.theme : "default",
            style: props.style ? props.style : {},
            label: props.label ? props.label : undefined,
        };

        this.callbacs = {};
        this.callbacs.onClick = props.onClick ? props.onClick : () => {};
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            width: props.width ? props.width : 19,
            height: props.height ? props.height : 21,
            theme: props.theme ? props.theme : "default",
            style: props.style ? props.style : {},
            label: props.label ? props.label : undefined,
        });
    }

    onClick() {
        this.callbacs.onClick();
    }

    render() {
        const style = this.state.style;
        if (!style.height) style.height = `${this.state.height}px`;

        return (
            <div
                id={this.state.id}
                className={`icons iconsStyle1 calendar ${this.state.theme}${this.state.label ? " flex" : ""}`}
                onClick={this.onClick.bind(this)}
                style={style}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={this.state.width}
                    height={this.state.height}
                    viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                >
                    <path
                        fill="#2EA6F8"
                        fillRule="evenodd"
                        d="M13 2V0h2v2h.08a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3V0h2v2h8zM3 7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H3zm1 3h2v2H4v-2zm4 0h2v2H8v-2zm4 0h2v2h-2v-2z"
                    />
                </svg>
            </div>
        );
    }
}

export default Calendar;
