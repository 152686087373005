import React from "react";
import { Field, Formik } from "formik";

import { Modal } from "@components/Modal";
import { TextField, TextAreaField } from "@components/FormikFields";
import { IPropsModal } from "@components/Modal/types";
import { IAuthMethod } from "@screens/Settings/Authentifications/types";

const AuthMethodModal = ({ title, action, close, isOpen, buttonName, item }: IPropsModal<IAuthMethod>) => (
    <Modal
        isOpen={isOpen}
        onClose={close}
        title={title}
        confirm={{
            label: buttonName,
            onConfirm: () => {},
        }}
        /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
        typeButton="submit"
        nameForm="editForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                auth_method: item.auth_method,
                auth_method_id: item.auth_method_id,
                auth_settings: item.auth_settings,
            }}
            validate={({ auth_method, auth_settings }) => {
                const errors: any = {};
                if (!auth_method) errors.auth_method = window.locales.invalidAuthMethod;
                if (typeof auth_settings !== "string" && typeof auth_settings !== "object")
                    errors.auth_settings = window.locales.invalidConfig;

                try {
                    typeof auth_settings === "string" && JSON.parse(auth_settings);
                } catch (e) {
                    errors.auth_settings = window.locales.invalidConfig;
                }

                return errors;
            }}
            onSubmit={({ auth_method, auth_method_id, auth_settings }) => {
                if (typeof auth_settings === "string") {
                    const jsonAuthSettings = JSON.parse(auth_settings);
                    Object.keys(jsonAuthSettings)
                        .filter((key) => typeof jsonAuthSettings[key] === "string")
                        .forEach((key) => {
                            jsonAuthSettings[key] = jsonAuthSettings[key].trim();
                        });
                    auth_settings = JSON.stringify(jsonAuthSettings);
                } else if (typeof auth_settings === "object") {
                    Object.keys(auth_settings)
                        .filter((key) => typeof auth_settings[key] === "string")
                        .forEach((key) => {
                            auth_settings[key] = auth_settings[key].trim();
                        });

                    auth_settings = JSON.stringify(auth_settings);
                }

                action({
                    auth_method_id,
                    auth_method,
                    auth_settings,
                    show: true,
                });
                close();
            }}
        >
            {({ values, handleSubmit }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="editForm" onSubmit={handleSubmit}>
                    <section>
                        <Field component={TextField} placeholder="Auth method" type="text" name="auth_method" />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.auth_settings}
                            type="text"
                            name="auth_settings"
                            placeholder="Type auth settings"
                        />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { AuthMethodModal };
