import { createSlice } from "@reduxjs/toolkit";

import { IAccount } from "@state/account/types";

const initialState: IAccount = {
    uid: "",
    name: "",
    isOkta: false,
    verified: false,
    authorized: false,
    accessToken: "",
    firebaseToken: "",
    infoFromFirebase: {
        first_name: "",
        last_name: "",
        company: "",
        company_code: "",
        state: "",
        city: "",
        country: "",
        projectPrefix: "",
    },
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        updateAccount(state, action) {},
        deleteAccount() {
            return initialState;
        },
        getAccount() {},
        setAccount(state, action) {
            return action.payload;
        },
    },
});

export const { getAccount, setAccount, deleteAccount, updateAccount } = accountSlice.actions;

export default accountSlice.reducer;
