import { useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";

const useCreatePortalInBody = () => {
    const wrapperRef = useRef<HTMLElement | null>(null);
    if (wrapperRef.current === null && typeof document !== "undefined") {
        const portal = document.createElement("div");
        portal.setAttribute("data-body-portal", "");
        wrapperRef.current = portal;
    }
    useLayoutEffect(() => {
        const wrapper = wrapperRef.current;
        if (!wrapper || typeof document === "undefined") {
            return undefined;
        }
        document.body.appendChild(wrapper);
        return () => {
            document.body.removeChild(wrapper);
        };
    }, []);

    return (children: any) => wrapperRef.current && createPortal(children, wrapperRef.current);
};

export { useCreatePortalInBody };
