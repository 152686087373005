import { Formik } from "formik";
import React, { useState } from "react";

import { Modal } from "@components/Modal";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import { InputCode } from "@screens/Landing/Authorization/InputCode";

const ModalCode = ({
    isOpen,
    closeModal,
    applyForm,
    isError,
}: {
    isError: boolean;
    isOpen: boolean;
    closeModal: () => void;
    applyForm: (data: any) => void;
}) => {
    const [code, setCode] = useState("");
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            title="Verification code"
            confirm={{
                label: window.locales.confirm,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="codeForm"
            disabled={false}
        >
            <Formik
                initialValues={{
                    code: "",
                }}
                onSubmit={() => applyForm(code)}
            >
                {({ handleSubmit }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="codeForm" onSubmit={handleSubmit}>
                        <div className={authorizationStyle.fieldContainer}>
                            <InputCode
                                isError={isError}
                                submit={(num: string) => {
                                    setCode(num);
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export { ModalCode };
