import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { GroupStatusServersType } from "@screens/Pools/DiscoveryPool.types";
import Table from "@components/Table";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import { IObjectsRequest } from "@screens/Objects/Objects.types";
import { GENERAL_NAMESPACE, ObjectEnum } from "@screens/Objects/Object/Object.constants";
import { getObjectByKindRequest } from "@services/Objects";
import base64ToJson from "@common/methods/base64toJSON";
import { serversHeader } from "@screens/Pools/Pool/Servers/Servers.utils";
import useServer from "@screens/Pools/Pool/useServer";
import ServerStatusModal from "@screens/Pools/Pool/Servers/ServerStatusModal";

function LocationServers() {
    const { location = "", pool = "" } = useParams();

    const getData = useCallback(async (): Promise<void> => {
        const dataGroupServers: IObjectsRequest = {
            kind: ObjectEnum.DISCOVERY_LOCATION_STATUS,
            name: `${pool}/${location}`,
            metadata_only: false,
            namespace: GENERAL_NAMESPACE,
        };

        const { data } = await getObjectByKindRequest(dataGroupServers);

        if (!data || !data.object) {
            return;
        }
        const objectConfig = base64ToJson(data.object.data) as GroupStatusServersType;

        objectConfig.servers && setGroupsServers(objectConfig.servers);
    }, [location, pool]);
    const { setGroupsServers, tableData, searchCallback, resetSelectedServerStatus, selectedServerStatus } =
        useServer(getData);

    return (
        <>
            <ServerStatusModal item={selectedServerStatus} closeModal={resetSelectedServerStatus} />
            <Table
                tableData={tableData}
                headings={serversHeader}
                title={window.locales.locations}
                emptyMessage={window.locales.noServers}
            >
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={searchCallback} />
                    </div>
                </div>
            </Table>
        </>
    );
}

export default LocationServers;
