import React from "react";

import iconWindows from "@common/img/svg/windows-icon.svg";
import * as supportStyle from "@screens/Settings/Members/Supports/style.scss";

const WindowsSupport = () => (
    <span className={supportStyle.icon}>
        <img src={iconWindows} alt="img" />
    </span>
);

export { WindowsSupport };
