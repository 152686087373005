import styled from "styled-components";

export const ContainerBtn = styled.button<{ $isNegative: boolean }>`
    background-color: ${({ theme, $isNegative }) =>
        $isNegative ? theme.colors.negativeButton.bg : theme.colors.button.bg};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    line-height: 24px;
    padding: 12px 24px;
    letter-spacing: 0.02em;
    font-size: ${({ theme }) => theme.text.button.size};
    font-weight: ${({ theme }) => theme.text.button.weight};
    outline: unset;
    border: unset;
    cursor: pointer;
    user-select: none;

    :hover {
        background-color: ${({ theme, $isNegative }) =>
            $isNegative ? theme.colors.negativeButtonHover.bg : theme.colors.buttonHover.bg};
    }

    :active {
        background-color: ${({ theme, $isNegative }) =>
            $isNegative ? theme.colors.negativeButtonActive.bg : theme.colors.buttonActive.bg};
    }
`;
