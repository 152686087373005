import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";
import { ThemeProvider } from "styled-components";

import theme from "@common/style/themes";

export interface ThemeProjectProps {
    children: ReactNode;
}

const isProd = process.env.CURRENT_API === "prod";
const ConfigTheme: FC<ThemeProjectProps> = observer(({ children }) => (
    <ThemeProvider theme={isProd ? theme.defaultTheme : theme.stageTheme}>{children}</ThemeProvider>
));

export { ConfigTheme };
