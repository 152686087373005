import React from "react";

import * as style from "@components/Empty/style.scss";
import boxIcon from "@common/img/svg/box-icon.svg";

import { IEmptyProps } from "./types";

const Empty = ({ message }: IEmptyProps) => (
    <div className={style.emptyContainer}>
        <img src={boxIcon} alt="icon" />
        <div className={style.text}>
            <p>{message}</p>
        </div>
    </div>
);

export { Empty };
