// extracted by mini-css-extract-plugin
var _1 = "style-buttonContainer__FfrC4";
var _2 = "style-formContent__Eppyr";
var _3 = "style-inputButton__wnwkA";
var _4 = "style-inputWithButton__httG8";
var _5 = "style-labelSwitcher__go7Nq";
var _6 = "style-resetButton___uELI";
var _7 = "style-switcher__gwDe7";
var _8 = "style-switcherContainer__fTuaB";
var _9 = "style-switcherField__k8_sv";
export { _1 as "buttonContainer", _2 as "formContent", _3 as "inputButton", _4 as "inputWithButton", _5 as "labelSwitcher", _6 as "resetButton", _7 as "switcher", _8 as "switcherContainer", _9 as "switcherField" }
