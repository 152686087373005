import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { pageViewsTracking } from "@common/gaTracking/pageViewsTracking";
import * as style from "@screens/Settings/style.scss";

import Header from "./Header";

const VPN = () => {
    const location = useLocation();

    useEffect(() => {
        pageViewsTracking(location.pathname);
    }, [location.pathname]);

    return (
        <>
            <Header />
            <div className={style.settingsContainer}>
                <Outlet />
            </div>
        </>
    );
};

export default VPN;
