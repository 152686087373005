import { ExportModals, TExportAction, IExportModal, IMask, TForm } from "@screens/Export/ExportActions/types";

const initialStateExportModal = {
    userModal: false,
    devicesModal: false,
    sessionsModal: false,
};

const reducerExportModals = (state: IExportModal, action: TExportAction) => {
    switch (action.type) {
        case ExportModals.userModal:
            return {
                ...state,
                userModal: true,
            };
        case ExportModals.devicesModal:
            return {
                ...state,
                devicesModal: true,
            };
        case ExportModals.sessionsModal:
            return {
                ...state,
                sessionsModal: true,
            };
        case ExportModals.closeModals:
            return initialStateExportModal;
        default:
            return initialStateExportModal;
    }
};

const mask: IMask = {
    users: "users",
    devices: "devices",
    sessions: "sessionsbycarrier",
};

const initialStateForm: TForm = {
    days: {
        from: undefined,
        till: undefined,
    },
    time: {
        from: "00:00",
        till: "23:59",
    },
};

export { reducerExportModals, initialStateExportModal, mask, initialStateForm };
