import { createSlice } from "@reduxjs/toolkit";

const projectsSlice = createSlice({
    name: "projects",
    initialState: [],
    reducers: {
        getProjects() {},
        setProjects(state, action) {
            return action.payload;
        },
    },
});

export const { setProjects, getProjects } = projectsSlice.actions;

export default projectsSlice.reducer;
