import React from "react";

import * as style from "@components/IconProject/style.scss";

const UnsetIconProject = ({ projectName }: { projectName: string }) => {
    const [firstWord, secondWord] = projectName.split(/[ ,]+/);
    const logoName = secondWord ? firstWord[0] + secondWord[0] : firstWord[0];

    return logoName ? <div className={style.imgContainer}>{logoName}</div> : null;
};

const ProjectIcon = ({ icon, carrier_name }: { icon: string | File | null; carrier_name: string }) =>
    icon ? (
        <div className={style.projectIcon} style={{ backgroundImage: `url(${icon})` }} />
    ) : (
        <UnsetIconProject projectName={carrier_name} />
    );

export { UnsetIconProject, ProjectIcon };
