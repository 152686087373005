import React from "react";

import { ContainerBtn } from "@common/button/components";

interface IButtonProps {
    children: string;
    name: string;
    onClick: () => void;
    type: "button" | "submit" | "reset" | undefined;
    form: string | undefined;
    disabled: boolean;
    isNegative: boolean;
}

const Button = ({ children, onClick, name, form, type, disabled, isNegative }: IButtonProps) => (
    <ContainerBtn type={type} name={name} onClick={onClick} form={form} disabled={disabled} $isNegative={isNegative}>
        {children}
    </ContainerBtn>
);

export { Button };
