import React from "react";

import { MACOsSupport } from "@screens/Settings/Members/Supports/MACOs";
import { ESupport } from "@screens/Settings/Members/types";
import { IOsSupport } from "@screens/Settings/Members/Supports/IOs";
import { AndroidSupport } from "@screens/Settings/Members/Supports/Android";
import { WindowsSupport } from "@screens/Settings/Members/Supports/Windows";
import * as style from "@screens/Settings/Members/Supports/style.scss";

const Supports = ({ supports }: { supports: ESupport[] }) => (
    <div className={style.supportCellContainer}>
        {supports.includes(ESupport.IOs) && <IOsSupport />}
        {supports.includes(ESupport.MACOs) && <MACOsSupport />}
        {supports.includes(ESupport.Android) && <AndroidSupport />}
        {supports.includes(ESupport.Windows) && <WindowsSupport />}
    </div>
);

export { Supports };
