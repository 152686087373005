const text = {
    fontBody: {
        fontFamily: "Gordita",
        h1: {
            size: 28,
            weight: 700,
        },
        h6: {
            size: 16,
            weight: 600,
        },
        h5: {
            size: 16,
            weight: "lighter",
        },
        regular: {
            size: 16,
            weight: 300,
        },
    },
    modal: {
        h1: {
            size: 28,
        },
        regular: {
            size: 16,
            weight: 300,
        },
    },
    button: {
        size: "16px",
        weight: 600,
    },
};
export default text;
