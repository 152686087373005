import styled from "styled-components";

const StatusInvite = styled.span<{ $isExpired: boolean }>`
    font-size: 12px;
    opacity: 0.6;
    line-height: 26px;
    color: ${({ $isExpired, theme }) => ($isExpired ? theme.colors.negative : theme.colors.white)};
`;

export { StatusInvite };
