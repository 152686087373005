import React from "react";

import iconApple from "@common/img/svg/apple-icon.svg";
import * as supportStyle from "@screens/Settings/Members/Supports/style.scss";

const IOsSupport = () => (
    <span className={supportStyle.icon}>
        <img src={iconApple} alt="apple" />
    </span>
);

export { IOsSupport };
