import React, { ChangeEvent, ReactElement, useMemo } from "react";
import { Field, Formik } from "formik";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { CustomInput } from "@screens/Landing/Authorization/Input";
import objectStore from "@state/object";
import { NEW_PAGE } from "@common/constant/urls";
import getDateUTCString from "@common/methods/getDateUTCString";
import * as buttonStyle from "@components/Button/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconClose from "@common/img/svg/close-icon.svg";
import { IconPlus } from "@common/icons";

import * as localStyle from "./ObjectMetadata.style.scss";

const regexKey = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;

const KindMetadata = observer((): ReactElement => {
    const { name } = useParams();
    const isDisabled = useMemo(() => name !== NEW_PAGE, [name]);
    const data = useMemo(() => objectStore.objectData.metadata, [objectStore.objectData.metadata]);

    const deleteLabel = (key: string) => {
        delete objectStore.objectData.metadata.labels[key];
        objectStore.updateMetadata(data);
    };

    return (
        <Formik
            initialValues={{
                name: data.name,
                create_time: getDateUTCString(data.createTime),
                update_time: getDateUTCString(data.updateTime),
                new_label: "",
                ...data.labels,
            }}
            enableReinitialize
            validate={(values) => {
                const errors: Record<string, string> = {};
                if (values.new_label && !regexKey.test(values.new_label)) {
                    errors.new_label = window.locales.incorrectValue;
                }

                return errors;
            }}
            onSubmit={async ({ new_label }, { setFieldValue, setFieldError }) => {
                if (!Object.prototype.hasOwnProperty.call(data.labels, new_label)) {
                    data.labels[new_label] = "";
                    await setFieldValue("new_label", "");
                    objectStore.updateMetadata(data);
                } else {
                    setFieldError("new_label", window.locales.labelExists);
                }
            }}
        >
            {({ handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit} className={localStyle.metadataContainer}>
                    <Field
                        component={CustomInput}
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            objectStore.updateMetadata({ name: event.target.value });
                            handleChange(event);
                        }}
                        disabled={isDisabled}
                    />
                    <Field component={CustomInput} disabled type="text" name="create_time" placeholder="Create time" />
                    <Field component={CustomInput} disabled type="text" name="update_time" placeholder="Update time" />
                    {data.labels &&
                        Object.entries(data.labels).map(([key]) => (
                            <div key={key} className={localStyle.metadataContainer__field_label}>
                                <Field
                                    component={CustomInput}
                                    type="text"
                                    name={key}
                                    placeholder={`Type a ${key}`}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        objectStore.objectData.metadata.labels[key] = event.target.value;
                                        objectStore.updateMetadata(data);
                                        handleChange(event);
                                    }}
                                />
                                <div className={localStyle.metadataContainer__field_button}>
                                    <TargetIcon onClick={() => deleteLabel(key)}>
                                        <img src={iconClose} alt="close" />
                                    </TargetIcon>
                                </div>
                            </div>
                        ))}
                    <div className={localStyle.metadataContainer__new_label}>
                        <Field
                            component={CustomInput}
                            type="text"
                            name="new_label"
                            placeholder="Type a new key for the label"
                        />
                        <div className={localStyle.metadataContainer__buttons}>
                            <button className={buttonStyle.buttonAdd} type="submit">
                                <IconPlus theme="blue" />
                                {window.locales.addLabel}
                            </button>
                        </div>
                        <div className={localStyle.metadataContainer__empty} />
                    </div>
                </form>
            )}
        </Formik>
    );
});

export default KindMetadata;
