import React from "react";
import { Field, Formik } from "formik";

import { Modal } from "@components/Modal";
import { TextField, TextAreaField } from "@components/FormikFields";
import { IPropsModal } from "@components/Modal/types";
import { IPaymentMethod } from "@screens/Settings/Payments/types";

const PaymentModal = ({ title, action, close, isOpen, buttonName, item }: IPropsModal<IPaymentMethod>) => (
    <Modal
        isOpen={isOpen}
        onClose={close}
        title={title}
        confirm={{
            label: buttonName,
            onConfirm: () => {},
        }}
        /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
        typeButton="submit"
        nameForm="editForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                purchase_type: item.purchase_type,
                purchase_settings: item.purchase_settings,
            }}
            validate={({ purchase_type, purchase_settings }) => {
                const errors: any = {};
                if (!purchase_type) errors.purchase_type = window.locales.invalidType;

                try {
                    typeof purchase_settings === "string" && JSON.parse(purchase_settings);
                } catch (e) {
                    errors.purchase_settings = window.locales.invalidConfig;
                }

                return errors;
            }}
            onSubmit={({ purchase_type, purchase_settings }) => {
                action({
                    purchase_type: purchase_type.toLowerCase(),
                    purchase_settings,
                    show: false,
                });
                close();
            }}
        >
            {({ values, handleSubmit }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="editForm" onSubmit={handleSubmit}>
                    <section>
                        <Field component={TextField} placeholder="Purchase type" type="text" name="purchase_type" />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.purchase_settings}
                            type="text"
                            name="purchase_settings"
                            placeholder="Type purchase settings"
                        />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { PaymentModal };
