import React from "react";

import { TableObjectProps } from "@components/Table/types";
import { AuthGrantType } from "@services/Auth/Grant/Auth.Grant.types";
import * as table from "@components/Table/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconTrash from "@common/img/svg/trash-icon.svg";

const headingUserGrant = [
    {
        isSort: true,
        heading: window.locales.grantor,
    },
    {
        isSort: true,
        heading: window.locales.entitlements,
    },
];

type UserGrantsTableType = {
    grantor: string;
    entitlements: TableObjectProps;
    buttons?: TableObjectProps;
    event?: () => void;
};

const accumulateUserGrants = (
    objects: AuthGrantType[],
    showEditModal: (property: AuthGrantType) => void,
    onDelete: (property: AuthGrantType) => void,
): UserGrantsTableType[] =>
    objects.map((entitlement: AuthGrantType) => {
        const { grantor, entitlements } = entitlement;

        return {
            grantor,
            entitlements: {
                tooltip: {
                    title: JSON.stringify(entitlements),
                    content: JSON.stringify(entitlements, undefined, 4),
                },
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => onDelete(entitlement)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };
    });

export { headingUserGrant, accumulateUserGrants };
