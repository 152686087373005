import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import cloneDeep from "lodash/cloneDeep";
import { observer } from "mobx-react-lite";
import { Editor } from "@monaco-editor/react";

import { StatusPoolContext } from "@screens/Pools/Pool/Pool";
import { GroupStatusType, GroupType } from "@screens/Pools/DiscoveryPool.types";
import { TextAreaField, TextField } from "@components/FormikFields";
import * as localStyle from "@screens/Pools/Pool/Parameters/ConfigParameters.style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { usePool } from "@screens/Pools/Pool/usePool";
import objectStore from "@state/object";
import { NEW_PAGE } from "@common/constant/urls";
import { NameContainer } from "@components/FormikFields/components";
import { monacoEditorOptions } from "@common/constant/monacoOption";

const initGroupParameters: GroupStatusType = {
    name: "",
    priority: 0,
    avg_load: 0,
    available_servers: 0,
    description: "",
    disabled_servers: 0,
    request_selector: {},
    total_servers: 0,
    unavailable_servers: 0,
};

const GroupParameters = observer(() => {
    const { group = "", pool = "" } = useParams();
    const statusData = useContext(StatusPoolContext);
    const navigate = useNavigate();
    const { poolAction, isRequesting, poolData } = usePool(objectStore.objectData);
    const [groupConfig, setGroupConfig] = useState<GroupStatusType>(initGroupParameters);

    useEffect(() => {
        const groupStatus = statusData.groups?.find((item) => item.name === group);
        groupStatus && setGroupConfig(groupStatus);
    }, [group, statusData]);

    return (
        <div className={localStyle.configParametersPage}>
            <Formik
                initialValues={{
                    name: groupConfig.name,
                    request_selector: JSON.stringify(groupConfig.request_selector, undefined, 4),
                    server_selector: JSON.stringify(groupConfig.server_selector, undefined, 4),
                    description: groupConfig.description,
                    priority: groupConfig.priority,
                }}
                enableReinitialize
                validate={({ name, request_selector, server_selector, priority }) => {
                    const errors: any = {};
                    const { emptyName, invalidConfig, incorrectValue } = window.locales;
                    if (!name) errors.name = emptyName;
                    try {
                        typeof server_selector === "string" && JSON.parse(server_selector);
                    } catch (e) {
                        errors.server_selector = invalidConfig;
                    }

                    try {
                        typeof request_selector === "string" && JSON.parse(request_selector);
                    } catch (e) {
                        errors.request_selector = invalidConfig;
                    }

                    if (Number.isNaN(Number(priority))) errors.priority = incorrectValue;

                    return errors;
                }}
                onSubmit={async ({ name, request_selector, server_selector, description = "", priority }) => {
                    const values: GroupType = {
                        name,
                        server_selector:
                            typeof server_selector === "string" ? JSON.parse(server_selector) : server_selector,
                        request_selector:
                            typeof request_selector === "string" ? JSON.parse(request_selector) : request_selector,
                        description,
                        priority: Number(priority),
                    };

                    const clonePoolData = cloneDeep(poolData);
                    if (clonePoolData.groups) {
                        const indexOfGroup = clonePoolData.groups.findIndex((group) => group.name === values.name);

                        if (indexOfGroup >= 0) {
                            clonePoolData.groups[indexOfGroup] = values;
                        } else {
                            clonePoolData.groups.push(values);
                        }
                    } else {
                        clonePoolData.groups = [values];
                    }

                    await poolAction(clonePoolData);
                    if (group === NEW_PAGE) {
                        navigate(`/pools/${pool}/groups`);
                    }
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="tokenForm" onSubmit={handleSubmit} className={localStyle.poolConfigData}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type name"
                                type="text"
                                name="name"
                                disabled={groupConfig.name}
                            />
                        </section>
                        <section>
                            <NameContainer $isError={false}>{window.locales.request_selector}</NameContainer>
                            <Editor
                                height="20vh"
                                options={monacoEditorOptions}
                                value={values.request_selector}
                                onChange={(value) => setFieldValue("request_selector", value)}
                                theme="vs-dark"
                                defaultLanguage="json"
                            />
                        </section>
                        <section>
                            <NameContainer $isError={false}>{window.locales.server_selector}</NameContainer>
                            <Editor
                                height="20vh"
                                options={monacoEditorOptions}
                                value={values.server_selector}
                                onChange={(value) => setFieldValue("server_selector", value)}
                                theme="vs-dark"
                                defaultLanguage="json"
                            />
                        </section>
                        <section>
                            <Field
                                component={TextAreaField}
                                value={values.description}
                                placeholder="Type description"
                                type="text"
                                name="description"
                            />
                        </section>
                        <section>
                            <Field component={TextField} placeholder="Type priority" type="text" name="priority" />
                        </section>
                        <div className={buttonStyle.singleButtonContainer}>
                            <button disabled={isRequesting} className={buttonStyle.buttonSubmit} type="submit">
                                {window.locales.save}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
});

export default GroupParameters;
