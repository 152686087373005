import { AxiosResponse } from "axios";

import { postJSONRequestByFirebaseToken } from "@common/requests/requestByFirebaseToken";
import { IDeleteObjectRequest, IObjectRequest, IObjectsRequest, ObjectType } from "@screens/Objects/Objects.types";

interface IObjectsResponse {
    objects: ObjectType[];
}

interface IObjectResponse {
    result: string;
    object: ObjectType;
    current: ObjectType;
}

const getObjectsRequest = async (data: IObjectsRequest): Promise<AxiosResponse<IObjectsResponse>> =>
    postJSONRequestByFirebaseToken("/objects/v1/list", data);

const getObjectByKindRequest = (data: IObjectsRequest): Promise<AxiosResponse<IObjectResponse>> =>
    postJSONRequestByFirebaseToken("/objects/v1/get", data);

const deleteObjectRequest = (data: IDeleteObjectRequest): Promise<AxiosResponse<IObjectResponse>> =>
    postJSONRequestByFirebaseToken("/objects/v1/delete", data);

const editAndCreateObjectRequest = (data: IObjectRequest): Promise<AxiosResponse<IObjectResponse>> =>
    postJSONRequestByFirebaseToken("/objects/v1/put", data);

export { getObjectsRequest, getObjectByKindRequest, deleteObjectRequest, editAndCreateObjectRequest };

export type { IObjectResponse, IObjectsResponse };
