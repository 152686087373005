import React from "react";

import { TData } from "./types";
import * as style from "./style.scss";

const Tooltip = (data: TData) => {
    const { direction, text } = data;

    return (
        <div className={`${style.tooltip} ${style[direction]}`}>
            <div className={style.arrow} />
            <div className={style.content}>{text}</div>
        </div>
    );
};

export { Tooltip };
