import React, { ReactElement, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "@state/index";
import Table from "@components/Table";
import { Search } from "@components/Search/Search";
import {
    accumulateObjects,
    accumulateNamespaces,
    namespaceHeader,
    initObject,
    objectHeader,
    kindHeader,
    accumulateKinds,
    generalNamespace,
} from "@screens/Objects/Objects.utils";
import { HeaderType, IDeleteObjectRequest, ObjectType } from "@screens/Objects/Objects.types";
import { deleteObjectRequest, getObjectByKindRequest, getObjectsRequest } from "@services/Objects";
import { Modal } from "@components/Modal";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import * as buttonStyle from "@components/Button/style.scss";
import { GLOBAL_PAGE, KIND_PAGE, NEW_PAGE } from "@common/constant/urls";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { successInfo } from "@state/success";
import { ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import base64ToJson from "@common/methods/base64toJSON";
import { IconPlus } from "@common/icons";
import { useFilteredData } from "@common/hooks/useFilteredData";
import * as commonStyle from "@common/style/style.scss";

import * as style from "./Objects.style.scss";

const filterFunction =
    (searchValue: string) =>
    ({ metadata: { name, hash } }: ObjectType): boolean =>
        name.toLowerCase().includes(searchValue) || hash.includes(searchValue);

const initialState = initialStateModal(initObject);
const reducerObjects = reducerModal(initialState);
const Objects = (): ReactElement => {
    const navigate = useNavigate();
    const { kind = "", namespace = "" } = useParams();
    const account = useSelector((state: RootState) => state.account);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [modals, dispatchModal] = useReducer(reducerObjects, initialState);
    const [headings, setHeadings] = useState<HeaderType[]>([]);
    const canCreate = !permissions.includes(PermissionEnum.PUT);
    const [placeholder, setPlaceholder] = useState(window.locales.noObjects);
    const { setData, setSearch, filteredList } = useFilteredData<ObjectType>(filterFunction);

    const showDeleteModal = (object: ObjectType) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: object,
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initObject,
        });
    };

    const deleteObject = async () => {
        try {
            closeModal();
            const { kind, name, revision: requireRevision } = modals.item.metadata;
            const object: IDeleteObjectRequest = {
                kind,
                name,
                requireRevision,
                returnPrevious: false,
            };
            await deleteObjectRequest(object);
            successInfo.setSuccessInfo();
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        } finally {
            await getObjects();
        }
    };

    const getPermissions = useCallback(async (): Promise<string[]> => {
        const { data } = await getObjectByKindRequest({
            kind: ObjectEnum.PERMISSION,
            name: kind,
            namespace: namespace !== GLOBAL_PAGE ? namespace : "",
        });

        if (!data || !data.object) {
            return [];
        }
        const { ops } = base64ToJson(data.object.data);
        setPermissions(ops);

        return ops as string[];
    }, [kind, namespace]);

    const getObjects = useCallback(async () => {
        if (kind) {
            const permissions = await getPermissions();
            const userCanRead = permissions.includes(PermissionEnum.GET);
            if (!userCanRead) {
                setPlaceholder(window.locales.noPermissions);
                return;
            }

            const { data } = await getObjectsRequest({
                kind,
                metadata_only: false,
                namespace: namespace !== GLOBAL_PAGE ? namespace : "",
            });

            data && setData(data.objects);
        } else if (namespace) {
            const { data } = await getObjectsRequest({
                kind: KIND_PAGE,
                metadata_only: false,
            });

            data && setData(data.objects);
        } else {
            const { data = { objects: [] } } = await getObjectsRequest({
                kind: ObjectEnum.NAMESPACE,
            });

            setPlaceholder(window.locales.noObjects);
            setData([generalNamespace, ...data.objects]);
        }
    }, [getPermissions, kind, namespace, setData]);

    useEffect(() => {
        if (account.authorized) {
            getObjects().then();
        }
    }, [account.authorized, getObjects]);

    const tableData = useMemo(() => {
        if (kind) {
            setHeadings(objectHeader);
            return accumulateObjects(filteredList, navigate, showDeleteModal, permissions);
        }
        if (namespace) {
            setHeadings(kindHeader);
            return accumulateKinds(filteredList, navigate);
        }
        setHeadings(namespaceHeader);
        return accumulateNamespaces(filteredList, navigate);
    }, [filteredList, kind, namespace, navigate, permissions]);

    return (
        <div className={commonStyle.mainPageContainer}>
            <Table tableData={tableData} headings={headings} title="projects" emptyMessage={placeholder}>
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={setSearch} />
                    </div>
                    {kind && (
                        <button
                            disabled={canCreate}
                            className={buttonStyle.buttonAdd}
                            onClick={() => navigate(NEW_PAGE)}
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    )}
                </div>
            </Table>
            {/* Modal delete location */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.deleteObject}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteObject,
                }}
            >
                Do you want to delete the object {modals.item.metadata.name}?
            </Modal>
        </div>
    );
};

export default Objects;
