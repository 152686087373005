import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";

const ColorLinearProgress = styled(LinearProgress)`
    background-color: ${({ theme }) => theme.colors.darkBg}!important;
    & .MuiLinearProgress-bar {
        background-color: ${({ theme }) => theme.colors.backlight};
    }
`;

export { ColorLinearProgress };
