import React, { Component } from "react";

class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id ? props.id : "",
            value: props.value ? props.value : "",
            icon: props.icon ? props.icon : undefined,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
            options: props.options ? props.options : [],
            notValid: props.notValid ? props.notValid : false,
            disabled: props.disabled ? props.disabled : false,
            className: props.className ? props.className : false,
            iconPosition: props.iconPosition ? props.iconPosition : "left",
        };

        this.callbacs = {};
        this.callbacs.onBlur = props.onBlur ? props.onBlur : () => {};
        this.callbacs.onFocus = props.onFocus ? props.onFocus : () => {};
        this.callbacs.onEnter = props.onEnter ? props.onEnter : () => {};
        this.callbacs.onChange = props.onChange ? props.onChange : () => {};
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            id: props.id ? props.id : "",
            value: props.value ? props.value : "",
            icon: props.icon ? props.icon : undefined,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
            options: props.options ? props.options : [],
            notValid: props.notValid ? props.notValid : false,
            disabled: props.disabled ? props.disabled : false,
            className: props.className ? props.className : false,
            iconPosition: props.iconPosition ? props.iconPosition : "left",
        });
    }

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    onBlur() {
        const state = this.state;
        state.focus = false;

        this.callbacs.onBlur();
        this.setState(state);
    }

    onFocus() {
        const state = this.state;
        state.focus = true;

        this.callbacs.onFocus();
        this.setState(state);
    }

    onChange(event) {
        const state = this.state;
        state.value = event.target.value;

        this.callbacs.onChange(state.value);
        this.setState(state);
    }

    onEnter(event) {
        if (event.key !== "Enter" || this.state.disabled) {
            return;
        }

        this.callbacs.onEnter();
    }

    setFocus() {
        this.input.focus();
    }

    render() {
        const options = [];

        if (this.state.label) {
            options.push(
                <option key="label" value="default">
                    {this.state.label}
                </option>,
            );
        }

        for (const k in this.state.options) {
            options.push(
                <option key={k} disabled={this.state.disabled ? "disabled" : ""} value={this.state.options[k][0]}>
                    {this.state.options[k][1]}
                </option>,
            );
        }

        return (
            <div
                className={`form select ${this.state.theme}${
                    this.state.icon ? ` withIcon ${this.state.iconPosition}IconPosition` : ""
                }${this.state.notValid ? " notValid" : ""}${this.state.disabled ? " disabled" : ""}`}
                style={this.state.style}
            >
                {this.state.icon && this.state.iconPosition === "left" ? this.state.icon : ""}
                <select value={this.state.value} onChange={this.onChange.bind(this)} id={this.state.id}>
                    {options}
                </select>
                {this.state.icon && this.state.iconPosition === "right" ? this.state.icon : ""}
            </div>
        );
    }
}

export default Select;
