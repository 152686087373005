const headings = [
    {
        isSort: false,
        heading: "Service",
    },
    {
        isSort: false,
        heading: "Proxy",
    },
    {
        isSort: false,
        heading: "User country",
    },
    {
        isSort: false,
        heading: "Protocol",
    },
    {
        isSort: false,
        heading: "Device type",
    },
    {
        isSort: false,
        heading: "App version",
    },
];

export { headings };
