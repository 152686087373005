import axios from "axios";

import Network from "@common/Network";
import { getFirebaseTokenFromState } from "@common/getFirebaseTokenFromState";
import { deleteRequestByFirebaseToken, getRequestByFirebaseToken } from "@common/requests/requestsAPI";
import { AccessType } from "@services/Access/Access.types";

axios.defaults.baseURL = `/api/${process.env.CURRENT_API}/`;
const loadUsers = (publickey: string) =>
    getRequestByFirebaseToken<AccessType>("portal/project/access", {
        publickey,
    });

const deleteUser = (email: string, publickey: string) =>
    deleteRequestByFirebaseToken("portal/project/access", {
        publickey,
        email,
    });

const getRoles = (): Promise<{ data: { roles: [] } }> => axios.get("portal/roles");

const setRole = async (email: string, role: string, publickey: string) => {
    const access_token = await getFirebaseTokenFromState();
    const request = "portal/project/access";
    const params = {
        publickey,
        email,
        role,
    };

    return Network.put(request, params, null, access_token);
};

export { loadUsers, deleteUser, getRoles, setRole };
