import React, { useEffect } from "react";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "@components/Header";
import * as style from "@screens/Profile/style.scss";
import * as commonStyle from "@common/style/style.scss";
import { resetActiveProject } from "@state/activeProject";
import { RootState } from "@state/index";

const Profile = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);
    useEffect(() => {
        dispatch(resetActiveProject());
    }, [dispatch]);

    if (account.isOkta) {
        return <Navigate replace to="/projects" />;
    }

    return (
        <>
            <Header />
            <div className={style.contentContainer}>
                <div className={style.titleContainer}>
                    <div className={style.title}>{window.locales.profile}</div>
                </div>
                <div className={commonStyle.tabsContainer}>
                    <NavLink
                        to="main"
                        className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                    >
                        {window.locales.main}
                    </NavLink>
                    <NavLink
                        to="security"
                        className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                    >
                        {window.locales.accountSecurity}
                    </NavLink>
                </div>
                <div className={style.formContainer}>
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default Profile;
