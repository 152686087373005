import React from "react";
import { Outlet } from "react-router-dom";

import * as style from "@components/PageWithMenu/style.scss";
import SideBar from "@components/SideBar/SideBar";
import Header from "@components/Header";

const PageWithMenu = () => (
    <>
        <Header />
        <SideBar />
        <div className={style.contentContainer}>
            <Outlet />
        </div>
    </>
);

export default PageWithMenu;
