import React from "react";
import "@common/storage";
import "./locales";
import "@common/config";
import "./change-favicon";
import "./loader";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import { store } from "@state/index";
import InitApp from "@services/firebase/InitApp";

import { App } from "./app";

InitApp();

const rootElement = document.getElementById("page");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);
