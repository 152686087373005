import en from "./en";
import jp from "./jp";
import { CURRENT_LOCAL } from "@constants/variables";

window.setLocales = (locale = "en") => {
    window.storage.set(CURRENT_LOCAL, locale);
    if (locale === "jp") {
        window.locales = {
            ...en,
            ...jp,
        };
        return;
    }

    window.locales = en;
};

window.setLocales(window.storage.get(CURRENT_LOCAL));
