const deviceOptions = [
    {
        value: "android",
        label: "android",
    },
    {
        value: "apple-ios",
        label: "apple-ios",
    },
    {
        value: "chrome",
        label: "chrome",
    },
    {
        value: "desktop",
        label: "desktop",
    },
    {
        value: "iOS",
        label: "iOS",
    },
    {
        value: "macos",
        label: "macos",
    },
    {
        value: "mobile",
        label: "mobile",
    },
    {
        value: "router",
        label: "router",
    },
    {
        value: "windows",
        label: "windows",
    },
    {
        value: "windows-exe",
        label: "windows-exe",
    },
];

const protocolOptions = [
    {
        value: "ipsec",
        label: "ipsec",
    },
    {
        value: "ipsec-eap-mschapv2",
        label: "ipsec-eap-mschapv2",
    },
    {
        value: "ipsec-ikev2-ios",
        label: "ipsec-ikev2-ios",
    },
    {
        value: "openvpn",
        label: "openvpn",
    },
    {
        value: "openvpn-udp",
        label: "openvpn-udp",
    },
    {
        value: "openvpn-tcp",
        label: "openvpn-tcp",
    },
    {
        value: "hydra-tcp",
        label: "hydra-tcp",
    },
];

export { deviceOptions, protocolOptions };
