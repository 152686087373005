import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
    margin-right: 5px;
    &:hover {
        path {
            fill: #ffffff;
        }
    }
`;

export default (props) => (
    <Icon width={props.width} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.4375 0H0.5625C0.413316 0 0.270242 0.0592632 0.164752 0.164752C0.0592632 0.270242 0 0.413316 0 0.5625L0 17.4375C0 17.5867 0.0592632 17.7298 0.164752 17.8352C0.270242 17.9407 0.413316 18 0.5625 18H17.4375C17.5867 18 17.7298 17.9407 17.8352 17.8352C17.9407 17.7298 18 17.5867 18 17.4375V0.5625C18 0.413316 17.9407 0.270242 17.8352 0.164752C17.7298 0.0592632 17.5867 0 17.4375 0ZM16.875 16.875H1.125V1.125H16.875V16.875Z"
            fill="#B2B9C4"
        />
        <path
            d="M14.3944 2.81254L11.4188 5.79379V3.21191H10.2938V7.14941C10.2938 7.2986 10.353 7.44167 10.4585 7.54716C10.564 7.65265 10.7071 7.71191 10.8563 7.71191L14.7937 7.71191V6.58691H12.2119L15.1875 3.61129L14.3944 2.81254Z"
            fill="#B2B9C4"
        />
        <path
            d="M7.1438 10.2937H3.2063V11.4187H5.78817L2.81255 14.3944L3.60567 15.1875L6.5813 12.2119V14.7937H7.7063L7.7063 10.8562C7.7063 10.7071 7.64704 10.564 7.54155 10.4585C7.43606 10.353 7.29298 10.2937 7.1438 10.2937Z"
            fill="#B2B9C4"
        />
    </Icon>
);
