import React, { useState, memo, useEffect, useCallback } from "react";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";

import { Modal } from "@components/Modal";
import { IServerPoolModal, TServerPool } from "@screens/Settings/VPN/PoolSelectionRules/types";
import { TextField, CustomSelect } from "@components/FormikFields";
import { deviceOptions, protocolOptions } from "@screens/Settings/VPN/utils";
import { TLocation, TPool, TPools } from "@screens/Network/types";
import { RootState } from "@state/index";

import * as style from "./style.scss";

const BridgePoolModal = memo(({ title, action, close, pool, isOpen, pools = [], buttonName }: IServerPoolModal) => {
    const [countries, setCountries] = useState<{ value: unknown; label: unknown }[]>([]);
    const dataPools: TPools = useSelector((state: RootState) => state.pools);

    const accumulateLocations = useCallback(
        (server_pool: string) => {
            const [paidPool] = dataPools.privatePools.filter((item: TPool) => item.name === server_pool);
            const [freePool] = dataPools.publicPools?.filter((item: TPool) => item.name === server_pool);

            const locationList = new Set();
            const extendLocations = (location: TLocation) => {
                const { country } = location.labels;
                locationList.add(country);
            };

            if (paidPool) {
                paidPool.locations?.forEach((location: TLocation) => extendLocations(location));
            }

            if (freePool) {
                freePool.locations?.forEach((location: TLocation) => extendLocations(location));
            }

            const listCountries = [...locationList].map((country) => ({
                value: country,
                label: country,
            }));

            setCountries(listCountries);
        },
        [dataPools.privatePools, dataPools.publicPools],
    );

    useEffect(() => {
        pool?.server_pool && accumulateLocations(pool.server_pool);
    }, [accumulateLocations, pool]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={close}
            title={title}
            confirm={{
                label: buttonName,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="editForm"
            disabled={false}
        >
            <Formik
                initialValues={{
                    server_pool: pool?.server_pool || "",
                    method: pool?.method || "",
                    user_status: pool?.method || "",
                    protocol: pool?.protocol || "",
                    user_country: pool?.user_country || "",
                    user_state: pool?.user_state || "",
                    user_city: pool?.user_city || "",
                    username: pool?.username || "",
                    device_id: pool?.device_id || "",
                    device_type: pool?.device_type || "",
                    extra_country: pool?.extra_country || "",
                    extra_language: pool?.extra_language || "",
                    matcher: pool?.matcher || "",
                }}
                validate={({ server_pool }) => {
                    const errors: any = {};
                    if (!server_pool) errors.role = window.locales.invalidRole;
                    accumulateLocations(server_pool);

                    return errors;
                }}
                onSubmit={({
                    server_pool,
                    method,
                    user_status,
                    matcher,
                    extra_country,
                    extra_language,
                    user_city,
                    user_state,
                    device_id,
                    username,
                    protocol,
                    user_country,
                    device_type,
                }) => {
                    const serverPool: TServerPool = {};
                    server_pool && (serverPool.server_pool = server_pool);
                    method && (serverPool.method = method);
                    user_status && (serverPool.user_status = user_status);
                    protocol && (serverPool.protocol = protocol);
                    user_country && (serverPool.user_country = user_country);
                    user_state && (serverPool.user_state = user_state);
                    user_city && (serverPool.user_city = user_city);
                    username && (serverPool.username = username);
                    device_id && (serverPool.device_id = device_id);
                    device_type && (serverPool.device_type = device_type);
                    extra_country && (serverPool.extra_country = extra_country);
                    extra_language && (serverPool.extra_language = extra_language);
                    matcher && (serverPool.matcher = matcher);

                    action(serverPool);
                    close();
                }}
            >
                {({ values, handleSubmit }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="editForm" onSubmit={handleSubmit}>
                        <div className={style.poolField}>
                            <section>
                                <Field
                                    name="server_pool"
                                    component={CustomSelect}
                                    placeholder="Select server pool"
                                    selected={values.server_pool}
                                    options={
                                        pool
                                            ? [
                                                  ...pools,
                                                  {
                                                      value: pool.server_pool,
                                                      label: pool.server_pool,
                                                  },
                                              ]
                                            : pools
                                    }
                                />
                            </section>
                            <section>
                                <Field component={TextField} placeholder="Type method" type="text" name="method" />
                            </section>
                        </div>
                        <div className={style.poolField}>
                            <section>
                                <Field
                                    component={TextField}
                                    placeholder="Type user status"
                                    type="text"
                                    name="user_status"
                                />
                            </section>
                            <section>
                                <Field
                                    name="protocol"
                                    options={protocolOptions}
                                    component={CustomSelect}
                                    placeholder="Select protocol"
                                />
                            </section>
                        </div>
                        <div className={style.poolField}>
                            <section>
                                <Field
                                    name="user_country"
                                    options={countries}
                                    component={CustomSelect}
                                    placeholder="Select country"
                                />
                            </section>
                            <section>
                                <Field
                                    component={TextField}
                                    placeholder="Type user state"
                                    type="text"
                                    name="user_state"
                                />
                            </section>
                        </div>
                        <div className={style.poolField}>
                            <section>
                                <Field
                                    component={TextField}
                                    placeholder="Type user city"
                                    type="text"
                                    name="user_city"
                                />
                            </section>
                            <section>
                                <Field component={TextField} type="text" name="username" placeholder="Type username" />
                            </section>
                        </div>
                        <div className={style.poolField}>
                            <section>
                                <Field
                                    component={TextField}
                                    placeholder="Type device id"
                                    type="text"
                                    name="device_id"
                                />
                            </section>
                            <section>
                                <Field
                                    name="device_type"
                                    options={deviceOptions}
                                    component={CustomSelect}
                                    placeholder="Select device type"
                                />
                            </section>
                        </div>
                        <div className={style.poolField}>
                            <section>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name="extra_country"
                                    placeholder="Type extra country"
                                />
                            </section>
                            <section>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name="extra_language"
                                    placeholder="Type extra language"
                                />
                            </section>
                            <section>
                                <Field component={TextField} type="text" name="matcher" placeholder="Type matcher" />
                            </section>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
});

export { BridgePoolModal };
