import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SignUpForm from "@screens/Landing/Authorization/SignUp/Form";
import { SignUpInvitation } from "@screens/Landing/Authorization/SignUp/Invitation";
import { ForgotCredentials } from "@screens/Landing/Authorization/ForgotPassword";
import { ResetCredentials } from "@screens/Landing/Authorization/ResetPassword";
import { SuccessSentReset } from "@screens/Landing/Authorization/SuccessSentReset";
import { CodeForm } from "@screens/Landing/Authorization/SignIn/MFA/Code";
import PhoneForm from "@screens/Landing/Authorization/SignIn/MFA/Phone";
import { SuccessReset } from "@screens/Landing/Authorization/SuccessReset";
import { ResetError } from "@screens/Landing/Authorization/ResetError";
import { deleteAccount } from "@state/account";
import { getParameterByName } from "@common/getParameterByName";
import { RootState } from "@state/index";
import { acceptUserInvitationRequest, getUserInvitationRequest } from "@services/Invite";
import { EAuthorizationStatus, ECode } from "@interfaces";
import { getHashParameterByName } from "@common/getHashParameterByName";
import { Form, TInvite } from "@screens/Landing/Authorization/types";
import { resetActiveProject } from "@state/activeProject";
import { If } from "@components/If";
import { Pending } from "@screens/Panding";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import { ExpiredPassword } from "@screens/Landing/Authorization/ExpiredPassword";
import { AuthContext } from "@common/auth-context";
import { VerifyEmail } from "@screens/Landing/Authorization/VerifyEmail";
import { requestCheckProvider } from "@services/Authorization";

import SignInForm from "./SignIn/Form";

const Authorization = ({ initPage }: { initPage: Form }) => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);
    const [typeOfForm, setTypeOfForm] = useState(initPage);
    const [resolver, setResolver] = useState(null);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [actionCode, setActionCode] = useState(() => getParameterByName(ECode.Reset));
    const [isPending, setIsPending] = useState(!!actionCode);
    const refAccept = useRef(false);
    const [invitation, setInvitation] = useState<TInvite | null>(null);
    const [, setAuthorizationStatus] = useContext(AuthContext);

    useLayoutEffect(() => {
        setTypeOfForm(initPage);
    }, [initPage]);

    const resetPassword = useCallback(async () => {
        const droppedEmail = await window.fb.default.auth().verifyPasswordResetCode(actionCode);
        setEmail(droppedEmail);
        dispatch(deleteAccount());
        setTypeOfForm(Form.ResetPwd);
        setIsPending(false);
    }, [actionCode, dispatch]);

    const revertSecondFactory = useCallback(() => {
        window.fb.default.auth().applyActionCode(actionCode);
        navigate("/");
        setActionCode("");
    }, [actionCode, navigate]);

    const verifyEmail = useCallback(async () => {
        const { currentUser } = window.fb.default.auth();
        await window.fb.default.auth().applyActionCode(actionCode);
        currentUser?.reload();
        navigate("/");
        setActionCode("");
        setTypeOfForm(Form.SignIn);
    }, [actionCode, navigate]);

    const backToSignInButton = () => {
        dispatch(deleteAccount());
        setActionCode("");
        navigate("/");
        setTypeOfForm(Form.SignIn);
        setIsPending(false);
    };

    const acceptInvite = useCallback(
        async (token = "") => {
            const inviteCode = getHashParameterByName(ECode.Invite);
            if (!invitation) return;

            const { project_id } = invitation;

            try {
                setAuthorizationStatus(EAuthorizationStatus.Authorized);
                await acceptUserInvitationRequest(inviteCode, token);
                setActionCode("");
                navigate(`/projects/${project_id}/dashboard`);
                refAccept.current = true;
            } catch (e: unknown) {
                navigate("/");
                setTypeOfForm(Form.ErrorLink);
            }
        },
        [invitation, setAuthorizationStatus, navigate],
    );

    useEffect(() => {
        (async function checkInvite() {
            if (!invitation) return;
            setIsPending(true);
            const { is_new, user_email, is_invited = true } = invitation;
            const { name = "" } = account;
            const lowerCaseUserEmail = user_email.toLowerCase().trim();

            if (refAccept.current) {
                setTypeOfForm(Form.SignIn);
                return;
            }

            if (is_new && !name) {
                const inviteCode = getHashParameterByName(ECode.Invite);
                const {
                    data: { redirect_url },
                } = await requestCheckProvider(user_email, window.location.origin, inviteCode);

                if (redirect_url) {
                    window.location.replace(redirect_url);
                    return;
                }

                setTypeOfForm(Form.UserInvitation);
                setIsPending(false);
                setEmail(user_email);
            } else if (name.toLowerCase() === lowerCaseUserEmail && is_invited) {
                acceptInvite().then();
            } else if (name.toLowerCase() === lowerCaseUserEmail && !is_new && !is_invited) {
                acceptInvite().then();
            } else if (name) {
                dispatch(deleteAccount());
            } else if (!name) {
                setTimeout(() => setIsPending(false), 3000);
            } else if (name.toLowerCase() === lowerCaseUserEmail && !is_invited) {
                setAuthorizationStatus(EAuthorizationStatus.Authorized);
            }
        })();
    }, [acceptInvite, account, dispatch, invitation, setAuthorizationStatus]);

    const acceptSignUp = (token: string) => {
        const inviteCode = getHashParameterByName(ECode.Invite);
        if (inviteCode && !refAccept.current) {
            setIsPending(true);
            acceptInvite(token).then();
        }
    };

    useEffect(() => {
        const inviteCode = getHashParameterByName(ECode.Invite);
        if (inviteCode) {
            setIsPending(true);
            getUserInvitationRequest(inviteCode)
                .then(async ({ data }) => {
                    setInvitation(data.invitation);
                })
                .catch(() => {
                    dispatch(resetActiveProject());
                    setTypeOfForm(Form.ErrorLink);
                    setIsPending(false);
                    navigate("/");
                });
        }
    }, [dispatch, navigate, setAuthorizationStatus]);

    useLayoutEffect(() => {
        actionCode &&
            window.fb.default
                .auth()
                .checkActionCode(actionCode)
                .then(async ({ operation }: { operation: string }) => {
                    if (operation === "PASSWORD_RESET") {
                        await resetPassword();
                    }

                    if (operation === "VERIFY_EMAIL") {
                        await verifyEmail();
                    }

                    if (operation === "REVERT_SECOND_FACTOR_ADDITION") {
                        revertSecondFactory();
                    }
                })
                .catch(() => {
                    setActionCode("");
                    navigate("/");
                    setTypeOfForm(Form.ErrorLink);
                })
                .finally(() => {
                    setIsPending(false);
                });
    }, [actionCode, resetPassword, account.authorized, verifyEmail, revertSecondFactory, navigate]);

    const content = (type: Form) => {
        switch (type) {
            case Form.SignIn:
                return (
                    <SignInForm
                        goToMFA={(value: any) => {
                            setResolver(value);
                            setTypeOfForm(Form.Code);
                        }}
                        goToForgotPwd={() => setTypeOfForm(Form.ForgotPwd)}
                    />
                );
            case Form.ForgotPwd:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ForgotCredentials
                            setSuccess={(value: string) => {
                                setEmail(value);
                                setTypeOfForm(Form.SuccessSentPWD);
                            }}
                        />
                    </>
                );
            case Form.ResetPwd:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ResetCredentials
                            email={email}
                            actionCode={actionCode}
                            setSuccess={(value) => {
                                setActionCode("");
                                navigate("/");
                                setEmail(value);
                                setTypeOfForm(Form.SuccessResetPWD);
                            }}
                            setError={() => setTypeOfForm(Form.ErrorLink)}
                        />
                    </>
                );
            case Form.Code:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <CodeForm resolver={resolver} />
                    </>
                );
            case Form.Phone:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <PhoneForm goToForgotPwd={() => setTypeOfForm(Form.ForgotPwd)} />
                    </>
                );
            case Form.SuccessSentPWD:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SuccessSentReset email={email} />
                    </>
                );
            case Form.SuccessResetPWD:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SuccessReset email={email} />
                    </>
                );
            case Form.ExpiredPwd:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ExpiredPassword />
                    </>
                );
            case Form.UserInvitation:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SignUpInvitation email={email} goToSignup={() => setTypeOfForm(Form.SignUp)} />
                    </>
                );
            case Form.ErrorLink:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <ResetError />
                    </>
                );
            case Form.VerifyEmail:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <VerifyEmail email={account.name} />
                    </>
                );
            case Form.SignUp:
                return (
                    <>
                        <div className={authorizationStyle.backToSignInButton} onClick={backToSignInButton}>
                            ← Back to Sign In
                        </div>
                        <SignUpForm email={email} goToSignIn={acceptSignUp} />
                    </>
                );
            default:
                return <div />;
        }
    };

    return (
        <>
            {content(typeOfForm)}
            <If condition={isPending}>
                <Pending />
            </If>
        </>
    );
};

export default Authorization;
