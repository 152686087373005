const customStyles = {
    option: () => ({
        padding: 5,
    }),
    indicatorSeparator: (styles: any) => ({ display: "none" }),
    container: (provided: any) => ({
        ...provided,
        width: "100%",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: "8px 12px",
        display: "flex",
        flexWrap: "nowrap",
    }),
    placeholder: () => ({
        color: "#FFFFFF32",
    }),
    menu: (provided: any) => ({
        ...provided,
        background: "#252832",
        padding: "8px 16px",
        color: "#FFF",
        zIndex: 10,
        fontSize: "16px",
        maxHeight: "250px",
        overflow: "hidden",
    }),
    multiValue: (provided: any, state: any) => {
        const color = state.isDisabled ? "#FFFFFF33" : "#FFFFFF";
        return {
            ...provided,
            background: "#252832",
            margin: "0 2px",
            color,
        };
    },
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: "#FFF",
        fontSize: "14px",
    }),
    input: (provided: any) => ({
        ...provided,
        color: "#FFF",
    }),
    menuList: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        cursor: "pointer",
    }),
    control: (provided: any, state: any) => {
        const background = state.isDisabled ? "#1A1C23" : "#303340";
        return {
            display: "flex",
            borderRadius: "8px",
            padding: "2px",
            background,
            fontSize: "16px",
            "&:hover": {
                background: "#3D4152",
            },
        };
    },
    singleValue: (provided: any, state: any) => {
        const color = state.isDisabled ? "#FFFFFF33" : "#FFFFFF";
        return {
            ...provided,
            color,
        };
    },
};

export { customStyles };
