import React from "react";
// @ts-ignore
import ReactCountryFlag from "react-country-flag";

import { Data } from "@components/Table/types";
import { getCountryName } from "@common/methods";
import iconCheckMark from "@common/img/svg/check-mark-icon.svg";

const headings = [
    {
        isSort: true,
        heading: window.window.locales.code,
    },
    {
        isSort: true,
        heading: window.locales.location,
    },
    {
        isSort: true,
        heading: window.locales.country,
    },
    {
        isSort: false,
        heading: window.locales.limited,
    },
    {
        isSort: false,
        heading: window.locales.unlimited,
    },
    {
        isSort: false,
        heading: window.locales.private,
    },
];

const pattern = new RegExp("^[A-Za-z]{2}$");

const locationElem = (isValidCode: boolean, country: string) =>
    isValidCode ? (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
            }}
        >
            {" "}
            <ReactCountryFlag svg countryCode={country.toLowerCase()} style={{ fontSize: "1.5em" }} />
            {getCountryName(country)}
        </div>
    ) : (
        <div />
    );

const limitedElement = (types: any[], value: any) =>
    types.includes(value) ? <img src={iconCheckMark} alt="success" /> : <div />;

const accumulateCountries = (
    infoTableCountries: { city: string; types: string[]; code: string }[],
    countryCode: string,
): Data[] =>
    infoTableCountries.map(({ city, types, code }) => {
        const isValidCode = pattern.test(countryCode);
        const countryName = getCountryName(countryCode);
        return {
            code,
            location: city,
            country: {
                sortColumn: countryName,
                component: locationElem(isValidCode, countryCode.toLowerCase()),
            },
            limited: {
                component: limitedElement(types, "free_server_pool"),
            },
            unlimited: {
                component: limitedElement(types, "paid_server_pool"),
            },
            private: "",
        };
    });

export { headings, accumulateCountries };
