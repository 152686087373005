import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Table from "@components/Table";
import { RootState } from "@state/index";
import { Data } from "@components/Table/types";

import { Pool } from "../types";
import { TDescriptionLocation, TLocation, TPool, TPoolLocation, TPools } from "../../types";
import { accumulateCountries, headings } from "./utils";

const TableLocations = (props: any) => {
    const pools: TPools = useSelector((state: RootState) => state.pools);
    const [locations, setLocations] = useState<TPoolLocation>();
    const [body, setBody] = useState<Data[]>([]);

    const bodyTable = useCallback(() => {
        if (locations) {
            const parseCountries = Object.entries(locations);
            return parseCountries.reduce((entries: Data[], values) => {
                const [countryCode, countryInfo] = values;
                return [...entries, ...accumulateCountries(countryInfo, countryCode)];
            }, []);
        }
        return [];
    }, [locations]);

    useEffect(() => {
        const updateBody = bodyTable();
        setBody(updateBody);
    }, [bodyTable]);

    useEffect(() => {
        const { paid_server_pool, free_server_pool, private_pools } = props.pools;
        const [paidPool] = pools.publicPools.filter((pool: TPool) => pool.name === paid_server_pool);
        const [freePool] = pools.publicPools?.filter((pool: TPool) => pool.name === free_server_pool);
        const privatePools = private_pools
            ? pools.privatePools?.filter((pool: TPool) => pool.name, private_pools)
            : null;
        const locationList: TPoolLocation = {};
        const extendLocations = (location: TLocation, type: string) => {
            const code = location.name;
            const { country, city } = location.labels;
            if (locationList[country]) {
                const index = locationList[country].findIndex(
                    (currentCity: TDescriptionLocation) => currentCity.city === city,
                );
                index > -1
                    ? locationList[country][index].types.push(type)
                    : locationList[country].push({
                          code,
                          city,
                          types: [type],
                      });
            } else {
                locationList[country] = [
                    {
                        code,
                        city,
                        types: [type],
                    },
                ];
            }
        };
        paidPool?.locations?.forEach((location: TLocation) => extendLocations(location, Pool.Paid));
        freePool?.locations?.forEach((location: TLocation) => extendLocations(location, Pool.Free));
        privatePools?.map((privatePool: TPool) =>
            privatePool.locations?.forEach((location: TLocation) => extendLocations(location, Pool.Private)),
        );
        setLocations(locationList);
    }, [props.pools, pools.privatePools, pools.publicPools]);

    return (
        <Table
            tableData={body}
            headings={headings}
            title={window.locales.country}
            emptyMessage={window.locales.noLocations}
        >
            {props.children}
        </Table>
    );
};

export default TableLocations;
