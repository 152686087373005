export const firebaseConfig = {
    apiKey: "AIzaSyBjJGXAQW9wuT7O1_bHMoLPaT6YSM1ELGU",
    authDomain: "web-portal-for-partners.firebaseapp.com",
    databaseURL: "https://web-portal-for-partners.firebaseio.com",
    projectId: "web-portal-for-partners",
    storageBucket: "web-portal-for-partners.appspot.com",
    messagingSenderId: "328412734035",
    appId: "1:328412734035:web:d2e637c869d07b17d09ca6",
    measurementId: "G-9F5VS6VSTR",
};
