function filterEmptyStrings(obj: Record<string, any>): Record<string, any> {
    const newObj: { [key: string]: any } = {};

    Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (value !== "" && !(typeof value === "object" && !Array.isArray(value))) {
            newObj[key] = value;
        } else if (typeof value === "object" && !Array.isArray(value)) {
            const nestedObj = filterEmptyStrings(value);
            if (Object.keys(nestedObj).length !== 0) {
                newObj[key] = nestedObj;
            }
        }
    });

    return newObj;
}

export default filterEmptyStrings;
