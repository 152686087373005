import React, { memo } from "react";

import docIcon from "@common/img/svg/doc-icon.svg";
import LeftTopBar from "@components/Header/LeftTopBar/LeftTopBar";
import { LinearDeterminate } from "@components/LinearDeterminate";
import * as style from "@components/Header/style.scss";
import Profile from "@components/Header/Profile/Profile";

enum EnvironmentEnum {
    Prod = "prod",
    Dev = "dev",
    Stage = "stage",
}

const CurrentEnvironment = process.env.CURRENT_API;

const Header = () => (
    <div className={style.headerContainer}>
        <LeftTopBar />
        <div className={style.envsContainer}>
            <span
                className={`${style.envContainer} ${CurrentEnvironment === EnvironmentEnum.Prod && style.selectedEnv}`}
            >
                <a href="https://pango-cloud.com" target="_blank" rel="noopener noreferrer">
                    Production
                </a>
            </span>
            <span
                className={`${style.envContainer} ${CurrentEnvironment === EnvironmentEnum.Stage && style.selectedEnv}`}
            >
                <a href="https://stage.pango-cloud.com" target="_blank" rel="noopener noreferrer">
                    Stage
                </a>
            </span>
            <span
                className={`${style.envContainer} ${CurrentEnvironment === EnvironmentEnum.Dev && style.selectedEnv}`}
            >
                <a href="https://dev.pango-cloud.com" target="_blank" rel="noopener noreferrer">
                    Dev
                </a>
            </span>
        </div>
        <div className={style.infoContainer}>
            <div className={style.documentationContainer}>
                <img src={docIcon} alt="img" />
                <a href="https://auravpn.gitbook.io/" target="_blank" rel="noopener noreferrer">
                    {window.window.locales.docs}
                </a>
            </div>
            <Profile />
        </div>
        <LinearDeterminate />
    </div>
);

export default memo(Header);
