import React, { Component } from "react";

import { DayPicker } from "react-day-picker";

const MS_IN_DAY = 86400000;

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.last = new Date();
        this.last.setDate(1);
        this.last.setMonth(this.last.getMonth() - 1);

        this.state = {
            days: props.days,
            time: props.time,
            currentMonth: this.last,
            devicesDropdown: false,
        };

        this.callbacks = {
            updateSearch: props.updateSearch,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            days: props.days,
            time: props.time,
        });
    }

    handleDayClick(input) {
        const day = new Date(input);
        const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
        const days = this.state.days;

        const dayTs = new Date(date).getTime();
        const fromTs = new Date(days.from).getTime();
        const tillTs = new Date(days.till).getTime();

        if (dayTs > Date.now()) {
            return;
        }
        if (!fromTs && !tillTs) {
            days.from = date;
            days.till = date;
        } else if (fromTs === dayTs) {
            days.till = date;
        } else if (tillTs === dayTs) {
            days.from = date;
        } else if (fromTs > dayTs) {
            if (dayTs + 30 * MS_IN_DAY < tillTs) return;
            days.from = date;
        } else {
            if (dayTs - 30 * MS_IN_DAY > fromTs) return;
            days.till = date;
        }

        this.setState(
            {
                days,
            },
            this.updateSearch.bind(this),
        );
    }

    renderDay(day) {
        day.setHours(0, 0, 0);
        const className = this.checkIfSelected(day);

        return <div className={`custom-day${className}`}>{day.getDate()}</div>;
    }

    updateSearch() {
        this.callbacks.updateSearch({
            days: this.state.days,
            time: {
                from: this.state.time.from.replace(/_/g, "0"),
                till: this.state.time.till.replace(/_/g, "0"),
            },
        });
    }

    checkIfSelected(day) {
        const ts = day.getTime();
        const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
        const from = new Date(this.state.days.from).getTime();
        const till = new Date(this.state.days.till).getTime() + MS_IN_DAY - 1;

        if (ts >= Date.now()) {
            return " disabled";
        }

        if (!from && !till) return "";

        if (ts > from + 31 * MS_IN_DAY || ts < till - 31 * MS_IN_DAY) return " disabled";

        if (from > ts || till < ts) return "";

        let className = " selected";

        if (this.state.days.from === date) {
            className += " outer first";
        } else if (this.state.days.till === date) {
            className += " outer last";
        } else {
            className += " center";

            if (day.getDay() === 0) {
                className += " first";
            } else if (day.getDay() === 6) {
                className += " last";
            }
        }

        if ((till - from) / MS_IN_DAY > 1) className += " multiple";

        return className;
    }

    showToday() {
        const day = new Date();
        const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;

        this.setState(
            {
                days: {
                    from: date,
                    till: date,
                },
                currentMonth: this.last,
            },
            this.updateSearch.bind(this),
        );
    }

    showThisWeek() {
        const till = new Date();
        const tillTs = till.getTime();
        const fromTs = tillTs - till.getDay() * MS_IN_DAY;
        const from = new Date(fromTs);

        this.setState(
            {
                days: {
                    from: `${from.getMonth() + 1}/${from.getDate()}/${from.getFullYear()}`,
                    till: `${till.getMonth() + 1}/${till.getDate()}/${till.getFullYear()}`,
                },
                currentMonth: this.last,
            },
            this.updateSearch.bind(this),
        );
    }

    showThisMonth() {
        const till = new Date();
        const tillDate = `${till.getMonth() + 1}/${till.getDate()}/${till.getFullYear()}`;
        const from = new Date(tillDate);
        const fromDate = `${from.getMonth() + 1}/1/${from.getFullYear()}`;

        this.setState(
            {
                days: {
                    from: fromDate,
                    till: tillDate,
                },
                currentMonth: this.last,
            },
            this.updateSearch.bind(this),
        );
    }

    showYesterday() {
        const day = new Date(Date.now() - MS_IN_DAY);
        const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;

        this.setState(
            {
                days: {
                    from: date,
                    till: date,
                },
                currentMonth: this.last,
            },
            this.updateSearch.bind(this),
        );
    }

    showLastWeek() {
        const today = new Date();
        const till = new Date(today.getTime() - (today.getDay() + 1) * MS_IN_DAY);
        const tillTs = till.getTime();
        const fromTs = tillTs - 6 * MS_IN_DAY;
        const from = new Date(fromTs);

        this.setState(
            {
                days: {
                    from: `${from.getMonth() + 1}/${from.getDate()}/${from.getFullYear()}`,
                    till: `${till.getMonth() + 1}/${till.getDate()}/${till.getFullYear()}`,
                },
                currentMonth: this.last,
            },
            this.updateSearch.bind(this),
        );
    }

    showLastMonth() {
        const today = new Date();
        const till = new Date(today.getTime() - today.getDate() * MS_IN_DAY);

        this.setState(
            {
                days: {
                    from: `${till.getMonth() + 1}/1/${till.getFullYear()}`,
                    till: `${till.getMonth() + 1}/${till.getDate()}/${till.getFullYear()}`,
                },
                currentMonth: new Date(`${till.getMonth()}/1/${till.getFullYear()}`),
            },
            this.updateSearch.bind(this),
        );
    }

    render() {
        return (
            <div className="calendar_dropdown">
                <DayPicker
                    onDayClick={this.handleDayClick.bind(this)}
                    numberOfMonths={2}
                    renderDay={this.renderDay.bind(this)}
                    month={this.state.currentMonth}
                    months={window.locales.fullMonth}
                    weekdaysShort={window.locales.fullWeek}
                    fixedWeeks
                />
                <div className="DayPicker-delimiter" />
                <div className="calendar_dropdown_options" style={{ padding: "20px 0 0 0" }}>
                    <div className="calendar_dropdown_row">
                        <div className="calendar_dropdown_item">
                            <span onClick={() => this.showToday()}>{window.locales.today}</span>
                        </div>
                        <div className="calendar_dropdown_item">
                            <span onClick={() => this.showThisWeek()}>{window.locales.thisWeek}</span>
                        </div>
                        <div className="calendar_dropdown_item">
                            <span onClick={() => this.showThisMonth()}>{window.locales.thisMonth}</span>
                        </div>
                        <div className="calendar_dropdown_item">
                            <span onClick={() => this.showYesterday()}>{window.locales.yesterday}</span>
                        </div>
                        <div className="calendar_dropdown_item">
                            <span onClick={() => this.showLastWeek()}>{window.locales.lastWeek}</span>
                        </div>
                        <div className="calendar_dropdown_item">
                            <span onClick={() => this.showLastMonth()}>{window.locales.lastMonth}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Calendar;
