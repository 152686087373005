import styled from "styled-components";

const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-width: 1px;
    border-style: solid;
    border-color: hsla(0, 0%, 89.8%, 0.3);
    border-radius: 2px;

    &:after {
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        content: "";
        position: absolute;
        display: none;
    }
`;

const FieldCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
        ~ ${Checkmark} {
            background-color: ${({ theme }) => theme.colors.backlight};
            opacity: 1;

            &:after {
                display: block;
            }
        }
    }
`;

export { Checkmark, FieldCheckbox };
