import React, { useEffect, useState, useRef, FC, useCallback, useMemo } from "react";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    TooltipProps,
    LegendProps,
} from "recharts";

import { kFormatterNumber, kFormatterTraffic } from "@common/kFormatter";
import { IconIncrease, IconQuestionGraph, IconSqueeze } from "@common/icons";
import IconNoFound from "@common/icons/components/NoFound";
import * as style from "@screens/Dashboard/Graphs/style.scss";

import { Colors } from "../Colors";
import { Circle, GraphContainer, ItemMetric, Titles } from "./components";

interface GraphData {
    data: any[];
    id: string;
    info: string;
    stacked: boolean;
}

interface Metric {
    key: string;
    index: number;
}

type MetricItem = any & {
    color: string;
    dataKey: string;
    value: string;
};

interface ILegend {
    payload: any;
    currentData: any;
    currentMetric: any;
    toggleMetric: any;
    kFormatter: any;
}

const CustomTooltip: FC<TooltipProps<any, any> | any> = ({ payload, label, kFormatter }) => (
    <div className={style.tooltipContainer}>
        <p>{label}</p>
        {payload &&
            payload.map(
                (line: { value: number; color: string; dataKey: string }, index: number) =>
                    line.value &&
                    line.value > 0 && (
                        <div className={style.itemTooltip} key={index}>
                            <div className={style.name}>
                                <Circle $color={line.color} />
                                {line.dataKey} :
                            </div>
                            <label className={style.value}>{kFormatter(line.value.toString())}</label>
                        </div>
                    ),
            )}
    </div>
);

const CustomLegend: FC<LegendProps | any> = React.memo(
    ({ payload, currentData, currentMetric, toggleMetric, kFormatter }: ILegend) => (
        <div className={style.legendContainer}>
            {payload &&
                payload
                    .filter((property: MetricItem) => currentData[property.dataKey])
                    .map((property: MetricItem, index: number) => (
                        <ItemMetric
                            key={index}
                            selected={currentMetric ? currentMetric.key === property.dataKey : true}
                            onClick={() => toggleMetric(property.dataKey)}
                        >
                            <div className={style.nameItemMetric}>
                                <Circle $color={property.color} />
                                {property.dataKey}
                            </div>
                            {kFormatter(currentData[property.dataKey])}
                        </ItemMetric>
                    ))}
        </div>
    ),
    (prevProps, nextProps) => prevProps.payload.length === nextProps.payload.length,
);

const StackedAreaChart = (props: GraphData) => {
    const [data, setData] = useState(props.data);
    const [currentMetric, setCurrentMetric] = useState<Metric | null>(null);
    const [currentData, setCurrentData] = useState<any>({});
    const [opened, setOpened] = useState(false);
    const [isInView, setIsInView] = useState(false);
    const root = useRef<HTMLObjectElement>(null);
    const kFormatter = useMemo(
        () => (props.id === "network_traffic" ? kFormatterTraffic : kFormatterNumber),
        [props.id],
    );

    useEffect(() => {
        if (!props.data) return;
        setData(props.data);
        setCurrentData(props.data[props.data.length - 1]);
    }, [props.data]);

    const onIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting) {
            observer.disconnect();
        }

        setIsInView(isIntersecting);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection, { threshold: 0.5 });
        if (root.current) {
            observer.observe(root.current);
        }
    }, []);

    const toggleMetric = useCallback(
        (key: string) => {
            if (currentMetric && currentMetric.key === key) {
                setData(props.data);
                setCurrentMetric(null);
            } else {
                const item = props.data;
                if (!item) return;
                const index = Object.keys(item).indexOf(key);
                const newData = props.data.map((graphData) => ({
                    name: graphData.name,
                    [key]: graphData[key],
                }));
                setData(newData);
                setCurrentMetric({
                    key,
                    index,
                });
            }
        },
        [currentMetric, props.data],
    );

    return (
        <GraphContainer $opened={opened}>
            <Titles $width="92%">
                <div className={style.titleContainer}>
                    {window.locales[props.id]}
                    <div className={style.iconContainer}>
                        <a href={props.info}>
                            <IconQuestionGraph width={20} />
                        </a>
                        <div className={style.zoomContainer} onClick={() => setOpened(!opened)}>
                            {opened ? <IconSqueeze width={20} /> : <IconIncrease width={20} />}
                        </div>
                    </div>
                </div>
                <div className={style.titleLegend}>
                    {data && data.length !== 0 ? window.locales.currentData : window.locales.noData}
                </div>
            </Titles>
            <div className={style.viewPort} ref={root}>
                <ResponsiveContainer height={300} width="92%">
                    {isInView && data && data.length !== 0 ? (
                        <AreaChart data={data}>
                            <CartesianGrid vertical={false} stroke="#636C7A" />
                            <XAxis tickLine={false} dataKey="name" stroke="#B2B9C4" axisLine={{ stroke: "#636C7A" }} />
                            <YAxis
                                tickLine={false}
                                tickFormatter={kFormatter}
                                tickCount={7}
                                stroke="#B2B9C4"
                                axisLine={false}
                                height={10}
                            />
                            <Tooltip content={<CustomTooltip kFormatter={kFormatter} />} />
                            <Legend
                                layout="vertical"
                                verticalAlign="top"
                                align="right"
                                content={
                                    <CustomLegend
                                        currentMetric={currentMetric}
                                        currentData={currentData}
                                        kFormatter={kFormatter}
                                        toggleMetric={toggleMetric}
                                    />
                                }
                            />
                            {Object.keys(data[0]).map(
                                (key, index) =>
                                    key !== "name" && (
                                        <Area
                                            key={index}
                                            type="monotone"
                                            stackId={props.stacked ? 1 : index}
                                            dataKey={key}
                                            stroke={
                                                currentMetric
                                                    ? Colors[currentMetric.index]
                                                    : Colors[index % Colors.length]
                                            }
                                            fillOpacity={0.3}
                                            fill={
                                                currentMetric
                                                    ? Colors[currentMetric.index]
                                                    : Colors[index % Colors.length]
                                            }
                                        />
                                    ),
                            )}
                        </AreaChart>
                    ) : (
                        <div className={style.containerNoFound}>
                            <IconNoFound />
                            <h3>{window.locales.sorryNoData}</h3>
                        </div>
                    )}
                </ResponsiveContainer>
            </div>
        </GraphContainer>
    );
};

export { StackedAreaChart };
