import React from "react";

import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";

const ResetError = () => (
    <div className={authorizationStyle.formCenter}>
        <div className={authorizationStyle.signFormContainer}>
            <div className={authorizationStyle.titleContainer}>
                <div className={authorizationStyle.title}>Sorry!</div>
            </div>
            <div className={authorizationStyle.infoContainer}>
                <span>The link is expired or invalid</span>
                Try again
            </div>
        </div>
    </div>
);

export { ResetError };
