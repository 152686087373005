import { EModals, TAction, TModal } from "@components/Modal/types";

const initialStateModal = <T>(item: T) => ({
    editModal: false,
    deleteModal: false,
    createModal: false,
    item,
});

const reducerModal =
    <T>(initItem: TModal<T>) =>
    (state: TModal<T>, action: TAction<T>): TModal<T> => {
        switch (action.type) {
            case EModals.CreateModal:
                return {
                    ...state,
                    createModal: true,
                    item: action.payload,
                };
            case EModals.DeleteModal:
                return {
                    ...state,
                    deleteModal: true,
                    item: action.payload,
                };
            case EModals.EditModal:
                return {
                    ...state,
                    editModal: true,
                    item: action.payload,
                };
            case EModals.CloseModals:
                return initItem;
            default:
                return initItem;
        }
    };

export { reducerModal, initialStateModal };
