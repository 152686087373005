import { call, put, select, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";

import { clonePool, deletePool, loadPoolDescription, loadPools, updatePool } from "@services/Network";
import { duplicatePool, getPools, removePool, setPools, changePool } from "@state/pools/index";
import RootState from "@state/interfaces";
import { editProject } from "@services/Project";
import { TPermission, TPool } from "@screens/Network/types";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { successInfo } from "@state/success";

const selectActiveProject = (state: RootState) => state.activeProject;

const getPoolProject = (pool: TPool, publickey: string) => {
    const [exist] = pool.permissions.filter(
        (permission: TPermission) => permission.subject === publickey || permission.subject === "*",
    );
    return exist.can_list;
};

export function* getPoolsSaga(): SagaIterator {
    try {
        const { publickey } = yield select(selectActiveProject);
        if (!publickey) return;

        const tempPools = yield call(loadPools);
        const poolsResponse = yield call(() =>
            Promise.allSettled(tempPools.map((name: string) => loadPoolDescription(name))),
        );
        const pools = poolsResponse.filter((poolResponse: any) => poolResponse.value).map((pool: any) => pool.value);
        const privatePools = pools.filter((pool: TPool) => getPoolProject(pool, publickey));
        const publicPools = pools.filter((pool: TPool) => !getPoolProject(pool, publickey));
        yield put(
            setPools({
                privatePools,
                publicPools,
            }),
        );
    } catch (e: unknown) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* duplicatePoolSaga(action: PayloadAction<string>) {
    try {
        yield call(() => clonePool(action.payload));
        yield put(getPools());
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* removePoolSaga(action: PayloadAction<string>): SagaIterator {
    try {
        if (!action.payload) return;
        const activeProject = yield select(selectActiveProject);
        yield call(() => deletePool(action.payload));
        const { config } = activeProject;
        if (config.server_pool && config.server_pool === action.payload) {
            delete config.server_pool;
        }

        if (config.private_pools && config.private_pools.includes(action.payload)) {
            config.private_pools.splice(config.private_pools.indexOf(action.payload), 1);
        }

        const stringifyConfig = JSON.stringify(config);
        yield call(editProject, {
            ...activeProject,
            config: stringifyConfig,
        });
        yield put(getPools());
        successInfo.setSuccessInfo();
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* updatePoolSaga(action: any) {
    try {
        const { pool, body } = action.payload;
        yield call(() => updatePool(pool, { ...body }));
        yield put(getPools());
        successInfo.setSuccessInfo();
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export const PoolsSagas = [
    takeEvery(getPools.type, getPoolsSaga),
    takeEvery(changePool.type, updatePoolSaga),
    takeEvery(duplicatePool.type, duplicatePoolSaga),
    takeEvery(removePool.type, removePoolSaga),
];
