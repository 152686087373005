import React from "react";

import iconAndroid from "@common/img/svg/androind-icon.svg";
import * as supportStyle from "@screens/Settings/Members/Supports/style.scss";

const AndroidSupport = () => (
    <span className={supportStyle.icon}>
        <img src={iconAndroid} alt="androind" />
    </span>
);

export { AndroidSupport };
