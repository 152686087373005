import { getAccountInfo } from "@services/firebase";
import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";

const getLatestUpdatePwd = async (idToken: string) => {
    try {
        const {
            data: { users },
        } = await getAccountInfo(idToken);
        const [user] = users;
        const { passwordUpdatedAt } = user;

        return new Date(passwordUpdatedAt);
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
        return new Date();
    }
};

export { getLatestUpdatePwd };
