import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import errorIcon from "@common/img/svg/error-icon.svg";
import { errorInfo } from "@state/error";

import * as styles from "./styles.scss";

const ErrorNotification = observer(() => {
    useEffect(() => {
        if (errorInfo.isError) {
            setTimeout(() => {
                errorInfo.resetErrorInfo();
            }, 10000);
        }
    }, [errorInfo.isError]);

    return (
        <div className={`${styles.errorNotificationContainer} ${!errorInfo.isError && styles.hide}`}>
            <div className={styles.errorNotification}>
                <div className={styles.iconContainer}>
                    <img src={errorIcon} alt="error" />
                </div>
                <div className={styles.textContainer}>
                    {errorInfo.title && `${errorInfo.title}: `} {errorInfo.description}
                </div>
            </div>
        </div>
    );
});

export default ErrorNotification;
