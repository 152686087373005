import { AxiosResponse } from "axios";

import { postJSONRequestByFirebaseToken } from "@common/requests/requestByFirebaseToken";
import { AuthUserType } from "@services/Auth/User/Auth.User.types";
import { AuthGrantType, AuthGrantsType } from "@services/Auth/Grant/Auth.Grant.types";

export class AuthGrantService {
    private project = "";

    setProject(project: string) {
        this.project = project;
    }

    list = (data: Pick<AuthUserType, "user_id">): Promise<AxiosResponse<AuthGrantsType>> =>
        postJSONRequestByFirebaseToken("/auth/grant/list", data, { "X-project": this.project });

    create = (data: Pick<AuthUserType, "user_id"> & AuthGrantType): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/grant/create", data, { "X-project": this.project });

    revoke = (data: Pick<AuthUserType, "user_id"> & Pick<AuthGrantType, "grantor">): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/grant/revoke", data, { "X-project": this.project });
}

const authGrantService = new AuthGrantService();

export default authGrantService;
