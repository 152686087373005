import React from "react";
import { NavLink, Outlet } from "react-router-dom";

import * as commonStyle from "@common/style/style.scss";

const Dashboard = () => (
    <>
        <h1>{window.locales.dashboard}</h1>
        <div className={commonStyle.tabsContainer}>
            <NavLink
                to="general"
                className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
            >
                {window.locales.dashboardGeneral}
            </NavLink>
            <NavLink
                to="location"
                className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
            >
                {window.locales.dashboardLocation}
            </NavLink>
        </div>
        <Outlet />
    </>
);

export default Dashboard;
