import { call, put, select, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import { loadPayments } from "@services/Payments";
import { getPayments, setPayments } from "@state/payments";
import RootState from "@state/interfaces";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";

const selectActiveProject = (state: RootState) => state.activeProject;

export function* getPaymentsSaga(): SagaIterator {
    try {
        const activeProject = yield select(selectActiveProject);
        if (!activeProject.publickey) {
            return;
        }

        const response = yield call(() => loadPayments(activeProject));
        yield put(setPayments(response));
    } catch (e: unknown) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export const PaymentsSagas = [takeEvery(getPayments.type, getPaymentsSaga)];
