import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getPools } from "@state/pools";
import { RootState } from "@state/index";
import * as commonStyle from "@common/style/style.scss";

const Network = () => {
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeProject.publickey) {
            dispatch(getPools());
        }
    }, [dispatch, activeProject.publickey]);

    return (
        <div id="screenNetwork">
            <h1>{window.locales.network}</h1>
            <div className={commonStyle.tabsContainer}>
                <NavLink
                    to="countries"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.countries}
                </NavLink>
                <NavLink
                    to="location"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.locations}
                </NavLink>
                <NavLink
                    to="pools"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.pools}
                </NavLink>
            </div>
            <Outlet />
        </div>
    );
};

export default Network;
