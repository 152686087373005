import axios, { AxiosResponse } from "axios";

import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";
import { successInfo } from "@state/success";

const PREFIXES = {
    prod: "af-prod",
    dev: "af-dev",
    stage: "af-stage",
};

type EnvType = "prod" | "dev" | "stage";
const env = (process.env.CURRENT_API as EnvType) || "prod";
type FileType = "bpl" | "cnl";
const getFileFromGoogleRequest = <T>(
    publicKey: string,
    fileUrl: string,
    fileType: FileType,
): Promise<AxiosResponse<T>> => {
    const folder = PREFIXES[env];
    const request = `https://storage.googleapis.com/${folder}/project/${publicKey}/files/${fileType}/${fileUrl}`;
    return axios.get(request);
};

const saveDataToFileRequest = async (publickey: string, file: string, fileType: FileType): Promise<void> => {
    const access_token = getPortalTokenFromState();
    const request = "partner/files/upload";

    Network.post(
        request,
        {
            publickey,
            name: fileType,
        },
        {
            isFile: true,
            file,
        },
        access_token,
    ).finally(() => successInfo.setSuccessInfo());
};

export { getFileFromGoogleRequest, saveDataToFileRequest };
