import {
    deleteRequestByFirebaseToken,
    getRequestByFirebaseToken,
    postUrlencodedRequestByFirebaseToken,
    putUrlencodedRequestByFirebaseToken,
} from "@common/requests/requestsAPI";

const getAuthentifications = async (publickey: string) => {
    const { data = { all_auth_settings: [] } } = await getRequestByFirebaseToken<{
        all_auth_settings: [];
    }>("portal/project/authentifications_setting", { publickey });
    return data.all_auth_settings;
};

const editAuthentification = async (
    publickey: string,
    auth_method: string,
    auth_settings: string,
    auth_settings_id: string,
) =>
    putUrlencodedRequestByFirebaseToken("portal/project/authentifications_setting", {
        publickey,
        auth_method,
        auth_settings,
        auth_settings_id,
    });

const addAuthentification = async (publickey: string, auth_method: string, auth_settings: string) =>
    postUrlencodedRequestByFirebaseToken("portal/project/authentifications_setting", {
        publickey,
        auth_method,
        auth_settings,
    });

const deleteAuthentifications = (publickey: string, auth_method: string, auth_settings_id: string) =>
    deleteRequestByFirebaseToken("portal/project/authentifications_setting", {
        publickey,
        auth_method,
        auth_settings_id,
    });

export { addAuthentification, getAuthentifications, editAuthentification, deleteAuthentifications };
