import { TPool } from "@screens/Network/types";

const defaultPool: TPool = {
    description: "",
    name: "",
    version: 0,
    locations: [],
    permissions: [],
    groups: [],
};

export { defaultPool };
