const envColors = {
    backlight: "#ffa823",
    button: {
        bg: "#ffa823",
        font: "#fff",
    },
    buttonHover: {
        bg: "#ffa823",
        font: "#fff",
    },
    buttonActive: {
        bg: "#ffa823",
    },
};

export default envColors;
