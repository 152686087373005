import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";

import { DiscoveryPoolConfig } from "@screens/Pools/DiscoveryPool.types";
import filterEmptyStrings from "@common/methods/filterEmptyStringValues";
import objectStore from "@state/object";
import jsonToBase64 from "@common/methods/jsonToBase64";
import { GENERAL_NAMESPACE, ObjectEnum } from "@screens/Objects/Object/Object.constants";
import { IMetadata, IObjectRequest } from "@screens/Objects/Objects.types";
import { editAndCreateObjectRequest } from "@services/Objects";
import { NEW_PAGE } from "@common/constant/urls";
import base64ToJson from "@common/methods/base64toJSON";
import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";
import { ObjectStoreType } from "@state/object/ObjectStore.types";
import { checkObjectResponse } from "@common/methods/objectRequest";

const usePool = (objectData: ObjectStoreType) => {
    const { pool = "" } = useParams();
    const navigate = useNavigate();
    const poolData = useMemo<DiscoveryPoolConfig>(() => objectData.data as DiscoveryPoolConfig, [objectData.data]);
    const poolMetadata = useMemo<IMetadata>(() => objectData.metadata as IMetadata, [objectData.metadata]);
    const [isRequesting, setIsRequesting] = useState(false);

    const poolAction = async (partOfPool: DiscoveryPoolConfig) => {
        try {
            if (!isRequesting) {
                setIsRequesting(true);
            }

            const dataObject = {
                ...poolData,
                ...partOfPool,
            };

            const object = filterEmptyStrings({
                ...objectData.metadata,
                requireRevision: objectData.metadata.revision,
                data: jsonToBase64(dataObject),
                kind: ObjectEnum.DISCOVERY_POOL_CONFIG,
                namespace: GENERAL_NAMESPACE,
                onlyCreate: false,
                onlyUpdate: false,
                returnPrevious: false,
                returnCurrent: true,
            }) as IObjectRequest;

            const { data } = await editAndCreateObjectRequest({
                ...object,
                onlyCreate: pool === NEW_PAGE,
                onlyUpdate: pool !== NEW_PAGE,
            });

            if (!data) return;

            checkObjectResponse(data, () => {
                if (pool === NEW_PAGE) {
                    navigate("/pools");
                } else {
                    const object = base64ToJson(data.current.data);
                    objectStore.updateMetadata({ ...data.current.metadata });
                    objectStore.updateData({ ...object });
                }
            });
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        } finally {
            setTimeout(() => {
                setIsRequesting(false);
            }, 2000);
        }
    };

    return {
        poolAction,
        isRequesting,
        poolData,
        poolMetadata,
    };
};

export { usePool };
