import React from "react";
import isEqual from "lodash/isEqual";

import { TServerPool, TServerPoolForm } from "@screens/Settings/VPN/Offload/types";
import { TargetIcon } from "@components/TargetIcon";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import * as tableStyle from "@components/Table/style.scss";

const makeRuleForModal = (config: TServerPoolForm, service: string): TServerPool => {
    const {
        user_region = "",
        protocol = { in: [] },
        device_type = { in: [] },
        app_version = { semver: "" },
        proxy = [],
    } = config.selector;

    return {
        service,
        proxy: proxy.join(", "),
        user_region,
        protocol: protocol.in,
        device_type: device_type.in,
        app_version: app_version.semver,
    } as TServerPool;
};

const accumulatePools = (
    offloadConfigs: { [p: string]: TServerPoolForm[] },
    editOffload: (rule: TServerPool) => void,
    showDeleteModal: (rule: TServerPool) => void,
): FlatArray<
    {
        proxy: string;
        protocol: string;
        buttons: { component: JSX.Element };
        app_version: string | "";
        service: string;
        user_region: string | "";
        device_type: string;
    }[][],
    1
>[] =>
    Object.entries(offloadConfigs)
        .map(([service, offloadConfig]) =>
            offloadConfig.map((config) => {
                const {
                    user_region = "",
                    protocol = { in: [] },
                    device_type = { in: [] },
                    app_version = { semver: "" },
                    proxy = [],
                } = config.selector;
                const dataProtocol = Array.isArray(protocol.in) ? protocol.in.join(", ") : `${protocol}`;

                return {
                    service,
                    proxy: proxy.join(", "),
                    user_region,
                    protocol: dataProtocol,
                    device_type: Array.isArray(device_type.in) ? device_type.in.join(", ") : device_type.in,
                    app_version: app_version.semver,
                    buttons: {
                        component: (
                            <div className={tableStyle.buttonsColumn}>
                                <TargetIcon onClick={() => editOffload(makeRuleForModal(config, service))}>
                                    <img src={iconPen} alt="img" />
                                </TargetIcon>
                                <TargetIcon onClick={() => showDeleteModal(makeRuleForModal(config, service))}>
                                    <img src={iconTrash} alt="img" />
                                </TargetIcon>
                            </div>
                        ),
                    },
                };
            }),
        )
        .flat();

const modalObjectToRule = (modal: TServerPool): TServerPoolForm => {
    const { user_region, protocol, device_type, app_version, proxy } = modal;

    return {
        selector: {
            user_region,
            protocol: {
                in: protocol,
            },
            device_type: {
                in: device_type,
            },
            app_version: {
                semver: app_version,
            },
            proxy: proxy.split(", "),
        },
    };
};

const isRuleEqualModal = (rule: TServerPoolForm, modal: TServerPool): boolean =>
    isEqual(rule, modalObjectToRule(modal));

export { accumulatePools, isRuleEqualModal };
