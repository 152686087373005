import React from "react";

import { getCountryName, getDateString } from "@common/methods";
import { Data } from "@components/Table/types";
import * as table from "@components/Table/style.scss";

import { IChange, IEvent } from "./types";

const validTypes = ["PARTNER_LOGIN", "PARTNER_LOGOUT", "CREATE_CARRIER_ADMIN"];

function getChanges(eventChanges: IChange[], type: string): string {
    const parseChanges = eventChanges.map((itemEvent) => {
        const cnlEvent = itemEvent.property_value === "cnl" ? window.locales.events.ADD_FILE_CNL : "";
        const bplEvent = itemEvent.property_value === "bpl" ? window.locales.events.ADD_FILE_BPL : "";
        const fileNameEvent = itemEvent.property_name === "filename" ? window.locales.events.ADD_FILE_CHANGES : "";
        const adminRole = () => {
            if (itemEvent.property_name === "email") return itemEvent.property_value;
            if (itemEvent.property_name === "roleName")
                return itemEvent.property_value || window.locales.roles[itemEvent.property_value].name;
            return "";
        };
        switch (type) {
            case "ADD_FILE":
                return cnlEvent || bplEvent || fileNameEvent;
            case "UPDATE_CARRIER":
                return window.locales.settings;
            case "CREATE_FIREBASE_ADMIN":
            case "UPDATE_CARRIER_FIREBASE_ADMIN_ROLE":
                return `${adminRole()}`;
            case "DELETE_CARRIER_PURCHASE_SETTINGS":
                return itemEvent.property_value;
            case "DELETE_CARRIER_FIREBASE_ADMIN":
                return itemEvent.property_name === "email" ? itemEvent.property_value : null;
            case "CREATE_AUTH_METHOD":
            case "UPDATE_CARRIER_AUTH_METHOD":
            case "DELETE_CARRIER_AUTH_METHOD":
                return itemEvent.property_name === "authMethod" || itemEvent.property_name === "authMehtodName"
                    ? itemEvent.property_value
                    : null;
            case "CREATE_CARRIER":
                return itemEvent.property_name === "identity" ? itemEvent.property_value : null;
            case "UPDATE_CARRIER_ICON":
                return window.locales.events.UPDATE_CARRIER_ICON_CHANGES;
            case "CREATE_SUBSCRIBER":
            case "DISABLE_USER":
            case "UPDATE_SUBSCRIBER":
            case "SET_TRAFFIC_LIMIT":
            case "DELETE_TRAFFIC_LIMIT":
                return window.locales.events.USER;
            case "DELETE_USER_DATA":
                return window.locales.events.USER;
            case "DELETE_DEVICE":
                return window.locales.events.DEVICE;
            case "CREATE_CARRIER_COUNTRY":
            case "DELETE_CARRIER_COUNTRY":
                return getCountryName(eventChanges[0].property_value);
            case "PARTNER_LOGIN":
            case "PARTNER_LOGOUT":
            case "CREATE_CARRIER_ADMIN":
                return "";
            case "GENERATE_REPORT":
                if (itemEvent.property_name !== "type") return window.locales[itemEvent.property_value];
                return null;
            case "MASS_USER_UPDATE":
                return window.locales.users;
            default:
                return false;
        }
    });
    const setChanges = new Set(parseChanges);
    return [...setChanges].join(":");
}

interface IDataItemProps {
    date: string;
    time: string;
}
const DataItem: React.FC<IDataItemProps> = ({ date, time }) => (
    <>
        <div className={table.dateItem}>{date}</div>
        <div className={table.time}>{time}</div>
    </>
);

const accumulateEvents = (events: IEvent[]): Data[] => {
    const validEventsType = events.filter((event) => !validTypes.includes(event.type));

    return validEventsType.map((event) => {
        const { event_author, type, event_changes, changes } = event;
        const date = (event.time && getDateString(event.time)) || {
            date: "",
            time: "",
        };

        const changesJson = JSON.parse(changes, (key, value) => {
            if (typeof value === "string") {
                try {
                    return JSON.parse(value);
                } catch (e) {
                    return value;
                }
            } else return value;
        });

        return {
            date: {
                sortColumn: event.time,
                component: <DataItem {...date} />,
            },
            author: event_author.firebase_email || "",
            type: window.locales.events[type] || type,
            changes: {
                sortColumn: getChanges(event_changes, type),
                tooltip: {
                    title: getChanges(event_changes, type),
                    content: JSON.stringify(changesJson, null, "\t"),
                },
            },
        };
    });
};

const getDateFrom = (from: Date) =>
    `${from
        .getFullYear()
        .toString()}${from.getMonth() + 1}${from.getDate()}${from.getHours()}${from.getMinutes()}${from.getSeconds()}`;

const getDateTill = (till: Date) =>
    `${till
        .getFullYear()
        .toString()}${till.getMonth() + 1}${till.getDate()}${till.getHours()}${till.getMinutes()}${till.getSeconds()}`;

const log = (events: IEvent[]) =>
    events.map((event) => {
        const date: { date: string; time: string } = getDateString(event.time) || {
            date: "",
            time: "",
        };
        return `${date.date} ${date.time} ${event.api} ${event.author} ${event.changes} ${event.type}`;
    });

const downloadFile = (cursor: number, events: IEvent[], activeProject: any) => {
    const element = document.createElement("a");
    const from = new Date(cursor ?? Date.now() - 24 * 60 * 60 * 1000);
    const till = new Date(Date.now());

    element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(log(events).join("\n"))}`);
    element.setAttribute(
        "download",
        `EVENTS-LOG_${activeProject.publickey}_FROM_${getDateFrom(from)}_TILL_${getDateTill(till)}.txt`,
    );
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

const headings = [
    {
        isSort: true,
        heading: "Date",
    },
    {
        isSort: true,
        heading: "Author",
    },
    {
        isSort: true,
        heading: "Type",
    },
    {
        isSort: true,
        heading: "Changes",
    },
];

export { accumulateEvents, downloadFile, headings };
