import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { updateActiveProject } from "@state/activeProject";
import { RootState } from "@state/index";
import Table from "@components/Table";
import { Modal } from "@components/Modal";
import Search from "@screens/Settings/Bypass/Search/Search";
import { IconDownload, IconPlus, IconUpload } from "@common/icons";
import { validationDomain } from "@common/methods/сheckDomain";
import * as style from "@screens/Settings/Bypass/Bypass.style.scss";
import { ERoles } from "@screens/Settings/enums";
import { getFileFromGoogleRequest, saveDataToFileRequest } from "@services/GoogleStorage/GoogleStorage.service";

import { AddByPassDomainInput } from "./addByPassDomainInput";
import { checkUrl, headings, accumulateByPassDomains } from "./Bypass.utils";

const Bypass = () => {
    const activeProject = useSelector((state: RootState | any) => state.activeProject);
    const [data, setData] = useState<string[]>([]);
    const [filteredList, setFilteredList] = useState<string[]>([]);
    const [tableBody, setTableBody] = useState<any>([]);
    const [isShowAddInput, setIsShowAddInput] = useState(false);
    const [message, setMessage] = useState("");
    const url = useRef("");
    const fileUrl = useRef("");
    const filteredData: any = useRef();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const triggerModal = () => setIsOpenModal((prev) => !prev);

    const deleteDomain = useCallback(
        (index: number) => {
            setData((prevState: any) => prevState.filter((item: string, i: number) => i !== index && item));
            setFilteredList(data.filter((item: string, i: number) => i !== index && item));
        },
        [data, setData],
    );

    const submitAdd = useCallback(() => {
        if (!url.current || !validationDomain(url.current)) {
            setMessage(window.locales.domainNotValid);
            return;
        }
        if (data.includes(url.current)) {
            setMessage(window.locales.domainNotValid);
            return;
        }
        setMessage("");
        setData([...data, url.current]);
        setFilteredList([...data, url.current]);
    }, [data]);

    const changeDomainsUrl = useCallback(
        (e: string) => {
            url.current = e;
        },
        [url],
    );

    const loadData = useCallback(async () => {
        if (!fileUrl.current) return;
        try {
            const { data } = await getFileFromGoogleRequest<string>(activeProject.publickey, fileUrl.current, "bpl");
            if (!data) return;
            const list = data.split("\n").filter((value: string) => value.trim() !== "");
            setFilteredList(list);
            setData(list);
        } catch (e) {
            window.console.error(e);
        }
    }, [activeProject.publickey]);

    useEffect(() => {
        if (activeProject.config.files) {
            fileUrl.current = checkUrl(activeProject);
            loadData().then();
        }
    }, [activeProject, loadData]);

    useEffect(() => {
        if (activeProject.publickey) {
            filteredData.current = filteredList ?? [];
            const parsedByPassDomains = accumulateByPassDomains(data || [], deleteDomain);
            setTableBody(parsedByPassDomains);
        }
    }, [activeProject, changeDomainsUrl, data, deleteDomain, filteredList, message, submitAdd]);

    const saveData = async () => {
        triggerModal();
        try {
            data && (await saveDataToFileRequest(activeProject.publickey, data.join("\n"), "bpl"));
        } finally {
            updateActiveProject(activeProject.publickey);
        }
    };

    const downloadFile = () => {
        window.open(fileUrl.current);
    };

    const searchChanged = (search: any) => {
        if (search !== "" || search) {
            setData(
                filteredData.current.filter((urlDomain: string) =>
                    urlDomain.toLowerCase().trim().includes(search.toLowerCase().trim()),
                ),
            );
        }
        if (search.length === 0 || !search) setData(filteredData.current);
    };

    const toggleAdd = () => {
        setIsShowAddInput((prev) => !prev);
    };

    const submit = {
        label: window.locales.domainsSaveQuestionSubmit,
        onConfirm: saveData,
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return false;

        const file = e.target.files[0];

        if (!file || !file.name) return false;
        const reader = new FileReader();

        reader.onload = (theFile) => {
            if (!theFile.target || !theFile || !theFile.target.result) return false;
            const result = atob(theFile.target.result.toString().replace(/[^,]+,([^,]+)$/, "$1")).split("\n");
            if (!result || result.length === 0) return false;
            const validResult = result.filter((item) => validationDomain(item));
            setData(validResult);
            return setFilteredList(validResult);
        };

        return reader.readAsDataURL(file);
    };

    const submitEdit = () => {
        triggerModal();
    };

    return (
        <div className={style.bypassContainer}>
            {isShowAddInput && (
                <AddByPassDomainInput
                    message={message}
                    changeUrl={changeDomainsUrl}
                    submitAddDomain={submitAdd}
                    url={url}
                />
            )}
            <Table
                tableData={tableBody}
                headings={headings}
                title={window.locales.vpnBypassList}
                emptyMessage={window.locales.noDomains}
            >
                <div className="bypassScreen">
                    <Search publickey={activeProject.publickey} searchChanged={searchChanged} />
                    {activeProject.role !== ERoles.Support && (
                        <IconPlus theme="blue" label={window.locales.add} onClick={toggleAdd} />
                    )}
                    {activeProject.role !== ERoles.Support && (
                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                            }}
                        >
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <input
                                    id="file-upload"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(e)}
                                    type="file"
                                    style={{
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "30px",
                                        display: "none",
                                    }}
                                />
                                <IconUpload
                                    style={{
                                        marginLeft: "10px",
                                        zIndex: 1,
                                    }}
                                    theme="blue"
                                    label={window.locales.upload}
                                />
                            </label>
                        </div>
                    )}
                    {!fileUrl.current.includes("empty") && data && data.length > 0 && (
                        <IconDownload
                            style={{ marginLeft: "10px" }}
                            theme="blue"
                            label={window.locales.download}
                            onClick={downloadFile}
                        />
                    )}
                    {activeProject.role !== ERoles.Support && (
                        <div className="saveButton" onClick={submitEdit}>
                            {window.locales.save}
                        </div>
                    )}
                </div>
            </Table>
            <Modal
                isOpen={isOpenModal}
                onClose={triggerModal}
                title={window.locales.domainsSaveQuestionTitle}
                confirm={submit}
            >
                {window.locales.domainsSaveQuestionText}
            </Modal>
        </div>
    );
};

export default Bypass;
