import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import cloneDeep from "lodash/cloneDeep";
import { Editor } from "@monaco-editor/react";

import { StatusPoolContext } from "@screens/Pools/Pool/Pool";
import { LocationStatusType, LocationType } from "@screens/Pools/DiscoveryPool.types";
import { TextAreaField, TextField } from "@components/FormikFields";
import * as localStyle from "@screens/Pools/Pool/Parameters/ConfigParameters.style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { usePool } from "@screens/Pools/Pool/usePool";
import objectStore from "@state/object";
import { NEW_PAGE } from "@common/constant/urls";
import { monacoEditorOptions } from "@common/constant/monacoOption";
import { NameContainer } from "@components/FormikFields/components";

const initGroupParameters: LocationStatusType = {
    name: "",
    avg_load: 0,
    available_servers: 0,
    description: "",
    disabled_servers: 0,
    ipv6: false,
    total_servers: 0,
    unavailable_servers: 0,
};

const LocationParameters = observer(() => {
    const { location = "", pool = "" } = useParams();
    const navigate = useNavigate();
    const statusData = useContext(StatusPoolContext);
    const [locationStatus, setLocationStatus] = useState<LocationStatusType>(initGroupParameters);
    const { poolAction, isRequesting, poolData } = usePool(objectStore.objectData);

    useEffect(() => {
        const locationStatus = statusData.locations?.find((item) => item.name === location);
        locationStatus && setLocationStatus(locationStatus);
    }, [location, statusData]);

    return (
        <div className={localStyle.configParametersPage}>
            <Formik
                initialValues={{
                    name: locationStatus.name,
                    labels: JSON.stringify(locationStatus.labels, undefined, 4),
                    server_selector: JSON.stringify(locationStatus.server_selector, undefined, 4),
                    description: locationStatus.description,
                }}
                enableReinitialize
                validate={({ name, labels, server_selector }) => {
                    const errors: any = {};
                    const { emptyName, invalidConfig } = window.locales;
                    if (!name) errors.name = emptyName;

                    try {
                        typeof server_selector === "string" && JSON.parse(server_selector);
                    } catch (e) {
                        errors.server_selector = invalidConfig;
                    }

                    try {
                        typeof labels === "string" && JSON.parse(labels);
                    } catch (e) {
                        errors.labels = invalidConfig;
                    }

                    return errors;
                }}
                onSubmit={async (values) => {
                    const { name = "", server_selector, labels, description = "" } = values;
                    const instanceServerSelector =
                        typeof server_selector === "string" ? JSON.parse(server_selector) : server_selector;
                    const instanceLabels = typeof labels === "string" ? JSON.parse(labels) : labels;

                    const editedLocation: LocationType = {
                        name: name.trim(),
                        description: description.trim(),
                        server_selector: instanceServerSelector,
                        labels: instanceLabels,
                    };

                    const clonePoolData = cloneDeep(poolData);
                    if (clonePoolData.locations) {
                        const indexOfGroup = clonePoolData.locations.findIndex(
                            (locationItem) => locationItem.name === editedLocation.name,
                        );

                        if (indexOfGroup >= 0) {
                            clonePoolData.locations[indexOfGroup] = editedLocation;
                        } else {
                            clonePoolData.locations.push(editedLocation);
                        }
                    } else {
                        clonePoolData.locations = [editedLocation];
                    }
                    await poolAction(clonePoolData);
                    if (location === NEW_PAGE) {
                        navigate(`/pools/${pool}/locations`);
                    }
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="tokenForm" onSubmit={handleSubmit} className={localStyle.poolConfigData}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type name"
                                type="text"
                                name="name"
                                disabled={locationStatus.name}
                            />
                        </section>
                        <section>
                            <NameContainer $isError={false}>{window.locales.labels}</NameContainer>
                            <Editor
                                height="20vh"
                                options={monacoEditorOptions}
                                value={values.labels}
                                onChange={(value) => setFieldValue("labels", value)}
                                theme="vs-dark"
                                defaultLanguage="json"
                            />
                        </section>
                        <section>
                            <NameContainer $isError={false}>{window.locales.server_selector}</NameContainer>
                            <Editor
                                height="20vh"
                                options={monacoEditorOptions}
                                value={values.server_selector}
                                onChange={(value) => setFieldValue("server_selector", value)}
                                theme="vs-dark"
                                defaultLanguage="json"
                            />
                        </section>
                        <section>
                            <Field
                                component={TextAreaField}
                                value={values.description}
                                placeholder="Type description"
                                type="text"
                                name="description"
                            />
                        </section>
                        <div className={buttonStyle.singleButtonContainer}>
                            <button disabled={isRequesting} className={buttonStyle.buttonSubmit} type="submit">
                                {window.locales.save}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
});

export default LocationParameters;
