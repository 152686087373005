import { call, put, select, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import RootState from "@state/interfaces";
import { getPurchases, setPurchases } from "@state/purchases/index";
import { loadPurchases } from "@services/Purchases";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";

const selectUser = (state: RootState) => state.users.selectedUser;

export function* getPurchasesSaga(): SagaIterator {
    try {
        const user = yield select(selectUser);
        if (!user) {
            return;
        }

        const response = yield call(() => loadPurchases(user.id));
        yield put(setPurchases(response));
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export const PurchasesSagas = [takeEvery(getPurchases.type, getPurchasesSaga)];
