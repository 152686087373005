import React, { useCallback, useEffect, useRef } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@state/index";
import { getPool } from "@state/pools";
import * as commonStyle from "@common/style/style.scss";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import { successInfo } from "@state/success";
import iconCopy from "@common/img/svg/copy-icon.svg";
import { Pending } from "@screens/Panding";

import * as localStyle from "./style.scss";

const Pool = () => {
    const { project, pool = "" } = useParams();
    const pools = useSelector((state: RootState) => state.pools);
    const projectId = useRef(project);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goToPoolsPage = useCallback(() => navigate(`/projects/${project}/network/pools/`), [project, navigate]);

    if (!pool) {
        goToPoolsPage();
    }

    useEffect(() => {
        dispatch(getPool(pool));
    }, [dispatch, pools.publicPools, pools.privatePools, pool]);

    useEffect(() => {
        projectId.current !== project && goToPoolsPage();
    }, [project, goToPoolsPage]);

    if (pools.publicPools.length === 0) {
        return <Pending />;
    }

    return (
        <>
            <div className={localStyle.poolHeader}>
                <span className={localStyle.item}>{window.locales.pool}:</span> {pool}
                <EnvIconContainer
                    action={() => {
                        successInfo.setSuccessInfoWithText(window.locales.copiedSuccess);
                        navigator.clipboard.writeText(pool).then();
                    }}
                >
                    <img src={iconCopy} alt="Copy" />
                </EnvIconContainer>
            </div>
            <div className={commonStyle.tabsContainer}>
                <NavLink
                    to="optimal"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.optimalLocation}
                </NavLink>
                <NavLink
                    to="rules"
                    className={({ isActive }) => `${commonStyle.link} ${isActive && commonStyle.activeLink}`}
                >
                    {window.locales.locationRules}
                </NavLink>
            </div>
            <Outlet />
        </>
    );
};

export default Pool;
