import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { RootState } from "@state/index";
import * as settingsStyle from "@screens/Settings/style.scss";
import { ERoles } from "@screens/Settings/enums";
import Table from "@components/Table";
import { accumulateClientNetworks, headings } from "@screens/Settings/VPN/ClientNetworks/utils";
import { Data } from "@components/Table/types";
import { ClientNetworksModal } from "@screens/Settings/VPN/ClientNetworks/ClientNetworkModal";
import { IClientNetworks } from "@screens/Settings/VPN/ClientNetworks/types";
import { Modal } from "@components/Modal";
import { updateActiveProject } from "@state/activeProject";
import * as buttonStyle from "@components/Button/style.scss";
import * as tableContainerStyle from "@components/TableContainer/style.scss";
import { reducerModal, initialStateModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import { IconPlus } from "@common/icons";
import { getFileFromGoogleRequest, saveDataToFileRequest } from "@services/GoogleStorage/GoogleStorage.service";

const defaultClientNetwork: IClientNetworks = {
    id: undefined,
    type: "",
    ssid: [],
    bssid: [],
    authorized: "",
    action: "",
};

const initialState = initialStateModal(defaultClientNetwork);
const reducerServerPools = reducerModal(initialState);

const TIME_REFRESH_DATA = 3000;
const ClientNetworks = () => {
    const fileUrl = useRef("");
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const [modals, dispatchModal] = useReducer(reducerServerPools, initialState);
    const [body, setBody] = useState<Data[]>([]);
    const [networks, setNetworks] = useState<IClientNetworks[]>([]);
    const dispatch = useDispatch();

    const showEditModal = (network: IClientNetworks) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: network,
        });
    };

    const showDeleteModal = (network: IClientNetworks) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: network,
        });
    };

    const checkUrl = useCallback(() => {
        if (!activeProject.config.files) return;
        if (!activeProject.config.files.cnl) return;
        fileUrl.current = activeProject.config.files.cnl;
    }, [activeProject.config.files]);

    const addNetwork = async (network: IClientNetworks) => {
        await saveDataToFileRequest(activeProject.publickey, JSON.stringify([...networks, network]), "cnl");
        setTimeout(() => {
            dispatch(updateActiveProject(activeProject.publickey));
        }, TIME_REFRESH_DATA);
    };

    const editNetwork = async ({ type, action, authorized, ssid, bssid, id }: IClientNetworks) => {
        if (!id) return;
        const newNetworks = cloneDeep(networks);
        if (!newNetworks[id]) return;
        newNetworks[id] = {
            type,
            action,
            authorized,
            ssid,
            bssid,
        };

        await saveDataToFileRequest(activeProject.publickey, JSON.stringify([...newNetworks]), "cnl");
        setTimeout(() => {
            dispatch(updateActiveProject(activeProject.publickey));
        }, TIME_REFRESH_DATA);
    };

    const deleteNetwork = async () => {
        if (typeof modals.item.id === "undefined") return;
        const newNetworks = cloneDeep(networks);
        if (!newNetworks[modals.item!.id]) return;
        newNetworks.splice(modals.item!.id, 1);

        await saveDataToFileRequest(activeProject.publickey, JSON.stringify([...newNetworks]), "cnl");
        setTimeout(() => {
            dispatch(updateActiveProject(activeProject.publickey));
        }, TIME_REFRESH_DATA);
        closeModal();
    };

    const downloadFile = () => {
        window.open(fileUrl.current);
    };

    const getClientNetworks = useCallback(async () => {
        const { data } = await getFileFromGoogleRequest<IClientNetworks[]>(
            activeProject.publickey,
            fileUrl.current,
            "cnl",
        );
        if (!data) return;
        const content = accumulateClientNetworks(data, showEditModal, showDeleteModal);
        setNetworks(data);
        setBody(content);
    }, [activeProject.publickey]);

    useEffect(() => {
        checkUrl();
        if (!fileUrl.current) return;
        getClientNetworks().then();
    }, [activeProject.publickey, checkUrl, getClientNetworks]);

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: defaultClientNetwork,
        });
    };

    return (
        <div className={settingsStyle.tabContainer}>
            <Table
                tableData={body}
                headings={headings}
                title={window.locales.clientNetworks}
                emptyMessage={window.locales.noData}
            >
                <div className={tableContainerStyle.toolsContainer}>
                    {activeProject.role !== ERoles.Support && (
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: defaultClientNetwork,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    )}
                    {fileUrl.current && (
                        <button className={buttonStyle.buttonDownload} type="submit" onClick={downloadFile}>
                            {window.locales.download}
                        </button>
                    )}
                </div>
            </Table>

            {/* Modal create client network */}
            <ClientNetworksModal
                isOpen={modals.createModal}
                title={window.locales.addClientNetwork}
                close={closeModal}
                buttonName={window.locales.confirm}
                submit={addNetwork}
                clientNetwork={modals.item}
            />

            {/* Modal edit client network */}
            <ClientNetworksModal
                isOpen={modals.editModal}
                title={window.locales.editClientNetwork}
                close={closeModal}
                buttonName={window.locales.confirm}
                submit={editNetwork}
                clientNetwork={modals.item}
            />

            {/* Modal deleting client network */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                title={window.locales.deleteClientNetwork}
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteNetwork,
                }}
            >
                {`Do you really want to delete client network by id ${modals.item.id}?`}
            </Modal>
        </div>
    );
};

export default ClientNetworks;
