import React from "react";
import { NavigateFunction } from "react-router/dist/lib/hooks";

import { capitalize } from "@common/methods/capitalize";
import * as style from "@screens/Projects/style.scss";
import { UnsetIconProject } from "@components/IconProject";

import { IProjectItemProps, ProjectType, TableProjectItem } from "./Projects.types";

const projectIcon: React.FC<IProjectItemProps> = ({ icon, name, index }) => (
    <div className={`${style.projectNameContainer} publickey`} key={index}>
        {icon ? <img alt={name} src={icon} /> : <UnsetIconProject projectName={name} />} <h5>{name}</h5>
    </div>
);

const accumulateProjects = (projects: ProjectType[], navigate: NavigateFunction): TableProjectItem[] =>
    projects.map((project, index) => {
        const {
            carrier_name,
            icon,
            publickey,
            current_user_role = { name: "" },
            stats = {
                active_users: 0,
                active_devices: 0,
            },
        } = project;

        const role = capitalize(current_user_role.name.toLowerCase());
        return {
            project_name: {
                sortColumn: carrier_name,
                component: projectIcon({
                    icon: icon as string,
                    name: carrier_name,
                    index,
                }),
            },
            project_id: publickey,
            users: stats.active_users,
            devices: stats.active_devices,
            role,
            event: () => {
                navigate(`${publickey}/dashboard`);
            },
        };
    });

const headings = [
    {
        isSort: true,
        heading: window.locales.projectName,
    },
    {
        isSort: true,
        heading: window.locales.publickey,
    },
    {
        isSort: true,
        heading: window.locales.users,
    },
    {
        isSort: true,
        heading: window.locales.devices,
    },
    {
        isSort: true,
        heading: window.locales.role,
    },
];
export { accumulateProjects, headings };
