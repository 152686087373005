import { css } from "styled-components";

import text from "./text";

const { fontBody } = text;
const typography = css`
    h6 {
        font-size: ${fontBody.h6.size}px;
        font-weight: ${fontBody.h6.weight};
        margin: 0;
    }
    h5 {
        font-size: ${fontBody.h5.size}px;
        font-weight: ${fontBody.h5.weight};
        margin: 0;
    }
`;
export default typography;
