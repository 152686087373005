import Network from "@common/Network";
import { getFirebaseTokenFromState } from "@common/getFirebaseTokenFromState";

const loadPayments = (project) =>
    new Promise(async (resolve) => {
        const access_token = await getFirebaseTokenFromState();
        const request = "portal/project/payments_settings";
        const params = {
            publickey: project.publickey,
        };

        Network.get(request, params, access_token)
            .then((response) => {
                try {
                    return resolve(response.response.all_purchase_settings);
                } catch (e) {
                    return resolve([]);
                }
            })
            .catch(() => resolve([]));
    });

const addPayment = async (publickey, purchase_type, purchase_settings) => {
    const access_token = await getFirebaseTokenFromState();
    const request = "portal/project/payments_settings";
    const params = {
        publickey,
    };
    const body = {
        [purchase_type]: JSON.parse(purchase_settings),
    };

    return Network.post(request, params, body, access_token);
};

const editPayment = async (purchase_type, purchase_settings, project) => {
    const access_token = await getFirebaseTokenFromState();
    const request = "portal/project/payments_settings";
    const params = {
        publickey: project.publickey,
    };
    const body = {
        [purchase_type]: JSON.parse(purchase_settings),
    };

    return Network.put(request, params, body, access_token);
};

const deletePayment = async (purchase_type, project) => {
    const access_token = await getFirebaseTokenFromState();
    const request = "portal/project/payments_settings";
    const params = {
        publickey: project.publickey,
        purchase_type,
    };

    return Network.delete(request, params, null, access_token);
};

export { addPayment, editPayment, loadPayments, deletePayment };
