import React from "react";

import { getNameField } from "@components/FormikFields/utils";
import * as style from "@components/FormikFields/style.scss";
import { ITextAreaField } from "@components/FormikFields/TextAreaField/TextAreaField.types";

import { TextAreaFieldInput, NameContainer } from "../components";

const TextAreaField = (data: ITextAreaField) => {
    const { field, form, disabled, type, placeholder, resizable = false, isTitle = true, size = "small" } = data;
    const { name } = field;
    const { touched, errors } = form;
    const error = touched[name] ? errors[name] : "";
    const getNewName = getNameField(name);

    if (typeof field.value === "object") {
        field.value = JSON.stringify({ ...field.value }, undefined, 4);
    }

    const height = size === "small" ? "100px" : "326px";

    return (
        <>
            {isTitle && <NameContainer $isError={Boolean(error)}>{getNewName}</NameContainer>}
            <div className={style.textFieldContainer}>
                <TextAreaFieldInput
                    $height={height}
                    className={resizable && style.resizable}
                    placeholder={placeholder}
                    type={type}
                    disabled={disabled}
                    {...field}
                    $error={error}
                />
                <div className={style.textErrorContainer}>{error}</div>
            </div>
        </>
    );
};

export default TextAreaField;
