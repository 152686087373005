const getAge = (date: string | Date | number): string => {
    if (!date) return "";
    let updateDate = Number(date);
    if (updateDate < 946684800000) updateDate *= 1000;
    const today = new Date();
    const timeDifference = Number(today) - updateDate;

    // Calculate the number of years, months, weeks, and days
    const years = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));
    const remainingMonths = Math.floor(
        (timeDifference % (365.25 * 24 * 60 * 60 * 1000)) / (30.44 * 24 * 60 * 60 * 1000),
    );
    const remainingWeeks = Math.floor((timeDifference % (30.44 * 24 * 60 * 60 * 1000)) / (7 * 24 * 60 * 60 * 1000));
    const remainingDays = Math.floor((timeDifference % (7 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));

    if (years > 0) return `${years}y`;
    if (remainingMonths > 0) return `${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
    if (remainingWeeks > 0) return `${remainingWeeks} week${remainingWeeks > 1 ? "s" : ""}`;
    if (remainingDays > 0) return `${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
    return window.locales.today;
};

export { getAge };
