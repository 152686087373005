import React, { useCallback, useState } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";

import * as localStyle from "@screens/Auth/Auth.styled.scss";
import { If } from "@components/If";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import { successInfo } from "@state/success";
import iconCopy from "@common/img/svg/copy-icon.svg";
import * as userStyle from "@screens/Auth/AuthUser/AuthUser.styled.scss";
import iconDelete from "@common/img/svg/delete-icon.svg";
import * as commonStyle from "@common/style/style.scss";
import { Modal } from "@components/Modal";
import { NEW_PAGE } from "@common/constant/urls";
import authUserService from "@services/Auth/User/Auth.User.service";

function AuthUserContainer() {
    const [isModalDelete, setIsModalDelete] = useState(false);
    const { project: namespace = "", user = "" } = useParams();
    const navigate = useNavigate();
    authUserService.setProject(namespace);

    const closeDeleteModal = useCallback(() => {
        setIsModalDelete(false);
    }, []);

    const deleteUser = async () => {
        setIsModalDelete(false);
        await authUserService.delete({ user_id: user });
        navigate(`/auth/${namespace}/user`);
    };

    return (
        <>
            <Modal
                isNegative
                isOpen={isModalDelete}
                onClose={closeDeleteModal}
                title={window.locales.deletePoolGroup}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteUser,
                }}
            >
                Do you want to delete {user}?
            </Modal>
            <If condition={user !== NEW_PAGE}>
                <div className={localStyle.authHeaderContainer}>
                    <span className={localStyle.item}>{window.locales.userId}:</span> {user}
                    <EnvIconContainer
                        action={() => {
                            successInfo.setSuccessInfoWithText(window.locales.copiedSuccess);
                            navigator.clipboard.writeText(user).then();
                        }}
                    >
                        <img src={iconCopy} alt="Copy" />
                    </EnvIconContainer>
                    <div className={userStyle.deleteContainer}>
                        <EnvIconContainer action={() => setIsModalDelete(true)}>
                            <img src={iconDelete} alt="img" />
                        </EnvIconContainer>
                    </div>
                </div>
            </If>
            <div className={commonStyle.underTabsContainer}>
                <NavLink
                    to="parameters"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.parameters}
                </NavLink>
                <If condition={user !== NEW_PAGE}>
                    <>
                        <NavLink
                            to="entitlements"
                            className={({ isActive }) =>
                                `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`
                            }
                        >
                            {window.locales.entitlements}
                        </NavLink>
                        <NavLink
                            to="grants"
                            className={({ isActive }) =>
                                `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`
                            }
                        >
                            {window.locales.grants}
                        </NavLink>
                    </>
                </If>
            </div>
            <Outlet />
        </>
    );
}

export default AuthUserContainer;
