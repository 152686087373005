import Network from "@common/Network";
import { getFirebaseTokenFromState } from "@common/getFirebaseTokenFromState";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";

const getNetwork = (publickey) =>
    new Promise((resolve) => {
        loadNetwork(publickey)
            .then((response) => {
                try {
                    return resolve(response.response.countries);
                } catch (e) {
                    return resolve([]);
                }
            })
            .catch(() => resolve([]));
    });

const loadNetwork = async (publickey) => {
    const access_token = await getFirebaseTokenFromState();
    const request = "portal/project/countries";
    const params = {
        publickey,
    };

    return Network.post(request, params, null, access_token);
};

const setVisibility = (publickey, country, visibility) =>
    new Promise(async (resolve, reject) => {
        const access_token = await getFirebaseTokenFromState();
        const request = "portal/project/country";
        const params = {
            publickey,
            country,
            visibility,
        };

        return Network.put(request, params, null, access_token)
            .then(() => resolve())
            .catch(() => reject({ message: "Internal server error" }));
    });

const loadPools = () =>
    new Promise((resolve, reject) => {
        const request = "partner/pools";
        const access_token = getPortalTokenFromState();
        Network.get(request, {}, access_token)
            .then((response) => {
                try {
                    return resolve(response.response.pools);
                } catch (e) {
                    return resolve([]);
                }
            })
            .catch(() => reject({ message: "Internal server error" }));
    });

const loadPoolDescription = (name) =>
    new Promise((resolve) => {
        const request = `partner/pool/${name}`;
        const access_token = getPortalTokenFromState();

        Network.get(request, {}, access_token)
            .then((response) => {
                try {
                    return resolve(response.response);
                } catch (e) {
                    return resolve(undefined);
                }
            })
            .catch(() => resolve(undefined));
    });

const clonePool = (name) =>
    new Promise((resolve, reject) => {
        const request = `partner/pool/${name}/clone`;
        const access_token = getPortalTokenFromState();

        Network.post(request, {}, { name: `${name}_${Date.now()}` }, access_token)
            .then((response) => {
                try {
                    return resolve(response.response);
                } catch (e) {
                    return resolve(undefined);
                }
            })
            .catch(() => reject(undefined));
    });

const deletePool = (name) =>
    new Promise((resolve, reject) => {
        const request = `partner/pool/${name}`;
        const access_token = getPortalTokenFromState();

        Network.delete(request, {}, null, access_token)
            .then((response) => {
                try {
                    return resolve(response.response);
                } catch (e) {
                    return reject(undefined);
                }
            })
            .catch(() => reject(undefined));
    });

const updatePool = (name, body) =>
    new Promise((resolve, reject) => {
        const request = `partner/pool/${name}`;
        const access_token = getPortalTokenFromState();

        Network.patch(request, {}, body, access_token)
            .then((response) => {
                try {
                    return resolve(response.response);
                } catch (e) {
                    return reject(undefined);
                }
            })
            .catch((e) => reject(e));
    });

export { deletePool, clonePool, loadPools, loadPoolDescription, getNetwork, loadNetwork, setVisibility, updatePool };
