import axios, { AxiosResponse } from "axios";
import qs from "qs";

import { getFirebaseTokenFromState } from "@common/getFirebaseTokenFromState";
import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";
import { ParametersType, PutParamsType } from "@common/requests/types";
import { refreshToken } from "@common/requests/utils";
import { progress } from "@state/progress";

axios.defaults.baseURL = `/api/${process.env.CURRENT_API}/`;
const getRequestByFirebaseToken = async <T>(url: string, parameters?: ParametersType): Promise<AxiosResponse<T>> => {
    try {
        progress.increaseLength();
        const access_token = await getFirebaseTokenFromState();
        return await axios.get(url, {
            headers: { Authorization: `Bearer ${access_token}` },
            params: {
                ...parameters,
                t: Number(new Date()),
            },
        });
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });

        throw Error();
    } finally {
        progress.decreaseLength();
    }
};

const postUrlencodedRequestByFirebaseToken = async <T>(url: string, parameters: ParametersType): Promise<T> => {
    try {
        progress.increaseLength();
        const access_token = await getFirebaseTokenFromState();
        const data = qs.stringify(parameters);
        return await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    } catch (e: any | IErrorResponse) {
        const error: IErrorResponse = e;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
        return e;
    } finally {
        progress.decreaseLength();
    }
};

const postJSONRequestByFirebaseToken = async <T>(
    url: string,
    parameters: ParametersType,
    extraHeaders: Record<string, string> = {},
): Promise<T> => {
    try {
        progress.increaseLength();
        const access_token = await getFirebaseTokenFromState();
        return await axios.post(url, parameters, {
            headers: {
                Authorization: `Bearer ${access_token}`,
                ...extraHeaders,
            },
        });
    } catch (e: any | IErrorResponse) {
        const error: IErrorResponse = e;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
        return e;
    } finally {
        progress.decreaseLength();
    }
};

const deleteRequestByFirebaseToken = async <T>(url: string, parameters: ParametersType): Promise<T> => {
    try {
        progress.increaseLength();
        const access_token = await getFirebaseTokenFromState();
        return await axios.delete(url, {
            headers: { Authorization: `Bearer ${access_token}` },
            params: parameters,
        });
    } catch (e: any | IErrorResponse) {
        const error: IErrorResponse = e;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
        return e;
    } finally {
        progress.decreaseLength();
    }
};

const putUrlencodedRequestByFirebaseToken = async <T>(url: string, parameters: PutParamsType): Promise<T> => {
    try {
        const access_token = await getFirebaseTokenFromState();
        const data = qs.stringify(parameters);
        return await axios.put(url, data, {
            headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    } catch (e: any | IErrorResponse) {
        refreshToken(e.response.status);
        errorInfo.setErrorInfo({
            title: e.response.status,
            description: e.response.statusText,
        });
        return e;
    } finally {
        progress.decreaseLength();
    }
};

export {
    getRequestByFirebaseToken,
    postUrlencodedRequestByFirebaseToken,
    putUrlencodedRequestByFirebaseToken,
    deleteRequestByFirebaseToken,
    postJSONRequestByFirebaseToken,
};
