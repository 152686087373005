import { clearData } from "@common/methods";

export default () =>
    new Promise((resolve) =>
        window.fb.default
            .auth()
            .signOut()
            .then(() => {
                clearData();
                return resolve();
            }),
    );
