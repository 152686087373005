import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

import RootState from "@state/interfaces";
import { Data } from "@components/Table/types";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { IPaymentMethod } from "@screens/Settings/Payments/types";
import { If } from "@components/If";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { EModals } from "@components/Modal/types";
import Table from "@components/Table";
import { getPayments } from "@state/payments";
import { PaymentModal } from "@screens/Settings/Payments/PaymentModal";
import { Modal } from "@components/Modal";
import { addPayment, deletePayment, editPayment } from "@services/Payments";
import { successInfo } from "@state/success";
import { IconPlus } from "@common/icons";

import { Permissions } from "../../../enums";
import { accumulatePaymentMethods, headings } from "./utils";

const initPayment: IPaymentMethod = {
    purchase_settings: {
        bundles: [],
        url: "",
        password: "",
    },
    purchase_type: "",
    show: false,
};

const initialState = initialStateModal(initPayment);
const reducerPayments = reducerModal(initialState);

const Payments = () => {
    const [modals, dispatchModal] = useReducer(reducerPayments, initialState);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const permissions = useSelector((state: RootState) => state.permissions);
    const payments = useSelector((state: RootState) => state.payments);
    const navigate = useNavigate();
    const [body, setBody] = useState<Data[]>([]);
    const isEdit = useMemo(() => permissions.includes(Permissions.ProjectPaymentUpdate), [permissions]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!permissions.includes(Permissions.PaymentRead)) {
            navigate("/");
        }
    }, [navigate, permissions]);

    const showEditModal = (paymentMethod: IPaymentMethod) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: paymentMethod,
        });
    };

    const showDeleteModal = (paymentMethod: IPaymentMethod) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: paymentMethod,
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initPayment,
        });
    };

    // TODO: Need to fix hiding
    const handleVision = useCallback(
        (purchase_type: string) => {
            const changedData = cloneDeep(payments);
            const index = changedData.findIndex((method) => method.purchase_type === purchase_type);
            changedData[index].show = !changedData[index].show;

            const content = accumulatePaymentMethods(changedData, isEdit, showEditModal, showDeleteModal, handleVision);
            setBody(content);
        },
        [isEdit, payments],
    );

    useEffect(() => {
        const content = accumulatePaymentMethods(payments, isEdit, showEditModal, showDeleteModal, handleVision);
        setBody(content);
    }, [handleVision, isEdit, payments]);

    useEffect(() => {
        dispatch(getPayments());
    }, [dispatch]);

    const deletePaymentMethod = () => {
        deletePayment(modals.item.purchase_type, activeProject).then(() => {
            dispatch(getPayments());
        });
        closeModal();
    };

    const createPaymentMethod = (paymentMethod: IPaymentMethod) => {
        addPayment(activeProject.publickey, paymentMethod.purchase_type, paymentMethod.purchase_settings).then(() => {
            successInfo.setSuccessInfo();
            dispatch(getPayments());
        });
    };

    const editPaymentMethod = (paymentMethod: IPaymentMethod) => {
        editPayment(paymentMethod.purchase_type, paymentMethod.purchase_settings, activeProject).then(() => {
            successInfo.setSuccessInfo();
            dispatch(getPayments());
        });
    };

    return (
        <>
            <Table
                tableData={body}
                headings={headings}
                title={window.locales.paymentMethod}
                emptyMessage={window.locales.noPayments}
            >
                <If condition={isEdit}>
                    <div className={poolStyle.extraButtons}>
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: initPayment,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </If>
            </Table>

            {/* Modal add payment method */}
            <PaymentModal
                title={window.locales.addPaymentsMethod}
                action={createPaymentMethod}
                isOpen={modals.createModal}
                item={modals.item}
                close={closeModal}
                buttonName={window.locales.confirm}
            />

            {/* Modal edit payment method */}
            <PaymentModal
                title={window.locales.editPaymentsMethod}
                action={editPaymentMethod}
                isOpen={modals.editModal}
                item={modals.item}
                close={closeModal}
                buttonName={window.locales.confirm}
            />

            {/* Modal delete auth method */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                title={window.locales.deleteRule}
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deletePaymentMethod,
                }}
            >
                {`Do you really want to delete payment method ${modals.item.purchase_type}?`}
            </Modal>
        </>
    );
};

export default Payments;
