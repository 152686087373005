type TokenType = {
    access_token: string;
    refresh_token: string;
    expiry: number;
    token_type: string;
    user_email: string;
};

const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const EXPIRE_TOKEN = "expiry";
const TOKEN_TYPE = "token_type";
const USER_EMAIL = "user_email";

const setTokenInStorage = ({ access_token, refresh_token, expiry, token_type, user_email }: TokenType): void => {
    localStorage.setItem(ACCESS_TOKEN, access_token);
    localStorage.setItem(REFRESH_TOKEN, refresh_token);
    localStorage.setItem(TOKEN_TYPE, token_type);
    localStorage.setItem(EXPIRE_TOKEN, `${expiry}`);
    localStorage.setItem(USER_EMAIL, user_email);
};

const getTokenInStorage = (): TokenType | null => {
    const access_token = localStorage.getItem(ACCESS_TOKEN);
    if (!access_token) return null;
    const refresh_token = localStorage.getItem(REFRESH_TOKEN) ?? "";
    const token_type = localStorage.getItem(TOKEN_TYPE) ?? "";
    const expiry = localStorage.getItem(EXPIRE_TOKEN) ?? "";
    const user_email = localStorage.getItem(USER_EMAIL) ?? "";

    return {
        access_token,
        refresh_token,
        token_type,
        expiry: Number(expiry),
        user_email,
    };
};

const deleteTokenInStorage = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(TOKEN_TYPE);
    localStorage.removeItem(EXPIRE_TOKEN);
    localStorage.removeItem(USER_EMAIL);
    localStorage.clear();
    sessionStorage.clear();
};

export { setTokenInStorage, deleteTokenInStorage, getTokenInStorage };

export type { TokenType };
