import colors from "@common/style/themes/colors";
import typography from "@common/style/themes/typography";
import text from "@common/style/themes/text";
import { breakpoints } from "@common/style/themes/breakpoints";

import stageColors from "./stage";
import defaultColors from "./default";

const main = {
    text,
    typography,
    breakpoints,
};

const theme = {
    defaultTheme: {
        ...main,
        colors: {
            ...colors,
            ...defaultColors,
        },
    },
    stageTheme: {
        ...main,
        colors: {
            ...colors,
            ...stageColors,
        },
    },
};

export default theme;
