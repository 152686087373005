import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";

import { progress } from "@state/progress";
import { If } from "@components/If";

import { ColorLinearProgress } from "./components";

const LinearDeterminate = observer(() => {
    const [valueProgress, setProgress] = useState(20);
    const [isProgress, setIsProgress] = useState(false);
    const refLength = useRef(0);

    useEffect(() => {
        const { lengthRequests } = progress;
        const isFinishedProgress = refLength.current > 0 && lengthRequests <= 0;
        if (lengthRequests > 0) {
            setIsProgress(true);
        }

        if (valueProgress === 90) {
            setTimeout(() => {
                setProgress(0);
                setIsProgress(false);
                progress.resetLength();
            }, 5000);
        }

        if (isFinishedProgress) {
            setTimeout(() => {
                setProgress(100);
            }, 1000);

            setTimeout(() => {
                setIsProgress(false);
                setProgress(20);
            }, 1500);
        }

        const timer =
            lengthRequests &&
            setInterval(() => {
                setProgress((oldProgress) => {
                    const diff = Math.random() * 10;
                    const value = isFinishedProgress ? 100 : Math.min(oldProgress + diff, 100);
                    return value >= 90 ? 90 : value;
                });
            }, 400);

        refLength.current = lengthRequests;

        return () => {
            timer && clearInterval(timer);
        };
    }, [progress.lengthRequests]);

    return (
        <If condition={isProgress}>
            <Box
                sx={{
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    zIndex: 1000,
                }}
            >
                <ColorLinearProgress variant="determinate" value={valueProgress} />
            </Box>
        </If>
    );
});

export { LinearDeterminate };
