import React, { ReactElement, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { pageViewsTracking } from "@common/gaTracking/pageViewsTracking";

import * as localStyle from "../Object.style.scss";
import Header from "./Header";

const ObjectWrapper = (): ReactElement => {
    const location = useLocation();

    useEffect(() => {
        pageViewsTracking(location.pathname);
    }, [location.pathname]);

    return (
        <div className={localStyle.objectPage}>
            <Header />
            <Outlet />
        </div>
    );
};

export default ObjectWrapper;
