export const kFormatterNumber = (num: string): string => {
    const number = Number(num);
    return Math.abs(number) >= 1.0e12
        ? `${Math.round(Math.abs(number) / 1.0e12)} T`
        : Math.abs(Number(num)) >= 1.0e9
          ? `${Math.round(Math.abs(number) / 1.0e9)} B`
          : Math.abs(Number(num)) >= 1.0e6
            ? `${Math.round(Math.abs(number) / 1.0e6)} M`
            : Math.abs(Number(num)) >= 1.0e3
              ? `${Math.round(Math.abs(number) / 1.0e3)} K`
              : `${Math.round(number)}`;
};

export const kFormatterTraffic = (num: string): string =>
    Math.abs(Number(num)) >= 1.0e12
        ? `${Math.round(Math.abs(Number(num)) / 1.0e12)} TiB`
        : Math.abs(Number(num)) >= 1.0e9
          ? `${Math.round(Math.abs(Number(num)) / 1.0e9)} GiB`
          : Math.abs(Number(num)) >= 1.0e6
            ? `${Math.round(Math.abs(Number(num)) / 1.0e6)} MiB`
            : Math.abs(Number(num)) >= 1.0e3
              ? `${Math.round(Math.abs(Number(num)) / 1.0e3)} KiB`
              : `${Math.round(Number(num))} B`;
