import { makeObservable, observable, action } from "mobx";

import { ObjectStoreType } from "@state/object/ObjectStore.types";

const initObject: ObjectStoreType = {
    metadata: {
        namespace: "",
        revision: "",
        hash: "",
        kind: "",
        name: "",
        author: "",
        labels: {},
    },
    data: {},
};

class ObjectStore {
    objectData: ObjectStoreType = initObject;

    constructor() {
        makeObservable(this, {
            objectData: observable,
            updateMetadata: action,
            updateData: action,
            resetObjectData: action,
        });
    }

    updateMetadata(metadata: Record<string, any>) {
        this.objectData.metadata = {
            ...this.objectData.metadata,
            ...metadata,
        };
    }

    updateData(data: Record<string, unknown> | string) {
        if (typeof data === "string") {
            this.objectData.data = data;
        } else if (typeof data === "object") {
            const prevState =
                typeof this.objectData.data === "string" ? JSON.parse(this.objectData.data) : this.objectData.data;
            this.objectData.data = {
                ...prevState,
                ...data,
            };
        }
    }

    resetObjectData() {
        this.objectData = initObject;
    }
}

const objectStore = new ObjectStore();
export { initObject };
export default objectStore;
