import { createSlice } from "@reduxjs/toolkit";

import { IToken } from "@state/token/types";

const initialState: IToken = {
    access_token: "",
    create_time: 0,
    expire_time: 0,
};

const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        getToken(state, action) {},
        setToken(state, action) {
            return action.payload;
        },
        resetToken() {
            return initialState;
        },
    },
});

export const { getToken, setToken, resetToken } = tokenSlice.actions;

export { initialState as initToken };

export default tokenSlice.reducer;
