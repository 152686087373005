import styled from "styled-components";

import { Colors } from "@common/style";
import { IInput } from "@interfaces";

const { red } = Colors;

export const FieldInput = styled.input<IInput>`
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ $isInvalid }) => ($isInvalid ? red : "hsla(0,0%,89.8%,.3)")};
    font-size: 16px;
    box-sizing: border-box;
    padding: 15px 25px;
    width: 100%;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    background: transparent;
    outline: unset;

    :-webkit-autofill {
        -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
    }

    :disabled {
        opacity: 0.5;
    }
`;

export const ButtonSettings = styled.button`
    outline: unset;
    width: 180px;
    height: 50px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.backlight};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    border: unset;
`;
