import React, { useReducer, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@state/index";
import { IconPlus } from "@common/icons";
import { Modal } from "@components/Modal";
import Calendar from "@screens/Export/Calendar";
import { exportData } from "@services/Export";
import * as style from "@screens/Export/ExportActions/style.scss";

import { initialStateExportModal, reducerExportModals, mask, initialStateForm } from "./utils";
import { ExportModals, TForm } from "./types";

const ExportActions = ({ exported }: { exported: () => void }) => {
    const activeProject = useSelector((state: RootState) => state.activeProject) || null;
    const [modals, dispatchModal] = useReducer(reducerExportModals, initialStateExportModal);
    const [showPopUp, setShowPopUp] = useState(false);
    const isSupport = activeProject?.role === "SUPPORT";
    const [form, setForm] = useState(initialStateForm);
    const [disabled, setDisabled] = useState(true);

    const formSubmit = async (type: "users" | "devices" | "sessions") => {
        const from = form.days.from
            ? new Date(`${form.days.from} ${form.time.from.replace(/_/g, "0")}`).getTime()
            : false;
        const till = form.days.till
            ? new Date(`${form.days.till} ${form.time.till.replace(/_/g, "0")}`).getTime()
            : false;

        try {
            await exportData(from, till, mask[type]);
            dispatchModal({
                type: ExportModals.closeModals,
                payload: null,
            });
            exported();
        } catch {
            dispatchModal({
                type: ExportModals.closeModals,
                payload: null,
            });
        }
    };

    const updateSearch = ({ days, time }: TForm) => {
        setForm({
            days: {
                from: days.from,
                till: days.till,
            },
            time: {
                from: time.from,
                till: time.till,
            },
        });

        if (disabled) {
            setDisabled(false);
        }
    };

    const openModal = (modal: ExportModals) => {
        dispatchModal({
            type: modal,
            payload: true,
        });
        setDisabled(true);
    };

    const closeModal = () =>
        dispatchModal({
            type: ExportModals.closeModals,
            payload: null,
        });

    return (
        <div className={style.exportContainer}>
            {!isSupport && (
                <IconPlus
                    label={window.locales.exportAdd}
                    theme="blue flex"
                    onClick={() => setShowPopUp((prev) => !prev)}
                />
            )}
            {showPopUp && (
                <div className={style.popUp}>
                    <div className={style.popUpRow}>
                        <span
                            onClick={() => {
                                openModal(ExportModals.userModal);
                                setShowPopUp((prev) => !prev);
                            }}
                        >
                            {window.locales.exportUsers}
                        </span>
                    </div>
                    <div className={style.popUpRow}>
                        <span
                            onClick={() => {
                                openModal(ExportModals.devicesModal);
                                setShowPopUp((prev) => !prev);
                            }}
                        >
                            {window.locales.exportDevices}
                        </span>
                    </div>
                    <div className={style.popUpRow}>
                        <span
                            onClick={() => {
                                openModal(ExportModals.sessionsModal);
                                setShowPopUp((prev) => !prev);
                            }}
                        >
                            {window.locales.exportSessions}
                        </span>
                    </div>
                </div>
            )}
            <Modal
                isOpen={modals.userModal}
                title={window.locales.exportUsersData}
                onClose={closeModal}
                confirm={{
                    label: window.locales.exportDataSubmit,
                    onConfirm: () => formSubmit("users"),
                }}
            >
                {window.locales.exportUsersDataText}
            </Modal>
            <Modal
                isOpen={modals.devicesModal}
                title={window.locales.exportDevicesData}
                onClose={closeModal}
                confirm={{
                    label: window.locales.exportDataSubmit,
                    onConfirm: () => formSubmit("devices"),
                }}
            >
                {window.locales.exportDevicesDataText}
            </Modal>
            <Modal
                isOpen={modals.sessionsModal}
                title={window.locales.exportSessionsData}
                onClose={closeModal}
                confirm={{
                    label: window.locales.exportDataSubmit,
                    onConfirm: () => formSubmit("sessions"),
                }}
                disabled={disabled}
            >
                <div id="calendar_dropdown" className={`${style.calendarContainer} field`}>
                    <Calendar
                        days={{
                            from: form.days.from,
                            till: form.days.till,
                        }}
                        time={{
                            from: form.time.from,
                            till: form.time.till,
                        }}
                        updateSearch={updateSearch}
                    />
                </div>
            </Modal>
        </div>
    );
};

export { ExportActions };
