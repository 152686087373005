import { store } from "@state/index";
import { progress } from "@state/progress";

const getPortalTokenFromState = (): string => {
    const { token } = store.getState();
    const { access_token } = token;
    if (!access_token) {
        progress.resetLength();
        throw Error("Missing portal token");
    }

    return access_token;
};

export { getPortalTokenFromState };
