import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { TServerPool, TServerPoolForm } from "@screens/Settings/VPN/Offload/types";
import { OffloadPoolModal } from "@screens/Settings/VPN/Offload/PoolModal";
import { If } from "@components/If";
import Table from "@components/Table";
import { RootState } from "@state/index";
import { accumulatePools, isRuleEqualModal } from "@screens/Settings/VPN/Offload/utils";
import { editActiveProject } from "@state/activeProject";
import { Modal } from "@components/Modal";
import * as settingsStyle from "@screens/Settings/style.scss";
import { ERoles } from "@screens/Settings/enums";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { Data } from "@components/Table/types";
import { IconPlus } from "@common/icons";

import { headings } from "./constants";

const initServerPool: TServerPool = {
    server_pool: "",
    app_version: "",
    device_type: [],
    protocol: [],
    proxy: "",
    service: "",
    user_region: "",
};

const initialState = initialStateModal(initServerPool);
const reducerServerPools = reducerModal(initialState);

const Offload = () => {
    const dispatch = useDispatch();
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const [body, setBody] = useState<Data[]>([]);
    const [modals, dispatchModal] = useReducer(reducerServerPools, initialState);

    useEffect(() => {
        if (activeProject.publickey) {
            const { config } = activeProject;
            const { offload = { rules: {} } } = config;
            const { rules = {} } = offload;
            const parsedOffloadConfig = accumulatePools(rules, showEditModal, showDeleteModal);
            setBody(parsedOffloadConfig);
        }
    }, [activeProject]);

    const showEditModal = (rule: TServerPool) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: rule,
        });
    };

    const showDeleteModal = (rule: TServerPool) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: rule,
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initServerPool,
        });
    };

    const addRule = (rule: TServerPoolForm, service: string = "") => {
        try {
            if (activeProject.publickey) {
                const { config } = activeProject;
                const { offload = { rules: {} } } = config;
                const { rules = {} } = offload;

                if (service) {
                    const cloneServerPools: { [key: string]: TServerPoolForm[] } = cloneDeep(rules);

                    if (cloneServerPools[service]) cloneServerPools[service].push(rule);
                    else cloneServerPools[service] = [rule];

                    dispatch(
                        editActiveProject({
                            ...activeProject,
                            config: {
                                ...config,
                                offload: {
                                    rules: {
                                        ...cloneServerPools,
                                    },
                                },
                            },
                        }),
                    );
                }
            }

            closeModal();
        } catch (e: any) {
            window.console.error(e);
        }
    };

    const getIndexRule = (serverPools: { [key: string]: TServerPoolForm[] }) =>
        serverPools[modals.item.service]
            .filter((value: TServerPoolForm) => value)
            .findIndex((value) => modals.item && value.selector && isRuleEqualModal(value, modals.item));

    const editRule = (rule: TServerPoolForm, service: string = "") => {
        if (activeProject.publickey && modals.item) {
            const { config } = activeProject;
            const { offload = { rules: {} } } = config;
            const { rules = {} } = offload;
            const cloneServerPools: { [key: string]: TServerPoolForm[] } = cloneDeep(rules);
            const indexRule = getIndexRule(cloneServerPools);

            if (service !== modals.item.service && indexRule >= 0) {
                cloneServerPools[modals.item.service].splice(indexRule, 1);

                if (cloneServerPools[service]) cloneServerPools[service].push(rule);
                else cloneServerPools[service] = [rule];
            } else {
                indexRule === -1 ? cloneServerPools[service].push(rule) : (cloneServerPools[service][indexRule] = rule);
            }

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config: {
                        ...config,
                        offload: {
                            rules: {
                                ...cloneServerPools,
                            },
                        },
                    },
                }),
            );
        }
    };

    const deleteRule = () => {
        if (activeProject && modals.item.service) {
            const { config } = activeProject;
            const { offload = { rules: {} } } = config;
            const { rules = {} } = offload;
            const cloneServerPools: { [key: string]: TServerPoolForm[] } = cloneDeep(rules);
            const indexRule = getIndexRule(cloneServerPools);

            cloneServerPools[modals.item.service].splice(indexRule, 1);

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config: {
                        ...config,
                        offload: {
                            rules: {
                                ...cloneServerPools,
                            },
                        },
                    },
                }),
            );

            closeModal();
        }
    };

    return (
        <div className={settingsStyle.tabContainer}>
            <Table
                tableData={body}
                headings={headings}
                title="Offload configuration"
                emptyMessage={window.locales.noRules}
            >
                <If condition={activeProject?.role !== ERoles.Support}>
                    <div className={poolStyle.extraButtons}>
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: initServerPool,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </If>
            </Table>
            <OffloadPoolModal
                isOpen={modals.createModal}
                title="Add rule"
                pool={modals.item}
                close={closeModal}
                action={addRule}
                buttonName={window.locales.confirm}
            />
            {/* Edit server pool */}
            <OffloadPoolModal
                buttonName={window.locales.confirm}
                isOpen={modals.editModal}
                title="Edit rule"
                pool={modals.item}
                close={closeModal}
                action={editRule}
            />
            {/* Modal deleting server pool */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                title={window.locales.deleteRule}
                onClose={closeModal}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteRule,
                }}
            >
                {`Do you really want to delete rule for ${modals.item?.server_pool}?`}
            </Modal>
        </div>
    );
};

export default Offload;
