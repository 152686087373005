import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";

const loadPurchases = (uid) =>
    new Promise((resolve, reject) => {
        const request = `partner/subscribers/${uid}/purchase`;
        const access_token = getPortalTokenFromState();

        Network.get(request, {}, access_token)
            .then((response) => resolve(response.response.purchases))
            .catch(() => reject());
    });

const deletePurchases = (uid, pid) =>
    new Promise((resolve, reject) => {
        const request = `partner/subscribers/${uid}/purchase`;
        const access_token = getPortalTokenFromState();
        const params = {
            purchase_id: pid,
        };

        Network.delete(request, params, null, access_token)
            .then((response) => {
                if (response.response.result === "NOT_FOUND") {
                    return reject({ message: "Purchases not found" });
                }
                return resolve(true);
            })
            .catch(() => reject({ message: "Internal server error" }));
    });

const activatePurchase = (uid, pid, activeProject) =>
    new Promise((resolve, reject) => {
        const request = "portal/purchase/recovery";
        const access_token = getPortalTokenFromState();
        const params = {
            publickey: activeProject.publickey,
            purchase_history_id: pid,
        };

        Network.post(request, params, null, access_token)
            .then(() => resolve(true))
            .catch(() => reject({ message: "Internal server error" }));
    });

export { loadPurchases, deletePurchases, activatePurchase };
