import React from "react";
import { createPortal } from "react-dom";

import * as style from "@screens/Panding/style.scss";
import preloaderIcon from "@common/img/svg/preloader-logo-icon.svg";

const Pending = () => {
    const appRoot = document.getElementById("page");
    if (!appRoot) return null;

    return (
        <>
            {createPortal(
                <div className={style.pendingContainer}>
                    <img src={preloaderIcon} alt="img" />
                </div>,
                appRoot,
            )}
        </>
    );
};

export { Pending };
