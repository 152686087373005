type TPool = {
    free_server_pool: string;
    paid_server_pool: string;
    private_pools: string[];
};

enum Pool {
    Free = "free_server_pool",
    Paid = "paid_server_pool",
    Private = "private_pools",
}

export { Pool };
export type { TPool };
