export enum EPage {
    Dashboard = "dashboard",
    Users = "users",
    User = "user",
    ActiveSession = "active_sessions",
    Network = "network",
    Settings = "settings",
    ExportData = "export",
    Log = "event",
    Billing = "billing",
    LicenseKey = "license_keys",
    VPN = "vpn",
    Pool = "pool",
}
