import React, { FC } from "react";

import { Input } from "@common/form";
import { IconPlus } from "@common/icons";
import { IAddInputProps } from "@screens/Settings/Bypass/Bypass.types";
import * as bypassStyle from "@screens/Settings/Bypass/Bypass.style.scss";

const AddByPassDomainInput: FC<IAddInputProps> = ({ message, changeUrl, submitAddDomain, url }) => (
    <div className={bypassStyle.inputContainer}>
        <Input
            maxLength={255}
            label={window.locales.domain}
            theme="dark"
            value={url.current.value}
            onBlur={(e: string) => changeUrl(e)}
        />
        <IconPlus theme="blue" label={window.locales.add} onClick={submitAddDomain} />
        <div className={bypassStyle.error}>{message}</div>
    </div>
);

export { AddByPassDomainInput };
