import { call, put, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";

import { getToken, setToken } from "@state/token";
import { signIn } from "@services/Users/Users";
import { IErrorResponse, IActiveProject } from "@interfaces";
import { errorInfo } from "@state/error";

export function* getTokenSaga(action: PayloadAction<IActiveProject>): SagaIterator {
    try {
        const activeProject = { ...action.payload };
        if (!activeProject.publickey) return;

        const { response } = yield call(signIn, activeProject.publickey, activeProject.privatekey);
        yield put(setToken(response));
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export const TokenSagas = [takeEvery(getToken.type, getTokenSaga)];
