import React, { ReactElement, useCallback, useEffect, useMemo, useReducer } from "react";
import { useParams } from "react-router-dom";

import { AuthGrantType } from "@services/Auth/Grant/Auth.Grant.types";
import authGrantService from "@services/Auth/Grant/Auth.Grant.service";
import { useFilteredData } from "@common/hooks/useFilteredData";
import Table from "@components/Table";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import { accumulateUserGrants, headingUserGrant } from "@screens/Auth/AuthUser/AuthUserGrants/AuthUserGrants.utils";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus } from "@common/icons";
import { Modal } from "@components/Modal";
import { successInfo } from "@state/success";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import AuthUserGrantModal from "@screens/Auth/AuthUser/AuthUserGrants/Modal";

const initGrant: AuthGrantType = {
    grantor: "",
};

const filterFunction =
    (searchValue: string) =>
    ({ grantor }: AuthGrantType): boolean =>
        grantor.toLowerCase().includes(searchValue);

const initialState = initialStateModal(initGrant);
const reducerObjects = reducerModal(initialState);

function AuthUserGrants(): ReactElement {
    const { project: namespace = "", user: user_id = "" } = useParams();
    const [modals, dispatchModal] = useReducer(reducerObjects, initialState);
    authGrantService.setProject(namespace);
    const { setData, setSearch, filteredList } = useFilteredData<AuthGrantType>(filterFunction);

    const getGrants = useCallback(async () => {
        const { data } = await authGrantService.list({ user_id });
        setData(data.grants);
    }, [setData, user_id]);

    useEffect(() => {
        getGrants().then();
    }, [getGrants]);

    const showDeleteModal = (grant: AuthGrantType) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: grant,
        });
    };

    const showEditModal = (grant: AuthGrantType) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: grant,
        });
    };

    const showCreateModal = () => {
        dispatchModal({
            type: EModals.CreateModal,
            payload: initGrant,
        });
    };
    const tableData = useMemo(() => accumulateUserGrants(filteredList, showEditModal, showDeleteModal), [filteredList]);

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initGrant,
        });
    };

    const revokeGrant = async () => {
        try {
            closeModal();
            await authGrantService.revoke({
                user_id,
                grantor: modals.item.grantor,
            });
            successInfo.setSuccessInfo();
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        } finally {
            await getGrants();
        }
    };

    const createGrant = async (grant: AuthGrantType) => {
        try {
            await authGrantService.create({
                user_id,
                ...grant,
            });
            successInfo.setSuccessInfo();
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        } finally {
            await getGrants();
        }
    };

    return (
        <>
            <AuthUserGrantModal
                closeModal={closeModal}
                title={window.locales.addGrant}
                item={modals.item}
                isOpen={modals.createModal}
                action={createGrant}
            />
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.revokeGrant}
                confirm={{
                    label: window.locales.revoke,
                    onConfirm: revokeGrant,
                }}
            >
                Do you want to revoke the grant {modals.item.grantor}?
            </Modal>
            <Table
                tableData={tableData}
                headings={headingUserGrant}
                title="projects"
                emptyMessage={window.locales.noGrants}
            >
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={setSearch} />
                    </div>
                    <button className={buttonStyle.buttonAdd} onClick={showCreateModal}>
                        <IconPlus theme="blue" />
                        {window.locales.add}
                    </button>
                </div>
            </Table>
        </>
    );
}

export default AuthUserGrants;
