import countryList from "country-list";

import StateList from "@constants/states";

const optionsState = Object.entries(StateList).map((key) => {
    const [code, value] = key;
    return {
        value: code,
        label: value,
    };
});

const optionsCountry = Object.keys(countryList.getCodeList()).map((key) => {
    const country = key.toUpperCase();
    return {
        value: country,
        label: country,
    };
});

export { optionsState, optionsCountry };
