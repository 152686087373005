import { useCallback, useEffect, useRef, useState } from "react";

export const useFilteredData = <T>(callback: (searchValue: string) => (value: T) => boolean) => {
    const filteredData = useRef<T[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [filteredList, setFilteredList] = useState<T[]>([]);
    const [data, setData] = useState<T[]>([]);

    useEffect(() => {
        if (data.length === 0) {
            setFilteredList([]);

            return;
        }

        filteredData.current = data;
        if (searchValue) {
            const filterFunction = callback(searchValue);
            const filtered = filteredData.current.filter(filterFunction);

            setFilteredList(filtered);
            return;
        }

        setFilteredList(filteredData.current);
    }, [callback, data, searchValue]);

    const setSearch = useCallback((value: string) => {
        setSearchValue(value.toLowerCase().trim());
    }, []);

    return {
        setSearch,
        filteredList,
        setData,
    };
};
