import { Field, Formik } from "formik";
import React from "react";

import { Modal } from "@components/Modal";
import { ILicenseKey } from "@screens/LicenseKeys/types";
import { numberToIsoString } from "@common/methods/numberToIsoString";
import { TextAreaField, TextField } from "@components/FormikFields";

const ModalLicenseKey = ({
    title,
    isOpen,
    closeModal,
    handleLicenseKey,
    licenseKey,
}: {
    title: string;
    isOpen: boolean;
    closeModal: () => void;
    handleLicenseKey: (newLicenseKey: ILicenseKey) => void;
    licenseKey: ILicenseKey;
}) => (
    <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={title}
        confirm={{
            label: window.locales.confirm,
            onConfirm: () => {},
        }}
        /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
        typeButton="submit"
        nameForm="tokenForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                token: licenseKey.token,
                metadata: licenseKey.metadata ? JSON.stringify(licenseKey.metadata) : "",
                expire_time: numberToIsoString(licenseKey.expire_time),
            }}
            validate={({ token, metadata, expire_time }) => {
                const errors: any = {};
                if (token.length > 32) errors.token = window.locales.incorrectLicenseToken;
                try {
                    typeof metadata === "string" && JSON.parse(metadata);
                } catch (e) {
                    errors.metadata = window.locales.incorrectLicenseMetadata;
                }
                if (Date.now() > Date.parse(expire_time.toString()))
                    errors.expire_time = window.locales.incorrectLicenseDate;

                return errors;
            }}
            onSubmit={({ token, metadata, expire_time }) => {
                const newLicenseKey = {
                    token: token.trim(),
                    metadata: {},
                    expire_time: Date.parse(expire_time.toString()) / 1000,
                };

                if (metadata) {
                    newLicenseKey.metadata = JSON.parse(metadata);
                }

                handleLicenseKey(newLicenseKey);
            }}
        >
            {({ values, handleSubmit }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="tokenForm" onSubmit={handleSubmit}>
                    <section>
                        <Field
                            disabled={!!licenseKey.token}
                            component={TextField}
                            placeholder="0abc094kdlmv89"
                            type="text"
                            name="token"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.metadata}
                            placeholder="Paste data in json"
                            type="text"
                            name="metadata"
                        />
                    </section>
                    <section>
                        <Field component={TextField} type="datetime-local" name="expire_time" />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { ModalLicenseKey };
