import { store } from "@state/index";
import { progress } from "@state/progress";
import base64ToJson from "@common/methods/base64toJSON";
import { deleteTokenInStorage, getTokenInStorage } from "@common/tokenStorage";
import { requestRefreshToken } from "@services/Authorization";

const getFirebaseTokenFromState = async (): Promise<string> => {
    const { account } = store.getState();
    const { firebaseToken, isOkta } = account;
    if (!firebaseToken) {
        progress.resetLength();
        throw Error("Missing firebase token");
    }
    try {
        const now = new Date();
        const { exp } = base64ToJson(firebaseToken.split(".")[1]);
        const isExpired = new Date(exp * 1000) <= now;
        const tokenStorage = getTokenInStorage();
        if (isExpired && tokenStorage && isOkta) {
            const {
                data: { token },
            } = await requestRefreshToken(tokenStorage.refresh_token);
            store.dispatch({
                type: "account/setAccount",
                payload: {
                    ...account,
                    firebaseToken: token.access_token,
                },
            });

            return token.access_token;
        }

        const { currentUser } = window.fb.default.auth();
        if (isExpired && currentUser) {
            const token = await currentUser?.getIdToken(true);
            store.dispatch({
                type: "account/setAccount",
                payload: {
                    ...account,
                    firebaseToken: token,
                },
            });

            return token;
        }

        return firebaseToken;
    } catch {
        deleteTokenInStorage();
        window.location.replace(window.location.origin);
        return "";
    }
};

export { getFirebaseTokenFromState };
