// extracted by mini-css-extract-plugin
var _1 = "style-backPageContainer__zh0cd";
var _2 = "style-bigModalContainer__WcLUb";
var _3 = "style-extraButtons__VN2On";
var _4 = "style-item__vq4jK";
var _5 = "style-nameLink__pxXLC";
var _6 = "style-namePool__pE8RY";
var _7 = "style-permissionContainer__wnGz5";
var _8 = "style-poolHeader__efc7y";
export { _1 as "backPageContainer", _2 as "bigModalContainer", _3 as "extraButtons", _4 as "item", _5 as "nameLink", _6 as "namePool", _7 as "permissionContainer", _8 as "poolHeader" }
