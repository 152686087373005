import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";

const exportData = (from, till, type) =>
    new Promise((resolve, reject) => {
        const access_token = getPortalTokenFromState();
        let tz = "America/Los_Angeles";
        try {
            tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {
            window.console.error(e);
        }

        const request = `partner/report/${type}`;
        const params = {
            tz,
        };

        if (from) params.start_time = from;
        if (till) params.end_time = till;

        Network.post(request, params, null, access_token)
            .then(() => resolve())
            .catch(() => reject());
    });

const getTaskList = () =>
    new Promise((resolve) => {
        const request = "partner/report/list";
        const access_token = getPortalTokenFromState();

        Network.get(request, {}, access_token)
            .then((response) => {
                try {
                    return resolve(response.response.reports);
                } catch (e) {
                    return resolve([]);
                }
            })
            .catch(() => resolve([]));
    });

export { exportData, getTaskList };
