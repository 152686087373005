import { Reducer } from "react";

import { MFAActions } from "@screens/Profile/AccountSecurity/TwoFactoryAuth/types";

const reducer: Reducer<
    { isEdit: boolean; phoneInstalled: boolean; isSentCode: boolean; timer: boolean },
    {
        type: MFAActions;
        text?: string;
    }
> = (state, action) => {
    switch (action.type) {
        case MFAActions.SentCode:
            return {
                isEdit: true,
                phoneInstalled: state.phoneInstalled,
                isSentCode: true,
                timer: true,
            };
        case MFAActions.DisableMFA:
            return {
                isEdit: false,
                phoneInstalled: false,
                isSentCode: false,
                timer: false,
            };
        case MFAActions.EnableMFA:
            return {
                isEdit: false,
                phoneInstalled: true,
                isSentCode: false,
                timer: false,
            };
        case MFAActions.CancelMFA:
            return {
                isEdit: false,
                phoneInstalled: state.phoneInstalled,
                isSentCode: false,
                timer: false,
            };
        case MFAActions.DisableTimer:
            return {
                isEdit: true,
                phoneInstalled: true,
                isSentCode: true,
                timer: false,
            };
        case MFAActions.EditPhone:
            return {
                isEdit: true,
                phoneInstalled: state.phoneInstalled,
                isSentCode: false,
                timer: false,
            };
        default:
            return {
                isEdit: false,
                phoneInstalled: false,
                isSentCode: false,
                timer: false,
            };
    }
};

export default reducer;
