import { Colors } from "@common/style";
import { ICategory, ISelector } from "@screens/Settings/Fireshield/types";
import { validationDomain } from "@common/methods/сheckDomain";

const { lightBlue, white } = Colors;

const customStyles = {
    option: () => ({
        padding: 5,
    }),
    menu: (provided: any) => ({
        ...provided,
        background: "#1A1C23",
        padding: "5px 10px",
    }),
    multiValue: (provided: any) => ({
        ...provided,
        background: lightBlue,
        color: white,
        margin: "8px 2px",
    }),
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: white,
        fontSize: "14px",
    }),
    menuList: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        cursor: "pointer",
    }),
    control: () => ({
        display: "flex",
        minWidth: "176px",
        border: "1px solid #cccccc4d",
        borderRadius: "4px",
        height: "50px",
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: "inherit",
    }),
};

const servicesList = ["bbss", "bitdefender", "securityzones"];

const categories = {
    category: [
        "safe",
        "unsafe",
        "unsafe:malware",
        "unsafe:fraud",
        "unsafe:spam",
        "unsafe:untrusted",
        "unsafe:phishing",
        "custom",
    ],
    type: ["bypass", "vpn", "proxy_peer", "block_dns", "block_alert_page"],
};

const parseServices = (services: string[]) =>
    services.reduce((accumulateServices: ISelector[], item) => {
        if (servicesList.includes(item)) {
            return [
                ...accumulateServices,
                {
                    label: item,
                    value: item,
                },
            ];
        }
        return accumulateServices;
    }, []);

const parseDomains = (domains: { [key: string]: string[] }): ISelector[] =>
    Object.keys(domains).reduce((accumulateServices: ISelector[], item) => {
        const { category } = categories;
        if (category.includes(item)) {
            return [
                ...accumulateServices,
                {
                    label: item,
                    value: item,
                },
            ];
        }
        return accumulateServices;
    }, []);

const parseCategories = (values: ICategory[], type: "category" | "type"): ISelector[] =>
    values.reduce((accumulateValues: ISelector[], item) => {
        if (categories[type].includes(item[type])) {
            return [
                ...accumulateValues,
                {
                    label: item[type],
                    value: item[type],
                },
            ];
        }
        return accumulateValues;
    }, []);

const updateCategories = (getTypes: ISelector[], getCategories: ISelector[]): ICategory[] => {
    const filteredCategories = getCategories.filter(
        (categoryObj: ISelector, index: number) => categoryObj && getTypes[index],
    );

    return filteredCategories.map((obj: any, index: number) => ({
        type: obj.value,
        category: getTypes[index].value,
    }));
};

const updateDomains = (getCategoriesDomainsUrls: { [p: string]: string[] }) => {
    const filteredDomains = Object.entries(getCategoriesDomainsUrls).filter((item) => !!item[1]);

    return filteredDomains.reduce((domainsObj: { [key: string]: string[] }, item) => {
        if (item[1].length > 0) {
            const validDomains = item[1].filter((el) => validationDomain(el));
            return {
                ...domainsObj,
                [item[0]]: validDomains,
            };
        }
        return domainsObj;
    }, {});
};

export { parseCategories, parseServices, customStyles, parseDomains, servicesList, updateCategories, updateDomains };
