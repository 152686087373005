export default (inObj, pn) => {
    const res = [];
    const ks = [];
    for (const k in inObj) {
        if (ks.indexOf(inObj[k][pn]) === -1) {
            ks.push(inObj[k][pn]);
            res.push(inObj[k]);
        }
    }
    return res;
};
