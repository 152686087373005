const envColors = {
    backlight: "#2E8DE5",
    button: {
        bg: "#2E8DE5",
        font: "#fff",
    },
    buttonHover: {
        bg: "#1074D0",
        font: "#fff",
    },
    buttonActive: {
        bg: "#065FB2",
    },
};

export default envColors;
