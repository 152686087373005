import React, { Component } from 'react';

class SmallArrow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : '',
      width: props.width ? props.width : 6,
      height: props.height ? props.height : 4,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    };

    this.callbacs = {};
    this.callbacs.onClick = props.onClick ? props.onClick : () => {};
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      id: props.id ? props.id : '',
      width: props.width ? props.width : 6,
      height: props.height ? props.height : 4,
      theme: props.theme ? props.theme : 'default',
      style: props.style ? props.style : {},
      label: props.label ? props.label : undefined,
    });
  }

  onClick() {
    this.callbacs.onClick();
  }

  render() {
    const style = this.state.style;
    if (!style.height) style.height = `${this.state.height}px`;

    return (
      <div
        id={this.state.id}
        className={`icons iconsStyle2 smallArrow ${this.state.theme}${this.state.label ? ' flex' : ''}`}
        onClick={this.onClick.bind(this)}
        style={style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.state.width}
          height={this.state.height}
          viewBox={`0 0 ${this.state.width} ${this.state.height}`}
        >
          <path fill="none" fillRule="evenodd" strokeWidth="2" d="M6 .065l-3.095 3L0 .25" />
        </svg>
      </div>
    );
  }
}

export default SmallArrow;
