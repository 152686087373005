import React, { ReactElement } from "react";
import { Field, Formik } from "formik";
import { Editor } from "@monaco-editor/react";

import { Modal } from "@components/Modal";
import { TextField } from "@components/FormikFields";
import { monacoEditorOptions } from "@common/constant/monacoOption";
import { ModalType } from "@screens/Features/FeatureModal/FeatureModal.types";
import { AuthGrantType } from "@services/Auth/Grant/Auth.Grant.types";

function AuthUserGrantModal({ title, isOpen, closeModal, action, item }: ModalType<AuthGrantType>): ReactElement {
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            title={title}
            confirm={{
                label: window.locales.confirm,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="tokenForm"
            disabled={false}
        >
            <Formik
                initialValues={{
                    ...item,
                    entitlements: item.entitlements ? JSON.stringify(item.entitlements, undefined, 4) : "",
                }}
                validate={({ grantor }) => {
                    const errors: Record<string, string> = {};
                    if (!grantor) errors.twoFactorNumber = window.locales.invalidFieldEmpty;
                    return errors;
                }}
                onSubmit={({ grantor, entitlements, expires, not_before }) => {
                    const convertedEntitlements: Record<string, unknown> = entitlements ? JSON.parse(entitlements) : {};
                    action({
                        grantor,
                        expires: expires || undefined,
                        not_before: not_before || undefined,
                        entitlements: convertedEntitlements,
                    });

                    closeModal();
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="tokenForm" onSubmit={handleSubmit}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type name"
                                type="text"
                                name="grantor"
                                disabled={item.grantor}
                            />
                        </section>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type not before"
                                type="number"
                                name="not_before"
                            />
                        </section>
                        <section>
                            <Field component={TextField} placeholder="Type expires" type="number" name="expires" />
                        </section>
                        <section>
                            <Editor
                                height="30vh"
                                options={monacoEditorOptions}
                                value={values.entitlements}
                                onChange={(value) => setFieldValue("entitlements", value)}
                                theme="vs-dark"
                                defaultLanguage="json"
                            />
                        </section>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

export default AuthUserGrantModal;
