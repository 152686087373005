import { useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { validate as isValidUUID } from "uuid";

import * as localStyle from "@screens/Auth/Auth.styled.scss";
import * as userStyle from "@screens/Auth/AuthUser/AuthUser.styled.scss";
import getDateUTCString from "@common/methods/getDateUTCString";
import { CustomInput } from "@screens/Landing/Authorization/Input";
import * as buttonStyle from "@components/Button/style.scss";
import { AuthUserCreateRequest, AuthUserDataType, AuthUserType } from "@services/Auth/User/Auth.User.types";
import authUserService from "@services/Auth/User/Auth.User.service";
import { NEW_PAGE } from "@common/constant/urls";
import { If } from "@components/If";
import { Modal } from "@components/Modal";
import { ChangePasswordModal } from "@screens/Auth/AuthUser/Parameters/ChangePasswordModal";
import * as style from "@screens/Profile/AccountSecurity/TwoFactoryAuth/style.scss";
import { ToggleSwitch } from "@components/ToggleSwitch";

const initUser: AuthUserDataType = {
    first_name: "",
    last_name: "",
    id: "",
    project: "",
    username: "",
    create_time: "",
    custom_data: null,
    canceled: false,
};

function AuthUserParameters() {
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    const [userData, setUserData] = useState<AuthUserDataType>(initUser);
    const [canceledUser, setCanceledUser] = useState(false);
    const { project: namespace = "", user = "" } = useParams();
    authUserService.setProject(namespace);

    const getUser = useCallback(async () => {
        if (isValidUUID(user) && user !== NEW_PAGE) {
            const { data } = await authUserService.get({ user_id: user });
            setCanceledUser(data.canceled);
            setUserData(data);
        }
    }, [user]);

    useEffect(() => {
        getUser().then();
    }, [getUser]);

    const updateUser = async (changedUser: AuthUserType) => {
        setIsRequesting(true);
        await authUserService.update(changedUser);
        setIsRequesting(false);
    };

    const createUser = async (changedUser: AuthUserCreateRequest) => {
        setIsRequesting(true);
        await authUserService.create(changedUser);
        setIsRequesting(false);
    };

    const cancelUser = async () => {
        setIsRequesting(true);
        setCanceledUser(true);
        await authUserService.cancel({ user_id: user });
        setIsModalDelete(false);
        setIsRequesting(false);
        await getUser();
    };

    const reactivateUser = async () => {
        setIsRequesting(true);
        setCanceledUser(false);
        await authUserService.reactivate({ user_id: user });
        setIsModalDelete(false);
        await getUser();
        setIsRequesting(false);
    };

    const changePassword = async (password: string) => {
        setIsRequesting(true);
        await authUserService.setPassword({
            user_id: user,
            password,
        });
        setIsChangePassword(false);
        setIsRequesting(false);
    };

    return (
        <div className={localStyle.authPageContainer}>
            <ChangePasswordModal
                isOpen={isChangePassword}
                closeModal={() => setIsChangePassword(false)}
                title={window.locales.changePassword}
                action={changePassword}
                item=""
            />
            <Modal
                isNegative
                isOpen={isModalDelete}
                onClose={() => setIsModalDelete(false)}
                title={window.locales.cancelPoolGroup}
                confirm={{
                    label: window.locales.cancel,
                    onConfirm: cancelUser,
                }}
            >
                Do you want to cancel {user}?
            </Modal>
            <Formik
                initialValues={{
                    ...userData,
                    password: "",
                    create_time: getDateUTCString(userData!.create_time),
                }}
                enableReinitialize
                validate={(values) => {
                    const errors: Record<string, string> = {};
                    const { username } = values;
                    if (!username) errors.username = window.locales.invalidFieldEmpty;
                    return errors;
                }}
                onSubmit={async (values) => {
                    const { username = "", id = "", first_name = "", last_name = "", password = "", canceled } = values;
                    if (user === NEW_PAGE) {
                        await createUser({
                            first_name,
                            last_name,
                            username,
                            project: namespace,
                            canceled,
                            password,
                        });
                    } else {
                        await updateUser({
                            user_id: id,
                            username,
                            first_name,
                            last_name,
                        });
                    }
                }}
            >
                {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit} className={userStyle.authUserContainer}>
                        <Field component={CustomInput} type="text" name="username" placeholder="Username" />
                        <Field component={CustomInput} type="text" name="first_name" placeholder="First name" />
                        <Field component={CustomInput} type="text" name="last_name" placeholder="Last name" />
                        <If condition={user === NEW_PAGE}>
                            <Field component={CustomInput} type="password" name="password" placeholder="Password" />
                        </If>
                        <div className={style.switcherField}>
                            <div />
                            <div className={style.switcher}>
                                <Field
                                    component={ToggleSwitch}
                                    disabled={user === NEW_PAGE}
                                    value={canceledUser}
                                    onClick={() => {
                                        values.canceled ? reactivateUser() : cancelUser();
                                    }}
                                />
                                <div className={style.labelSwitcher}>
                                    {values.canceled ? "User is canceled" : "User is active"}
                                </div>
                            </div>
                        </div>
                        <div className={buttonStyle.groupButtonContainer}>
                            <button type="submit" disabled={isRequesting} className={buttonStyle.buttonSubmit}>
                                {window.locales.save}
                            </button>
                            <button
                                disabled={user === NEW_PAGE || values.canceled}
                                className={buttonStyle.inlineButtonCancel}
                                type="button"
                                onClick={() => {
                                    setIsChangePassword(true);
                                }}
                            >
                                {window.locales.changePassword}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default AuthUserParameters;
