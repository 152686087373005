import React from "react";

import * as style from "@components/Checkbox/style.scss";

const Checkbox = (data: any) => {
    const { field, text } = data;
    return (
        <div className={style.checkboxContainer}>
            <label className={style.checkboxButton}>
                <input id={field.name} className={style.checkboxInput} {...field} type="checkbox" />
                <span className={style.checkmark} />
            </label>
            <label className={style.labelText} htmlFor={field.name}>
                {text}
            </label>
        </div>
    );
};

export default Checkbox;
