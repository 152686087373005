import { Chip } from "@mui/material";
import React from "react";

import { ChipType } from "@screens/Pools/DiscoveryPool.types";
import { ServerType } from "@screens/Pools/Pool/Servers/Servers.types";
import { IconStatus } from "@common/icons";
import { Data } from "@components/Table/types";
import { LabelsServer } from "@components/LabelsServer";

const initServer: ServerType = {
    load: 0,
    enabled: false,
    connect_ip: "",
    name: "",
    active: false,
    country: "",
    domain: "",
    ipv4: false,
    protocols: [],
    ipv6: false,
    ipv4_check: false,
    server_group: "",
    ipv6_check: false,
    priority: 0,
    sessions: 0,
    labels: {
        server_city: "",
        server_country: "",
        server_name: "",
        server_state: "",
        project: "",
    },
};

const serversHeader = [
    {
        isSort: true,
        heading: window.locales.name,
    },
    {
        isSort: true,
        heading: window.locales.load,
    },
    {
        isSort: true,
        heading: window.locales.sessions,
    },
    {
        isSort: false,
        heading: window.locales.label,
    },
    {
        isSort: false,
        heading: window.locales.server_group,
    },
    {
        isSort: false,
        heading: window.locales.active,
    },
    {
        isSort: false,
        heading: window.locales.enabled,
    },
];

const accumulateServers = (objects: ServerType[], setSelectedServer: (serverName: string) => void): Data[] =>
    objects.map((server) => {
        const { name, active, labels, load, server_group, sessions, enabled } = server;
        const status: ChipType = {
            color: "error",
            label: "Error",
        };
        status.label = load.toFixed(2).toString();
        status.color = load <= 0.7 ? "success" : load <= 1 ? "warning" : "error";

        return {
            name,
            load: {
                sortColumn: status.label,
                component: (
                    <Chip color={status.color} disabled={false} size="small" variant="filled" label={status.label} />
                ),
            },
            sessions,
            label: {
                component: LabelsServer(labels),
            },
            server_group,
            active: {
                component: (
                    <IconStatus
                        status={active ? "on" : "off"}
                        label={active ? window.locales.active : window.locales.disabled}
                    />
                ),
            },
            enabled: {
                component: (
                    <IconStatus
                        status={enabled ? "on" : "off"}
                        label={enabled ? window.locales.enabled : window.locales.disabled}
                    />
                ),
            },
            event: () => setSelectedServer(name),
        };
    });

export { accumulateServers, initServer, serversHeader };
