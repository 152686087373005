import { call, put, select, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";

import { getUser, getUsersData, loadMoreUsers, setUser, setUsers } from "@state/users";
import { getUsers, loadRegularUsers } from "@services/Users/Users";
import RootState from "@state/interfaces";
import { getTraffic, setTraffic } from "@state/traffic";
import { loadTraffic } from "@services/Traffic";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";

const selectActiveProject = (state: RootState) => state.activeProject;
const selectListOfUsers = (state: RootState) => state.users.listOfUsers;

export function* getUsersSaga(action: PayloadAction<string>): SagaIterator {
    try {
        const activeProject = yield select(selectActiveProject);
        if (!activeProject.publickey) return;

        const users = yield call(getUsers, action.payload, activeProject);
        yield put(setUsers(users));
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* getMoreUsersSaga(action: PayloadAction<number>): SagaIterator {
    try {
        const activeProject = yield select(selectActiveProject);
        const listOfUsers = yield select(selectListOfUsers);
        const response = yield call(getUsers, undefined, activeProject, action.payload);
        yield put(setUsers([...listOfUsers, ...response]));
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* getUserSaga(action: PayloadAction<string>): SagaIterator {
    try {
        if (!action.payload) return;
        const activeProject = yield select(selectActiveProject);
        if (!activeProject.publickey) return;
        const response = yield call(() => loadRegularUsers(`/${action.payload}`));
        const [user] = response;
        yield put(getTraffic(user));
        yield put(setUser(user));
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export function* getTrafficSaga(action: any): SagaIterator {
    try {
        const { id } = action.payload;
        const response = yield call(() => loadTraffic(id));
        yield put(setTraffic(response));
    } catch (e: unknown | IErrorResponse) {
        const error = e as IErrorResponse;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });
    }
}

export const UsersSagas = [
    takeEvery(getUsersData.type, getUsersSaga),
    takeEvery(loadMoreUsers.type, getMoreUsersSaga),
    takeEvery(getUser.type, getUserSaga),
    takeEvery(getTraffic.type, getTrafficSaga),
];
