import React from "react";

import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";

const SuccessReset = ({ email }: { email: string }) => (
    <div className={authorizationStyle.formCenter}>
        <div className={authorizationStyle.signFormContainer}>
            <div className={authorizationStyle.titleContainer}>
                <div className={authorizationStyle.title}>Success!</div>
                <div className={authorizationStyle.text}>
                    You set new password for <span>{email}</span>
                </div>
            </div>
        </div>
    </div>
);

export { SuccessReset };
