import React, { FC } from "react";

import { ICheckbox } from "@interfaces";
import { Checkmark, FieldCheckbox } from "@screens/Settings/Fireshield/components";
import * as fireshieldStyle from "@screens/Settings/Fireshield/style.scss";

const CustomCheckbox: FC<ICheckbox> = ({ onChange, checked }) => (
    <label className={fireshieldStyle.checkboxContainer}>
        <FieldCheckbox type="checkbox" onChange={onChange} checked={checked} />
        <Checkmark />
    </label>
);

export { CustomCheckbox };
