import React from "react";

import { Data } from "@components/Table/types";
import { TargetIcon } from "@components/TargetIcon";
import * as table from "@components/Table/style.scss";
import iconTrash from "@common/img/svg/trash-icon.svg";

const checkUrl = (activeProject: any) => {
    const fileUrl = activeProject.config.files.bpl;
    if (!fileUrl) return "";

    return fileUrl;
};

const accumulateByPassDomains = (data: string[], onDelete: (i: number) => void): Data[] =>
    data.map((itemDomain, index) => ({
        domain: itemDomain,
        buttons: {
            component: (
                <div className={table.buttonsColumn}>
                    <TargetIcon onClick={() => onDelete(index)}>
                        <img src={iconTrash} alt="img" />
                    </TargetIcon>
                </div>
            ),
        },
    }));

const headings = [
    {
        isSort: false,
        heading: "domain",
    },
    {
        isSort: false,
        heading: "",
    },
];

export { checkUrl, headings, accumulateByPassDomains };
