import axios from "axios";

import { getFirebaseTokenFromState } from "@common/getFirebaseTokenFromState";
import Network from "@common/Network";
import { deleteRequestByFirebaseToken, getRequestByFirebaseToken } from "@common/requests/requestsAPI";

axios.defaults.baseURL = `/api/${process.env.CURRENT_API}/`;
const sendInviteUserRequest = async (user_email: string, admin_role: string, project_id: string) => {
    const access_token = await getFirebaseTokenFromState();
    const request = "portal/project/invite";

    const body = {
        project_id,
        admin_role,
        user_email,
    };

    return Network.post(request, {}, body, access_token);
};

const getUserInvitationRequest = (code: string) =>
    axios.get("portal/project/invite", {
        params: { code },
    });

const deleteUserInvitationRequest = (code: string, project_id: string) =>
    deleteRequestByFirebaseToken("portal/project/invite", {
        code,
        project_id,
    });

const getListInvitationRequest = (project_id: string) =>
    getRequestByFirebaseToken<{ invitations: [] }>("portal/project/invite/list", {
        select_expired: true,
        project_id,
    });

const acceptUserInvitationRequest = (code: string, firebaseToken = "") => {
    const access_token = firebaseToken || getFirebaseTokenFromState();
    const request = "portal/project/invite/accept";
    const params = {
        code,
    };

    return Network.get(request, params, access_token);
};

export {
    sendInviteUserRequest,
    getUserInvitationRequest,
    deleteUserInvitationRequest,
    getListInvitationRequest,
    acceptUserInvitationRequest,
};
