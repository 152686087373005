enum Form {
    SignIn,
    ForgotPwd,
    ResetPwd,
    Code,
    Phone,
    SuccessResetPWD,
    SuccessSentPWD,
    ErrorLink,
    UserInvitation,
    SignUp,
    ExpiredPwd,
    VerifyEmail,
}

type TInvite = {
    is_new: boolean;
    user_email: string;
    project_id: string;
    is_invited: boolean;
};

export type { TInvite };

export { Form };
