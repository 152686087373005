import React from "react";

import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import * as buttonStyle from "@components/Button/style.scss";

const SignUpInvitation = ({ email, goToSignup }: { email: string; goToSignup: () => void }) => (
    <div className={authorizationStyle.formCenter}>
        <div className={authorizationStyle.signFormContainer}>
            <div className={authorizationStyle.titleContainer}>
                <div className={authorizationStyle.title}>
                    Invitation <br /> accepted
                </div>
                <div className={authorizationStyle.text}>
                    We have created an account for <span>{email}</span> <br />
                    Continue and create your password
                </div>
            </div>
            <div className={authorizationStyle.buttonContainer}>
                <button className={buttonStyle.buttonSubmit} onClick={goToSignup}>
                    Create password
                </button>
            </div>
        </div>
    </div>
);

export { SignUpInvitation };
