import styled from "styled-components";

import { IModalContentProps } from "@components/Modal/types";

const ModalContent = styled.div<IModalContentProps>`
    position: absolute;
    box-sizing: border-box;
    color: #fff;
    padding: 28px;
    border-radius: 12px;
    min-width: 472px;
    max-width: 560px;
    background-color: ${({ theme }) => theme.colors.bgModal};
    opacity: ${({ $isOpen }) => {
        switch ($isOpen) {
            case true:
                return "1";
            default:
                return "0";
        }
    }};
    transition: ${({ $isOpen }) => {
        switch ($isOpen) {
            case true:
                return "all linear 0.25s;";
            case false:
                return "all linear 0.25s;";
            default:
                return "";
        }
    }};
`;

export { ModalContent };
