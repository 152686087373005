import { NavigateFunction } from "react-router/dist/lib/hooks";
import { Chip } from "@mui/material";
import React from "react";

import {
    DiscoveryPoolStatus,
    DiscoveryPoolStatusData,
    ChipType,
    DiscoveryTableType,
} from "@screens/Pools/DiscoveryPool.types";
import { GENERAL_NAMESPACE, ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import { getAge } from "@common/getAge";
import * as table from "@components/Table/style.scss";
import { If } from "@components/If";
import { TargetIcon } from "@components/TargetIcon";
import iconTrash from "@common/img/svg/trash-icon.svg";
import { IObjectsRequest } from "@screens/Objects/Objects.types";

const discoveryPoolConfigHeader = [
    {
        isSort: true,
        heading: window.locales.name,
    },
    {
        isSort: true,
        heading: window.locales.load,
    },
    {
        isSort: true,
        heading: window.locales.total_servers,
    },
    {
        isSort: true,
        heading: window.locales.available_servers,
    },
    {
        isSort: true,
        heading: window.locales.disabled_servers,
    },
    {
        isSort: true,
        heading: window.locales.unavailable_servers,
    },
    {
        isSort: true,
        heading: window.locales.age,
    },
];

const initDiscoveryPoolStatus: DiscoveryPoolStatus = {
    metadata: {
        labels: {},
        namespace: "",
        revision: "",
        hash: "",
        kind: "",
        name: "",
        author: "",
    },
};

const dataDiscoveryPoolConfig: IObjectsRequest = {
    kind: ObjectEnum.DISCOVERY_POOL_CONFIG,
    metadata_only: false,
    namespace: GENERAL_NAMESPACE,
};

const dataDiscoveryPoolStatus: IObjectsRequest = {
    kind: ObjectEnum.DISCOVERY_POOL_STATUS,
    metadata_only: false,
    namespace: GENERAL_NAMESPACE,
};

const defaultStatusPool: DiscoveryPoolStatusData = {
    name: "",
    disabled_servers: 0,
    avg_load: 0,
    available_servers: 0,
    description: "",
    total_servers: 0,
    unavailable_servers: 0,
    version: 0,
};

const accumulateDiscoveryPoolConfig = (
    objects: DiscoveryPoolStatus[],
    navigate: NavigateFunction,
    onDelete: (object: DiscoveryPoolStatus) => void,
    permissions: string[],
): DiscoveryTableType[] =>
    objects.map(({ metadata, data = defaultStatusPool }) => {
        const { name, updateTime = "" } = metadata;
        const status: ChipType = {
            color: "error",
            label: "Error",
        };
        const { avg_load, available_servers, total_servers, disabled_servers, unavailable_servers } = data;

        if (avg_load !== undefined) {
            status.label = avg_load.toFixed(2).toString();
            status.color = avg_load <= 0.7 ? "success" : avg_load <= 1 ? "warning" : "error";
        }

        if (available_servers === 0) {
            status.color = "error";
            status.label = "empty";
        }

        const userCanDelete = permissions.includes(PermissionEnum.DELETE);

        const row: DiscoveryTableType = {
            name,
            load: {
                sortColumn: status.label,
                component: (
                    <Chip color={status.color} disabled={false} size="small" variant="filled" label={status.label} />
                ),
            },
            total_servers: total_servers ?? "",
            available_servers: available_servers ?? "",
            disabled_servers: disabled_servers ?? "",
            unavailable_servers: unavailable_servers ?? "",
            age: {
                sortColumn: Number(updateTime),
                component: getAge(updateTime),
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <If condition={userCanDelete}>
                            <TargetIcon
                                onClick={() =>
                                    onDelete({
                                        metadata,
                                        data,
                                    })
                                }
                            >
                                <img src={iconTrash} alt="img" />
                            </TargetIcon>
                        </If>
                    </div>
                ),
            },
            event: () => navigate(metadata.name),
        };

        return row;
    });

export {
    discoveryPoolConfigHeader,
    accumulateDiscoveryPoolConfig,
    initDiscoveryPoolStatus,
    dataDiscoveryPoolConfig,
    dataDiscoveryPoolStatus,
    defaultStatusPool,
};
