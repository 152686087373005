import { progress } from "@state/progress";
import { errorInfo } from "@state/error";
import { QUERY_RANGE_REQUEST } from "@services/Graphs";
import { deleteTokenInStorage } from "@common/tokenStorage";

const requestWithoutNotification = [QUERY_RANGE_REQUEST];

class Network {
    static get(url, params, authHeader) {
        return Network.request("GET", url, params, null, authHeader);
    }

    static post(url, params, body, authHeader) {
        return Network.request("POST", url, params, body, authHeader);
    }

    static put(url, params, body, authHeader) {
        return Network.request("PUT", url, params, body, authHeader);
    }

    static delete(url, params, body, authHeader) {
        return Network.request("DELETE", url, params, body, authHeader);
    }

    static patch(url, params, body, authHeader) {
        return Network.request("PATCH", url, params, body, authHeader);
    }

    static request(method, url, params, body, authHeader = "") {
        return new Promise(async (resolve, reject) => {
            const isNotification = !requestWithoutNotification.includes(url);
            const p = [];
            for (const k in params) {
                p.push(`${k}=${encodeURIComponent(params[k])}`);
            }
            if (p.length > 0) {
                url = url.indexOf("?") === -1 ? `${url}?${p.join("&")}` : `${url}&${p.join("&")}`;
            }

            const request = new XMLHttpRequest();

            request.onprogress = ({ lengthComputable, total, loaded }) => {
                if (lengthComputable) {
                    loaded !== total && progress.decreaseLength();
                }
            };

            request.onloadend = () => {
                progress.decreaseLength();
            };

            request.open(method, `/api/${process.env.CURRENT_API}/${url}`);
            progress.increaseLength();

            request.setRequestHeader("Authorization", `Bearer ${authHeader}`);

            request.timeout = 10000;

            request.onload = async () => {
                let response;
                try {
                    response = JSON.parse(request.responseText);
                } catch (e) {
                    response = request.responseText ? request.responseText : "";
                }

                if (request.status >= 200 && request.status < 300) {
                    resolve({
                        success: true,
                        status: request.status,
                        response,
                    });
                } else {
                    if (request.status === 401) {
                        deleteTokenInStorage();
                        window.location.replace(window.location.origin);
                    }

                    const message =
                        response && response.error
                            ? response.error
                            : response && response.result
                              ? response.result
                              : window.locales.internalServerError;

                    isNotification &&
                        errorInfo.setErrorInfo({
                            title: request.status,
                            description: message,
                        });

                    reject({
                        success: false,
                        status: request.status,
                        response,
                        message,
                    });
                }
            };

            request.onerror = () => {
                isNotification &&
                    errorInfo.setErrorInfo({
                        title: request.status,
                        description: window.locales.internalServerError,
                    });

                reject({
                    success: false,
                    status: request.status,
                    message: window.locales.internalServerError,
                });
            };

            request.ontimeout = () => {
                progress.decreaseLength();

                isNotification &&
                    errorInfo.setErrorInfo({
                        title: request.status,
                        description: "ComponentNetwork timeout",
                    });

                reject({
                    success: false,
                    status: request.status,
                    message: "ComponentNetwork timeout",
                });
            };

            if (body && typeof body === "object") {
                if (body.isFile && typeof body.file !== "undefined") {
                    try {
                        const formData = new FormData();
                        formData.append("file", new Blob([body.file], { type: "text/plain" }), "data");
                        body = formData;
                    } catch (e) {
                        body = "";
                        isNotification &&
                            errorInfo.setErrorInfo({
                                description: e.message,
                            });
                    }
                } else {
                    try {
                        body = JSON.stringify(body);
                        request.setRequestHeader("Content-type", "application/json;charset=UTF-8");
                        request.setRequestHeader("Accept", "application/json, text/plain, */*");
                    } catch (e) {
                        body = "";
                    }
                }
            } else if (body && typeof body === "string") {
                request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            }

            request.send(body);
        });
    }
}

export default Network;
