import { Field, Formik } from "formik";
import React from "react";

import { Modal } from "@components/Modal";
import { TextAreaField, TextField } from "@components/FormikFields";
import { IPoolGroup } from "@screens/Network/Pool/Servers/types";

const OptimalLocationModal = ({
    title,
    isOpen,
    closeModal,
    action,
    poolGroup,
}: {
    title: string;
    isOpen: boolean;
    closeModal: () => void;
    action: (item: IPoolGroup) => void;
    poolGroup: IPoolGroup;
}) => (
    <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={title}
        confirm={{
            label: window.locales.confirm,
            onConfirm: () => {},
        }}
        /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
        typeButton="submit"
        nameForm="tokenForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                name: poolGroup.name,
                request_selector: poolGroup.request_selector,
                server_selector: poolGroup.server_selector,
                description: poolGroup.description,
                priority: poolGroup.priority,
            }}
            validate={({ name, request_selector, server_selector, priority }) => {
                const errors: any = {};
                const { emptyName, invalidConfig, incorrectValue } = window.locales;
                if (!name) errors.name = emptyName;
                try {
                    typeof server_selector === "string" && JSON.parse(server_selector);
                } catch (e) {
                    errors.server_selector = invalidConfig;
                }

                try {
                    typeof request_selector === "string" && JSON.parse(request_selector);
                } catch (e) {
                    errors.request_selector = invalidConfig;
                }

                if (Number.isNaN(Number(priority))) errors.priority = incorrectValue;

                return errors;
            }}
            onSubmit={({ name, request_selector, server_selector, description, priority }) => {
                const values: IPoolGroup = {
                    name,
                    server_selector:
                        typeof server_selector === "string" ? JSON.parse(server_selector) : server_selector,
                    request_selector:
                        typeof request_selector === "string" ? JSON.parse(request_selector) : request_selector,
                    description,
                    priority: Number(priority),
                };

                action(values);
                closeModal();
            }}
        >
            {({ values, handleSubmit }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="tokenForm" onSubmit={handleSubmit}>
                    <section>
                        <Field
                            component={TextField}
                            placeholder="Type name"
                            type="text"
                            name="name"
                            disabled={poolGroup.name}
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.request_selector}
                            placeholder="Type selector"
                            type="text"
                            name="request_selector"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.server_selector}
                            placeholder="Type server selector"
                            type="text"
                            name="server_selector"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.description}
                            placeholder="Type description"
                            type="text"
                            name="description"
                        />
                    </section>
                    <section>
                        <Field component={TextField} placeholder="Type priority" type="text" name="priority" />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { OptimalLocationModal };
