import React from "react";

import * as table from "@components/Table/style.scss";

const LabelsCountry = (country: string, city: string) => (
    <div>
        <div className={`${table.tableTagName} tagField`}>
            {window.locales.country}:{country && <div className={table.tableTagCountries}>{country}</div>}
        </div>
        <div className={`${table.tableTagName} tagField`}>
            {window.locales.location}:{city && <div className={table.tableTagCountries}>{city}</div>}
        </div>
    </div>
);

export default LabelsCountry;
