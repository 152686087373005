import React, { ReactElement, useCallback } from "react";
import { useParams } from "react-router-dom";

import { getObjectByKindRequest } from "@services/Objects";
import { GENERAL_NAMESPACE, ObjectEnum } from "@screens/Objects/Object/Object.constants";
import base64ToJson from "@common/methods/base64toJSON";
import { ServerType } from "@screens/Pools/Pool/Servers/Servers.types";
import Table from "@components/Table";
import { serversHeader } from "@screens/Pools/Pool/Servers/Servers.utils";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import useServer from "@screens/Pools/Pool/useServer";
import ServerStatusModal from "@screens/Pools/Pool/Servers/ServerStatusModal";

const Servers = (): ReactElement => {
    const { pool = "" } = useParams();

    const getDiscoveryPoolData = useCallback(async () => {
        const { data } = await getObjectByKindRequest({
            kind: ObjectEnum.DISCOVERY_POOL_SERVERS,
            name: pool,
            metadata_only: false,
            namespace: GENERAL_NAMESPACE,
        });

        if (!data || !data.object) {
            return;
        }

        const { servers = [] } = base64ToJson(data.object.data);
        setGroupsServers(servers as ServerType[]);
    }, [pool]);

    const { setGroupsServers, tableData, searchCallback, selectedServerStatus, resetSelectedServerStatus } =
        useServer(getDiscoveryPoolData);

    return (
        <>
            <ServerStatusModal item={selectedServerStatus} closeModal={resetSelectedServerStatus} />
            <Table
                tableData={tableData}
                headings={serversHeader}
                title={window.locales.servers}
                emptyMessage={window.locales.noServers}
            >
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={searchCallback} />
                    </div>
                </div>
            </Table>
        </>
    );
};

export default Servers;
