import styled from "styled-components";

import iconCalendar from "@common/img/svg/calendar-icon.svg";
import { Colors } from "@common/style";

const { negative, _80 } = Colors;

const TextAreaFieldInput = styled.textarea<{ $error: boolean; $height: string }>`
    height: ${({ $height }) => $height};
    resize: none;
    overflow-y: auto;
    outline: 0;
    border: 0;
    width: 100%;
    font-size: 16px;
    padding: 12px;
    border-radius: 8px;
    color: ${({ theme, $error }) => ($error ? theme.colors.negative : theme.colors.white)};
    background: ${({ theme, $error }) => ($error ? theme.colors.input.text.error : theme.colors.input.text.default)};

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
        -webkit-box-shadow: ${({ theme }) => `0 0 0 1000px ${theme.colors.input.text.default} inset`};
    }

    :hover {
        background: ${({ theme }) => theme.colors.input.text.hover};
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors._20};
    }

    :disabled {
        background: ${({ theme }) => theme.colors.input.text.disabled};
        color: ${({ theme }) => theme.colors._20};
    }
`;

const TextFieldInput = styled.input<{ $error: boolean }>`
    outline: 0;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    padding: 12px;
    border-radius: 8px;
    color: ${({ theme, $error }) => ($error ? theme.colors.negative : theme.colors.white)};
    background: ${({ theme, $error }) => ($error ? theme.colors.input.text.error : theme.colors.input.text.default)};

    &::-webkit-calendar-picker-indicator {
        background-image: url("${iconCalendar}");
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
        -webkit-box-shadow: ${({ theme }) => `0 0 0 1000px ${theme.colors.input.text.default} inset`};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors._20};
    }

    &:hover {
        background: ${({ theme }) => theme.colors.input.text.hover};
    }

    &:disabled {
        background: ${({ theme }) => theme.colors.input.text.disabled};
        color: ${({ theme }) => theme.colors._20};
    }
`;

const NameContainer = styled.span<{ $isError: boolean }>`
    color: ${({ $isError }) => ($isError ? negative : _80)};
    line-height: 16px;
    font-size: 12px;
    margin-bottom: 4px;
`;

export { TextFieldInput, TextAreaFieldInput, NameContainer };
