import { createSlice } from "@reduxjs/toolkit";

const trafficSlice = createSlice({
    name: "traffic",
    initialState: null,
    reducers: {
        getTraffic(user: any, action: any) {},
        setTraffic(state, action) {
            return action.payload;
        },
    },
});

export const { getTraffic, setTraffic } = trafficSlice.actions;

export default trafficSlice.reducer;
