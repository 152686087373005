import React from "react";

import * as commonStyle from "@common/style/style.scss";

export const Colors = {
    blue: "#00A2FF",
    yellow: "#FFA823",
    red: "#FF0001",
    dark: "#232A30",
    grey: "#2E3740",
    white: "#FFFFFF",
    orange: "#F2726C",
    lightGrey: "#5B5F64",
    lightOrange: "#FE8B85",
    green: "#27AE60",
    black: "#000000",
    lightBlue: "#2EA6F8",
    landingBlue: "#1E306E",
    landingLightBlue: "#47C1FD1A",
    nightshade: "#3E3B57",
    mediumGrey: "#68717e",
    negative: "#ED4055",
    _80: "#FFFFFFCC",
};

export const CustomErrorMessage = (msg: any) => <div className={commonStyle.errorMessageContainer}>{msg.children}</div>;
