declare global {
    interface Window {
        setLocales: (locales: string) => void;
        locales: Readonly<Record<string, any>>;
        data: {
            projectSearch: string;
        };
        config: any;
        user: {
            authorized: boolean;
            phoneNumber: string;
            firebaseToken: string;
            infoFromFirebase: any;
            uid: string;
            name: string;
        };
        fb: any;
        storage: any;
        isBusiness: boolean;
    }
}

export enum EAuthorizationStatus {
    Authorized = 0,
    Unauthorized = 1,
    Pending = 2,
}

export interface IActiveProject {
    privatekey: string;
    config: string;
    publickey: string;
    current_user_role: {
        permissions: any;
    };
}

export interface IErrorResponse {
    status: string;
    message: string;
    code: string;
}

export interface IInput {
    $isInvalid: boolean;
}

export interface ICheckbox {
    onChange: () => void;
    checked: boolean;
}

export interface IFireShieldItem {
    label: string;
    value: string;
}

export type TSearchProps = {
    callback: (val: string) => void;
};

export interface IHidden {
    currentPwd: boolean;
    newPwd: boolean;
    rePwd: boolean;
}

export const enum ECode {
    Reset = "oobCode",
    Invite = "invite_code",
    Code = "code",
    State = "state",
    Error = "error",
    ErrorDescription = "error_description",
}
