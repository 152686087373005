import React, { useState } from "react";
import { Field, Formik } from "formik";

import { CustomInput } from "@screens/Landing/Authorization/Input";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { requestCheckProvider } from "@services/Authorization";
import { validationEmail } from "@common/methods/checkEmail";

type TSignInWithOkta = {
    goToForgotPwd: () => void;
    changeForm: (email: string) => void;
    email: string;
};

const SignInWithOkta = ({ goToForgotPwd, changeForm, email }: TSignInWithOkta) => {
    const [isError, setIsError] = useState(false);
    const [isProcess, setIsProcess] = useState(false);

    const goToSignIn = async (email: string) => {
        setIsProcess(true);

        try {
            const {
                data: { redirect_url },
            } = await requestCheckProvider(email, window.location.origin);
            if (!redirect_url) {
                changeForm(email);
                return;
            }

            window.location.replace(redirect_url);
        } catch (e) {
            setIsProcess(false);
        }
    };

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Sign In</div>
                </div>
                <Formik
                    initialValues={{
                        email,
                    }}
                    validate={(values) => {
                        const errors: any = {};
                        setIsError(false);
                        if (!values.email) errors.email = window.locales.invalidEmptyEmail;
                        if (!validationEmail(values.email)) errors.email = window.locales.invalidEmail;
                        return errors;
                    }}
                    onSubmit={({ email }) => goToSignIn(email)}
                >
                    {({ values, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                            <div className={authorizationStyle.fieldContainer}>
                                <Field
                                    dataTestId="input.email"
                                    data-test-id="input.email"
                                    component={CustomInput}
                                    value={values.email}
                                    type="text"
                                    name="email"
                                    placeholder="Enter your email"
                                    autoComplete="username"
                                />
                            </div>
                            {isError && (
                                <div className={authorizationStyle.errorContainer}>
                                    {window.locales.contactAdministrator}
                                </div>
                            )}
                            <div className={authorizationStyle.infoContainer}>
                                <span>Forgot your password?</span>
                                <div className={authorizationStyle.buttonResetPassword} onClick={goToForgotPwd}>
                                    Reset password
                                </div>
                            </div>
                            <div className={authorizationStyle.buttonContainer}>
                                <button
                                    className={buttonStyle.buttonSubmit}
                                    disabled={isProcess}
                                    type="submit"
                                    data-test-id="button.signin"
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className={authorizationStyle.notificationContainer}>
                                By proceeding, I acknowledge that I have read and accept&nbsp;
                                <a target="_blank" href="https://www.aura.com/legal" rel="noreferrer">
                                    Terms of Service
                                </a>
                                &nbsp;and the&nbsp;
                                <a target="_blank" href="https://www.aura.com/legal/privacy-policy" rel="noreferrer">
                                    Privacy Policy
                                </a>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SignInWithOkta;
