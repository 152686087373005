import React, { useRef, useState } from "react";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import { FormikErrors } from "formik/dist/types";

import { resetPassword, signIn } from "@services/firebase";
import { If } from "@components/If";
import { CheckMark } from "@components/CheckMark";
import { RootState } from "@state/index";
import * as twoFactoryAuthStyle from "@screens/Profile/AccountSecurity/TwoFactoryAuth/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import * as profileStyle from "@screens/Profile/style.scss";
import { TextField } from "@components/FormikFields";
import { ModalCode } from "@screens/Profile/AccountSecurity/ChangePassword/ModalCode";
import { isValidPwd } from "@common/isValidPwd";
import { errorInfo } from "@state/error";

export const ChangePassword = () => {
    const { name } = useSelector((state: RootState) => state.account);
    const [isChangedPassword, setIsChangedPassword] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const refResolver = useRef(null);
    const verificationId = useRef("");
    const [codeModal, setCodeModal] = useState(false);
    const recaptchaVerifier = useRef(null);
    const captchaRef = useRef(null);
    const [isErrorCode, setErrorCode] = useState(false);

    const goToSignIn = async (
        email: string,
        password: string,
        setErrors: (errors: FormikErrors<any>) => void,
        resetForm: any,
    ) => {
        signIn(email.toLowerCase(), password)
            .then(async () => {
                await window.fb.default.auth().currentUser.updatePassword(password);
                resetForm({});
            })
            .catch((error) => {
                const { code, resolver, message } = error;
                errorInfo.setErrorInfo({
                    title: code,
                    description: message,
                });

                if (code === "auth/multi-factor-auth-required") {
                    recaptchaVerifier.current = new window.fb.default.auth.RecaptchaVerifier(captchaRef.current, {
                        size: "invisible",
                    });
                    const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
                    const [multiFactorHint] = resolver.hints;
                    phoneAuthProvider
                        .verifyPhoneNumber(
                            {
                                multiFactorHint,
                                session: resolver.session,
                            },
                            recaptchaVerifier.current,
                        )
                        .then((value: string) => {
                            verificationId.current = value;
                        })
                        .catch(() => {
                            // @ts-ignore
                            window.grecaptcha.reset(captchaRef.current);
                        });
                    setCodeModal(true);
                    refResolver.current = resolver;
                } else {
                    setErrors({
                        current_password: window.locales.invalidPwd,
                    });
                }
            });
    };

    const updatePassword = async (code: string, password: string, setErrors: any, resetForm: any) => {
        try {
            const cred = window.fb.default.auth.PhoneAuthProvider.credential(verificationId.current, code);
            const multiFactorAssertion = window.fb.default.auth.PhoneMultiFactorGenerator.assertion(cred);
            if (!refResolver.current) return;
            // @ts-ignore
            await refResolver.current.resolveSignIn(multiFactorAssertion);
            setCodeModal(false);
        } catch (e) {
            setErrorCode(true);
        }

        try {
            await window.fb.default.auth().currentUser.updatePassword(password);
            setIsChangedPassword(true);
            resetForm({});
        } catch (e) {
            setErrors({
                new_password: " ",
            });
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                }}
                validate={(values) => {
                    const errors: any = {};
                    const { current_password, new_password, confirm_password } = values;
                    const {
                        invalidEmptyPassword,
                        invalidEmptyCurrentPassword,
                        invalidEmailMatchPassword,
                        weakPassword,
                        invalidPasswordMatch,
                    } = window.locales;
                    if (current_password.length === 0) errors.current_password = invalidEmptyCurrentPassword;
                    if (!new_password) errors.new_password = invalidEmptyPassword;
                    if (new_password === name) errors.new_password = invalidEmailMatchPassword;
                    if (!confirm_password) errors.confirm_password = invalidEmptyPassword;
                    if (new_password.length < 8) errors.new_password = weakPassword;
                    if (confirm_password !== new_password) errors.confirm_password = invalidPasswordMatch;
                    setIsChangedPassword(false);
                    setIsResetPassword(false);
                    return errors;
                }}
                onSubmit={async (values, { setErrors, resetForm }) => {
                    const { current_password, new_password } = values;
                    const { data } = await isValidPwd(name, new_password);
                    data
                        ? await goToSignIn(name, current_password, setErrors, resetForm)
                        : setErrors({ new_password: window.locales.weakPassword });
                }}
            >
                {({ values, handleSubmit, dirty, isValid, setErrors, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalCode
                            isError={isErrorCode}
                            closeModal={() => setCodeModal(false)}
                            isOpen={codeModal}
                            applyForm={(code: string) => {
                                setErrorCode(false);
                                updatePassword(code, values.new_password, setErrors, resetForm).then();
                            }}
                        />
                        <div className={profileStyle.titleSection}>
                            {window.locales.changePassword}
                            <div
                                className={twoFactoryAuthStyle.resetButton}
                                onClick={async () => {
                                    await resetPassword(name);
                                    name && setIsResetPassword(true);
                                }}
                            >
                                {window.locales.reset}
                            </div>
                            <If condition={isResetPassword}>
                                <div className={profileStyle.notificationContainer}>
                                    <CheckMark />
                                    {window.locales.descriptionCheckMailbox}
                                </div>
                            </If>
                        </div>
                        <div className={profileStyle.fieldsContainer}>
                            <Field
                                component={TextField}
                                type="password"
                                name="current_password"
                                autoComplete="current-password"
                                placeholder="Current password"
                            />
                            <Field
                                component={TextField}
                                type="password"
                                name="new_password"
                                autoComplete="new-password"
                                placeholder="New password"
                            />
                            <Field
                                component={TextField}
                                type="password"
                                autoComplete="new-password"
                                name="confirm_password"
                                placeholder="Repeat new password"
                            />
                        </div>
                        <div className={profileStyle.buttonContainer}>
                            <button className={buttonStyle.buttonSubmit} type="submit" disabled={!(isValid && dirty)}>
                                {window.locales.save}
                            </button>
                            <If condition={isChangedPassword}>
                                <div className={profileStyle.notificationContainer}>
                                    <CheckMark />
                                    {window.locales.passwordChanged}
                                </div>
                            </If>
                        </div>
                    </form>
                )}
            </Formik>
            <div ref={captchaRef} />
        </>
    );
};
