import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { ServerType } from "@screens/Pools/Pool/Servers/Servers.types";
import { accumulateServers } from "@screens/Pools/Pool/Servers/Servers.utils";
import RootState from "@state/interfaces";
import { getObjectByKindRequest } from "@services/Objects";
import { GENERAL_NAMESPACE, ObjectEnum, ResultResponse } from "@screens/Objects/Object/Object.constants";
import base64ToJson from "@common/methods/base64toJSON";
import { useFilteredData } from "@common/hooks/useFilteredData";

const filterFunction =
    (searchValue: string) =>
    ({ name }: ServerType): boolean =>
        name.toLowerCase().includes(searchValue) || name.includes(searchValue);

const useServer = (getData: () => Promise<void>) => {
    const account = useSelector((state: RootState) => state.account);
    const [selectedServerStatus, setSelectedServerStatus] = useState<Record<string, unknown> | null>(null);
    const { setData, setSearch, filteredList } = useFilteredData<ServerType>(filterFunction);

    const setSelectedServer = useCallback(async (serverName: string) => {
        const { data } = await getObjectByKindRequest({
            kind: ObjectEnum.NODE_STATUS,
            name: serverName,
            namespace: GENERAL_NAMESPACE,
        });

        if (data && data.result === ResultResponse.GET_RESULT_OK) {
            const serverStatus = base64ToJson(data.object.data);
            setSelectedServerStatus(serverStatus);
        }
    }, []);

    const resetSelectedServerStatus = () => setSelectedServerStatus(null);

    useEffect(() => {
        if (!account.authorized) return;
        getData().then();
    }, [account.authorized, getData]);

    const searchCallback = useCallback(
        (value: string) => {
            setSearch(value);
        },
        [setSearch],
    );

    const tableData = useMemo(
        () => accumulateServers(filteredList, setSelectedServer),
        [filteredList, setSelectedServer],
    );

    return {
        tableData,
        searchCallback,
        setGroupsServers: setData,
        selectedServerStatus,
        resetSelectedServerStatus,
    };
};

export default useServer;
