import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import ReactGA from "react-ga";

import { firebaseConfig } from "@common/config";
import { changeCss } from "@common/methods";

const getInfoFromFirebase = (uid: string) =>
    new Promise((resolve) => {
        window.fb.default
            .database()
            .ref(`/users/${uid}`)
            .once("value")
            .then((result: any) => {
                result = result && result.val ? result.val() : {};
                return resolve(result);
            });
    });

const InitApp = () => {
    if (window.fb) return;
    ReactGA.initialize("G-VBF9BXR3GM");
    ReactGA.pageview(window.location.pathname + window.location.search);

    // TODO: Old version of style
    changeCss();

    window.fb = {
        default: firebase,
        custom: {},
    };

    window.fb.default.initializeApp(firebaseConfig);

    window.fb.default.auth().useDeviceLanguage();
};

export { getInfoFromFirebase };

export default InitApp;
