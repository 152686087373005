import { createSlice, current } from "@reduxjs/toolkit";

import { TPool, TPools } from "@screens/Network/types";

const initialState = {
    privatePools: [],
    publicPools: [],
    pool: {
        description: "",
        name: "",
        version: 0,
        locations: [],
        permissions: [],
        groups: [],
    },
};

const poolsSlice = createSlice({
    name: "pools",
    initialState,
    reducers: {
        getPool(state: TPools, action) {
            const { privatePools, publicPools } = current(state);
            const [privetPool] = privatePools.filter((pool: TPool) => pool.name === action.payload);
            const [publicPool] = publicPools.filter((pool: TPool) => pool.name === action.payload);
            return {
                ...state,
                pool: privetPool ?? publicPool,
            };
        },
        getPools() {},
        setPools(state, action) {
            return action.payload;
        },
        duplicatePool() {},
        removePool() {},
        changePool(state, action) {},
    },
});

export const { getPools, setPools, duplicatePool, removePool, getPool, changePool } = poolsSlice.actions;

export { initialState as initPools };

export default poolsSlice.reducer;
