import ReactGA from "react-ga";

const pageViewsTracking = (pathname: string) => {
    let pageView;

    if (pathname === "*") {
        pageView = "/not-found";
    } else {
        pageView = pathname;
    }

    ReactGA.pageview(pageView);
};

export { pageViewsTracking };
