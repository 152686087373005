import React, { Component } from "react";

class ExportingFailed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id ? props.id : "",
            width: props.width ? props.width : 20,
            height: props.height ? props.height : 20,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
        };

        this.callbacs = {};
        this.callbacs.onClick = props.onClick ? props.onClick : () => {};
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            id: props.id ? props.id : "",
            width: props.width ? props.width : 20,
            height: props.height ? props.height : 20,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
        });
    }

    onClick() {
        this.callbacs.onClick();
    }

    render() {
        const style = this.state.style;
        if (!style.height) style.height = `${this.state.height}px`;

        return (
            <div
                id={this.state.id}
                className={`icons addUser ${this.state.theme}${this.state.label ? " flex" : ""}`}
                onClick={this.onClick.bind(this)}
                style={style}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={this.state.width}
                    height={this.state.height}
                    viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                >
                    <path
                        fill="#61D26F"
                        fillRule="nonzero"
                        d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm3.277-13.638l1.628 1.161-4.77 6.695-4.25-3.623 1.298-1.522 2.589 2.207 3.505-4.918z"
                    />
                </svg>
                {this.state.label ? <div className="label">{this.state.label}</div> : ""}
            </div>
        );
    }
}

export default ExportingFailed;
