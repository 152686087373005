import { Field, Formik } from "formik";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { If } from "@components/If";
import RootState from "@state/interfaces";
import { updateAccount } from "@state/account";
import * as style from "@screens/Profile/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { TextField, CustomSelect } from "@components/FormikFields";
import { optionsCountry, optionsState } from "@common/optionsState";
import { IInfoFirebase } from "@state/account/types";

const defaultInfo = {
    company: "",
    last_name: "",
    company_code: "",
    projectPrefix: "",
    first_name: "",
    city: "",
    country: "",
    state: "",
};

const projectPrefixCode = (infoFromFirebase: IInfoFirebase) =>
    infoFromFirebase.projectPrefix ||
    infoFromFirebase.company_code ||
    infoFromFirebase.company?.replace(/\s/g, "").toLowerCase().substring(0, 3);

const getProperties = (infoFromFirebase: IInfoFirebase) =>
    infoFromFirebase
        ? {
              company: infoFromFirebase.company || "",
              last_name: infoFromFirebase.last_name || "",
              company_code: infoFromFirebase.company_code || "",
              projectPrefix: projectPrefixCode(infoFromFirebase),
              first_name: infoFromFirebase.first_name || "",
              city: infoFromFirebase.city || "",
              country: infoFromFirebase.country || "",
              state: infoFromFirebase.state || "",
          }
        : defaultInfo;

const Main = () => {
    const dispatch = useDispatch();
    const { name, infoFromFirebase } = useSelector((state: RootState) => state.account);

    const { company, last_name, first_name, projectPrefix, country, city, state } = useMemo(
        () => getProperties(infoFromFirebase),
        [infoFromFirebase],
    );

    return (
        <If condition={!!name}>
            <Formik
                initialValues={{
                    company_name: company,
                    last_name,
                    first_name,
                    city,
                    country,
                    state,
                    email: name,
                    project_prefix: projectPrefix,
                }}
                enableReinitialize
                validate={(values) => {
                    const { first_name, last_name, company_name, project_prefix } = values;
                    const {
                        invalidCompanyName,
                        invalidFirstName,
                        incorrectProjectPrefix,
                        invalidLastName,
                        invalidProjectPrefix,
                    } = window.locales;
                    const errors: any = {};
                    if (!first_name) errors.first_name = invalidFirstName;
                    if (!last_name) errors.last_name = invalidLastName;
                    if (!company_name) errors.company_name = invalidCompanyName;
                    if (!project_prefix) errors.project_prefix = invalidProjectPrefix;
                    if (project_prefix?.length > 8) errors.project_prefix = incorrectProjectPrefix;

                    return errors;
                }}
                onSubmit={(values) => {
                    const { company_name, last_name, first_name, city, country, state, project_prefix } = values;
                    dispatch(
                        updateAccount({
                            company: company_name,
                            last_name,
                            projectPrefix: project_prefix,
                            first_name,
                            city,
                            country,
                            state,
                        }),
                    );
                }}
            >
                {({ values, handleSubmit, dirty, isValid }) => (
                    <form onSubmit={handleSubmit}>
                        <section>
                            <div className={style.titleSection}>Personal data</div>
                            <div className={style.fieldsContainer}>
                                <Field component={TextField} disabled type="text" name="email" placeholder="Email" />
                                <Field component={TextField} type="text" name="first_name" placeholder="First Name" />
                                <Field component={TextField} type="text" name="last_name" placeholder="Last Name" />
                                <Field
                                    component={TextField}
                                    type="text"
                                    name="company_name"
                                    placeholder="Company Name"
                                />
                                <Field
                                    component={TextField}
                                    maxLength={8}
                                    type="text"
                                    name="project_prefix"
                                    placeholder="Project prefix"
                                />
                            </div>
                        </section>
                        <section>
                            <div className={style.titleSection}>Location data</div>
                            <div className={style.fieldsContainer}>
                                <Field
                                    name="country"
                                    selected={values.country}
                                    placeholder="Country"
                                    component={CustomSelect}
                                    options={optionsCountry}
                                />
                                <If condition={values.country !== "US"}>
                                    <Field
                                        component={TextField}
                                        value={values.state}
                                        type="text"
                                        name="state"
                                        placeholder="State"
                                    />
                                </If>
                                <If condition={values.country === "US"}>
                                    <Field
                                        component={CustomSelect}
                                        name="state"
                                        selected={values.state}
                                        placeholder="State"
                                        options={optionsState}
                                    />
                                </If>
                                <Field component={TextField} type="text" name="city" placeholder="City" />
                            </div>
                        </section>
                        <div className={style.buttonContainer}>
                            <button className={buttonStyle.buttonSubmit} type="submit" disabled={!(isValid && dirty)}>
                                {window.locales.save}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </If>
    );
};

export default Main;
