import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import Table from "@components/Table";
import { useFilteredData } from "@common/hooks/useFilteredData";
import { ObjectType } from "@screens/Objects/Objects.types";
import { getObjectsRequest } from "@services/Objects";
import { ObjectEnum } from "@screens/Objects/Object/Object.constants";
import { RootState } from "@state/index";
import { accumulateProjects, projectHeader } from "@screens/Auth/AuthProjects.utils";
import * as commonStyle from "@common/style/style.scss";

const filterFunction =
    (searchValue: string) =>
    ({ metadata: { name, hash } }: ObjectType): boolean =>
        name.toLowerCase().includes(searchValue) || hash.includes(searchValue);

function AuthProjects() {
    const account = useSelector((state: RootState) => state.account);
    const navigate = useNavigate();
    const { setData, setSearch, filteredList } = useFilteredData<ObjectType>(filterFunction);
    const tableData = useMemo(() => accumulateProjects(filteredList, navigate), [filteredList, navigate]);
    const getProjects = useCallback(async () => {
        const { data = { objects: [] } } = await getObjectsRequest({
            kind: ObjectEnum.NAMESPACE,
            metadata_only: true,
        });
        const { data: dataAuthConfig = { objects: [] } } = await getObjectsRequest({
            kind: ObjectEnum.PROJECT_AUTH_CONFIG,
            metadata_only: true,
            all_namespaces: true,
        });
        if (!data && !dataAuthConfig) return;

        const listAuth = dataAuthConfig.objects
            .filter((project) => project.metadata.name === "default")
            .map((project) => project.metadata.namespace);
        const authProjects: ObjectType[] = data.objects
            .filter((project) => listAuth.includes(project.metadata.name))
            .map((project) => ({
                ...project,
                data: "this project with Auth",
            }));

        const projects = data.objects.filter((project) => !listAuth.includes(project.metadata.name));

        data && setData([...authProjects, ...projects]);
    }, [setData]);

    useEffect(() => {
        if (account.authorized) {
            getProjects().then();
        }
    }, [account.authorized, getProjects]);

    return (
        <div className={commonStyle.mainPageContainer}>
            <Table
                tableData={tableData}
                headings={projectHeader}
                title="projects"
                emptyMessage={window.locales.noProjects}
            >
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={setSearch} />
                    </div>
                </div>
            </Table>
        </div>
    );
}

export default AuthProjects;
