import React, { useEffect, useState } from "react";
import VerificationInput from "react-verification-input";

import * as style from "@screens/Landing/Authorization/InputCode/style.scss";

const InputCode = ({ submit, isError }: { submit: (code: string) => void; isError: boolean }) => {
    const [value, setValue] = useState("");
    useEffect(() => {
        if (isError) {
            setTimeout(() => setValue(""), 3000);
        }
    }, [isError]);

    return (
        <div className={style.codeContainer}>
            <VerificationInput
                autoFocus
                value={value}
                classNames={{
                    container: style.container,
                    character: `${style.character} ${isError && style.error}`,
                    characterFilled: style.character__filled,
                    characterInactive: style.character__inactive,
                    characterSelected: style.character__selected,
                }}
                onChange={setValue}
                length={6}
                validChars="/^\d+$/"
                placeholder="0"
                onComplete={submit}
            />
        </div>
    );
};

export { InputCode };
