import { TableObjectProps } from "@components/Table/types";

const headingUserEntitlements = [
    {
        isSort: true,
        heading: window.locales.name,
    },
    {
        isSort: true,
        heading: window.locales.reducer,
    },
];

type UserEntitlementsTableType = {
    name: string;
    reducer: string;
    buttons?: TableObjectProps;
    event?: () => void;
};

const accumulateUserEntitlements = (objects: [string, string][]): UserEntitlementsTableType[] =>
    objects.map((entitlement: [string, string]) => {
        const [name, reducer] = entitlement;

        return {
            name,
            reducer,
        };
    });

export { headingUserEntitlements, accumulateUserEntitlements };
