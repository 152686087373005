import { TableObjectProps } from "@components/Table/types";

type SelectorType = Record<string, Record<string, string[] | unknown>>;

interface IPool {
    description: "";
    groups: any[];
    locations: any;
    name: string;
    permissions: IPermission[];
    selector: SelectorType;
    version: number;
}

interface IPermission {
    subject: string;
    can_read: boolean;
    can_write: boolean;
    can_use: boolean;
    can_list: boolean;
    list?: string[];
}

enum TypePool {
    Private = "private",
    Public = "public",
}

type TRowPool = {
    type: TypePool;
    name: string;
    request_selector: any;
    selector: any;
    description: string;
    event: () => void;
    buttons: TableObjectProps;
};

export type { IPool, IPermission, TRowPool, SelectorType };

export { TypePool };
