import countryData from "country-data";

export default (country) => {
    let name;
    try {
        name = countryData.countries[country.toUpperCase()].name;
    } catch (e) {
        name = country;
    }

    return name;
};
