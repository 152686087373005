enum MFAActions {
    SentCode,
    EnableMFA,
    DisableMFA,
    DisableTimer,
    EditPhone,
    CancelMFA,
}

export { MFAActions };
