export default (bytes) => {
    if (typeof bytes !== "number") return undefined;

    let multiplier = "";
    if (bytes < 0) {
        bytes *= -1;
        multiplier = "-";
    }

    if (bytes / (1024 * 1024 * 1024) > 1) {
        return {
            size: multiplier + (bytes / (1024 * 1024 * 1024)).toFixed(2),
            type: "Gb",
        };
    }
    if (bytes / (1024 * 1024) > 1) {
        return {
            size: multiplier + (bytes / (1024 * 1024)).toFixed(2),
            type: "Mb",
        };
    }
    if (bytes / 1024 > 1) {
        return {
            size: multiplier + (bytes / 1024).toFixed(2),
            type: "Kb",
        };
    }

    return {
        size: multiplier + bytes.toString(),
        type: "B",
    };
};
