import { makeAutoObservable } from "mobx";

class Progress {
    lengthRequests = 0;

    constructor() {
        makeAutoObservable(this);
    }

    resetLength() {
        this.lengthRequests = 0;
    }

    increaseLength() {
        this.lengthRequests += 1;
    }

    decreaseLength() {
        if (this.lengthRequests > 0) {
            this.lengthRequests -= 1;
        }
    }
}

export const progress = new Progress();
