import React, { ChangeEvent, useCallback, useState, useRef, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";

import { validationDomain } from "@common/methods/сheckDomain";
import { IconClose } from "@common/icons";
import * as fireshieldStyle from "@screens/Settings/Fireshield/style.scss";

import { IDomainInputProps } from "./types";
import { FieldInput } from "../../../components";

const DomainInput = ({ domainsUrl, setDomainsUrl, domain }: IDomainInputProps) => {
    const [updateDomainsUrl, setUpdateDomainsUrl] = useState(domainsUrl);
    const prevDomainsUrlRef = useRef<{ [p: string]: string[] } | null>(null);
    const [fieldValue, setFieldValue] = useState("");

    useEffect(() => {
        prevDomainsUrlRef.current = updateDomainsUrl;
    }, [updateDomainsUrl]);

    const changeDomainsUrl = useCallback(
        (e: ChangeEvent<HTMLInputElement>, index: number) => {
            setFieldValue("");
            let cloneDomainsUrl = updateDomainsUrl;
            if (e.currentTarget.value) {
                cloneDomainsUrl = cloneDeep(domainsUrl);
                cloneDomainsUrl[e.currentTarget.name][index] = e.currentTarget.value;
                setDomainsUrl(cloneDomainsUrl);
                setUpdateDomainsUrl(cloneDomainsUrl);
            }
        },
        [domainsUrl, setDomainsUrl, updateDomainsUrl],
    );

    const changeFieldDomain = useCallback(
        (e: ChangeEvent<HTMLInputElement>, index: number) => {
            const cloneDomainsUrl = cloneDeep(updateDomainsUrl);
            cloneDomainsUrl[e.currentTarget.name][index] = e.currentTarget.value;
            e.currentTarget.value = "";
            setUpdateDomainsUrl(cloneDomainsUrl);
            setDomainsUrl(cloneDomainsUrl);
        },
        [updateDomainsUrl, setDomainsUrl],
    );

    const deleteInput = (value: string, index: number) => {
        const domains: { [key: string]: string[] } = prevDomainsUrlRef.current ? prevDomainsUrlRef.current : {};
        const urls: string[] = domains && domains[value].filter((item: string, i: number) => i !== index && item);

        setDomainsUrl((prevState: any) => ({
            ...prevState,
            [domain]: urls,
        }));

        setUpdateDomainsUrl((prevState: any) => ({
            ...prevState,
            [domain]: urls,
        }));
    };

    return (
        <>
            {updateDomainsUrl[domain].map((url: string, index: number) => (
                <div className={fireshieldStyle.containerWithIcon} key={index}>
                    <FieldInput
                        autoComplete="off"
                        maxLength={255}
                        $isInvalid={!validationDomain(url)}
                        onChange={(e) => changeFieldDomain(e, index)}
                        onBlur={(e) => changeDomainsUrl(e, index)}
                        name={domain}
                        value={updateDomainsUrl[domain][index]}
                        placeholder="example.com"
                    />
                    <div className={fireshieldStyle.iconWrapper}>
                        <IconClose onClick={() => deleteInput(domain, index)} />
                    </div>
                </div>
            ))}
            <FieldInput
                value={fieldValue}
                onChange={(e) => setFieldValue(e.currentTarget.value)}
                autoComplete="off"
                maxLength={255}
                $isInvalid={false}
                onBlur={(e) => changeDomainsUrl(e, updateDomainsUrl[domain].length)}
                name={domain}
                placeholder="example.com"
            />
        </>
    );
};

export { DomainInput };
