import { createSlice } from "@reduxjs/toolkit";

const permissionsSlice = createSlice({
    name: "payments",
    initialState: [],
    reducers: {
        getPayments() {},
        setPayments(state, action) {
            return action.payload;
        },
    },
});

export const { setPayments, getPayments } = permissionsSlice.actions;

export default permissionsSlice.reducer;
