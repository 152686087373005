import React, { memo } from "react";
import { NavLink } from "react-router-dom";

import * as commonStyle from "@common/style/style.scss";

const HeaderVPN = memo(() => (
    <>
        <div className={commonStyle.underTabsContainer}>
            <NavLink
                to="overall"
                className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
            >
                Overall
            </NavLink>
            <NavLink
                to="bypass"
                className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
            >
                VPN Bypass list
            </NavLink>
            <NavLink
                to="networks"
                className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
            >
                Client Networks
            </NavLink>
            <NavLink
                to="sni"
                className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
            >
                SNI config
            </NavLink>
            <NavLink
                to="pool"
                className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
            >
                Pool selection rules
            </NavLink>
            <NavLink
                to="offload"
                className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
            >
                Offload config
            </NavLink>
        </div>
    </>
));

export default HeaderVPN;
