import React, { ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";

import { getParameterByName } from "@common/getParameterByName";
import { EAuthorizationStatus, ECode } from "@interfaces";
import { Pending } from "@screens/Panding";
import { AuthContext } from "@common/auth-context";

const OktaSignIn = (): ReactElement => {
    const [authorizationStatus] = useContext(AuthContext);
    const actionCodeOktaState = getParameterByName(ECode.State);
    if (!actionCodeOktaState) {
        return <Navigate replace to="/" />;
    }

    if (EAuthorizationStatus.Authorized === authorizationStatus) {
        return <Navigate replace to="/projects" />;
    }

    return <Pending />;
};

export default OktaSignIn;
