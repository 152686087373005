enum ExportModals {
    userModal,
    devicesModal,
    sessionsModal,
    closeModals,
}

interface IExportModal {
    userModal: boolean;
    devicesModal: boolean;
    sessionsModal: boolean;
}

type TExportAction = {
    type: ExportModals;
    payload: any;
};

type IMask = { users: string; devices: string; sessions: string };

type TForm = {
    days: {
        from: undefined | string;
        till: undefined | string;
    };
    time: {
        from: string;
        till: string;
    };
};

export type { TExportAction, IExportModal, IMask, TForm };
export { ExportModals };
