import React from "react";

import { ChangePassword } from "@screens/Profile/AccountSecurity/ChangePassword";
import { TwoFactoryAuthForm } from "@screens/Profile/AccountSecurity/TwoFactoryAuth";

const AccountSecurity = () => (
    <>
        <section>
            <TwoFactoryAuthForm />
        </section>
        <section>
            <ChangePassword />
        </section>
    </>
);

export default AccountSecurity;
