import { TableObjectProps } from "@components/Table/types";

type TInvite = {
    project_id: string;
    user_email: string;
    admin_role: string;
    expire_time: number;
    code: string;
};

type TRow = {
    email: string;
    role: string;
    inviteStatus: TableObjectProps | string;
    clientSupport?: TableObjectProps | string;
    buttons?: TableObjectProps;
};

enum EModals {
    EditMember,
    DeleteMember,
    DeleteInvite,
    ResendInvite,
    CreateInvite,
    CloseModals,
}

type TAction = {
    type: EModals;
    payload: any;
};

interface IMember {
    email: string;
    role?: string;
    code?: string;
    supports?: ESupport[];
}

interface IMemberModal {
    editMemberModal: boolean;
    deleteMemberModal: boolean;
    deleteInviteModal: boolean;
    resendInviteModal: boolean;
    createInviteModal: boolean;
    member: IMember | null;
}

enum ESupport {
    IOs = "ios",
    Android = "android",
    MACOs = "macos",
    Windows = "windows",
}

export type { TRow, TInvite, IMember, IMemberModal, TAction };

export { EModals, ESupport };
