import axios, { AxiosResponse } from "axios";

import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { deleteTokenInStorage, TokenType } from "@common/tokenStorage";

axios.defaults.baseURL = `/api/${process.env.CURRENT_API}/`;

interface IResponseToken {
    result: string;
    token: TokenType;
}

const requestCheckProvider = async (
    email: string,
    redirect_url: string,
    invite_code?: string,
): Promise<
    AxiosResponse<{
        result: string;
        redirect_url: string | undefined;
    }>
> => {
    try {
        return await axios.post(
            "portal/login/start",
            {
                email,
                redirect_url,
                invite_code,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    } catch (e: any | IErrorResponse) {
        const error: IErrorResponse = e;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });

        throw Error(e);
    }
};

const requestGetToken = async (code: string, state: string): Promise<AxiosResponse<IResponseToken>> => {
    try {
        return await axios.post(
            "portal/login/finish",
            {
                code,
                state,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    } catch (e: any | IErrorResponse) {
        const error: IErrorResponse = e;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });

        throw Error(e);
    }
};

const requestRefreshToken = async (refresh_token: string): Promise<AxiosResponse<IResponseToken>> => {
    try {
        return await axios.post(
            "portal/login/refresh_token",
            {
                refresh_token,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    } catch (e: any | IErrorResponse) {
        if (e.response.status === 401) {
            deleteTokenInStorage();
            window.location.replace(window.location.origin);
        }

        const error: IErrorResponse = e;
        errorInfo.setErrorInfo({
            title: error.code,
            description: error.message,
        });

        throw Error(e);
    }
};

export { requestCheckProvider, requestGetToken, requestRefreshToken };
