import { getFirebaseTokenFromState } from "@common/getFirebaseTokenFromState";
import Network from "@common/Network";
import { GraphDataType } from "@screens/Dashboard/GeoChart/GeoChart.types";

export const QUERY_RANGE_REQUEST = "portal/metric/query_range";
export const GetMetricsRequest = async (
    publickey: string,
    query: string,
    { startDate, endDate }: { startDate: Date; endDate: Date },
): Promise<GraphDataType> => {
    const access_token = await getFirebaseTokenFromState();
    const oneDay = 24 * 60 * 60 * 1000;
    const days = Math.round(Math.abs((Number(startDate) - Number(endDate)) / oneDay));

    const { response } = await Network.get(
        QUERY_RANGE_REQUEST,
        {
            publickey,
            query,
            start: Number(startDate) / 1000,
            end: Number(endDate) / 1000,
            step: days ? days * 2000 : 1000,
        },
        access_token,
    );

    return response.data.result;
};
