import React, { Component } from "react";

class Upload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id ? props.id : "",
            width: props.width ? props.width : 21,
            height: props.height ? props.height : 21,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
        };

        this.callbacs = {};
        this.callbacs.onClick = props.onClick ? props.onClick : () => {};
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            id: props.id ? props.id : "",
            width: props.width ? props.width : 21,
            height: props.height ? props.height : 21,
            theme: props.theme ? props.theme : "default",
            label: props.label ? props.label : undefined,
            style: props.style ? props.style : {},
        });
    }

    onClick() {
        this.callbacs.onClick();
    }

    render() {
        const style = this.state.style;
        if (!style.height) style.height = `${this.state.height}px`;

        return (
            <div
                id={this.state.id}
                className={`icons iconsStyle1 addUser ${this.state.theme}${this.state.label ? " flex" : ""}`}
                onClick={this.onClick.bind(this)}
                style={style}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={this.state.width}
                    height={this.state.height}
                    viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                >
                    <path d="M18.516 12.602v5.158c0 .41-.333.742-.743.742H3.227a.743.743 0 0 1-.743-.742v-5.158H1v5.158a2.23 2.23 0 0 0 2.227 2.227h14.546A2.23 2.23 0 0 0 20 17.76v-5.158h-1.484z" />
                    <path d="M10.5 15.422L5.888 10.81l1.05-1.05 2.82 2.82V1.014h1.484v11.568l2.82-2.82 1.05 1.05z" />
                </svg>
                {this.state.label ? <div className="label">{this.state.label}</div> : ""}
            </div>
        );
    }
}

export default Upload;
