import styled from "styled-components";

const DownloadContainer = styled.span`
    cursor: pointer;

    svg {
        path {
            fill: ${({ theme }) => theme.colors.backlight};
        }
    }
`;

export { DownloadContainer };
