import styled from "styled-components";

import { Colors } from "@common/style";
import { IInput } from "@interfaces";

const { landingBlue } = Colors;

const FieldInput = styled.input<IInput>`
    font-weight: 400;
    outline: 0;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    padding: 12px;
    background-color: #303340;
    color: #ffffff;
    box-sizing: border-box;
    border: ${({ $isInvalid }) => ($isInvalid ? "solid 1px #D40000" : "unset")};

    &:focus {
        border-color: ${landingBlue};
    }

    &::placeholder {
        color: #ffffff32;
    }

    &:disabled {
        background-color: #1a1c23;
        color: #ffffff33;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: #ffffff;
        -webkit-box-shadow: 0 0 0 1000px #303340 inset;
    }
`;

export { FieldInput };
