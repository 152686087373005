import Network from "@common/Network";
import { getPortalTokenFromState } from "@common/getPortalTokenFromState";
import { ISession } from "@screens/Users/User/Content/Sessions/types";

const loadSessions = async (uid: string, from: number, till: number): Promise<ISession[]> => {
    const request = `partner/subscribers/${uid}/sessions`;
    const access_token = getPortalTokenFromState();
    const params = {
        start_time: from,
        end_time: till,
    };

    return Network.get(request, params, access_token)
        .then((response) => response.response.sessions.sort((a: ISession, b: ISession) => a.end_time - b.end_time))
        .catch(() => []);
};

const loadActiveSessions = async (uid: string): Promise<ISession[]> => {
    const request = `partner/subscribers/${uid}/active_sessions`;
    const access_token = getPortalTokenFromState();
    return Network.get(request, {}, access_token)
        .then((response) => response.response.sessions)
        .catch(() => []);
};

const loadProjectActiveSessions = () =>
    new Promise((resolve) => {
        const request = "partner/active_sessions";
        const access_token = getPortalTokenFromState();

        return Network.get(request, {}, access_token)
            .then((response) => resolve(response.response.sessions))
            .catch(() => resolve([]));
    });

export { loadSessions, loadActiveSessions, loadProjectActiveSessions };
