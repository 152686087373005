import { all } from "redux-saga/effects";
import { Effect } from "@redux-saga/core/effects";

import { UsersSagas } from "@state/users/sagas";
import { PaymentsSagas } from "@state/payments/sagas";
import { PurchasesSagas } from "@state/purchases/sagas";
import { PoolsSagas } from "@state/pools/sagas";
import { AccountSagas } from "@state/account/sagas";

import { ProjectsSagas } from "./projects/sagas";
import { TokenSagas } from "./token/sagas";

const sagas: Array<Effect> = [];
export default function* rootSaga() {
    yield all(
        sagas.concat(TokenSagas, ProjectsSagas, UsersSagas, AccountSagas, PaymentsSagas, PurchasesSagas, PoolsSagas),
    );
}
