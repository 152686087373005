import { Field, Formik } from "formik";
import React from "react";

import { Modal } from "@components/Modal";
import { TextField } from "@components/FormikFields";

const ModalPassword = ({
    isOpen,
    closeModal,
    email,
    applyForm,
}: {
    email: string;
    isOpen: boolean;
    closeModal: () => void;
    applyForm: (data: any) => void;
}) => (
    <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title="Confirm access"
        confirm={{
            label: window.locales.confirm,
            onConfirm: () => {},
        }}
        /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
        typeButton="submit"
        nameForm="passwordForm"
        disabled={false}
    >
        <Formik
            initialValues={{
                password: "",
            }}
            validate={({ password }) => {
                const errors: any = {};
                if (password.length < 8) errors.password = window.locales.weakPassword;
                return errors;
            }}
            onSubmit={({ password }) => {
                const { currentUser } = window.fb.default.auth();
                const credential = window.fb.default.auth.EmailAuthProvider.credential(email, password);
                applyForm(currentUser.reauthenticateWithCredential(credential));
            }}
        >
            {({ values, handleSubmit }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="passwordForm" onSubmit={handleSubmit}>
                    <section>
                        <Field component={TextField} placeholder="Password" type="password" name="password" />
                    </section>
                </form>
            )}
        </Formik>
    </Modal>
);

export { ModalPassword };
