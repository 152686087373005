import React from "react";

import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";

const SuccessSentReset = ({ email }: { email: string }) => (
    <div className={authorizationStyle.formCenter}>
        <div className={authorizationStyle.signFormContainer}>
            <div className={authorizationStyle.titleContainer}>
                <div className={authorizationStyle.title}>Great!</div>
                <div className={authorizationStyle.text}>
                    We’ve sent an email with instructions to <span>{email}</span>
                </div>
            </div>
            <div className={authorizationStyle.infoContainer}>
                <span>Can&apos;t find email?</span>
                <a href="mailto: partners@aura.com" target="blank">
                    Contact us
                </a>
            </div>
        </div>
    </div>
);

export { SuccessSentReset };
